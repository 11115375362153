import {useQuery} from "react-query";
import {KVPair, ListPage, Order, ServerError} from "../models/types";
import {getOrder, getOrders} from "../restapi/order";


export function useRetrieveOrder({id, onError}: {id: number, onError?: (e: ServerError) => void}): Order | undefined {
    const orderQuery = useQuery(['Order'], () => getOrder(id), {
        onError: onError,
        retry: false
    });
    return orderQuery.data;
}

export function useRetrieveOrders({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<Order> | undefined {
    const itemsQuery = useQuery(['Order', 'page'], () => getOrders(page, pageSize, filters, sorting), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}
