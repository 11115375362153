import {Button} from "@wfp/ui";
import {iconCaretLeft, iconCaretRight} from '@wfp/icons';
import i18n from "../../i18n";
import {dateParse} from "../../hooks/formats";

interface Props {
    week?: string;
    disabled: boolean;
    onClickNext: () => void;
    onClickPrev: () => void;
}

export function NavigationWeek(props: Props) {
    return (
        <div className='flex justify-center'>
            <Button
                kind='navigation'
                icon={i18n.dir() === 'ltr' ? iconCaretLeft : iconCaretRight}
                iconDescription="navigation"
                onClick={i18n.dir() === 'ltr' ? props.onClickPrev : props.onClickNext}
                className='mx-5'
                disabled={props.disabled}
            ></Button>
            <h1>{!!props.week ? dateParse(props.week) : ''}</h1>
            <Button
                kind='navigation'
                icon={i18n.dir() === 'ltr' ? iconCaretRight : iconCaretLeft}
                iconDescription="navigation"
                onClick={i18n.dir() === 'ltr' ? props.onClickNext : props.onClickPrev}
                className='mx-5'
                disabled={props.disabled}
            ></Button>
        </div>
    )
}
