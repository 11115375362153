import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FormWizard, Modal } from "@wfp/ui";
import i18n from "../../../i18n";
import { School, ServerError, Wizard } from "../../../models/types";
import { completeWizard, deleteWizard, saveWizard } from "../../../restapi/wizard";
import { useRetrieveSurveyWizard } from "../../../hooks/wizard";
import { LoadingCenter } from "../../commons/loading-center";
import { Navigationbar } from "./navigationbar"
import { PageTransferSchools } from "./page-transfer-school"
import { PageSurveySchools } from "./page-survey-schools";
import { Item, PageSnacks } from "./page-snacks";
import { PageSummary } from "./page-summary";
import { HeaderSurveybar } from "./headerbar";
import { useLoggedUser } from "../../../hooks/user";
import { dateFormat } from "../../../hooks/formats";
import moment from "moment";

interface WizardState {
    error: string | null,
    page: number,
    surveySchool: number,
    transferSchool: number,
    surveyDate: string,
    snacks: Item[],
}

export interface WizardError extends ServerError {
    surveySchool: string[] | undefined,
    transferSchool: string[] | undefined,
    snacks: string[][] | undefined,
    quantity: string[][] | undefined,
}

interface WizardPage {
    header: string,
    label: string,
    title: string,
    nextLabel?: string,
    previousLabel?: string,
    component?: (p: any) => React.ReactNode
}

export const surveyWizardPages: WizardPage[] = [
    {
        header: 'select_school_survey_header',
        title: 'select_school_survey_title',
        label: 'select_schools_survey_label',
    },
    {
        header: 'select_snacks_header_page',
        title: 'select_snacks_title_page',
        label: 'select_snacks_label_page'
    },
    {
        header: 'select_school_transfer_header',
        title: 'select_school_transfer_title',
        label: 'select_school_transfer_label',
    },
    {
        header: 'summary',
        title: 'confirm_order_page_title',
        label: 'summary',
        nextLabel: 'confirm',
    }
];

export function SurveyWizard() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [confirming, setConfirming] = useState<boolean>();
    const [message, setMessage] = useState<string>();
    const [page, setPage] = useState<number>();
    const [error, setError] = useState<WizardError>();
    const [stateModified, setStateModified] = useState<boolean>(false);
    const [wizard, setWizard] = useState<Wizard>();
    const initialWizard = useRetrieveSurveyWizard({ id: Number(id), onError: (e) => setError(e as WizardError) });
    const user = useLoggedUser({});

    useEffect(() => {
        if (!!initialWizard) {
            if (!initialWizard.id) {
                console.log('no wizard');
                const value = { data: { page: 0 }, title: 'survey' } as Wizard;
                storeWizard(value).then((res) => doSetPage(res.data.page));
            } else {
                setWizard(initialWizard);
                doSetPage(initialWizard.data?.page || 0);
            }
        }
    }, [initialWizard]);

    function storeWizardState(data: WizardState): Promise<WizardState> {
        const w = { ...wizard, data: data } as Wizard;
        return storeWizard(w).then((res) => {
            setStateModified(false);
            return Promise.resolve(res.data as WizardState);
        });
    }

    function storeWizard(w: Wizard): Promise<Wizard> {
        if (!w) {
            return Promise.reject({ errors: ["wizard is null"] } as ServerError);
        }
        return saveWizard('survey', w).then((res) => {
            setWizard(res);
            return Promise.resolve(res);
        });
    }

    function handleChangeSchool(school: School): void {
        if (wizard) {
            wizard.data.partner = user?.entity;
            wizard.data.surveySchool = school.id;
            const newError = doCheckStateError({ ...wizard.data, page: 0 });
            if (!doCheckStateError({ ...wizard.data, page: 0 })) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeSnacks(snacks: Item[]): void {
        if (wizard) {
            wizard.data.snacks = snacks.filter((s: Item) => s.id !== 0 || s.quantity > 0);
            const hasPreviousError = !!error;
            const newError = doCheckStateError({ ...wizard.data, page: 1 });
            if (hasPreviousError) {
                setError(newError); // update|remove the error displayed
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeTrasferSchool(school: School): void {
        if (wizard) {
            wizard.data.transferSchool = school.id;
            wizard.data.surveyDate = dateFormat(moment());
            if (!doCheckStateError({ ...wizard.data, page: 2 })) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleExitWizard() {
        setMessage('confirm_exit?');
    }

    function handlePreviousPage() {
        if (page !== undefined) {
            handleNavigateToPage(page - 1)
        }
    }

    function handleNextPage() {
        if (page !== undefined) {
            handleNavigateToPage(page + 1)
        }
    }

    function handleNavigateToPage(to: number): void {
        if (to === -1) {
            return handleExitWizard();
        }
        if (to < (page || 0)) {
            return doChangePage(to);
        }
        doValidatePage(page !== undefined ? page : wizard?.data?.page || 0).then((allow) => {
            if (allow) {
                if (to === surveyWizardPages.length) {
                    return handleConfirmWizard();
                } else {
                    doChangePage(to);
                }
            }
        }).catch(error => setError(error));
    }

    function handleModalConfirmation(flag: boolean) {
        if (!flag) {
            return setMessage(undefined);
        }
        if (!!wizard) {
            if (!!wizard.id) {
                return deleteWizard(wizard.id).then(() => navigate('/mobile'));
            }
            return navigate('/mobile');
        }
    }


    function handleConfirmWizard() {
        if (!!wizard) {
            setConfirming(true)
            completeWizard(wizard.id).then(
                (w) => navigate(`/mobile`)
            ).catch(
                (error) => {
                    setError(error)
                }
            ).finally(() => setConfirming(false));
        }
    }

    function doChangePage(p: number) {
        if (stateModified) {
            storeWizardState({ ...wizard?.data, page: p }).then((w) => doSetPage(w.page));
        } else {
            doSetPage(p);
        }
    }

    function doSetPage(p: number) {
        setError(undefined);
        setPage(p);
    }



    function doCheckStateError(data: WizardState): WizardError | undefined {
        let isValid: boolean;
        const page = data.page;
        let error: WizardError;
        switch (page) {
            case 0:
                isValid = !!data.surveySchool && data.surveySchool !== 0;
                error = {
                    errors: isValid ? undefined : ["select_at_least_one_school"]
                } as WizardError;
                break
            case 1:
                const snacks = (data.snacks || []).filter((s: Item) => s.id !== 0);
                const quantity = (data.snacks || []).filter((s: Item) => s.quantity > 0);
                isValid = !!snacks.length && snacks.length === 2;
                error = {
                    errors: isValid ? undefined : ["select_at_least_one_snack"]
                } as WizardError;
                const idSet = new Set();
                snacks.forEach((s, idx) => {
                    if (!s.id) {
                        if (!error.snacks) {
                            error.snacks = new Array<string[]>(snacks.length);
                        }
                        error.snacks[idx] = ['select_snack'];
                    }
                    if (!s.quantity && quantity.length === 0) {
                        if (!error.quantity) {
                            error.quantity = new Array<string[]>(snacks.length);
                        }
                        error.quantity[idx] = ['select_quantity'];
                    }
                    if (idSet.has(s.id)) {
                        if (!error.snacks) {
                            error.snacks = new Array<string[]>(snacks.length);
                        }
                        error.snacks[idx] = ['snack_already_selected'];
                    }
                    idSet.add(s.id);
                });
                isValid &&= !!error ? Object.values(error).every(f => !f) : true;
                break
            case 2:
                isValid = !!data.transferSchool && data.transferSchool !== 0 && data.transferSchool !== data.surveySchool;
                error = {
                    errors: isValid ? undefined : [!data.transferSchool ? "select_at_least_one_school" : "cannot_be_same_school_as_survey_school"]
                } as WizardError;
                break;
            case 3:
                error = {
                    ...doCheckStateError({ ...data, page: 2 }),
                    ...doCheckStateError({ ...data, page: 1 }),
                    ...doCheckStateError({ ...data, page: 0 })
                } as WizardError;
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break;
            default:
                isValid = false;
                error = { errors: ["invalid_page"] } as WizardError;
                break;
        }
        return isValid ? undefined : error;
    }

    function doValidatePage(page: number): Promise<boolean> {
        if (!!wizard) {
            const error = doCheckStateError({ ...wizard.data, page: page });
            setError(error);
            return Promise.resolve(!error);
        }
        return Promise.resolve(false);
    }

    return (
        <div className='m-10'>
            {!wizard && (
                <LoadingCenter />
            )}
            {!!wizard && (
                <>
                    <h1 className={`text-start mt-5 mb-5 ${i18n.dir() === 'ltr' ? 'mr-0' : 'mr-10'}`}>
                        {t(surveyWizardPages[page || 0].title)}
                    </h1>
                    <FormWizard className="survey mr-3 w-full"
                        sidebar={<></>}
                        formHeader={page !== undefined ?
                            <HeaderSurveybar page={page!} message={!!message ? t(message)! : undefined} errors={!!error?.errors ? [t(error?.errors.toString())!] : !!error?.detail ? [t(error?.detail.toString())!] : undefined} /> : null}//TODO fix i18n
                        formControls={page !== undefined ? <Navigationbar
                            previous={handlePreviousPage}
                            previousDisabled={confirming}
                            previousLabel={page === 0 ? t("exit")! : surveyWizardPages[page!].previousLabel}
                            next={handleNextPage}
                            nextDisabled={!!error || confirming}
                            nextLabel={surveyWizardPages[page!].nextLabel}
                        /> : null}
                    >
                        {page === 0 && (
                            <PageSurveySchools
                                selectedSchool={wizard?.data?.surveySchool}
                                onChange={handleChangeSchool}
                                error={error}
                            />
                        )}
                        {page === 1 && (
                            <PageSnacks
                                error={error}
                                selectedSnacks={wizard?.data?.snacks}
                                onChange={handleChangeSnacks}
                            />
                        )}
                        {page === 2 && (
                            <PageTransferSchools
                                error={error}
                                selectedSchool={wizard?.data?.transferSchool}
                                onChange={handleChangeTrasferSchool}
                            />
                        )}
                        {page === 3 && confirming && (
                            <LoadingCenter />
                        )}
                        {page === 3 && (
                            <PageSummary
                                snacks={wizard.data?.snacks || []}
                                transferSchool={wizard.data?.transferSchool || 0}
                                surveySchool={wizard.data?.surveySchool || 0}
                                error={error}
                            />
                        )}
                    </FormWizard>
                    {!!message && (
                        <div>
                            <Modal
                                hideClose={false}
                                onRequestSubmit={() => handleModalConfirmation(true)}
                                onRequestClose={() => handleModalConfirmation(false)}
                                onSecondarySubmit={() => handleModalConfirmation(false)}
                                open={!!message}
                                primaryButtonText={t("confirm") as string}
                                secondaryButtonText={t("cancel") as string}
                                type='info'
                            >{t(message)}</Modal>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
