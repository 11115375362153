import {useQuery} from "react-query";
import {Commodity, KVPair, ServerError, UnitOfMeasure} from "../models/types";
import {retrieveCommodities, retrieveUnitOfMeasure} from "../restapi/commodity";


export function useRetrieveCommodities({filters, sorting, onError}: { filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void }): Commodity[] | undefined {
    const commodityQuery = useQuery(['commodity'], () => retrieveCommodities(filters, sorting), {onError: onError});
    return commodityQuery.data;
}

export function useRetrieveUnitOfMeasure({filters, sorting, onError}: { filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void }): UnitOfMeasure[] | undefined {
    const unitOfMeasureQuery = useQuery(['measure'], () => retrieveUnitOfMeasure(filters, sorting), {onError: onError});
    return unitOfMeasureQuery.data;
}