import { OrderItemHistoryData, ServerError } from "../../models/types";
import { datetimeParse } from "../../hooks/formats";
import { useGetOrderItemChangeHistory } from "../../hooks/order-item";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Blockquote } from "@wfp/ui";

interface Props {
    data: OrderItemHistoryData;
    open: boolean;
}

export function OrderItemHistory(props: Props) {
    const { t } = useTranslation();
    const [historyError, setHistoryError] = useState<ServerError>();
    const historyChange = useGetOrderItemChangeHistory({code: props.data.codeValue, onError: (e) => setHistoryError(e)});
    const [isActive, setIsActive] = useState(props.open);

    return (
        <>
            {!!historyChange?.length ? (
                <div className="accordion-item">
                    <div className="cursor-pointer flex items-center" onClick={() => setIsActive(!isActive)}>
                        <div className="p-1 text-color-blue">{isActive ? '-' : '+'}</div>
                        <div className="accordion-title-label text-color-blue p-2">{t('change_history')}</div>
                    </div>
                    {isActive && <div className="shadow-xl p-3 rounded-md bg-slate-100">
                        <div className="grid grid-cols-12 gap-2 text-color-blue">
                            <div className="col-span-3">{t('date')}</div>
                            <div className="col-span-3">{t('author')}</div>
                            <div>{t('item1')}</div>
                            <div className="text-end">{t('am')}</div>
                            <div className="text-end">{t('pm')}</div>
                            <div className="ml-2">{t('item2')}</div>
                            <div className="text-end">{t('am')}</div>
                            <div className="text-end">{t('pm')}</div>
                        </div>
                        {(historyChange.map((item) => (
                            <div className="grid grid-cols-12 gap-2 mt-3" key={item.id}>
                                <div className="col-span-3">{datetimeParse(item.createdOn)}</div>
                                <div className="col-span-3">{item.authorName}</div>
                                <div>{item.data.objectName1}</div>
                                <div className="text-end">{item.data.quantityAm1}</div>
                                <div className="text-end">{item.data.quantityPm1}</div>
                                <div className="ml-2">{item.data.objectName2}</div>
                                <div className="text-end">{item.data.quantityAm2}</div>
                                <div className="text-end">{item.data.quantityPm2}</div>
                            </div>
                        ))
                        )}
                    </div>
                    }
                </div>
            ) : (
                <>
                {!!historyError ? (
                    <Blockquote kind="error" withIcon>{t('change_history_missing')} {historyError?.detail?.toString()}</Blockquote>
                ) : ''}
                </>
    
            )}
        </>
    );
}
