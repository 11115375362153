import {Supplier} from "../../models/types";
import {useTranslation} from "react-i18next";

export const SupplierCard = (props: { item: Supplier }) => {
    const {t} = useTranslation();

    return (
        <div className="flex flex-col space-y-3 w-full items-start">
            <div>
                {t('id')}: {props.item.id}
            </div>
            <h2 className="text-gray-800 text-3xl font-semibold">
                {t('name')} {props.item.name}
            </h2>
            <div>
                {t('phone')}: {props.item.phone}
            </div>
        </div>
    );
};
