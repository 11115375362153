import {buildQueryString, restapi} from "./restapi";
import {KVPair, ListPage, OrderHistoryData, SchoolMeal, SchoolMealHistoryData} from "../models/types";

export function discardSchoolMeal(id: number): Promise<SchoolMeal> {
    return restapi.post<SchoolMeal>(`/api/v1/smt_meal/school_meal/${id}/discard/`).then(res => res.data);
}


export function downloadReceipt(id: number): Promise<boolean> {
    return restapi.get(`/api/v1/smt_meal/school_meal/${id}/print/`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                //'Accept': 'application/pdf'
            }
        })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `waybill-cold-meal-${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            return true;
        });

}

export function getSchoolMeals(page = 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<SchoolMeal>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<SchoolMeal>>(`/api/v1/smt_meal/school_meal/?${qs}`).then(res => ({
        ...res.data,
        filtering: filters,
        sorting: sorting
    }));
}

export function getSchoolMeal(pk: number): Promise<SchoolMeal> {
    return restapi.get<SchoolMeal>(`/api/v1/smt_meal/school_meal/${pk}/`).then(res => res.data);

}


export function getSchoolMealHistory(id: number): Promise<SchoolMealHistoryData> {
    return restapi.get<OrderHistoryData>(`/api/v1/smt_meal/school_meal/${id}/history/`).then((res) => res.data);
}
