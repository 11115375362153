import {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from "@wfp/ui";
import {iconAddGlyph, iconDeleteGlyph} from '@wfp/icons';
import {ListPage, Order, Role, ServerError} from "../models/types";
import {discardOrder, getOrders} from "../restapi/order";
import {useLoggedUser} from "../hooks/user";
import {useRetrieveOrders} from "../hooks/order";
import {OrderList} from "../components/order/list";
import {LoadingCenter} from "../components/commons/loading-center";

export default function Orders() {
    const user = useLoggedUser({});
    const navigate = useNavigate();
    const {i18n, t} = useTranslation();
    const [selected, setSelected] = useState<Order>();
    const [openDiscard, setOpenDiscard] = useState(false);
    const [page, setPage] = useState<ListPage<Order>>();
    const [error, setError] = useState<ServerError>();

    const data = useRetrieveOrders({onError: (e) => setError(e)});

    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    function doReloadPage() {
        getOrders(page?.pageNumber, page?.pageSize)
            .then(res => setPage(res))
            .catch(e => setError(e));
    }

    function handleDiscardClose() {
        setOpenDiscard(false);
        setError(undefined);
    }

    function handleDiscardConfirm() {
        if (!!selected) {
            discardOrder(selected.id)
                .then(() => {
                    setError(undefined);
                    setOpenDiscard(false);
                    doReloadPage();
                })
                .catch(error => setError(error));
        }
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(user => user.id === id);
        setSelected(selected);
    }

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter/>
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <div className="m-5 flex">
                            <div className="ml-0 text-center">
                                <Text kind='h1'>{t("order_overview")}</Text>
                            </div>
                            <div className="ml-auto">
                                <Button className="!inline-flex" icon={iconAddGlyph} iconReverse
                                        iconDescription="create"
                                        disabled={user?.role !== Role.PartnerManager}
                                        onClick={() => navigate('/orders/wizard')}>{t('create')}</Button>
                            </div>
                            <div className="ml-auto mr-0">
                                <Button className="!inline-flex" icon={iconAddGlyph} iconReverse
                                        iconDescription="create commodities order"
                                        disabled={user?.role !== Role.PartnerManager}
                                        onClick={() => navigate('/orders/wizard-commodities')}>{t('create_commodities_order')}</Button>
                            </div>
                        </div>
                        <OrderList page={page} mobile={true}/>
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb className="mb-5">
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('order')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="flex mb-5">
                                <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                                    <Text kind='h1'>{t("order_overview")}</Text>
                                </div>
                                <div className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                    <Button className='!inline-flex' disabled={!(selected?.state === 'REQUESTED')}
                                            onClick={() => setOpenDiscard(true)} icon={iconDeleteGlyph}
                                            iconReverse>
                                        {t('discard')}
                                    </Button>
                                </div>
                                <div className='mr-3'>
                                    <Button className="!inline-flex" icon={iconAddGlyph} iconReverse
                                            iconDescription="create"
                                            disabled={user?.role !== Role.PartnerManager}
                                            onClick={() => navigate('/orders/wizard')}>
                                        {t('create')}
                                    </Button>
                                </div>
                                <div className=" mr-3">
                                    <Button className="!inline-flex" icon={iconAddGlyph} iconReverse
                                            iconDescription="create commodities order"
                                            disabled={user?.role !== Role.PartnerManager}
                                            onClick={() => navigate('/orders/wizard-commodities')}>{t('create_commodities_order')}</Button>
                                </div>
                            </div>
                            <OrderList page={page} mobile={false} onSelectOrder={handleSelected}/>
                        </div>
                    </div>
                </>
            )}
            {openDiscard && !!selected && (
                <Modal
                    open={openDiscard}
                    modalHeading={t('discard_order')!}
                    modalLabel={t('discard_order')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleDiscardConfirm}
                    onRequestClose={handleDiscardClose}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleDiscardClose}
                >
                    <p>{`${t('discard_order_confirm')} ${selected.supplierName} ${selected.deliveryDate}`}</p>
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                    )}
                </Modal>
            )}
        </>
    );
}


