import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useParams} from 'react-router-dom';
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Form, Modal, Tab, Tabs, Text} from '@wfp/ui';
import {iconAddGlyph, iconDeleteGlyph, iconEditGlyph} from "@wfp/icons";
import {PartnerError, validatePartner} from "../../models/validate-partner";
import {
    KVPair,
    ListPage,
    Partner,
    PartnerSchool,
    PartnerSupplier,
    School,
    ServerError,
    Supplier
} from "../../models/types";
import {savePartner} from "../../restapi/partner";
import {addPartnerSchool, deletePartnerSchool, getPartnerSchools} from "../../restapi/partner-school";
import {getSchools} from "../../restapi/school";
import {useRetrievePartner} from "../../hooks/partner";
import {LoadingCenter} from "../commons/loading-center";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";
import {SchoolList} from "../school/list";
import {PartnerCard} from "./card";
import {PartnerForm} from "./form";
import {SupplierList} from "../supplier/list";
import {addPartnerSupplier, deletePartnerSupplier, getPartnerSupplier} from "../../restapi/partner-supplier";
import {getSuppliers} from "../../restapi/supplier";

export const PartnerDetail = () => {
    const {i18n, t} = useTranslation();
    const {id} = useParams();
    const [error, setError] = useState<PartnerError>();
    const [errorServer, setErrorServer] = useState<ServerError>();
    const [partner, setPartner] = useState<Partner>();
    const [partnerSchoolPage, setPartnerSchoolPage] = useState<ListPage<PartnerSchool>>();
    const [partnerSupplierPage, setPartnerSupplierPage] = useState<ListPage<PartnerSupplier>>();
    const [selected, setSelected] = useState<PartnerSchool>();
    const [selectedSupplierId, setSelectedSupplierId] = useState<PartnerSupplier>();
    const [selectedSupplier, setSelectedSupplier] = useState<Supplier>();
    const [selectedSchool, setSelectedSchool] = useState<School>();
    const [selectedSchoolCheck, setSelectedSchoolCheck] = useState<number>();
    const [selectedSupplierCheck, setSelectedSupplierCheck] = useState<number>();
    const [partnerEdit, setPartnerEdit] = useState<Partner>();
    const [openAddSchool, setOpenAddSchool] = useState(false);
    const [openAddSupplier, setOpenAddSupplier] = useState(false);
    const [openRemoveSchool, setOpenRemoveSchool] = useState(false);
    const [openRemoveSupplier, setOpenRemoveSupplier] = useState(false);
    const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(true);
    const [disableDeleteButtonSupplier, setDisableDeleteButtonSupplier] = useState<boolean>(true);
    const [tabNumber, setTabNumber] = useState(parseInt(localStorage.getItem('PartnerDetailTabNumber') || '0'));

    const data = useRetrievePartner({id: Number(id), onError: (e) => setError(e as PartnerError)});

    useEffect(() => {
        if (data) {
            setPartner(data);
            loadPartnerSchoolsPage(data);
            loadPartnerSupplierPage(data);
        }
    }, [data]);

    useEffect(() => {
        setSelectedSchoolCheck(undefined);
        setSelectedSupplierCheck(undefined);
        setSelectedSupplierId(undefined);
        setDisableDeleteButtonSupplier(true);
        setDisableDeleteButton(true);
    }, [tabNumber])

    function loadPartnerSchoolsPage(partner: Partner): void {
        const filters = [{key: 'partner', value: partner.id.toString()}];
        doLoadSchoolsPage(1, undefined, filters).catch((e) => setErrorServer(e));
    }

    function loadPartnerSupplierPage(partner: Partner): void {
        const filters = [{key: 'partner', value: partner.id.toString()}];
        doLoadSuppliersPage(1, undefined, filters).catch((e) => setErrorServer(e));
    }

    function doLoadSchoolsPage(pageNumber: number, pageSize?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<School>> {
        const sortingSchools = sorting?.map((sort) => {
            const hasPrefix = sort.startsWith('+') || sort.startsWith('-');
            const prefix = hasPrefix ? sort[0] : '';
            const field = hasPrefix ? sort.slice(1) : sort;
            return `${prefix}school_${field}`;
        });
        return getPartnerSchools(pageNumber, pageSize, options, sortingSchools).then((res) => {
            setPartnerSchoolPage(res);
            return toSchoolPage(res, options, sorting) || {} as ListPage<School>;
        });
    }

    function doLoadSuppliersPage(pageNumber: number, pageSize?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<Supplier>> {
        const sortingSupplier = sorting?.map((sort) => {
            const hasPrefix = sort.startsWith('+') || sort.startsWith('-');
            const prefix = hasPrefix ? sort[0] : '';
            const field = hasPrefix ? sort.slice(1) : sort;
            return `${prefix}supplier_${field}`;
        });
        return getPartnerSupplier(pageNumber, pageSize, options, sortingSupplier).then((res) => {
            setPartnerSupplierPage(res);
            return toSupplierPage(res, options, sorting) || {} as ListPage<Supplier>;
        });
    }

    function handleEditSubmit() {
        if (!!partnerEdit) {
            setError(undefined);
            validatePartner(partnerEdit)
                .then((data) => savePartner(data)
                    .then((res) => {
                        setPartner(res);
                        setError(undefined);
                        setPartnerEdit(undefined);
                    })
                    .catch(error => setError(error)))
                .catch(error => setError(error));
        }
    }

    function handleEditClose() {
        setPartnerEdit(undefined);
        setError(undefined);
    }

    function handleSchoolClick() {//override to avoid navigation over schools
    }

    function handleSchoolSelected(schoolId: number): void {
        const selected = (partnerSchoolPage?.results || []).find(item => item.id === schoolId);
        setSelected(selected);
        setSelectedSchoolCheck(schoolId);
        setDisableDeleteButton(false);
    }

    function handleSupplierSelected(supplierId: number): void {
        const selected = (partnerSupplierPage?.results || []).find(item => item.supplier === supplierId);
        setSelectedSupplierId(selected);
        setSelectedSupplierCheck(supplierId);
        setDisableDeleteButtonSupplier(false);
    }

    function handleAddSchoolSubmit() {
        if (!!selectedSchool?.id && !!partner) {
            const data: PartnerSchool = {school: selectedSchool.id, partner: partner.id} as PartnerSchool;
            addPartnerSchool(data).then(() => {
                setErrorServer(undefined);
                setOpenAddSchool(false);
                setSelectedSchool(undefined);
                loadPartnerSchoolsPage(partner);
            }).catch(error => setErrorServer(error));
        }
    }

    function handleAddSupplierSubmit() {
        if (!!selectedSupplier?.id && !!partner) {
            const data: PartnerSupplier = {supplier: selectedSupplier.id, partner: partner.id} as PartnerSupplier;
            addPartnerSupplier(data).then(() => {
                setErrorServer(undefined);
                setOpenAddSupplier(false);
                setSelectedSupplier(undefined);
                loadPartnerSupplierPage(partner);
            }).catch(error => setErrorServer(error));
        }
    }

    function handleAddSchoolCancel() {
        setErrorServer(undefined);
        setOpenAddSchool(false);
    }

    function handleAddSupplierCancel() {
        setErrorServer(undefined);
        setOpenAddSupplier(false);
        setSelectedSupplier(undefined);
    }

    function handleRemoveSchoolSubmit() {
        if (!!selected) {
            deletePartnerSchool(selected.id)
                .then(() => {
                    setErrorServer(undefined);
                    setOpenRemoveSchool(false);
                    if (!!partner) {
                        loadPartnerSchoolsPage(partner);
                    }
                })
                .catch(error => setErrorServer(error));
        }
    }

    function handleRemoveSupplierSubmit() {
        if (!!selectedSupplierId) {
            deletePartnerSupplier(selectedSupplierId.id)
                .then(() => {
                    setErrorServer(undefined);
                    setOpenRemoveSupplier(false);
                    if (!!partner) {
                        loadPartnerSupplierPage(partner);
                    }
                })
                .catch(error => setErrorServer(error));
        }
    }

    function handleRemoveSchoolCancel() {
        setErrorServer(undefined);
        setOpenRemoveSchool(false);
        setSelected(undefined);
    }

    function handleRemoveSupplierCancel() {
        setErrorServer(undefined);
        setOpenRemoveSupplier(false);
        setSelectedSupplierId(undefined);
    }

    function toSchoolPage(partnerSchool?: ListPage<PartnerSchool>, filtering?: KVPair[], sorting?: string[]): ListPage<School> | undefined {
        return !!partnerSchool ? {
            ...partnerSchool,
            filtering: filtering,
            sorting: sorting,
            results: partnerSchool.results.map((ps) => ps.schoolData || {} as School)
        } : undefined;
    }

    function toSupplierPage(partnerSupplier?: ListPage<PartnerSupplier>, filtering?: KVPair[], sorting?: string[]): ListPage<Supplier> | undefined {
        return !!partnerSupplier ? {
            ...partnerSupplier,
            filtering: filtering,
            sorting: sorting,
            results: partnerSupplier.results.map((ps) => ps.supplierData || {} as Supplier)
        } : undefined;
    }

    function handleTabChange(idx: number): void {
        localStorage.setItem("PartnerDetailTabNumber", idx.toString());
        setTabNumber(idx);
    }

    return (
        <div className="m-10">
            <Breadcrumb className='mb-5'>
                <BreadcrumbItem>
                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <NavLink to="/partners">{t('partner')}</NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem disableLink>
                    {t('view_partner')}
                </BreadcrumbItem>
            </Breadcrumb>
            <div className="flex mt-10 mb-5 mx-0">
                <div>
                    <Text kind='h1'>{t("partner_details")}</Text>
                </div>
                <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                    <div className="grid-flow-row flex auto-rows-max mb-10 text-end">
                        {tabNumber === 0 && (
                            <div className="mx-3">
                                <Button onClickCapture={() => setPartnerEdit(partner)} className='!inline-flex'
                                        icon={iconEditGlyph} iconReverse>
                                    {t('edit_partner')}
                                </Button>
                            </div>
                        )}
                        {tabNumber === 1 && (
                            <>
                                <div className="mx-3">
                                    <Button onClick={() => setOpenRemoveSchool(true)} disabled={disableDeleteButton}
                                            icon={iconDeleteGlyph} iconReverse
                                            className='!inline-flex'>
                                        {t('remove')}
                                    </Button>
                                </div>
                                <div className="mx-3">
                                    <Button onClick={() => setOpenAddSchool(true)} className='!inline-flex'
                                            icon={iconAddGlyph} iconReverse iconDescription="create">
                                        {t('add_school')}
                                    </Button>
                                </div>
                            </>
                        )}
                        {tabNumber === 2 && (
                            <>
                                <div className="mx-3">
                                    <Button onClick={() => setOpenRemoveSupplier(true)}
                                            disabled={disableDeleteButtonSupplier}
                                            icon={iconDeleteGlyph} iconReverse
                                            className='!inline-flex'>
                                        {t('remove')}
                                    </Button>
                                </div>
                                <div className="mx-3">
                                    <Button onClick={() => setOpenAddSupplier(true)} className='!inline-flex'
                                            icon={iconAddGlyph} iconReverse iconDescription="create">
                                        {t('add_supplier')}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {!!partner && (
                <Tabs selected={tabNumber}>
                    <Tab onClick={() => handleTabChange(0)} label={t("info")} href='' role='info' selected={true}
                         tabIndex={0}>
                        <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                            <PartnerCard item={partner}/>
                        </div>
                    </Tab>
                    <Tab onClick={() => handleTabChange(1)} label={t("schools")} href='' role='schools' selected={false}
                         tabIndex={1}>
                        <div>
                            {!!partnerSchoolPage && (
                                <SchoolList page={toSchoolPage(partnerSchoolPage)} mobile={false} onClick={handleSchoolClick}
                                            onSelect={handleSchoolSelected} loadPage={doLoadSchoolsPage}
                                            selectCheck={selectedSchoolCheck}/>
                            )}
                        </div>
                    </Tab>
                    <Tab onClick={() => handleTabChange(2)} label={t("suppliers")} href='' role='suppliers'
                         selected={false} tabIndex={2}>
                        <div>
                            {!!partnerSupplierPage && (
                                <SupplierList page={toSupplierPage(partnerSupplierPage)} mobile={false}
                                              onClick={handleSchoolClick} onSelect={handleSupplierSelected}
                                              loadPage={doLoadSuppliersPage} selectCheck={selectedSupplierCheck}/>
                            )}
                        </div>
                    </Tab>
                </Tabs>
            )}
            {!!partnerEdit && (
                <Modal
                    open={true}
                    modalHeading={t('edit_partner') || ''}
                    modalLabel={t('edit_partner') || ''}
                    primaryButtonText={t('save') || ''}
                    onRequestSubmit={handleEditSubmit}
                    onRequestClose={handleEditClose}
                    secondaryButtonText={t('cancel') || ''}
                    onSecondarySubmit={handleEditClose}
                >
                    <PartnerForm partner={partnerEdit} error={error} onChange={setPartnerEdit}/>
                </Modal>
            )}
            {!(error || partner) && (
                <LoadingCenter/>
            )}
            {openAddSchool && (
                <Modal
                    open={openAddSchool}
                    modalHeading={t('add_new_school_to_your_list')!}
                    modalLabel={t('add_school')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleAddSchoolSubmit}
                    onRequestClose={handleAddSchoolCancel}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleAddSchoolCancel}
                >
                    <Form>
                        {!!errorServer?.errors && (
                            <Blockquote kind="error" withIcon>{errorServer.errors}</Blockquote>
                        )}
                        <SMETSTypeahead<School>
                            className="w-full"
                            noOptionsMessage={t('no_option_value') as string}
                            value={selectedSchool}
                            onSelectOption={(o) => setSelectedSchool({
                                ...selectedSchool,
                                id: Number(o.id),
                                name: o.name
                            } as School)}
                            label={t('schools')}
                            search={(input) => getSchools(1, 5, [{
                                key: "name_ic",
                                value: input
                            }]).then(res => res.results)}
                        />
                    </Form>
                </Modal>
            )}
            {openAddSupplier && (
                <Modal
                    open={true}
                    modalHeading={t('add_new_supplier_to_your_list')!}
                    modalLabel={t('add_supplier')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleAddSupplierSubmit}
                    onRequestClose={handleAddSupplierCancel}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleAddSupplierCancel}
                >
                    <Form>
                        {!!errorServer?.errors && (
                            <Blockquote kind="error" withIcon>{errorServer.errors}</Blockquote>
                        )}
                        <SMETSTypeahead<Supplier>
                            className="w-full"
                            noOptionsMessage={t('no_option_value') as string}
                            value={selectedSupplier}
                            onSelectOption={(o) => setSelectedSupplier({
                                ...selectedSupplier,
                                id: Number(o.id),
                                name: o.name
                            } as Supplier)}
                            label={t('suppliers')}
                            search={(input) => getSuppliers(1, 5, [{
                                key: "name_ic",
                                value: input
                            }]).then(res => res.results)}
                        />
                    </Form>
                </Modal>
            )}
            {openRemoveSchool && !!selected && (
                <Modal
                    open={openRemoveSchool}
                    modalHeading={t('remove_school')!}
                    modalLabel={t('remove_school')}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleRemoveSchoolSubmit}
                    onRequestClose={handleRemoveSchoolCancel}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleRemoveSchoolCancel}
                >
                    <p>{`${t('remove_school_confirm')} `}</p>
                    {!!errorServer?.errors && (
                        <Blockquote kind="error" withIcon>{errorServer.errors}</Blockquote>
                    )}
                </Modal>
            )}
            {openRemoveSupplier && !!selectedSupplierId && (
                <Modal
                    open={openRemoveSupplier}
                    modalHeading={t('remove_supplier')!}
                    modalLabel={t('remove_supplier')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleRemoveSupplierSubmit}
                    onRequestClose={handleRemoveSupplierCancel}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleRemoveSupplierCancel}
                >
                    <p>{`${t('remove_supplier_confirm')} `}</p>
                    {!!errorServer?.errors && (
                        <Blockquote kind="error" withIcon>{errorServer.errors}</Blockquote>
                    )}
                </Modal>
            )}
        </div>
    )
}
