import { useTranslation } from "react-i18next";
import { CrateReclaim } from "../../models/types";

interface Props {
    item: CrateReclaim;
    onClick?: () => void
}

export const CrateReclaimCard = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
            <div className="flex items-start flex-col">
                <div className="text-sm text-color-blue mb-2">
                    {t('id')}: <span className="font-medium">{props.item.id}</span>
                </div>
                <h3 className="text-lg text-gray-800 font-semibold mb-4">
                    {props.item.schoolName}
                </h3>
                <div className="text-md text-gray-600 mb-3">
                    <strong className="text-gray-700">{t('supplier')}:</strong> {props.item.supplierName}
                </div>
                <div className="text-md text-gray-600 mb-3">
                    <strong className="text-gray-700">{t('driver')}:</strong> {props.item.driverUsername}
                </div>
                <div className="text-md text-gray-600 mb-3">
                    <strong className="text-gray-700">{t('quantities')}:</strong> {props.item.quantity}
                </div>
                <div className="text-md text-gray-600 mb-3">
                    <strong className="text-gray-700">{t('date')}:</strong> {props.item.createdOn}
                </div>
            </div>
        </div>
    );
};
