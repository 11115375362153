import React, {useEffect, useState} from "react";
import {NavLink, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Text} from "@wfp/ui";
import {iconAddGlyph, iconDocument} from "@wfp/icons";
import i18n from "../../i18n";
import {ListPage, Order, OrderItem, Role, ServerError} from "../../models/types";
import {getOrderItems} from "../../restapi/order-item";
import {confirmOrder, downloadOrder, getOrder} from "../../restapi/order";
import {useLoggedUser} from "../../hooks/user";
import {LoadingCenter} from "../commons/loading-center";
import {OrderItemList} from "../order-item/list";
import {OrderSummary} from "./summary";

export const OrderIssuance = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const user = useLoggedUser({});
    const [order, setOrder] = useState<Order>();
    const [error, setError] = useState<ServerError>();
    const [selectedIds, setSelectedIds] = useState<number[]>();
    const [orderItemsPage, setOrderItemsPage] = useState<ListPage<OrderItem>>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!!id && !isNaN(Number(id))) {
            getOrder(Number(id))
                .then((o) => setOrder(o))
                .catch((e) => setError(e));
        }
    }, [id]);

    useEffect(() => {
        if (!!order) {
            const filters = [{key: 'order', value: order.id.toString()}];
            getOrderItems(1, undefined, filters)
                .then(page => setOrderItemsPage(page))
                .catch(e => setError(e));
        }
    }, [order]);

    function handleConfirm(): void {
        if (!user) {
            return;
        }
        if (!!order) {
            confirmOrder(order.id)
                .then(res => setOrder(res))
                .catch(e => setError(e));
        }
    }

    function handleSelectOrderItems(selectedIds: number[]) {
        setSelectedIds(selectedIds);
    }

    function handlePrint() {
        if (!!order) {
            const ids = (selectedIds?.length === 1 && selectedIds[0] === -1) ? undefined : selectedIds;
            setLoading(true);
            downloadOrder(order.id, ids).catch((e) => setError(e)).finally(() => setLoading(false));
        }
    }

    return (
        <div className="m-10">
            <div>
                <Breadcrumb className="mb-5">
                    <BreadcrumbItem>
                        <NavLink to="/"><BreadcrumbHome/></NavLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <NavLink to="/issuance">{t('issuance')}</NavLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem disableLink>
                        {t('issue_order')}
                    </BreadcrumbItem>
                </Breadcrumb>
            </div>
            {!!error?.errors && (
                <Blockquote kind="error" withIcon>
                    {error.errors}
                </Blockquote>
            )}
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error?.detail?.toString()}
                </Blockquote>
            )}
            {!!order && (
                <div>
                    <div className="flex mb-5">
                        <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                            <Text kind='h1'>{t("issuance_confirmation")}</Text>
                        </div>
                        {!!user && user.role === Role.SupplierManager && (
                            <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                <div className="grid grid-flow-row auto-rows-max space-y-3">
                                    <Button className="!inline-flex" icon={iconAddGlyph}
                                            disabled={order.state !== 'REQUESTED'} iconReverse
                                            onClick={handleConfirm}>{t('confirm')}</Button>
                                    {loading ? <div><LoadingCenter/></div> :
                                        <Button className="!inline-flex" icon={iconDocument} iconReverse
                                                onClick={handlePrint}
                                                disabled={order.state === 'REQUESTED' || !selectedIds?.length}>{t('print_waybills')}</Button>
                                    }
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="mb-5">
                        <OrderSummary order={order} contentType={order.contentTitle}/>
                    </div>
                    {!!orderItemsPage && (
                        <>
                            <div className="visible md:hidden">
                                <OrderItemList contentType={order.contentTitle} page={orderItemsPage} mobile={true}/>
                            </div>
                            <div className="hidden md:block">
                                <OrderItemList contentType={order.contentTitle} onSelectOrderItems={handleSelectOrderItems} page={orderItemsPage} mobile={false}/>
                            </div>
                        </>
                    )}
                </div>
            )}
            {!(error || order) && (
                <LoadingCenter/>
            )}
        </div>
    );
};
