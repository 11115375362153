import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ContentType, Role } from './models/types';
import { useLoggedUser } from "./hooks/user";
import { CommoditiesWizard } from "./components/wizard/commodities/wizard";
import { MealsWizard } from "./components/wizard/meals/wizard";
import { OrderDetail } from "./components/order/detail";
import { OrderIssuance } from "./components/order/issuance";
import { OrderItemDetail } from "./components/order-item/detail";
import { OrderWizard } from "./components/wizard/order/wizard";
import { PartnerDetail } from "./components/partner/detail";
import { locationRoleChecker, RouteGuard, RouteGuardEntry } from "./components/commons/route-guard/route-guard";
import { SchoolDetail } from "./components/school/detail";
import { SchoolMealDetail } from "./components/school-meal/detail";
import { SchoolMealItemDetail } from "./components/school-meal-item/detail";
import { SupplierDetail } from "./components/supplier/detail";
import { UserDetail } from "./components/user/detail";
import { SnackDetail } from "./components/snack/detail";
import { Code } from "./pages/code/code";
import { QRScan } from "./pages/qr-scan/qr-scan";
import Deliveries from "./pages/mobile/deliveries/deliveries";
import Header from "./pages/layout/header";
import Home from "./pages/home";
import LogoutDone from "./pages/logout-done";
import Issuance from "./pages/issuance";
import LogsPage from "./pages/log-entries";
import MobileHome from "./pages/mobile/home";
import Orders from "./pages/orders";
import Partners from "./pages/partners";
import Planning from "./pages/planning";
import Recent from "./pages/mobile/recent/recent";
import Reports from "./pages/reports";
import Schools from "./pages/schools";
import SchoolMeals from "./pages/school-meals";
import Snacks from "./pages/snacks";
import Suppliers from "./pages/suppliers";
import Users from "./pages/users";
import UserProfile from "./pages/user-profile";
import { SurveyWizard } from "./components/wizard/survey/wizard";
import TransferOrders from "./pages/transfer-order";
import TransferOrderDetail from "./components/transfer/detail";
import CreateReclaim from "./pages/mobile/crate/reclaim";
import Reclaim from "./pages/reclaim";


export const RouteGuardConfig: RouteGuardEntry[] = [
    { label: 'home', routing: '/mobile', roles: [Role.SupplierDriver, Role.SchoolDriver, Role.SchoolRepresentative, Role.PartnerMonitor] },
    { label: 'issuance', routing: '/issuance', roles: [Role.SupplierManager] },
    { label: 'items', routing: '/snacks', roles: [Role.WFPManager] },
    { label: 'logs', routing: '/logs', roles: [Role.WFPManager] },
    { label: 'orders', routing: '/orders', roles: [Role.WFPManager, Role.PartnerManager] },
    { label: 'school_meals', routing: '/school-meals', roles: [Role.WFPManager, Role.PartnerManager, Role.SchoolManager] },
    { label: 'survey', routing: '/survey', roles: [Role.PartnerMonitor] },
    { label: 'partners', routing: '/partners', roles: [Role.WFPManager] },
    { label: 'transfers', routing: '/transfers', roles: [Role.PartnerManager, Role.PartnerMonitor, Role.SchoolRepresentative] },
    {
        label: 'reports',
        routing: '/reports',
        roles: [Role.WFPManager, Role.WFPReporter, Role.MinistryReporter, Role.PartnerManager, Role.SupplierManager]
    },
    {
        label: 'planning',
        routing: '/planning',
        roles: [Role.WFPManager, Role.PartnerManager, Role.PartnerStaff, Role.SupplierManager]
    },
    { label: 'schools', routing: '/schools', roles: [Role.WFPManager] },
    { label: 'suppliers', routing: '/suppliers', roles: [Role.WFPManager] },
    { label: 'user', routing: '/users', roles: [Role.WFPManager] },
    { routing: '/code', roles: ['*'] },
    { routing: '/qrscan', roles: ['*'] },
    { routing: '/items', roles: ['*'] },
    { routing: '/school-code', roles: ['*'] },
    { routing: '/transfer-code', roles: ['*'] },
    { routing: '/school-items', roles: ['*'] },
    { routing: '/user', roles: ['*'] },
    { routing: '/crate', roles: [Role.SupplierDriver]},
    { label: 'reclaims', routing: '/reclaims', roles:['*']},
];

const UserRouteGuard = () => {
    const user = useLoggedUser({});
    return (
        !user ? null : <RouteGuard fallback="/home" authorize={(l) => locationRoleChecker(user?.role || null, l, RouteGuardConfig)} />
    )
}

export function AppRouting() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/public" element={<LogoutDone />} />
                <Route path="/" element={<UserRouteGuard />}>
                    <Route path="/crate/create/reclaim" element={<CreateReclaim />} />
                    <Route path="/reclaims" element={<Reclaim />} />
                    <Route path="/code/:code" element={<Code contentType={ContentType.SNACKS} />} />
                    <Route path="/issuance" element={<Issuance />} />
                    <Route path="/issuance/:id" element={<OrderIssuance />} />
                    <Route path="/items/:id" element={<OrderItemDetail />} />
                    <Route path="/items/:id/qrscan" element={<QRScan />} />
                    <Route path="/logs" element={<LogsPage />} />
                    <Route path="/school-code/:code" element={<Code contentType={ContentType.MEALS} />} />
                    <Route path="/school-meals" element={<SchoolMeals />} />
                    <Route path="/school-meals/:id" element={<SchoolMealDetail />} />
                    <Route path="/school-meals/wizard-meals" element={<MealsWizard />} />
                    <Route path="/school-items/:id" element={<SchoolMealItemDetail />} />
                    <Route path="/school-items/:id/qrscan" element={<QRScan />} />
                    <Route path="/mobile" element={<MobileHome />} />
                    <Route path="/mobile/deliveries" element={<Deliveries />} />
                    <Route path="/mobile/recent" element={<Recent />} />
                    <Route path="/orders" element={<Orders />} />
                    <Route path="/orders/wizard" element={<OrderWizard />} />
                    <Route path="/orders/wizard-commodities" element={<CommoditiesWizard />} />
                    <Route path="/orders/wizard/:id" element={<OrderWizard />} />
                    <Route path="/orders/:id" element={<OrderDetail />} />
                    <Route path="/partners" element={<Partners />} />
                    <Route path="/partners/:id" element={<PartnerDetail />} />
                    <Route path="/planning" element={<Planning />} />
                    <Route path="/qrscan" element={<QRScan />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/schools" element={<Schools />} />
                    <Route path="/schools/:id" element={<SchoolDetail />} />
                    <Route path="/snacks/" element={<Snacks />} />
                    <Route path="/snacks/:id" element={<SnackDetail />} />
                    <Route path="/suppliers" element={<Suppliers />} />
                    <Route path="/suppliers/:id" element={<SupplierDetail />} />
                    <Route path="/survey/wizard" element={<SurveyWizard />} />
                    <Route path="/transfers" element={<TransferOrders />} />
                    <Route path="/transfer-code/:code" element={<Code contentType={ContentType.TRANSFER} />} />
                    <Route path="/transfers/:id/qrscan" element={<QRScan />} />
                    <Route path="/transfers/:id" element={<TransferOrderDetail />} />
                    <Route path="/user" element={<UserProfile />} />
                    <Route path="/users" element={<Users />} />
                    <Route path="/users/:id" element={<UserDetail />} />
                </Route>
                <Route path='*' element={<Navigate to='/home' replace />} />
            </Routes>
        </BrowserRouter>
    );
}
