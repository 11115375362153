import { useTranslation } from "react-i18next";
import { useRetrieveTransferOrderHistory } from "../../hooks/transfer";


interface Props {
    id: number;
}

export default function TransferHistory(props: Props) {
    const history = useRetrieveTransferOrderHistory({ id: props.id });
    const { t } = useTranslation();

    return (
        <div className={`${!!history?.transitions ? 'transition-opacity duration-300 opacity-100 ease-in' : 'opacity-0'}`}>
            {!!history?.transitions ? (
                <div className="grid grid-flow-row space-y-3 text-left">
                    {history.transitions.map((ot, idx) => (
                        <div key={idx} className={`bg-order-item-state-${ot.toState} flex flex-row space-x-3 p-3 rounded-3xl`}>
                            <div>{ot.timestamp}</div>
                            <div>{ot.username}</div>
                            <div>{t(ot.toState)}</div>
                            <div>
                                {!!ot.position && (
                                    <a className="text-white hover:text-blue-600" target="_blank" href={`https://www.openstreetmap.org/?mlat=${ot.position.latitude}&mlon=${ot.position.longitude}#map=14/${parseFloat(ot.position.latitude)}/${ot.position.longitude}`}>{t('transition_position')}: {`${parseFloat(ot.position.latitude)}, ${parseFloat(ot.position.longitude)}`}</a>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="grid grid-flow-row space-y-3 text-left">
                    <div>loading...</div>
                </div>
            )}
        </div>
    )
}
