import React, {useState} from "react";
import "./accordion.css";

interface Props {
    children: React.ReactNode;
    title: React.ReactNode;
    open?: boolean;
}

export const AccordionItem = (props: Props) => {
  const [isActive, setIsActive] = useState(props.open);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="accordion-title-toggle">{isActive ? '-' : '+'}</div>
          {!!props.title && (
          <div className="accordion-title-label">{props.title}</div>
          )}
      </div>
      {isActive && <div className="accordion-content">{props.children}</div>}
    </div>
  );
};
