import {School, ServerError} from "./types";


export interface SchoolError extends ServerError {
    cerd: string[] | undefined;
    detail: string[] | undefined;
    location: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    numberOfStudentsAm: string[] | undefined;
    numberOfStudentsPm: string[] | undefined;
    parent: string[] | undefined;
}

class SchoolErrorInfo implements SchoolError {
    cerd: string[] | undefined;
    detail: string[] | undefined;
    errors: string[] | undefined;
    location: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    numberOfStudentsAm: string[] | undefined;
    numberOfStudentsPm: string[] | undefined;
    parent: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateSchool(school: School): Promise<School> {
    const error = new SchoolErrorInfo();
    if (!school.cerd) {
        error.add('cerd', 'this_field_is_required');
    }
    if (!school.location) {
        error.add('location', 'this_field_is_required');
    }
    if (!school.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!school.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    if (!school.numberOfStudentsAm) {
        error.add('numberOfStudentsAm', 'this_field_is_required');
    }
    if (!school.numberOfStudentsPm) {
        error.add('numberOfStudentsPm', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(school) : Promise.reject(error);
}
