import { OrderItemTransitionPoint, ListPage, TransferTransitionPoint } from '../models/types';
import { restapi } from './restapi';


export function sendOrdetItemPosition(data: OrderItemTransitionPoint): Promise<OrderItemTransitionPoint> {
    return restapi.post('/api/v1/smt_core/order_item_transition_point/', data).then(res => res.data);
}
export function retrieveOrdetItemPositions(orderItem: number): Promise<ListPage<OrderItemTransitionPoint>> {
    return restapi.get('/api/v1/smt_core/order_item_transition_point/', { params: { orderItem: orderItem } }).then(res => res.data);
}

export function sendTransferPosition(data: TransferTransitionPoint): Promise<TransferTransitionPoint> {
    return restapi.post('/api/v1/smt_transfer/transfer_transition_point/', data).then(res => res.data);
}
export function retrieveTransferPositions(transfer: number): Promise<ListPage<TransferTransitionPoint>> {
    return restapi.get('/api/v1/smt_transfer/transfer_transition_point/', { params: { transfer: transfer } }).then(res => res.data);
}
