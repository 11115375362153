import {Blockquote} from "@wfp/ui";
import {useTranslation} from "react-i18next";
import {orderWizardPages} from "./wizard"

export interface HeaderProps {
    page: number
    errors?: string[]
    message?: string
}

export function Headerbar(props: HeaderProps) {
    const {t} = useTranslation();
    
    return (
        <div>
            <div className="flex mt-2 mb-2">
                {props.page !== undefined && (
                <div className="m-0">{t("step")} {t((props.page + 1).toString())}/{t((orderWizardPages.length).toString())}: {t(orderWizardPages[props.page].header)}</div>
                )}
            </div>
            {!!props.errors && props.page !== 3 &&(
                <Blockquote kind="error" withIcon>{props.errors.toString()}</Blockquote>
            )}
            {!!props.message && props.page !== 3 &&(
                <Blockquote kind="info" withIcon>{props.message}</Blockquote>
            )}
        </div>
    )
}
