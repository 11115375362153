import * as React from "react";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Module, ModuleBody, Select, SelectItem} from "@wfp/ui";
import {KVPair, Location, School} from "../../models/types";
import {getSchools} from "../../restapi/school";
import {SMETSModuleHeader} from "../commons/module-header";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";
import {getLocations} from "../../restapi/geo";
import {useRetrieveDistricts} from "../../hooks/geo";

export function PlanningFilters(props: { title?: React.ReactNode, onSubmit: (filters: KVPair[]) => void }) {
    const [visible, setVisible] = useState(false);
    const [schoolSelected, setSchoolSelected] = useState<School>();
    const [locationSelected, setLocationSelected] = useState<Location>();
    const {t} = useTranslation();
    const districtList = useRetrieveDistricts({});
    const [selected, setSelected] = useState<number>(0);

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setSchoolSelected({} as School);
        setLocationSelected({} as Location);
        setSelected(0);
    }

    function handleApply() {
        const filters: KVPair[] = [];
        if (!!schoolSelected?.id) {
            filters.push({key: "school", value: schoolSelected.id.toString()});
        }
        if (!!locationSelected?.id) {
            filters.push({key: "location", value: locationSelected.id.toString()});
        }
        if (selected > 0) {
            filters.push({key: "district", value: selected.toString()});
        }
        props.onSubmit(filters);
    }

    function searchLocation(input: string): Promise<Location[]> {
        const filters = [{key: "name_ic", value: input}];
        if(selected !== 0) {
            filters.push({ key: "district", value: selected.toString()});
        }
        return getLocations(1, 5, filters).then(res => res.results)
    }
    function searchSchool(input: string): Promise<School[]> {
        const filters = [{key: "name_ic", value: input}];
        if(selected !== 0) {
            filters.push({ key: "district", value: selected.toString()});
        }
        if (!!locationSelected?.id) {
            filters.push({key: "location", value: locationSelected.id.toString()});
        }
        return getSchools(1, 5, filters).then(res => res.results)
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader children={props.title} filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-4 space-x-5 items-baseline text-start">
                            <Select
                                id="district"
                                labelText={t('district')}
                                onChange={(e: any) => setSelected(e.target?.value)}
                                value={selected}
                            >
                                {[{id: 0, name: t("choose_one")}, ...(districtList || [])].map((district, idx) => (
                                    <SelectItem key={idx} id={district.id.toString()} value={district.id}
                                                text={t(district.name)}/>
                                ))}
                            </Select>
                            <SMETSTypeahead<Location>
                                noOptionsMessage={t('no_option_value') as string}
                                value={locationSelected}
                                onClick={handleApply}
                                onSelectOption={(o) => setLocationSelected({
                                    ...locationSelected,
                                    id: Number(o.id),
                                    name: o.name
                                } as Location)}
                                label={t('location')}
                                search={searchLocation}/>
                            <SMETSTypeahead<School>
                                noOptionsMessage={t('no_option_value') as string}
                                value={schoolSelected}
                                onClick={handleApply}
                                onSelectOption={(o) => setSchoolSelected({
                                    ...schoolSelected,
                                    id: Number(o.id),
                                    name: o.name
                                } as School)}
                                label={t('schools')}
                                search={searchSchool}/>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                    onClick={handleApply}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
