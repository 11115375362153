import {useEffect, useState} from "react";
import {Blockquote, Table} from "@wfp/ui";
import {useTranslation} from "react-i18next";
import {CrateBySchool, KVPair, ListPage, Sortable} from "../../../models/types";
import {formatFieldName, formatTableColumnCode, formatTableColumnField} from "../../../hooks/formats";
import {useRetrieveSnacks} from "../../../hooks/snack";
import {useRetrieveSnackCategories} from "../../../hooks/snack-category";
import {TablePagination} from "../../commons/table-pagination/table-pagination";
import "./table.css";

interface Props {
    hidePagination?: boolean;
    page?: ListPage<CrateBySchool>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}

const headers = ["school"];

const sort: Sortable[] = [
    {code: 'school', field: 'school', enabled: true, mode: 0},
];

export function CrateBySchoolTable(props: Props) {
    const {t} = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>(sort);
    const [extraColsSnack, setExtraColsSnack] = useState<Sortable[]>();
    const [extraColsCategory, setExtraColsCategory] = useState<Sortable[]>();
    const snacks = useRetrieveSnacks({});
    const snackCategories = useRetrieveSnackCategories({});

    useEffect(() => {
        if (!!snacks && !!snackCategories) {
            const extraColsSnack = snacks.map((snack) => {
                return {
                    label: snack.nameEn,
                    code: formatTableColumnCode({value: snack.nameEn}),
                    field: formatTableColumnField({value: snack.nameEn}),
                    mode: 0,
                    enabled: true
                };
            });
            setExtraColsSnack(extraColsSnack);
            const extraColsCategory = snackCategories.map((sc) => {
                return {
                    label: sc.nameEn,
                    code: formatTableColumnCode({value: sc.nameEn}),
                    field: formatTableColumnField({value: sc.nameEn}),
                    mode: 0,
                    enabled: true
                };
            });
            setExtraColsCategory(extraColsCategory);
            setSortables([...sortables, ...extraColsSnack, ...extraColsCategory]);
        }
    }, [snacks, snackCategories, sort]);

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort && !!props.page?.count && props.page?.count > 0) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    function itemFieldValue(item: any, field: string): string {
        const fieldName = formatFieldName(field);
        return item[fieldName];
    }

    return (
        <>
            {!!props.page && (
                <>
                    <Table>
                        <thead>
                        <tr className="item-row-head">
                            {[...headers, ...(extraColsSnack || []).map(s => s.label || ''), ...(extraColsCategory || []).map(s => s.label || '')].map((header, idx) => (
                                <th key={idx} onClick={() => handleSorting(idx)}
                                    className={props.sort && sortables?.at(idx)?.enabled && !!props.page?.count && props.page?.count > 0 ? "!text-center cursor-pointer" : "!text-center"}>
                                    {t(header)}
                                    {!!sortables?.length && !!props.page?.count && props.page?.count > 0 && (
                                        <span className="ml-0.5">
                                            {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        {!!props.page && (
                            <tbody>
                            {props.page.results.map((item: CrateBySchool, idx) => (
                                <tr className={`${idx ? "item-row" : "item-row-totals"}`} key={idx}>
                                    <td className={`item-col ${idx ? "text-start" : "text-center"}`}>
                                        {item.school}
                                    </td>
                                    {(extraColsSnack || []).map((s, idx) => (
                                        <td key={idx} className="item-col text-end">
                                            {itemFieldValue(item, s.field)}
                                        </td>
                                    ))}
                                    {(extraColsCategory || []).map((s, idx) => (
                                        <td key={idx} className="item-col text-end">
                                            {itemFieldValue(item, s.field)}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        )}
                    </Table>
                    {!props.hidePagination && (
                        <TablePagination page={props.page} loadPage={props.loadPage}/>
                    )}
                </>
            )}
            {!props.page && (
                <Blockquote>
                    <p>{t('select_date_and_click_apply')}</p>
                </Blockquote>
            )}
        </>
    )
}
