import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "@wfp/ui";
import { CrateReclaim, KVPair, ListPage, Role, Sortable } from "../../models/types";
import { useLoggedUser } from "../../hooks/user";
import { TablePagination } from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<CrateReclaim>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onSelectOrder?: (id: number) => void;
    onClick?: (id: number) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}


const headersDefault = ["id", "school", "supplier", "date", "quantities"];

export function CrateReclaimTable(props: Props) {
    const { t } = useTranslation();
    const user = useLoggedUser({});
    const [sortables, setSortables] = useState<Sortable[]>();
    const headers = !!user && user.role === Role.SupplierManager ?
        headersDefault.filter((header) => header !== "supplier") : headersDefault


    useEffect(() => {
        if (!!props.sortInfo && !!headers) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : { code: header, field: header, mode: 0, enabled: false }
            });
            setSortables(sorts);
        }
    }, [headers, props.sortInfo]);


    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <div>
            <Table>
                <thead>
                    <tr className="item-row-head">
                        {headers.map((header, idx) => (
                            <th key={idx} onClick={() => handleSorting(idx)} className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                                {t(header)}
                                {!!sortables?.length && (
                                    <span className="ml-0.5">
                                        {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                                    </span>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.page.results.map((item: CrateReclaim) => (
                        <tr className="item-row" key={item.id}>
                            <td className="item-col text-start">
                                {item.id}
                            </td>
                            <td className="item-col text-start" >
                                {item.schoolName}
                            </td>
                            {user?.role !== Role.SupplierManager && (
                                <td className="item-col text-start" >
                                    {item.supplierName}
                                </td>
                            )}
                            <td className="item-col text-start" >
                                {item.createdOn}
                            </td>
                            <td className="item-col text-start p-0.5" >
                                {item.quantity}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage} />
            )}
        </div>
    )
}
