import {ServerError, SnackCategory} from '../models/types';
import {useQuery} from "react-query";
import {retrieveCategories} from "../restapi/snack-category";


export function useRetrieveSnackCategories({onError}: {onError?: (e: ServerError) => void}): SnackCategory[] | undefined {
    const categoriesQuery = useQuery(['SnackCategories'], () => retrieveCategories(), {onError: onError});
    return categoriesQuery.data;
}

