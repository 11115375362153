import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Blockquote } from "@wfp/ui";
import { ContentType, KVPair, ListPage, Loss, OrderItem, Role, ServerError } from "../../models/types";
import { updateSorting } from "../../restapi/restapi";
import { acceptReturnOrderItem, getOrderItems, handoverOrderItem, undoPickupOrderItem } from "../../restapi/order-item";
import { saveLoss } from "../../restapi/loss";
import { useInViewport } from "../../hooks/useInViewport";
import { useLoggedUser } from "../../hooks/user";
import { HandoverConfirmation } from "./handover-confirmation";
import { LossConfirmation } from "./loss-confirmation";
import { OrderItemCard } from "./card";
import { ReturnConfirmation } from "./return-confirmation";
import { OrderItemSnacksTable } from "./order-snacks-table";
import { OrderItemCommoditiesTable } from "./order-commodities-table";
import { LoadingCenter } from "../commons/loading-center";
import { getCurrentPosition } from "../../hooks/position";
import { sendOrdetItemPosition } from "../../restapi/position";
import { datetimeFormat } from "../../hooks/formats";
import moment from "moment";


interface Props {
    page?: ListPage<OrderItem>,
    mobile?: boolean,
    disableFilter?: boolean,
    contentType?: string,
    onSelectOrder?: (id: number) => void,
    onSelectOrderItems?: (orderItemsId: number[]) => void
    onClickOrderItem?: (o: OrderItem) => void
}

export const OrderItemList = (props: Props) => {
    const user = useLoggedUser({});
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<ListPage<OrderItem>>();
    const [error, setError] = useState<ServerError>();
    const { isInViewport, ref } = useInViewport();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [orderItemReturn, setOrderItemReturn] = useState<OrderItem>();
    const [orderItemHandover, setOrderItemHandover] = useState<OrderItem>();
    const [orderItemLoss, setOrderItemLoss] = useState<OrderItem>();
    const [lossErrors, setLossErrors] = useState<ServerError[]>();
    const [showLoader, setShowLoader] = useState<boolean>(false);





    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        } else {
            loadPage();
        }
    }, [props.page]);


    function sendPosition(state: string, id: number, navigate: () => void) {
        const measuredOn = datetimeFormat(moment(new Date())) || undefined;
        if (!!user) {
            setShowLoader(true);
            getCurrentPosition().then((pos) => {
                sendOrdetItemPosition(
                    {
                        latitude: pos.coords.latitude.toFixed(6),
                        longitude: pos.coords.longitude.toFixed(6),
                        altitude: pos.coords.altitude?.toFixed(6) || undefined,
                        accuracy: pos.coords.accuracy?.toFixed(12),
                        altitudeAccuracy: pos.coords.altitudeAccuracy?.toFixed(12) || undefined,
                        measuredOn: measuredOn,
                        state: state,
                        orderItem: id,
                        user: user.pk,
                    }
                ).catch(error => setError(error));
            }).catch(error => setError(error))
                .finally(() => {
                    setShowLoader(false);
                    navigate();
                });
        }
        else {
            navigate();
        }
    }


    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getOrderItems(pageNumber, pageSize, options, sorting)
            .then((res) => {
                setLoading(false);
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error));
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page.next;
            if (result) {
                loadPage(page.pageNumber + 1, page.pageSize, page.filtering, page.sorting);
            }
            return result;
        }
        return false;
    }

    function handleAcceptReturn(orderItem: OrderItem) {
        setOrderItemReturn(orderItem);
    }

    function handleAcceptReturnConfirm() {
        if (!!orderItemReturn) {
            acceptReturnOrderItem(orderItemReturn.id)
                .then((data) => sendPosition(data.state, data.id, () => navigate('/mobile')))
                .catch((error) => setError(error));
        }
    }

    function handleAcceptReturnClose() {
        setOrderItemReturn(undefined);
        setError(undefined);
    }

    function handleHandover(orderItem: OrderItem) {
        setOrderItemHandover(orderItem);
    }

    function handleHandoverConfirm() {
        if (!!orderItemHandover) {
            handoverOrderItem(orderItemHandover.id)
                .then((data) => sendPosition(data.state, data.id, () => navigate('/crate/create/reclaim',{
                    state: {
                        order: orderItemHandover,
                      }
                })))
                .catch((error) => setError(error))
        }
    }

    function handleLoss(orderItem: OrderItem) {
        setOrderItemLoss(orderItem);
        setLossErrors(undefined);
    }

    function handleLossConfirm(losses: Loss[]) {
        if (!!losses) {
            setLossErrors(undefined);
            const errors = new Array<ServerError>(losses.length);
            const promises = losses.map((loss, idx) => saveLoss(loss).catch((error) => {
                errors[idx] = error;
                setLossErrors([...errors]);
            }));
            Promise.all(promises).then(() => {
                if (!(errors || []).find((e) => !!e ? !Object.values(e).every((f) => !f) : false)) {
                    setOrderItemLoss(undefined);
                    navigate('/mobile');
                }
            });
        }
    }

    function handleUndoPickup(orderItem: OrderItem) {
        undoPickupOrderItem(orderItem.id)
            .then((data) => sendPosition(data.state, data.id, () => navigate('/mobile')))
            .catch((error) => setError(error));
    }

    function scan(orderItem: OrderItem) {
        navigate(`/items/${orderItem.id}/qrscan`)
    }

    return (
        <>
            {showLoader ? <LoadingCenter /> : (
                <>
                    {!!orderItemReturn && (
                        <ReturnConfirmation
                            error={error}
                            orderItem={orderItemReturn}
                            onCancel={handleAcceptReturnClose}
                            onOk={() => handleAcceptReturnConfirm()}
                        />
                    )}
                    {!!orderItemLoss && (
                        <LossConfirmation
                            orderItem={orderItemLoss}
                            onCancel={() => setOrderItemLoss(undefined)}
                            onOk={(losses) => handleLossConfirm(losses)}
                        />
                    )}
                    {!!orderItemHandover && (
                        <HandoverConfirmation
                            orderItem={orderItemHandover}
                            onCancel={() => setOrderItemHandover(undefined)}
                            onOk={handleHandoverConfirm}
                        />
                    )}
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
                    )}
                    {!!page && (
                        <div>
                            {page?.count === 0 && (
                                <Blockquote kind="warning" withIcon>{t("empty_order_items_list")}</Blockquote>
                            )}
                            {!!props.mobile && page?.results.map((orderItem) => (
                                <div key={orderItem.id}
                                    className="flex items-start max-w-md py-4 px-4 bg-white shadow-md rounded-lg my-4 mx-2">
                                    <OrderItemCard
                                        item={orderItem}
                                        readonly={orderItem?.state === "DELIVERED" && user?.role === Role.SchoolRepresentative}
                                        acceptReturn={orderItem?.state === "DELIVERED" && user?.role === Role.SupplierDriver ? () => handleAcceptReturn(orderItem) : undefined}
                                        handover={orderItem?.state === "LOADED" && user?.role === Role.SupplierDriver ? () => handleHandover(orderItem) : undefined}
                                        undoPickup={orderItem?.state === "LOADED" && user?.role === Role.SupplierDriver ? () => handleUndoPickup(orderItem) : undefined}
                                        loss={orderItem?.state === "DONE" && user?.role === Role.SchoolRepresentative ? () => handleLoss(orderItem) : undefined}
                                        onClick={orderItem?.state === "DELIVERED" && user?.role === Role.SchoolRepresentative ? () => scan(orderItem) : undefined}
                                    />
                                </div>
                            ))}
                            {!!props.mobile && (
                                <div ref={ref}>
                                    {isInViewport && handleLoadMore() && <div>Loading.....</div>}
                                </div>
                            )}
                            {!props.mobile && page.count > 0 && (
                                <>
                                    {!!props.contentType && props.contentType === ContentType.COMMODITIES && (
                                        <OrderItemCommoditiesTable
                                            onSelectOrderItems={props?.onSelectOrderItems}
                                            hidePagination={page.count <= page.results.length}
                                            loadPage={loadPage}
                                            page={page}
                                            sort={handleSort}
                                        />
                                    )}
                                    {!!props.contentType && props.contentType === ContentType.SNACKS && (
                                        <OrderItemSnacksTable
                                            onClickOrderItem={props?.onClickOrderItem}
                                            onSelectOrderItems={props?.onSelectOrderItems}
                                            hidePagination={page.count <= page.results.length}
                                            loadPage={loadPage}
                                            page={page}
                                            sort={handleSort}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
}
