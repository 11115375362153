import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {NavLink} from "react-router-dom";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Tab, Tabs, Text} from '@wfp/ui';
import {KVPair, ListPage, LogEntry, LogType, ServerError} from "../models/types";
import {getLogEntries} from "../restapi/log-entry";
import {updateSorting} from "../restapi/restapi";
import {LoadingCenter} from "../components/commons/loading-center";
import {LogsTable} from "../components/log-entry/table";
import {LogsFilters} from "../components/log-entry/filters";

const logTypes = [LogType.REQUEST, LogType.LOGIN, LogType.CRUD];

export default function LogsPage() {
    const {t} = useTranslation();
    const [error, setError] = useState<ServerError>();
    const [page, setPage] = useState<ListPage<LogEntry>>()
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('LogEntriesTabIndex') || '0'));

    useEffect(() => {
        setError(undefined);
        setPage(undefined);
        loadPage();
    }, [tabIndex])

    function handleFiltering(filters: KVPair[]): void {
        const changed = filters.length !== page?.filtering?.length || filters.filter(kvp => !page?.filtering?.find(f => f.key === kvp.key && f.value === kvp.value)).length > 0
        if (changed) {
            loadPage(page?.pageNumber || 1, page?.pageSize, filters, page?.sorting);
        }
    }

    function handleSorting(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleTabChange(idx: number): void {
        localStorage.setItem("LogEntriesTabIndex", idx.toString());
        setTabIndex(idx);
    }

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setError(undefined);
        getLogEntries(logTypes[tabIndex], pageNumber, pageSize, options, sorting)
            .then(res => setPage(res))
            .catch(error => setError(error));
    }

    return (
        <>
            {!!error?.errors && (
                <Blockquote kind="error" withIcon>{error.errors.toString()}</Blockquote>
            )}
            {!error?.errors && !page && (
                <LoadingCenter/>
            )}
            {!!page && (
                <div className="hidden md:block">
                    <div className="m-10">
                        <Breadcrumb className="mb-5">
                            <BreadcrumbItem>
                                <NavLink to="/"><BreadcrumbHome/></NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem disableLink>
                                {t('logs')}
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className='mb-5'>
                            <div className="flex mb-5">
                                <Text kind='h1'>{t("logs_overview")}</Text>
                            </div>
                            <Tabs className='mt-0' selected={tabIndex}>
                                <Tab onClick={() => handleTabChange(0)} label={t(logTypes[0])} href=''
                                     role={logTypes[0]} selected tabIndex={0}>
                                    <div>
                                        <LogsFilters onSubmit={handleFiltering} logType={logTypes[tabIndex]}/>
                                        <LogsTable loadPage={loadPage} page={page} logType={logTypes[tabIndex]}
                                                   sort={handleSorting}/>
                                    </div>
                                </Tab>
                                <Tab onClick={() => handleTabChange(1)} label={t(logTypes[1])} href=''
                                     role={logTypes[0]} selected tabIndex={1}>
                                    <div>
                                        <LogsFilters onSubmit={handleFiltering} logType={logTypes[tabIndex]}/>
                                        <LogsTable loadPage={loadPage} page={page} logType={logTypes[tabIndex]}
                                                   sort={handleSorting}/>
                                    </div>
                                </Tab>
                                <Tab onClick={() => handleTabChange(2)} label={t(logTypes[2])} href=''
                                     role={logTypes[0]} selected tabIndex={2}>
                                    <div>
                                        <LogsFilters onSubmit={handleFiltering} logType={logTypes[tabIndex]}/>
                                        <LogsTable loadPage={loadPage} page={page} logType={logTypes[tabIndex]}
                                                   sort={handleSorting}/>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}


