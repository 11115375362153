import {buildQueryString, restapi} from "./restapi";
import {ListPage, KVPair, Commodity, UnitOfMeasure} from "../models/types";

export function getCommodity(id: number): Promise<Commodity> {
    return restapi.get<Commodity>(`/api/v1/smt_fab/commodity/${id}/`).then(res => res.data);
}

export function getCommodities(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Commodity>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Commodity>>(`/api/v1/smt_fab/commodity/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrieveCommodities(filters?: KVPair[], sorting?: string[]): Promise<Commodity[]> {
    const pageOptions = [{key: '__pagination__', value: '0'}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<Commodity[]>(`/api/v1/smt_fab/commodity/?${qs}`).then(res => res.data);
}

export function retrieveUnitOfMeasure(filters?: KVPair[], sorting?: string[]): Promise<UnitOfMeasure[]> {
    const pageOptions = [{key: '__pagination__', value: '0'}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<UnitOfMeasure[]>(`/api/v1/smt_fab/unit-of-measure/?${qs}`).then(res => res.data);
}
