import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal, Select, SelectItem} from "@wfp/ui";
import {OrderItem, ReturnReason} from "../../models/types";
import {useRetrieveReturnReasons} from "../../hooks/return-reason";
import {OrderItemCard} from "./card";

interface Props {
  orderItem: OrderItem;
  onOk: (reasonId: number) => void;
  onCancel: () => void;
  error?: string;
}

export const RejectConfirmation = (props: Props) => {
  const {t} = useTranslation();
  const [reasonId, setReasonId] = useState<number>();
  const returnReasons = useRetrieveReturnReasons({});

  const [returnReasonList, setReturnReasonList] = useState<ReturnReason[]>();
  useEffect(() => {
    if(!!returnReasons) {
        setReturnReasonList([{id: 0, name: "choose_one"} as ReturnReason, ...returnReasons]);
    }
  }, [returnReasons])

  const handleSelectChange = (event: any) => {
    if (!!event.target.value) {
      setReasonId(event.target.value);
    }
  };

  function handleConfirmation() {
    if (!!reasonId) {
      props.onOk(reasonId);
    }
  }

  return (
    <div>
      <Modal
        open={true}
        primaryButtonText={t("reject") as string}
        primaryButtonDisabled={!reasonId}
        onRequestSubmit={handleConfirmation}
        secondaryButtonText={t("cancel") as string}
        onSecondarySubmit={props.onCancel}
        onRequestClose={props.onCancel}
      >
        <OrderItemCard readonly={true} hideState={true} item={props.orderItem} />

        <Select
          name="reject_reason"
          className="mt-2"
          onChange={handleSelectChange}
          invalid={!!props.error}
          invalidText={props.error}
        >
            {returnReasonList?.map((rr, idx) => (
                <SelectItem key={idx} value={rr.id} text={t(rr.name)}/>
            ))}
        </Select>
      </Modal>
    </div>
  );
};
