import {useState} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem, Tab, Tabs, Text} from "@wfp/ui";
import {CrateBySchoolList} from "../components/report/crate-by-school/list";
import {SnackBySchoolList} from "../components/report/snack-by-school/list";
import {LossBySchoolList} from "../components/report/loss-by-school/list";
import {LoginByEntityList} from "../components/report/login-by-entity/list";
import {OrderBySupplierList} from "../components/report/order-by-supplier/list";
import {OrderItemSchoolList} from "../components/report/order-item-school/list";
import {SchoolAcceptanceTimingList} from "../components/report/school-acceptance-timing/list";
import { CrateReclaimList } from "../components/report/crate-reclaim/list";

export default function Reports() {
    const {t} = useTranslation();
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('ReportsTabIndex') || '0'));

    function handleTabChange(idx: number) {
        localStorage.setItem("ReportsTabIndex", idx.toString());
        setTabIndex(idx);
    }

    return (
        <>
            <div className="visible md:hidden">
                <div className="m-5 flex">
                    <div className="ml-0 text-center">
                        <Text kind='h1'>{t("report_overview")}</Text>
                    </div>
                </div>
                <Tabs selected={tabIndex} className='ml-5'>
                    <Tab onClick={() => handleTabChange(0)} href='' role='order' selected={true}
                         tabIndex={0} label={t('order_by_supplier')}>
                    </Tab>
                </Tabs>
            </div>
            <div className="hidden md:block">
                <div className="m-10">
                    <Breadcrumb className="mb-5">
                        <BreadcrumbItem>
                            <NavLink to="/"><BreadcrumbHome/></NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem disableLink>
                            {t('report')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="text-left mb-5">
                        <Text kind='h1' className="">{t("report_overview")}</Text>
                    </div>
                    <Tabs selected={tabIndex} className=''>
                        <Tab onClick={() => handleTabChange(0)} href='' role='snack_by_school' selected={true}
                             tabIndex={0} label={t('snack_by_school')}>
                            <SnackBySchoolList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(1)} href='' role='crate_by_school' selected={true}
                             tabIndex={0} label={t('crate_by_school')}>
                            <CrateBySchoolList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(2)} href='' role='order-by-supplier' selected={true}
                             tabIndex={1} label={t('order_by_supplier')}>
                            <OrderBySupplierList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(3)} href='' role='order_item_by_school' selected={true}
                             tabIndex={2} label={t('order_item_by_school')}>
                            <OrderItemSchoolList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(4)} href='' role='loss_by_school' selected={true}
                             tabIndex={3} label={t('loss_by_school')}>
                            <LossBySchoolList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(5)} href='' role='school_acceptance_timing' selected={true}
                             tabIndex={4} label={t('school_acceptance_timing')}>
                            <SchoolAcceptanceTimingList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(6)} href='' role='login_by_entity' selected={true}
                             tabIndex={5} label={t('login_by_entity')}>
                            <LoginByEntityList/>
                        </Tab>
                        <Tab onClick={() => handleTabChange(7)} href='' role='crate_by_supplier' selected={true}
                             tabIndex={6} label={t('crate_by_supplier')}>
                            <CrateReclaimList/>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}


