import {useEffect, useState} from "react";
import {Blockquote} from "@wfp/ui";
import {ListPage, OrderItem, Role, ServerError} from "../../../models/types";
import {LoadingCenter} from "../../../components/commons/loading-center";
import {OrderItemList} from "../../../components/order-item/list";
import {getOrderItems} from "../../../restapi/order-item";
import {useLoggedUser} from "../../../hooks/user";

const sorting = ["-delivery_date"];
const DriverRecentFilters = [{ key: "state", value:  "DELIVERED" }, {key: 'has_returned_items', value: 'true'}];
const SchoolRecentFilters = [{ key: "state", value:  "DONE" }];


export default function Recent() {
    const [error, setError] = useState<ServerError>();
    const [page, setPage] = useState<ListPage<OrderItem>>();

    const user = useLoggedUser({});

    useEffect(() => {
        if (!!user) {
            const filters = user.role === Role.SupplierDriver ? DriverRecentFilters : SchoolRecentFilters;
            getOrderItems(1, 5, filters, sorting)
                .then(res => setPage(res))
                .catch(e => setError(e));
        }
    }, [user]);

    return (
        <>
            {!!error?.detail && (
            <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
            <LoadingCenter />
            )}
            {!!page && (
            <>
                <div className="visible md:hidden">
                    <OrderItemList page={page} mobile={true}/>
                </div>
                <div className="hidden md:block">
                    <OrderItemList page={page} mobile={false}/>
                </div>
            </>
            )}
        </>
    );
}


