import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote} from "@wfp/ui";
import {School} from "../../../models/types";
import {getSchools} from "../../../restapi/school";
import {SMETSTypeahead} from "../../commons/typeahead/typeahead";
import {WizardError} from "./wizard";
import "./wizard.css";

interface IProps {
    error?: WizardError;
    onChange: (e: School) => void;
    school: School;
}

export function PageSchools(props: IProps) {
    const {t} = useTranslation();
    const [selectedSchool, setSelectedSchool] = useState<School>(props.school);

    function handleChangeSchool(e: any) {
        props.onChange(e as School);
    }

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("select_school_title")}</h2>
                <p>{t("select_school_subtitle")}</p>
            </Blockquote>
            <div className="flex">
                <div className="w-2/3">
                    <SMETSTypeahead<School>
                        className="w-full"
                        invalid={!!props.error?.school}
                        invalidText={t((props.error?.school || []).toString())!}
                        noOptionsMessage={t('no_option_value') as string}
                        value={selectedSchool}
                        onSelectOption={(o) => {
                            handleChangeSchool(o)
                            setSelectedSchool({
                                ...selectedSchool,
                                id: Number(o.id),
                                name: o.name
                            } as School)
                        }}
                        options={!!selectedSchool ? [selectedSchool] : undefined}
                        label={t('schools')}
                        search={(input) => getSchools(1, 5, [{key: "name_ic", value: input}, {key: 'central', value: 'true'}]).then(res => res.results)}
                    />
                </div>
            </div>
        </div>
    )
}
