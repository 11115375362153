import React from "react";
import {useTranslation} from "react-i18next";
import {StepNavigation} from "@wfp/ui";
import {SMETSStepNavigationItem} from "../../commons/step-navigation-item";
import {orderWizardPages} from "./wizard";


export function Sidebar(props: {selectedPage: number, onChange: (page: number) => void}) {
    const {t} = useTranslation();
    return (
        <StepNavigation selectedPage={props.selectedPage} role=''>
            {orderWizardPages.map((p, idx) =>
                <SMETSStepNavigationItem className='mr-0'
                    key={idx}
                    page={idx}
                    label={t(p.label)!}
                    selectedPage={props.selectedPage}
                    onChangePage={(page) => props.onChange(page)}
                />
             )}
        </StepNavigation>
    )
}
