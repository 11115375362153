import {Pagination} from "@wfp/ui";
import {KVPair, ListPage} from "../../../models/types";

interface Props<T> {
    page: ListPage<T> | undefined,
    loadPage: (page: number, pageSize?: number, filters?: KVPair[], sorting?: string[]) => void
}

const PageSizeOptions = [5, 10, 20, 50, 100];

export function TablePagination<T>({page, loadPage}: Props<T>) {

    const changePageTable = (pageTable: any) => loadPage(pageTable.page, pageTable.pageSize, page?.filtering, page?.sorting)

    return (
        <Pagination
            pageSize={page?.pageSize}
            pageSizes={PageSizeOptions}
            page={1}
            totalItems={page?.count}
            onChange={changePageTable}
        />
    );
}
