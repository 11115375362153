import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { iconDeleteGlyph, iconEditGlyph, iconLaunchGlyph } from "@wfp/icons"
import { Icon } from "@wfp/ui";
import { useLoggedUser } from "../../hooks/user";
import { Role } from "../../models/types";

function MobileHome() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useLoggedUser({});

    const handleQRScan = () => navigate('/qrscan')
    const handleDeliveries = () => navigate('/mobile/deliveries')
    const handleRecent = () => navigate('/mobile/recent')
    const handleSurvey = () => navigate('/survey/wizard')


    return (
        <>
                <div className="flex h-screen">
                    <div className="mt-20 mb-auto w-full">
                        <div className="flex flex-col space-y-10 items-center text-white font-bold">
                            <div className="flex cursor-pointer px-8 py-12 bg-wfp-blue rounded-lg w-4/5 h-1/3"
                                onClick={handleQRScan}>
                                <Icon className="mr-2 ml-2" description={''} fill="white"
                                    icon={iconLaunchGlyph} /> {t('scan_qr_code')}
                            </div>
                            <div className="flex cursor-pointer px-8 py-12 bg-wfp-blue rounded-lg w-4/5 h-1/3"
                                onClick={handleDeliveries}>
                                <FontAwesomeIcon className="mr-2 ml-2" icon={faShoppingBasket} /> {t('your_current_deliveries')}
                            </div>
                            {user?.role === Role.PartnerMonitor && (
                                <div className="flex cursor-pointer px-8 py-12 bg-wfp-blue rounded-lg w-4/5 h-1/3"
                                onClick={handleSurvey}>
                                <Icon className="mr-2 ml-2" description={''} fill="white"
                                    icon={iconEditGlyph} /> {t('add_survey')}
                            </div>
                            )}
                            {user?.role !== Role.SchoolDriver  && user?.role !== Role.PartnerMonitor &&(
                                <div className="flex cursor-pointer px-8 py-12 bg-wfp-blue rounded-lg w-4/5 h-1/3"
                                    onClick={handleRecent}>
                                    <Icon className="mr-2 ml-2" description={''} fill="white"
                                        icon={user?.role === Role.SupplierDriver ? iconEditGlyph : iconDeleteGlyph} />
                                    {user?.role === Role.SupplierDriver ? t('your_modifications') : t('declare_loss')}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
        </>
    );
}

export default MobileHome;
