import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Module, ModuleBody, Select, SelectItem} from "@wfp/ui";
import {SMETSModuleHeader} from "../../commons/module-header";
import {useRetrieveDistricts} from "../../../hooks/geo";

export function SchoolFilters(props: { onSubmit: (id: number) => void }) {
    const [visible, setVisible] = useState(false);
    const [districtSelected, setDistrictSelected] = useState<number>(0);
    const {t} = useTranslation();
    const districts = useRetrieveDistricts({});

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setDistrictSelected(0);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-3 space-x-5 items-baseline text-start">
                            <Select
                                labelText={t('district')}
                                value={districtSelected}
                                onChange={(e: any) => setDistrictSelected(e.target?.value)}>
                                {[{id: 0, name: t("choose_one")}, ...(districts || [])].map((district, idx) => (
                                    <SelectItem key={idx} id={district.id.toString()} value={district.id}
                                                text={district.name}/>
                                ))}
                            </Select>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                    onClick={() => props.onSubmit(districtSelected)}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
