import {ServerError, Partner} from "./types";


export interface PartnerError extends ServerError {
    detail: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    phone: string[] | undefined;
}

class PartnerErrorInfo implements PartnerError {
    detail: string[] | undefined;
    errors: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    phone: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validatePartner(partner: Partner): Promise<Partner> {
    const error = new PartnerErrorInfo();
    if (!partner.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!partner.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(partner) : Promise.reject(error);
}
