import { buildQueryString, restapi } from "./restapi";
import {
    CrateBySchool,
    SnackBySchool,
    KVPair,
    ListPage,
    LossBySchool,
    LoginByEntity,
    OrderSupplierReport,
    OrderItemSchool,
    SchoolAcceptanceTiming,
    CrateReclaimSupplierReport
} from '../models/types';

export function downloadCrateBySchool(options?: KVPair[]): Promise<boolean> {
    return downloadReport('crate-school', options);
}
export function downloadSnackBySchool(options?: KVPair[]): Promise<boolean> {
    return downloadReport('snack-school', options);
}
export function downloadLoginByEntity(options?: KVPair[]): Promise<boolean> {
    return downloadReport('user-login', options);
}
export function downloadLossBySchool(options?: KVPair[]): Promise<boolean> {
    return downloadReport('loss-school', options);
}
export function downloadOrdersBySupplier(options?: KVPair[]): Promise<boolean> {
    return downloadReport('order-supplier', options);
}
export function downloadOrdersItemSchool(options?: KVPair[]): Promise<boolean> {
    return downloadReport('order-item-school', options);
}
export function downloadSchoolAcceptanceTiming(options?: KVPair[]): Promise<boolean> {
    return downloadReport('school-acceptance-timing', options);
}
export function downloadCrateReclaim(options?: KVPair[]): Promise<boolean> {
    return downloadReport('crate-reclaim-supplier', options);
}
function downloadReport(report: string, options?: KVPair[]): Promise<boolean> {
    const formatOptions = [{ key: '__format__', value: 'csv' }];
    const qs = buildQueryString(!!options ? options.concat(formatOptions) : formatOptions);
    return restapi.get(`/api/v1/smt_report/${report}/?${qs}`, {
        responseType: 'arraybuffer',
        headers: {
            'Content-Type': 'text/csv',
            //'Accept': 'text/csv'
        }
    })
        .then((res) => {
            const cd = res.headers['content-disposition'] || '';
            const mask = 'attachment; filename='
            const filename = cd.startsWith(mask) ? cd.slice(mask.length).replaceAll('\\', '').replaceAll('"', '').replaceAll("'", "") : 'report.csv';
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            return true;
        });
}
export function getCrateBySchool(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<CrateBySchool>> {
    return getReport<CrateBySchool>('crate-school', page, page_size, options, sorting);
}
export function getSnackBySchool(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<SnackBySchool>> {
    return getReport<SnackBySchool>('snack-school', page, page_size, options, sorting);
}
export function getLoginByEntity(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<LoginByEntity>> {
    return getReport<LoginByEntity>('user-login', page, page_size, options, sorting);
}
export function getLossBySchool(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<LossBySchool>> {
    return getReport<LossBySchool>('loss-school', page, page_size, options, sorting);
}
export function getOrdersBySupplier(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<OrderSupplierReport>> {
    return getReport<OrderSupplierReport>('order-supplier', page, page_size, options, sorting);
}
export function getOrdersItemSchool(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<OrderItemSchool>> {
    return getReport<OrderItemSchool>('order-item-school', page, page_size, options, sorting);
}
export function getSchoolAcceptanceTiming(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<SchoolAcceptanceTiming>> {
    return getReport<SchoolAcceptanceTiming>('school-acceptance-timing', page, page_size, options, sorting);
}
export function getCrateReclaim(page = 1, page_size?: number, options?: KVPair[], sorting?: string[]): Promise<ListPage<CrateReclaimSupplierReport>> {
    return getReport<CrateReclaimSupplierReport>('crate-reclaim-supplier', page, page_size, options, sorting);
}

function getReport<T>(report: string, page = 1, page_size?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<T>> {
    const pageOptions = [{ key: 'page', value: String(page) }, { key: 'page_size', value: String(page_size || '') }];
    if (!!sorting) {
        pageOptions.push({ key: '__sort__', value: sorting.toString() });
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<T>>(`/api/v1/smt_report/${report}/?${qs}`).then(res => ({ ...res.data, filtering: filters, sorting: sorting }));
}


