import { restapi, buildQueryString } from "./restapi";
import { OrderItem, ListPage, KVPair, OrderItemChange, OrderItemChangeHistory } from "../models/types";

export function saveOrderItem(data: OrderItem): Promise<OrderItem> {
    if (!data.id) {
        return restapi.post<OrderItem>('/api/v1/smt_core/order_item/', data).then((res) => res.data);
    } else {
        return restapi.patch<OrderItem>(`/api/v1/smt_core/order_item/${data.id}/`, data).then((res) => res.data);
    }
}

export function acceptReturnOrderItem(id: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${id}/accept_return/`).then(res => res.data);
}

export function rejectReturnOrderItem(id: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${id}/reject_return/`).then(res => res.data);
}

export function getOrderItem(pk: number): Promise<OrderItem> {
    return restapi.get<OrderItem>(`/api/v1/smt_core/order_item/${pk}/`).then(res => res.data);
}

export function getOrderItems(page = 1, page_size?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<OrderItem>> {
    const pageOptions = [{ key: 'page', value: String(page) }, { key: 'page_size', value: String(page_size || '') }];
    if (!!sorting) {
        pageOptions.push({ key: '__sort__', value: sorting.toString() });
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<OrderItem>>(`/api/v1/smt_core/order_item/?${qs}`).then(res => ({ ...res.data, filtering: filters, sorting: sorting }));
}

export function pickupOrderItem(pk: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${pk}/pickup/`).then(res => res.data);
}

export function handoverOrderItem(pk: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${pk}/handover/`).then(res => res.data);
}

export function undoPickupOrderItem(pk: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${pk}/undo_pickup/`).then(res => res.data);
}

export function acceptOrderItem(pk: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${pk}/accept/`).then(res => res.data);
}

export function rejectOrderItem(itemId: number, reasonId: number): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${itemId}/reject/`, { "reason": reasonId }).then(res => res.data);
}

export function verifyOrderItemQRCode(code: string): Promise<number> {
    return restapi.post<{ pk: number }>(`/api/v1/smt_core/order_item/verify_code/`, { code }).then(res => Promise.resolve(res.data?.pk));
}

/**
 * Change a confirmed OrderItem all params are optional, one between objectId1 and objectId2 must be present
 * @param itemId instance id
 * @param objectId1 Crate objectId, optional
 * @param quantityAm1 non negative quantity, optional, ignored if objectId1 is not present
 * @param quantityPm1 non negative quantity, optional, ignored if objectId1 is not present
 * @param objectId2 Crate objectId, optional
 * @param quantityAm2 non negative quantity, optional, ignored if objectId2 is not present
 * @param quantityPm2 non negative quantity, optional, ignored if objectId2 is not present
 */
export function changeConfirmed(params: OrderItemChange): Promise<OrderItem> {
    return restapi.post<OrderItem>(`/api/v1/smt_core/order_item/${params.id}/change_confirmed/`, {
        objectId1: params.objectId1,
        quantityAm1: params.quantityAm1,
        quantityPm1: params.quantityPm1,
        objectId2: params.objectId2,
        quantityAm2: params.quantityAm2,
        quantityPm2: params.quantityPm2,
    }).then(res => res.data);
}

export function getOrderItemChangeHistory(code: string): Promise<OrderItemChangeHistory[]> {
    return restapi.get<OrderItemChangeHistory[]>(`/api/v1/smt_audit/order_item_change/`,{ params: { code: code, __pagination__: 0 }}).then(res => res.data);
}
