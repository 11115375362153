import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote} from "@wfp/ui";
import {KVPair, ListPage, ServerError, Sortable, Partner} from "../../models/types";
import {getPartners} from "../../restapi/partner";
import {updateSorting} from "../../restapi/restapi";
import {useInViewport} from "../../hooks/useInViewport";
import {PartnerCard} from "./card";
import {PartnerTable} from "./table";

const SortInfo: Sortable[] = [
    {code: 'id', field: 'id', enabled: true, mode: 1},
    {code: 'name', field: 'name', enabled: true, mode: 0},
];

interface Props {
    page?: ListPage<Partner>;
    mobile?: boolean;
    onClick?: (id: number) => void
    onSelect?: (id: number) => void
}

export const PartnerList = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState<ListPage<Partner>>()
    const [error, setError] = useState<ServerError>();
    const {isInViewport, ref} = useInViewport();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
      if (!!props.page) {
          setPage(props.page);
      }
    }, [props.page]);

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        doLoadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleClickPartner(id: number): void {
        if (!!props.onClick) {
            props.onClick(id);
        } else {
            navigate(`/partners/${id}`);
        }
    }

    function doLoadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        getPartners(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
                return Promise.resolve(res);
            })
            .catch( error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleLoadMore(): boolean {
        const result = !loading && !!page?.next;
        if (result) {
            doLoadPage(page?.pageNumber + 1, page?.pageSize, page?.filtering, page?.sorting);
        }
        return result;
    }

    return (
        <div>
            {!!error?.detail && (
            <Blockquote kind="error" withIcon>
                {error.detail.toString()}
            </Blockquote>
            )}
            {!!page && (
            <div>
                {page?.count === 0 && (
                <Blockquote kind="warning" withIcon>
                    {t("empty_partners_list")}
                </Blockquote>
                )}
                {!!props.mobile && page?.results.map((partner: Partner) => (
                <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                    <PartnerCard key={partner.id} item={partner} />
                </div>
                ))}
                {!!props.mobile && (
                <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                )}
                {!props.mobile && page.count > 0 && (
                <PartnerTable
                    page={page}
                    loadPage={doLoadPage}
                    hidePagination={page.count <= page.results.length}
                    onClick={handleClickPartner}
                    onSelect={props.onSelect}
                    sort={handleSort}
                    sortInfo={SortInfo}
                />
                )}
            </div>
            )}
        </div>
    );
}
