import {useState} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Tab, Tabs, Text} from '@wfp/ui';
import {ServerError} from "../models/types";
import {useGetSnacks} from "../hooks/snack";
import {LoadingCenter} from "../components/commons/loading-center";
import {SnackList} from "../components/snack/list";
import {SnackCategoryList} from "../components/snack-category/list";

export default function Snacks() {
    const {t} = useTranslation();
    const page = useGetSnacks({onError: (e) => setError(e)});
    const [error, setError] = useState<ServerError>();
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('SnacksTabIndex') || '0'));

    function handleTabChange(idx: number) {
        localStorage.setItem("SnacksTabIndex", idx.toString());
        setTabIndex(idx);
    }

    return (
        <>
            {!!error?.detail && (
            <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
            <LoadingCenter/>
            )}
            <div className="visible md:hidden">
                <div className="m-5 flex">
                    <div className="ml-0 text-center">
                        <Text kind='h1'>{t("snack_overview")}</Text>
                    </div>
                </div>

                <Tabs selected={tabIndex} className='ml-5'>
                    <Tab onClick={() => handleTabChange(0)} href='' role='snack' selected={true}
                         tabIndex={0} label={t('snack')}>
                        {!!page && (
                            <SnackList mobile={true} page={page}/>
                        )}
                    </Tab>
                    <Tab onClick={() => handleTabChange(1)} href='' role='category' selected={false}
                         tabIndex={1} label={t('category')}>
                        <SnackCategoryList mobile={true}/>
                    </Tab>
                </Tabs>
            </div>
            <div className="hidden md:block">
                <div className="m-10">
                    <Breadcrumb className="mb-5">
                        <BreadcrumbItem>
                            <NavLink to="/"><BreadcrumbHome/></NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem disableLink>
                            {t('items')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="text-left mb-5">
                        <Text kind='h1' className="">{t("snack_overview")}</Text>
                    </div>
                    <Tabs selected={tabIndex} className=''>
                        <Tab onClick={() => handleTabChange(0)} href='' role='snack' selected={true}
                             tabIndex={0} label={t('snack')}>
                            {!!page && (
                            <SnackList page={page}/>
                            )}
                        </Tab>
                        <Tab onClick={() => handleTabChange(1)} href='' role='category' selected={false}
                             tabIndex={1} label={t('category')}>
                            <SnackCategoryList/>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </>
    );
}


