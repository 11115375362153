import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem, FormWizard, Modal} from "@wfp/ui";
import i18n from "../../../i18n";
import {CommoditySchoolItem, Order, School, ServerError, Wizard} from "../../../models/types";
import {
    CommoditySchoolItemError,
    validateCommoditySchoolItemSynch
} from "../../../models/validate-commodity-school-item";
import {completeWizard, deleteWizard, saveCommoditiesWizard} from "../../../restapi/wizard";
import {useRetrieveCommoditiesWizard} from "../../../hooks/wizard";
import {LoadingCenter} from "../../commons/loading-center";
import {Headerbar} from "./headerbar";
import {Sidebar} from "./sidebar";
import {Navigationbar} from "./navigationbar"
import {PageDelivery} from "./page-delivery"
import {PageSchools} from "./page-schools";
import {PageSummary} from "./page-summary";
import {PageCommodities} from "./page-commodities";
import "./wizard.css";

interface WizardCommodityState {
    error: string | null,
    order: Order,
    items: CommoditySchoolItem[],
    page: number,
    school: School,
}

export interface WizardError extends ServerError {
    detail: string[] | undefined,
    supplier: string[] | undefined,
    school: string[] | undefined,
    deliveryDate: string[] | undefined,
    distributionDate: string[] | undefined,
    items: CommoditySchoolItemError[] | undefined;
}

interface WizardPage {
    header: string,
    label: string,
    title: string,
    nextLabel?: string,
    previousLabel?: string,
    component?: (p: any) => React.ReactNode
}

export const commoditiesWizardPages: WizardPage[] = [
    {
        header: 'select_commodities_header_page',
        title: 'select_commodities_title_page',
        label: 'select_commodities_label_page'
    },
    {
        header: 'select_schools_header_page',
        title: 'select_schools_students_title_page',
        label: 'select_schools_label_page',
    },
    {
        header: 'select_supplier_delivery_date_header',
        title: 'select_deliveries_details_title',
        label: 'select_define_delivery_label',
    },
    {
        header: 'summary',
        title: 'confirm_order_page_title',
        label: 'summary',
        nextLabel: 'confirm',
    }
];

export function CommoditiesWizard() {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>();
    const [page, setPage] = useState<number>();
    const [error, setError] = useState<WizardError>();
    const [stateModified, setStateModified] = useState<boolean>(false);
    const [wizard, setWizard] = useState<Wizard>();
    const initialWizard = useRetrieveCommoditiesWizard({id: Number(id), onError: (e) => setError(e as WizardError)});

    useEffect(() => {
        if (!!initialWizard) {
            if (!initialWizard.id) {
                const value = {data: {page: 0}, title: 'commodities'} as Wizard;
                storeWizard(value).then((res) => doSetPage(res.data.page));
            } else {
                setWizard(initialWizard);
                doSetPage(initialWizard.data?.page || 0);
            }
        }
    }, [initialWizard]);


    function storeWizardState(data: WizardCommodityState): Promise<WizardCommodityState> {
        const w = {...wizard, data: data} as Wizard;
        return storeWizard(w).then((res) => {
            setStateModified(false);
            return Promise.resolve(res.data as WizardCommodityState);
        });
    }

    function storeWizard(w: Wizard): Promise<Wizard> {
        if (!w) {
            return Promise.reject({errors: ["wizard is null"]} as ServerError);
        }
        return saveCommoditiesWizard(w).then((res) => {
            setWizard(res);
            return Promise.resolve(res);
        });
    }

    function handleChangeSchool(school: School): void {
        if (!!wizard) {
            wizard.data.school = school;
            if (!doCheckStateError({...wizard.data, page: 1})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeCommodity(commoditySchoolItems: CommoditySchoolItem[]): void {
        if (!!wizard) {
            wizard.data.items = commoditySchoolItems;
            if (!doCheckStateError({...wizard.data, page: 0})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeOrder(order: Order): void {
        if (wizard) {
            wizard.data.order = {...order} as Order;
            if (!doCheckStateError({...wizard.data, page: 2})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleExitWizard() {
        setMessage('confirm_exit?');
    }

    function handlePreviousPage() {
        if (page !== undefined) {
            handleNavigateToPage(page - 1)
        }
    }

    function handleNextPage() {
        if (page !== undefined) {
            handleNavigateToPage(page + 1)
        }
    }

    function handleNavigateToPage(to: number): void {
        if (to === -1) {
            return handleExitWizard();
        }
        if (to < (page || 0)) {
            return doChangePage(to);
        }
        doValidatePage(page !== undefined ? page : wizard?.data?.page || 0).then((allow) => {
            if (allow) {
                if (to === commoditiesWizardPages.length) {
                    return handleConfirmWizard();
                } else {
                    doChangePage(to);
                }
            }
        }).catch(error => setError(error));
    }

    function handleModalConfirmation(flag: boolean) {
        if (!flag) {
            return setMessage(undefined);
        }
        if (!!wizard) {
            if (!!wizard.id) {
                return deleteWizard(wizard.id).then(() => navigate('/orders'));
            }
            return navigate('/orders');
        }
    }

    function handleConfirmWizard() {
        if (!!wizard) {
            completeWizard(wizard.id).then(
                (w) => navigate(`/orders/${w.data.order.id}`)
            ).catch(
                (error) => setError(error)
            );
        }
    }

    function doChangePage(p: number) {
        if (stateModified) {
            storeWizardState({...wizard?.data, page: p}).then((w) => doSetPage(w.page));
        } else {
            doSetPage(p);
        }
    }

    function doSetPage(p: number) {
        setError(undefined);
        setPage(p);
    }

    function findDuplicates(array: CommoditySchoolItem[]): number[] {
        const duplicates: number[] = [];
        array.forEach((item, index, values) => {
            const duplicated = !!item.commodity && values.slice(0, index).findIndex(subItem => subItem.commodity === item.commodity) !== -1;
            if (duplicated) {
                duplicates.push(index);
            }
        });
        return duplicates;
    }

    function doCheckStateError(data: WizardCommodityState): WizardError | undefined {
        let isValid: boolean;
        const page = data.page;
        let error: WizardError;
        switch (page) {
            case 0:
                const errors = (data.items || [{} as CommoditySchoolItem]).map(item => validateCommoditySchoolItemSynch(item));
                const duplicates = findDuplicates(data.items || [{} as CommoditySchoolItem]);
                if (!!duplicates.length) {
                    duplicates.forEach((duplicateIdx) => {
                        const duplicateError: CommoditySchoolItemError = {
                            ...errors[duplicateIdx],
                            commodity: !!errors.at(duplicateIdx)?.commodity?.length ? [...errors.at(duplicateIdx)!.commodity!, 'duplicate'] : ['duplicate'],
                        } as CommoditySchoolItemError;
                        errors[duplicateIdx] = duplicateError;
                    })
                }
                isValid = !errors.find((error) => !!error);
                error = {
                    items: isValid ? undefined : errors
                } as WizardError;
                break;
            case 1:
                isValid = !!data.school && data.school.id > 0;
                error = {
                    school: isValid ? undefined : ["select_one_school"]
                } as WizardError;
                break
            case 2:
                error = {
                    supplier: !data.order?.supplier ? ["this_field_is_required"] : undefined,
                    deliveryDate: !data.order?.deliveryDate ? ["this_field_is_required"] : undefined,
                    distributionDate: !data.order?.distributionDate ? ["this_field_is_required"] : undefined,
                } as WizardError;
                if (moment(data.order?.distributionDate).isBefore(moment(data.order?.deliveryDate))) {
                    error.distributionDate = ["invalid_value"];
                }
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break;
            case 3:
                error = {
                    ...doCheckStateError({...data, page: 2}),
                    ...doCheckStateError({...data, page: 1}),
                    ...doCheckStateError({...data, page: 0})
                } as WizardError;
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break;
            default:
                isValid = false;
                error = {errors: ["invalid_page"]} as WizardError;
                break;
        }
        return isValid ? undefined : error;
    }

    function doValidatePage(page: number): Promise<boolean> {
        if (!!wizard) {
            const error = doCheckStateError({...wizard.data, page: page});
            setError(error);
            return Promise.resolve(!error);
        }
        return Promise.resolve(false);
    }

    return (
        <div className='m-10'>
            {!wizard && (
                <LoadingCenter/>
            )}
            {!!wizard && (
                <>
                    <div className="hidden md:block mt-10 mb-10">
                        <Breadcrumb className="mb-5">
                            <BreadcrumbItem className={`${i18n.dir() === 'ltr' ? 'mr-0' : '!mr-10'}`}>
                                <NavLink to="/"><BreadcrumbHome/></NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <NavLink to="/orders">{t('order')}</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem disableLink>{t('create_order')}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <h1 className={`text-start mt-10 mb-10 ${i18n.dir() === 'ltr' ? 'mr-0' : 'mr-10'}`}>
                        {t(commoditiesWizardPages[page || 0].title)}
                    </h1>
                    <FormWizard className="mr-3"
                                stickySidebar={false}
                                sidebar={page !== undefined ?
                                    <Sidebar onChange={handleNavigateToPage} selectedPage={page!}/> : null}
                                formHeader={page !== undefined ?
                                    <Headerbar page={page!} message={!!message ? t(message)! : undefined}
                                               errors={!!error?.errors ? [t(error?.errors.toString())!] : !!error?.detail ? [t(error?.detail.toString())!] : undefined}/> : null}//TODO fix i18n
                                formControls={page !== undefined ? <Navigationbar
                                    previous={handlePreviousPage}
                                    previousDisabled={false}
                                    previousLabel={page === 0 ? t("exit")! : commoditiesWizardPages[page!].previousLabel}
                                    next={handleNextPage}
                                    nextDisabled={!!error}
                                    nextLabel={commoditiesWizardPages[page!].nextLabel}
                                /> : null}
                    >
                        {page === 0 && (
                            <PageCommodities
                                error={error}
                                onChange={handleChangeCommodity}
                                items={wizard.data.items || [{} as CommoditySchoolItem]}
                            />
                        )}
                        {page === 1 && (
                            <PageSchools
                                error={error}
                                school={wizard.data.school || {}}
                                onChange={handleChangeSchool}
                            />
                        )}
                        {page === 2 && (
                            <PageDelivery
                                order={wizard?.data.order || {}}
                                error={error}
                                onChange={handleChangeOrder}
                            />
                        )}
                        {page === 3 && (
                            <PageSummary
                                school={wizard.data?.school || []}
                                commoditySchoolItems={wizard.data?.items || []}
                                order={wizard.data?.order}
                            />
                        )}
                    </FormWizard>
                    {!!message && (
                        <div>
                            <Modal
                                hideClose={false}
                                onRequestSubmit={() => handleModalConfirmation(true)}
                                onRequestClose={() => handleModalConfirmation(false)}
                                onSecondarySubmit={() => handleModalConfirmation(false)}
                                open={!!message}
                                primaryButtonText={t("confirm") as string}
                                secondaryButtonText={t("cancel") as string}
                                type='info'
                            >{t(message)}</Modal>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
