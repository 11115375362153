import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Table} from "@wfp/ui";
import {KVPair, ListPage, Role, SchoolMealItem, Sortable} from "../../models/types";
import {useLoggedUser} from "../../hooks/user";
import {TablePagination} from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<SchoolMealItem>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onSelectSchoolMealItems?: (orderItemsId: number[]) => void;
    sortInfo?: Sortable[];
    sort?: (field: string) => void;
}

const headers = ["school_ref", "school_name", "meal", "quantityAM", "quantityPM", "state"];

const sort: Sortable[] = [
    {field: 'school_ref', code: 'school_ref', enabled: true, mode: 0},
    {field: 'school_name', code: 'school_name', enabled: true, mode: 0},
    {field: 'meal', code: 'meal', enabled: true, mode: 0},
    {field: 'quantity_am', code: 'quantityAM', enabled: true, mode: 0},
    {field: 'quantity_pm', code: 'quantityPM', enabled: true, mode: 0},
    {field: 'state', code: 'state', enabled: true, mode: 0},
];

export function SchoolMealItemTable(props: Props) {
    const {t} = useTranslation();
    const user = useLoggedUser({});
    const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);
    const [rowsSelected, setRowsSelected] = useState<boolean[]>([]);
    const [sortables, setSortables] = useState<Sortable[]>(sort);

    useEffect(() => {
        if (!!props.page) {
            const allRowsSelected = props.page.results.map(() => false);
            setRowsSelected(allRowsSelected);
        }
    }, [props.page]);

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);

    function checkOrderItem(idx: number) {
        if (idx === -1) {
            setAllRowsSelected(!allRowsSelected);
            rowsSelected.forEach((_, index) => rowsSelected[index] = !allRowsSelected);
        } else {
            rowsSelected[idx] = !rowsSelected[idx];
            const selected = rowsSelected.filter(f => f);
            if (selected.length === 0) {
                setAllRowsSelected(false);
                idx = -1;
            }
            if (selected.length === rowsSelected.length) {
                setAllRowsSelected(true);
                idx = -1;
            }
        }
        setRowsSelected([...rowsSelected]);
        if (!!props.onSelectSchoolMealItems) {
            if (idx === -1) {
                props.onSelectSchoolMealItems(allRowsSelected ? [] : [-1]);
            } else {
                props.onSelectSchoolMealItems(props.page.results.filter((_, idx) => rowsSelected[idx]).map((oi) => oi.id));
            }
        }
    }

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <>
            <Table>
                <thead>
                <tr className="item-row-head">
                    {user?.role === Role.SupplierManager && (
                        <th>
                            <Checkbox id='all' labelText={''} name='row-select' value={-1}
                                      checked={allRowsSelected || false}
                                      onClick={() => checkOrderItem(-1)}></Checkbox>
                        </th>
                    )}
                    {headers.map((header, idx) => (
                        <th key={idx} onClick={() => handleSorting(idx)}
                            className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                            {t(header)}
                            {!!sortables?.at(idx) && (
                                <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {!!props.page && props.page.results.map((item, idx) => (
                    <tr className="item-row" key={item.id}>
                        {user?.role === Role.SupplierManager && (
                            <td>
                                <Checkbox id={`${item.id}`} labelText={''} name='row-select' value={item.id}
                                          checked={rowsSelected[idx] || false}
                                          onClick={() => checkOrderItem(idx)}></Checkbox>
                            </td>
                        )}
                        <td className="item-col text-start">
                            {item.school}
                        </td>
                        <td className="item-col text-start">
                            {item.schoolName}
                        </td>

                        <td className="item-col text-start">
                            {item.mealName}
                        </td>
                        <td className="item-col text-start">
                            {item.quantityAm}
                        </td>
                        <td className="item-col text-start">
                            {item.quantityPm}
                        </td>
                        <td className="item-col text-start !align-middle">
                            <div className={`bg-order-state-${item.state} rounded-3xl`}>
                                <p className={`ml-2 text-order-state-${item.state}`}>{item?.state}</p>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage}/>
            )}
        </>
    )
}
