import { useQuery } from "react-query";
import { getTransferOrder, getTransferOrderHistory, getTransferOrders } from "../restapi/transfer";
import { KVPair, ListPage, ServerError, TransferOrder, TransferOrderHistoryData } from "../models/types";

export function useRetrieveTransferOrders({ page = 1, pageSize, filters, sorting, onError }: { page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void }): ListPage<TransferOrder> | undefined {
    const itemsQuery = useQuery(['Transfer', 'page'], () => getTransferOrders(page, pageSize, filters, sorting), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}

export function useRetrieveTransferOrder({ id, onError }: { id: number, onError?: (e: ServerError) => void }): TransferOrder | undefined {
    const orderQuery = useQuery(['Transfer'], () => getTransferOrder(id), {
        onError: onError,
        retry: false
    });
    return orderQuery.data;
}

export function useRetrieveTransferOrderHistory({ id, onError }: { id: number, onError?: (e: ServerError) => void }): TransferOrderHistoryData | undefined {
    const orderQuery = useQuery(['transfer-order-history'], () => getTransferOrderHistory(id), {
        onError: onError,
        retry: false
    });
    return orderQuery.data;
}