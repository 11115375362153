import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {RadioButton, Table} from "@wfp/ui";
import {KVPair, ListPage, Order, Role, Sortable} from "../../models/types";
import {dateParse, datetimeParse} from "../../hooks/formats";
import {useLoggedUser} from "../../hooks/user";
import {TablePagination} from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<Order>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onSelectOrder?: (id: number) => void;
    onClick: (id: number) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}

const headerGroups = ["id", "supplier", "content_type", "state", "delivery_date", 'last_modified'];
const headerGroupsIssuance = ["order_id", "number_of_items",  "content_type", "state", "delivery_date", 'last_modified'];

export function OrderTable(props: Props) {
    const {t} = useTranslation();
    const [headers, setHeaders] = useState<string[]>([]);
    const [sortables, setSortables] = useState<Sortable[]>();
    const user = useLoggedUser({});

    useEffect(() => {
        if (!!user) {
            const headers = user.role === Role.SupplierManager ? headerGroupsIssuance : headerGroups;
            setHeaders(headers);
        }
    }, [user]);

    useEffect(() => {
        if (!!props.sortInfo && !!headers) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [headers, props.sortInfo]);

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <div>
            <Table>
                <thead>
                <tr className="item-row-head">
                {!!props.onSelectOrder && (
                    <th></th>
                )}
                {headers.map((header, idx) => (
                    <th key={idx} onClick={() => handleSorting(idx)} className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                        {t(header)}
                        {!!sortables?.length && (
                            <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                        )}
                    </th>
                ))}
                </tr>
                </thead>
                <tbody>
                {props.page.results.map((item: Order) => (
                    <tr className="item-row cursor-pointer" key={item.id}>
                        {!!props.onSelectOrder && (
                            <td>
                                <RadioButton labelText={''} name='row-select' value={item.id}
                                             onClick={() => !!props.onSelectOrder ? props.onSelectOrder(item.id) : {}}></RadioButton>
                            </td>
                        )}
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {item.id}
                        </td>
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {user?.role === Role.SupplierManager ? item.numberOfItems : item.supplierName}
                        </td>
                          <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {item.contentTitle}
                        </td>
                        <td className="item-col text-start p-0.5" onClick={() => props.onClick(item.id)}>
                            <div className={`bg-order-state-${item.state} rounded-3xl`}>
                                <p className={`ml-2 text-order-state-${item.state}`}>{item.state}</p>
                            </div>
                        </td>
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {dateParse(item.deliveryDate)}
                        </td>
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {!!item.updatedOn ? datetimeParse(item.updatedOn) : ''}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage}/>
            )}
        </div>
    )
}
