import {useEffect, useState} from 'react';
import {Blockquote} from "@wfp/ui";
import {ListPage, Role, ServerError, TransferOrder} from "../../../models/types";
import {LoadingCenter} from "../../../components/commons/loading-center";
import {OrderItemList} from "../../../components/order-item/list";
import {useRetrieveOrderItems} from "../../../hooks/order-item";
import {useLoggedUser} from "../../../hooks/user";
import {useRetrieveSchoolMealItems} from "../../../hooks/school-meal-item";
import {SchoolMealItemList} from "../../../components/school-meal-item/list";
import {TransferOrderList} from '../../../components/transfer/list';
import {getTransferOrders} from "../../../restapi/transfer";

export default function Deliveries() {
    const user = useLoggedUser({});
    const [error, setError] = useState<ServerError>();
    const [pageTransferOrders, setPageTransferOrders] = useState<ListPage<TransferOrder>>();

    const filters = [{key: "state", value: user?.role === Role.SchoolRepresentative ? "DELIVERED" : "LOADED"}];
    const page = useRetrieveOrderItems({filters, onError: (e) => setError(e)});
    const pageSchoolMeals = useRetrieveSchoolMealItems({filters, onError: (e) => setError(e)});

    useEffect(() => {
        if (user?.role === Role.SchoolRepresentative) {
            getTransferOrders(1,undefined, filters)
                .then((res) => setPageTransferOrders(res))
                .catch((e) => setError(e));
        }
    }, [user]);



    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && !pageSchoolMeals && (
                <LoadingCenter/>
            )}
            {user?.role !== Role.PartnerMonitor ? (
                <>
                    {!!page?.count && (
                        <div>
                            <OrderItemList page={page} mobile={true}/>
                        </div>
                    )}
                    {!!pageSchoolMeals?.count && (
                        <div>
                            <SchoolMealItemList page={pageSchoolMeals} mobile={true}/>
                        </div>
                    )}
                    {user?.role === Role.SchoolRepresentative && !!pageTransferOrders?.count && (
                        <div>
                            <TransferOrderList page={pageTransferOrders} mobile={true}/>
                        </div>
                    )}
                </>
            ) : (
                <>
                    {!!pageTransferOrders?.count && (
                        <div>
                            <TransferOrderList page={pageTransferOrders} mobile={true} />
                        </div>
                    )}
                </>
            )}
        </>
    );
}


