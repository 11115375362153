import {useState} from "react";
import {useTranslation} from "react-i18next";
import {SingleDatePicker} from "react-dates";
import moment from 'moment'
import {Moment} from "moment/moment";
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import {Button, Form, Module, ModuleBody, Select, SelectItem, TextInput} from "@wfp/ui";
import {SMETSModuleHeader} from "../commons/module-header";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";
import {KVPair, OrderState, Role, Supplier} from "../../models/types";
import {getSuppliers} from "../../restapi/supplier";
import {useLoggedUser} from "../../hooks/user";
import {dateFormat} from "../../hooks/formats";

export function OrderFilters(props: { onSubmit: (filters: KVPair[]) => void }) {
    const [visible, setVisible] = useState(false);
    const [orderId, setOrderId] = useState<string>('');
    const [selectedStateId, setSelectedStateId] = useState<number>(0);
    const [deliveryDateFilter, setDeliveryDateFilter] = useState<Moment | null>();
    const [focusDelivery, setFocusDelivery] = useState(false);
    const [supplierSelected, setSupplierSelected] = useState<Supplier>();
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const user = useLoggedUser({});
    const stateList = ["choose_one", ...Object.values(OrderState)];
    const stateListSupplierManager = ["choose_one", OrderState.LOADED, OrderState.CONFIRMED, OrderState.DONE];

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setDeliveryDateFilter(null);
        setSelectedStateId(0);
        setOrderId('');
        setSupplierSelected({} as Supplier);
    }


    function handleApplyFilters() {
        const filters: KVPair[] = [];
        if (!isNaN(Number(orderId))) {
            filters.push({key: "id", value: orderId});
        }
        if (!!supplierSelected && !isNaN(supplierSelected.id)) {
            filters.push({key: "supplier", value: supplierSelected.id.toString()});
        }
        if (selectedStateId > 0 && user?.role !== Role.SupplierManager) {
            filters.push({key: "state", value: stateList[selectedStateId]});
        }
        if (selectedStateId > 0 && user?.role === Role.SupplierManager) {
            filters.push({key: "state", value: stateListSupplierManager[selectedStateId]});
        }
        if (!!deliveryDateFilter) {
            filters.push({key: "deliveryDate", value: dateFormat(deliveryDateFilter) || ''});
        }
        props.onSubmit(filters);
    }

    function handleDeliveryDate(value: Moment | null) {
        setDeliveryDateFilter(value!);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-5 space-x-5 items-baseline text-start">
                            <TextInput
                                className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}
                                labelText={t('order_id') as string}
                                min={0}
                                onChange={(e: any) => setOrderId(e.target?.value)}
                                value={orderId}
                            />
                            {user?.role !== Role.SupplierManager && (
                            <SMETSTypeahead<Supplier>
                                noOptionsMessage={t('no_option_value') as string}
                                value={supplierSelected}
                                onSelectOption={(o) => setSupplierSelected({...supplierSelected, id: Number(o.id), name: o.name } as Supplier)}
                                label={t('suppliers')}
                                search={(input) => getSuppliers(1, 5, [{key: "name_ic", value: input}]).then(res => res.results)}
                            />
                            )}
                            <Select
                                labelText={t('state')}
                                value={selectedStateId}
                                onChange={(e: any) => setSelectedStateId(e.target?.value)}>
                                {(user?.role === Role.SupplierManager ? stateListSupplierManager : stateList).map((state, idx) => (
                                    <SelectItem key={idx} value={idx} text={t(state)}/>
                                ))}
                            </Select>
                            <div>
                                <label htmlFor="deliveryDateFilter" className="wfp--label">
                                    {t("select_delivery_date_title")}
                                </label>
                                <SingleDatePicker
                                    isRTL={i18n.dir() === 'rtl'}
                                    renderMonthText={month => {
                                        month.locale(i18n.language) // TODO: check this!!
                                        return (moment(month).format('MMMM YYYY'))
                                    }}
                                    renderDayContents={day => {
                                        day.locale('en') // TODO: check this!!
                                        return (moment(day).format('D'))
                                    }}
                                    date={deliveryDateFilter!}
                                    isOutsideRange={() => false}
                                    onDateChange={handleDeliveryDate}
                                    focused={focusDelivery}
                                    onFocusChange={(f) => setFocusDelivery(f.focused)}
                                    numberOfMonths={1}
                                    showClearDate={true}
                                    displayFormat="DD/MM/YYYY"
                                    id="deliveryDateFilter"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                    onClick={handleApplyFilters}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
