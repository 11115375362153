import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Module, ModuleBody } from "@wfp/ui";
import { SMETSModuleHeader } from "../commons/module-header";
import { SMETSTypeahead } from "../commons/typeahead/typeahead";
import { KVPair, Role, School, Supplier } from "../../models/types";
import { getSuppliers } from "../../restapi/supplier";
import { useLoggedUser } from "../../hooks/user";
import { getSchools } from "../../restapi/school";
import { SingleDatePicker } from "react-dates";
import moment from 'moment'
import { Moment } from "moment/moment";
import i18n from "../../i18n";
import { dateFormat } from "../../hooks/formats";

export function CrateReclaimFilter(props: { onSubmit: (filters: KVPair[]) => void }) {
    const [visible, setVisible] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState<School>();
    const [supplierSelected, setSupplierSelected] = useState<Supplier>();
    const [dateFilter, setDateFilter] = useState<Moment | null>();
    const [focusDate, setFocusDate] = useState(false);
    const { t } = useTranslation();
    const user = useLoggedUser({});


    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setSelectedSchool({} as School);
        setSupplierSelected({} as Supplier);
        setDateFilter(null);
    }


    function handleApplyFilters() {
        const filters: KVPair[] = [];
        if (!!supplierSelected && !isNaN(supplierSelected.id)) {
            filters.push({ key: "supplier", value: supplierSelected.id.toString() });
        }
        if (!!selectedSchool && !isNaN(selectedSchool.id)) {
            filters.push({ key: "school", value: selectedSchool.id.toString()});
        }
        if (!!dateFilter) {
            filters.push({key: "date", value: dateFormat(dateFilter) || ''});
        }
        props.onSubmit(filters);
    }



    function handleyDate(date: Moment | null): void {
        setDateFilter(date!);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>} />
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-4 space-x-5 items-baseline text-start">
                            {user?.role !== Role.SupplierManager && (
                                <SMETSTypeahead<Supplier>
                                    noOptionsMessage={t('no_option_value') as string}
                                    value={supplierSelected}
                                    onSelectOption={(o) => setSupplierSelected({ ...supplierSelected, id: Number(o.id), name: o.name } as Supplier)}
                                    label={t('suppliers')}
                                    search={(input) => getSuppliers(1, 5, [{ key: "name_ic", value: input }]).then(res => res.results)}
                                />
                            )}
                                <SMETSTypeahead<School>
                                    placeholder={t('search_name') as string}
                                    noOptionsMessage={t('no_option_value') as string}
                                    label={t('schools')}
                                    value={selectedSchool}
                                    onSelectOption={(e) => setSelectedSchool({...selectedSchool, id: Number(e.id), name: e.name} as School)}
                                    search={(e) => getSchools(1, 5, [{
                                        key: "name_ic",
                                        value: e
                                    }]).then(res => res.results)}
                                >
                                </SMETSTypeahead>
                                <div>
                                <label htmlFor="dateFilter" className="wfp--label">
                                    {t("select_date")}
                                </label>
                                <SingleDatePicker
                                    isRTL={i18n.dir() === 'rtl'}
                                    renderMonthText={month => {
                                        month.locale(i18n.language) // TODO: check this!!
                                        return (moment(month).format('MMMM YYYY'))
                                    }}
                                    renderDayContents={day => {
                                        day.locale('en') // TODO: check this!!
                                        return (moment(day).format('D'))
                                    }}
                                    date={dateFilter!}
                                    isOutsideRange={() => false}
                                    onDateChange={handleyDate}
                                    focused={focusDate}
                                    onFocusChange={(f) => setFocusDate(f.focused)}
                                    numberOfMonths={1}
                                    showClearDate={true}
                                    displayFormat="DD/MM/YYYY"
                                    id="dateFilter"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                onClick={handleApplyFilters}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
