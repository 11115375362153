import { RadioButton, Table } from '@wfp/ui';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {KVPair, ListPage, Sortable, Partner } from "../../models/types";
import {TablePagination} from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<Partner>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onClick: (id: number) => void,
    onSelect?: (id: number) => void,
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}

const headers = ["id", "name"];

export function PartnerTable(props: Props) {
    const {t} = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>();

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <>
        <Table>
            <thead>
                <tr className="item-row-head">
                {!!props.onSelect && (
                    <th></th>
                )}
                {headers.map((header, idx) => (
                    <th key={idx} onClick={() => handleSorting(idx)} className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                        {t(header)}
                        {!!sortables?.length && (
                            <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                        )}
                    </th>
                ))}
                </tr>
            </thead>
            <tbody>
            {props.page.results.map((item: Partner) => (
            <tr className="item-row cursor-pointer" key={item.id}>
                {!!props.onSelect && (
                    <td className="item-col text-start">
                        <RadioButton labelText={''} name='row-select' value={item.id}
                                     onClick={() => !!props.onSelect ? props.onSelect(item.id) : {}}></RadioButton>
                    </td>
                )}
                <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                    {item.id}
                </td>
                <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                    {item.name}
                </td>
            </tr>
            ))}
            </tbody>
        </Table>
        {!props.hidePagination && (
            <TablePagination page={props.page} loadPage={props.loadPage}/>
        )}
        </>
    )
}
