import {useTranslation} from "react-i18next";
import {Blockquote, Table} from "@wfp/ui";
import {School, SchoolMeal} from "../../../models/types";
import "./wizard.css";
import {dateFormat} from "../../../hooks/formats";

interface IProps {
    schools: School[],
    schoolMeal: SchoolMeal,
}

const Headers = ["school_ref", "school_name", "district", "meal", "quantityAM", "quantityPM"];

export function PageSummary(props: IProps) {

    const {t} = useTranslation();

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("confirm_order_title")}</h2>
                <div>
                    <p>{t("confirm_order_subtitle")}</p>
                </div>
            </Blockquote>
            <div className="mt-2 mb-4">
                <div className='flex'>
                    <div
                        className={props.schoolMeal?.deliveryDate ? `ml-1 bg-sky-100 p-2 rounded-full text-color-blue` : 'ml-1 bg-red-10 p-2 rounded-full text-red-700'}>
                        {t("delivery_date")}: {dateFormat(props.schoolMeal?.deliveryDate)}
                    </div>
                </div>
            </div>
            <Table className='text-start'>
                <thead>
                <tr className="item-row-head">
                    {Headers.map((title, index) => (
                        <th key={index}>{t(title)}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.schools?.map((s, idx) => (
                    <tr className="item-row" key={idx}>
                        <td className="item-col">
                            {s.cerd}
                        </td>
                        <td className="item-col">
                            {s.name}
                        </td>
                        <td className="item-col">
                            {s.districtName}
                        </td>
                        <td className="item-col">
                            {props.schoolMeal?.mealName}
                        </td>
                        <td className="item-col">
                            {s.numberOfStudentsAm}
                        </td>
                        <td className="item-col">
                            {s.numberOfStudentsPm}
                        </td>
                    </tr>))}
                </tbody>
            </Table>
        </div>
    )
}

