import { useQuery } from "react-query";
import { retrieveTransferPositions } from '../restapi/position';

import { ServerError } from "../models/types";


const options = {
    enableHighAccuracy: false,
    timeout: 10000,
    maximumAge: 0,
};

export function getCurrentPosition(): Promise<GeolocationPosition> {
    return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}

export function useGetCurrentPosition({ onError }: { onError?: (e: ServerError) => void }) {
    const userQuery = useQuery(['position'], () => getCurrentPosition(), {
        onError: onError
    });
    return userQuery.data;
}

export function useGetTransferPositionHistory({ id, onError }: { id: number, onError?: (e: ServerError) => void }) {
    const userQuery = useQuery(['tansfer-position'], () => retrieveTransferPositions(id), {
        onError: onError
    });
    return userQuery.data;
}
