import {getPartnerWeekByDate} from "../restapi/planning";
import {PartnerWeek, ServerError} from "../models/types";
import {useQuery} from "react-query";


export function useGetPartnerWeekByDate({date, onError, onSuccess}: {date: string, onError?: (e: ServerError) => void, onSuccess?: (data: PartnerWeek) => void}): PartnerWeek | undefined {
    const partnerWeekQuery = useQuery(['partner-week', date], () => getPartnerWeekByDate(date), {
        onSuccess: onSuccess,
        onError: onError
    });
    return partnerWeekQuery.data;
}
