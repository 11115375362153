import {useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Text} from "@wfp/ui";
import {iconAddGlyph, iconDocument} from '@wfp/icons';
import {LoadingCenter} from "../components/commons/loading-center";
import {useRetrieveOrders} from "../hooks/order";
import {OrderList} from "../components/order/list";
import {OrderState, ServerError} from "../models/types";

export default function Issuance() {
    const {t} = useTranslation();
    const page = useRetrieveOrders({
        filters: [
            {key: 'state', value: [OrderState.REQUESTED]},
            {key: 'state', value: [OrderState.CONFIRMED]},
            {key: 'state', value: [OrderState.LOADED]},
            {key: 'state', value: [OrderState.DONE]},
        ],
        onError: (e) => setError(e),
    });
    const [error, setError] = useState<ServerError>();
    const navigate = useNavigate();
    const [selectedId , setSelectedId] = useState<number>();

    function handleIssueOrder() {
        if(!!selectedId){
            navigate(`/issuance/${selectedId}`);
        }
    }

    function handleClickOrder(id: number): void {
        if(!!id) {
            setSelectedId(id);
            navigate(`/issuance/${id}`);
        }
    }

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter/>
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <div className="m-5 flex">
                             <div className="text-center">
                                <Text kind='h1'>{t("issuance_overview")}</Text>
                            </div>
                        </div>
                        <OrderList page={page} mobile={true} onSelectOrder={setSelectedId} onClick={handleClickOrder}/>
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb className="mb-5">
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('issuance')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="flex mb-5">
                                <div className="ml-0">
                                    <Text kind='h1'>{t("issuance_overview")}</Text>
                                </div>
                                <div className="ml-auto mr-0">
                                    <div className="grid grid-flow-row auto-rows-max space-y-3">
                                        <Button className="!inline-flex" icon={iconAddGlyph} iconReverse iconDescription="issue"
                                                disabled={!selectedId} onClick={handleIssueOrder}>{t('issue_order')}</Button>
                                        <Button className="!inline-flex" icon={iconDocument} iconReverse iconDescription="print"
                                                 disabled={true}>{t('print_waybills')}</Button>
                                    </div>
                                </div>
                            </div>
                            <OrderList page={page} mobile={false} onSelectOrder={setSelectedId} onClick={handleClickOrder} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}


