import {useTranslation} from "react-i18next";
import {Modal} from "@wfp/ui";
import {SchoolMealItem, ServerError} from "../../models/types";
import {SchoolMealItemCard} from "./card";

export interface Props {
    error?: ServerError;
    schoolMealItem: SchoolMealItem;
    onOk?: () => void;
    onCancel: () => void;
}

export const AcceptConfirmation = (props: Props) => {

    const {t} = useTranslation();

    return (
        <div>
            <Modal
                open={true}
                primaryButtonText={t('pickup') as string}
                onRequestSubmit={props.onOk}
                secondaryButtonText={t('cancel') as string}
                onSecondarySubmit={props.onCancel}
                onRequestClose={props.onCancel}
            >
                <SchoolMealItemCard readonly={true} hideState={true} item={props.schoolMealItem}/>
            </Modal>
        </div>
    );
};
