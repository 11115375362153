import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Blockquote, Button, Modal} from "@wfp/ui";
import {iconAddGlyph, iconDeleteGlyph} from "@wfp/icons";
import i18n from "../../i18n";
import {KVPair, ListPage, ServerError, Snack, Sortable} from '../../models/types';
import {SnackError, validateSnack} from "../../models/validate-snack";
import {updateSorting} from "../../restapi/restapi";
import {disableSnack, getSnacks, saveSnack} from "../../restapi/snack";
import {useInViewport} from "../../hooks/useInViewport";
import {SnackCard} from "./card";
import {SnackForm} from "./form";
import {SnackFilters} from "./filters";
import {SnackTable} from "./table";

const SortInfo: Sortable[] = [
    {code: 'id', field: 'id', enabled: true, mode: 0},
    {code: 'name', field: 'name', enabled: true, mode: 0},
    {code: 'category', field: 'category', enabled: true, mode: 0}
];

interface Props {
    page?: ListPage<Snack>,
    mobile?: boolean,
    disableFilter?: boolean,
    onClick?: (id: number) => void
}

export const SnackList = (props: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {isInViewport, ref} = useInViewport();
    const [error, setError] = useState<ServerError>();
    const [loading, setLoading] = useState(false);
    const [openCreate, setOpenCreate] = useState(false);
    const [openDisable, setOpenDisable] = useState(false);
    const [page, setPage] = useState<ListPage<Snack>>();
    const [selected, setSelected] = useState<Snack>();
    const [snackError, setSnackError] = useState<SnackError>();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        }
    }, [props.page]);

    function doLoadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        getSnacks(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleFilter(filters: KVPair[]): void {
        doLoadPage(page?.pageNumber, page?.pageSize, filters, page?.sorting);
    }

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        doLoadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleClickSnack(id: number): void {
        if (!!props.onClick) {
            props.onClick(id);
        } else {
            navigate(`/snacks/${id}`);
        }
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page?.next;
            if (result) {
                setLoading(true);
                doLoadPage(page.pageNumber + 1, page.pageSize, page.filtering, page.sorting);
            }
            return result;
        }
        return false;
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(item => item.id === id);
        setSelected(selected);
    }

    function handleDisableClose() {
        setOpenDisable(false);
        setSnackError(undefined);
    }

    function handleDisableSubmit() {
        if (!!selected) {
            disableSnack(selected.id)
                .then(() => {
                    setSnackError(undefined);
                    setOpenDisable(false);
                    setSelected(undefined);
                    doLoadPage();
                })
                .catch(error => setSnackError(error));
        }
    }

    function handleCreateCancel() {
        setOpenCreate(false);
        setSnackError(undefined);
        setSelected(undefined);
    }

    function handleCreateSubmit() {
        if (!!selected) {
            validateSnack(selected)
                .then((data) =>
                    saveSnack(data)
                        .then(() => {
                            setOpenCreate(false);
                            setError(undefined);
                            setSelected(undefined);
                            doLoadPage();
                        }).catch(e => setError(e)))
                .catch(e => setSnackError(e));
        }
    }

    function handleCreateOpen() {
        setOpenCreate(true);
        setSnackError(undefined);
    }

    return (
        <div>
        {!!error?.detail && (
            <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
        )}
        {page?.count === 0 && (
            <Blockquote kind="warning" withIcon>{t("empty_snack_list")}</Blockquote>
        )}
            <div>
                <div className="flex">
                    <div className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                        <Button className='!inline-flex' disabled={!selected} onClick={() => setOpenDisable(true)}
                                icon={iconDeleteGlyph}
                                iconReverse>
                            {t('delete')}
                        </Button>
                    </div>
                    <div className="mx-0">
                        <Button onClickCapture={handleCreateOpen} iconDescription="create"
                                className='!inline-flex' icon={iconAddGlyph} iconReverse>
                            {t('add_snack')}
                        </Button>
                    </div>
                </div>
                {!!props.mobile && (page?.results.map((snack: Snack) => (
                <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                    <SnackCard key={snack.id} item={snack} onClick={() => handleClickSnack(snack.id)}/>
                </div>
                )))}
                {!!props.mobile && (
                    <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                )}
                {!props.mobile && page && (
                    <>
                        <SnackFilters onSubmit={handleFilter}/>
                        <SnackTable page={page} loadPage={doLoadPage}
                                    onClick={handleClickSnack}
                                    hidePagination={page.count <= page.results.length}
                                    sort={handleSort}
                                    onSelect={handleSelected}
                                    sortInfo={SortInfo}
                                    selectId={selected?.id}
                        />
                    </>
                )}
            </div>
        {openDisable && !!selected && (
            <Modal
                open={true}
                modalHeading={t('delete_snack')!}
                modalLabel={t('delete_snack')!}
                primaryButtonText={t('confirm')!}
                onRequestSubmit={handleDisableSubmit}
                onRequestClose={handleDisableClose}
                secondaryButtonText={t('cancel')!}
                onSecondarySubmit={handleDisableClose}
            >
                <p>{`${t('delete_snack_confirm')} ${selected.name}`}</p>
                {!!(error?.errors || error?.detail) && (
                    <Blockquote kind="error" withIcon>{error.errors} {error.detail}</Blockquote>
                )}
            </Modal>
        )}
        {openCreate && (
            <Modal
                open={true}
                modalHeading={(!!selected?.id ? t('edit_snack') : t('add_new_snack')) as string}
                modalLabel={(!!selected?.id ? t('edit_snack') : t('add_new_snack')) as string}
                primaryButtonText={t('confirm')!}
                onRequestSubmit={handleCreateSubmit}
                onRequestClose={handleCreateCancel}
                secondaryButtonText={t('cancel')!}
            >
                <SnackForm snack={selected || {} as Snack}
                           error={snackError}
                           onChange={setSelected}
                />
            </Modal>
        )}
        </div>
    );
}
