import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Button, Icon, Select, SelectItem} from "@wfp/ui";
import {iconDelete} from "@wfp/icons";
import {Commodity, CommoditySchoolItem} from "../../../models/types";
import {useRetrieveCommodities} from "../../../hooks/commodity";
import {LoadingCenter} from "../../commons/loading-center";
import {NumberInputValue} from "../../commons/number-input";
import {WizardError} from "./wizard";
import "./wizard.css";

interface IProps {
    error?: WizardError;
    onChange: (e: CommoditySchoolItem[]) => void
    items: CommoditySchoolItem[];
}


export function PageCommodities(props: IProps) {
    const {t} = useTranslation();
    const [items, setItems] = useState<CommoditySchoolItem[]>(props.items);
    const commodityList = useRetrieveCommodities({});


    function handleAddCommodity() {
        const values = [...items, {} as CommoditySchoolItem];
        setItems(values);
        props.onChange(values);
    }

    function handleDeleteCommodity(idx: number) {
        const values = items.filter((_, index) => index !== idx);
        setItems(values);
        props.onChange(values);
    }

    function handleChangeCommoditySchoolItem(e: any, idx: number) {

        const updatedItems = [...items];
        updatedItems[idx] = {
            ...updatedItems[idx],
            commodity: parseInt(e.target.value),
            commodityName: commodityList?.find((c) => c.id === parseInt(e.target.value))?.name || '',
            unitOfMeasure: commodityList?.find((c) => c.id === parseInt(e.target.value))?.unitOfMeasure || 0,
            unitOfMeasureName: commodityList?.find((c) => c.id === parseInt(e.target.value))?.unitOfMeasureName || '',
        };
        setItems(updatedItems);
        props.onChange(updatedItems);
    }

    function handleChangeCommoditySchoolItemQuantity(e: number, idx: number) {
        items[idx].quantity = e;
        setItems((items) => items);
        props.onChange(items);
    }

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("select_commodities_title")}</h2>
                <div>
                    <p>{t("select_commodities_subtitle")}</p>
                </div>
            </Blockquote>
            {!commodityList && (
                <LoadingCenter/>
            )}
            {items?.map((commodity, idx) => (
                <div className='grid grid-cols-10 items-baseline text-start' key={idx}>
                    <div className='col-span-3 mx-2 mt-3 mb-6'>
                        <Select
                            labelText={t('item')}
                            value={commodity.commodity}
                            invalid={!!props?.error?.items?.at(idx)?.commodity ? {message: t(props?.error?.items?.at(idx)?.commodity || '')} : undefined}
                            onChange={(e: any) => handleChangeCommoditySchoolItem(e, idx)}>
                            {[{
                                id: 0,
                                name: t("choose_one")
                            } as Commodity, ...(commodityList || [])].map((commodity, idx) => (
                                <SelectItem key={idx} id={commodity.id.toString()} value={commodity.id}
                                            text={commodity.name}/>
                            ))}
                        </Select>
                    </div>
                    <div className='col-span-2 mx-2 mt-3 mb-6'>
                        <NumberInputValue
                            labelText={t('quantity')!}
                            value={commodity.quantity || 0}
                            invalid={!!props?.error?.items?.at(idx)?.quantity ? {message: t(props?.error?.items?.at(idx)?.quantity || '')} : undefined}
                            onChangeValue={(e) => handleChangeCommoditySchoolItemQuantity(e, idx)}
                            name="inputQuantityPm"
                            min={0}
                        />
                    </div>
                    <div className='mt-3 col-span-3 self-center justify-self-start flex mb-3'>
                        <div className='self-center'>
                            {items.at(idx)?.unitOfMeasureName || ''}
                        </div>
                        <div className='self-center'>
                        <span onClick={() => handleDeleteCommodity(idx)}
                              title={t('delete')!}
                              className='align-middle m-3 cursor-pointer tooltip transition ease-in-out hover:-translate-y-1 duration-300'>
                                <Icon description={'create'} width={'20'} height={'20'} fill={'#0b77c2'}
                                      icon={iconDelete}/>
                            <span className='tooltiptext bg-sky-100 text-color-blue'>{t('delete')!}</span></span>
                        </div>
                    </div>
                </div>
            ))}
            <div className='mt-3 mx-2'>
                <Button small onClick={handleAddCommodity}>add commodity</Button>
            </div>
        </div>
    )
}
