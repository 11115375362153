import {PartnerList} from "../components/partner/list";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from "@wfp/ui";
import {iconAddGlyph, iconDeleteGlyph} from "@wfp/icons";
import {ListPage, Partner} from "../models/types";
import {PartnerError, validatePartner} from '../models/validate-partner';
import {disablePartner, getPartners, savePartner} from '../restapi/partner';
import {useGetPartners} from "../hooks/partner";
import {LoadingCenter} from "../components/commons/loading-center";
import {PartnerForm} from "../components/partner/form";
import {SMETSTypeahead} from "../components/commons/typeahead/typeahead";

export default function Partners() {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState<ListPage<Partner>>();
    const [error, setError] = useState<PartnerError>();
    const [partner, setPartner] = useState<Partner>();
    const [selected, setSelected] = useState<Partner>();
    const [openCreate, setOpenCreate] = useState(false);
    const [openDisable, setOpenDisable] = useState(false);

    const data = useGetPartners({onError: (e) => setError(e as PartnerError)});
    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    function doReloadPage() {
        getPartners(page?.pageNumber, page?.pageSize)
            .then(res => setPage(res))
            .catch(e => setError(e));
    }

    function handleCreateCancel(): void {
        setOpenCreate(false);
        setPartner(undefined);
        setError(undefined);
    }

    function handleCreateSubmit(): void {
        if (!!partner) {
            validatePartner(partner)
                .then(value => savePartner(value)
                    .then(() => {
                        setError(undefined);
                        setPartner(undefined);
                        setOpenCreate(false);
                        doReloadPage();
                    })
                    .catch(e => setError(e)))
                .catch(e => setError(e));
        }
    }

    function handleDisableClose() {
        setOpenDisable(false);
        setError(undefined);
    }

    function handleDisableSubmit() {
        if (!!selected) {
            disablePartner(selected.id)
                .then(() => {
                    setError(undefined);
                    setOpenDisable(false);
                    doReloadPage();
                })
                .catch(error => setError(error));
        }
    }

    function handleClick(id: string | number): void {
        navigate(`/partners/${id}`);
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(item => item.id === id);
        setSelected(selected);
    }

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter/>
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <PartnerList page={page} mobile={true}/>
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb className='mb-5'>
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('partner')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className='mb-5'>
                                <div className="flex mb-5">
                                    <Text kind='h1'>{t("partner_overview")}</Text>
                                </div>
                                <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                    <div className="flex">
                                        <div className='ml-0 w-1/3 text-left'>
                                            <SMETSTypeahead<Partner>
                                                placeholder={t('search_name') as string}
                                                noOptionsMessage={t('no_option_value') as string}
                                                onSelectOption={(e) => handleClick(e.id)}
                                                search={(e) => getPartners(1, 5, [{
                                                    key: "name_ic",
                                                    value: e
                                                }]).then(res => res.results)}
                                            >
                                            </SMETSTypeahead>
                                        </div>
                                        <div className={`mr-0 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                            <Button className='!inline-flex' disabled={!selected} onClick={() => setOpenDisable(true)}
                                                    icon={iconDeleteGlyph}
                                                    iconReverse>
                                                {t('delete')}
                                            </Button>
                                        </div>
                                        <div className="mx-3">
                                            <Button onClickCapture={() => setOpenCreate(true)} iconDescription="create"
                                                    className='!inline-flex' icon={iconAddGlyph} iconReverse>
                                                {t('create_partner')}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <PartnerList page={page} mobile={false} onClick={handleClick} onSelect={handleSelected}/>
                        </div>
                    </div>
                </>
            )}
            {openCreate && (
                <Modal
                    open={openCreate}
                    modalHeading={t('add_partner')!}
                    modalLabel={t('create_partner')!}
                    primaryButtonText={t('create')!}
                    primaryButtonDisabled={!partner}
                    onRequestSubmit={handleCreateSubmit}
                    onRequestClose={handleCreateCancel}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleCreateCancel}
                >
                    <PartnerForm partner={partner || {} as Partner} error={error} onChange={setPartner}/>
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                    )}
                </Modal>
            )}
            {openDisable && !!selected && (
                <Modal
                    open={openDisable}
                    modalHeading={t('delete_partner')!}
                    modalLabel={t('delete_partner')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleDisableSubmit}
                    onRequestClose={handleDisableClose}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleDisableClose}
                >
                    <p>{`${t('delete_partner_confirm')} ${selected.name}`}</p>
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                    )}
                </Modal>
            )}
        </>
    );
}


