import {KVPair, ListPage, Partner, PartnerSchool, ServerError} from "../models/types";
import {useQuery} from "react-query";
import {retrievePartners} from "../restapi/partner";
import {getPartner, getPartners} from "../restapi/partner";
import {getPartnerSchools} from "../restapi/partner-school";

export function useGetPartners({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<Partner> | undefined {
    const partnerQuery = useQuery(['partner', 'page'], () => getPartners(page, pageSize, filters, sorting), {onError: onError});
    return partnerQuery.data;
}

export function useRetrievePartner({id, onError}: {id: number, onError?: (e: ServerError) => void}): Partner | undefined {
    const partnerQuery = useQuery(['partner'], () => getPartner(id), {
        onError: onError,
        retry: false
    });
    return partnerQuery.data;
}

export function useRetrievePartners({onError}: {onError?: (e: ServerError) => void}): Partner[] | undefined {
    const partnerQuery = useQuery(['partners'], () => retrievePartners(), {onError: onError});
    return partnerQuery.data;
}


export function useGetPartnerSchools({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<PartnerSchool> | undefined {
    const schoolsQuery = useQuery(['partner_schools', 'page'], () => getPartnerSchools(page, pageSize, filters, sorting), {onError: onError});
    return schoolsQuery.data;
}
