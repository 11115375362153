import {useTranslation} from "react-i18next";
import {
    SchoolMealHistoryData,
    SchoolMealItemHistoryData,
    SchoolMealItemTransition,
    SchoolMealTransition
} from "../../models/types";
import {datetimeParse} from "../../hooks/formats";
import {AccordionItem} from "../commons/accordion/accordion";

function TransitionRow(props: { transition: SchoolMealTransition | SchoolMealItemTransition }) {
    return (
        <div className="flex flex-row space-x-3 p-1">
            <div>{datetimeParse(props.transition.timestamp)}</div>
            <div>{props.transition.username}</div>
            <div>{props.transition.toState}</div>
        </div>
    )
}

function SchoolMealItemInfo(props: { item: SchoolMealItemHistoryData, t?: any }) {
    return (
        <div className="flex flex-row space-x-3 p-2 items-center">
            <div>{props.item.codeValue}</div>
            <div>{props.item.schoolName}</div>
            <div className={`bg-order-state-${props.item.state} rounded-3xl p-2`}>
                <p className={`text-order-state`}>{props.item.state}</p>
            </div>
        </div>
    )
}


export function SchoolMealHistory(props: { data: SchoolMealHistoryData }) {
    const {t} = useTranslation();

    return (
        <div className="grid grid-flow-row space-y-3 text-left">
            {props.data.transitions.map((ot, idx) => (
                <TransitionRow key={idx} transition={ot}/>
            ))}
            {!!props.data.items?.length && (
                <div className="font-semibold p-1">{t('items')}</div>
            )}
            <div className="mx-10">
                {props.data.items.map((item, idx) => (
                    <AccordionItem key={idx} title={<SchoolMealItemInfo item={item}/>} open={false}>
                        <div className="grid grid-flow-row auto-rows-max space-y-3">
                            {item.transitions.map((oit, idx) => (
                                <TransitionRow key={idx} transition={oit}/>
                            ))}
                        </div>
                    </AccordionItem>
                ))}
            </div>
        </div>
    );
}
