import {createRef} from "react";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
    Button,
    Link,
    MainNavigation,
    MainNavigationItem, SecondaryNavigation,
    SubNavigation,
    SubNavigationContent,
    SubNavigationHeader,
    SubNavigationItem,
    SubNavigationLink,
    SubNavigationTitle,
    User,
} from "@wfp/ui";
import {RouteGuardConfig} from "../../AppRouting";
import { Role, UserDetails } from '../../models/types';
import {useOptionalUser, useInvalidateUser} from '../../hooks/user';
import {clearToken} from "../../restapi/auth";
import LanguageMenu from "./lang-menu";

export default function UserMenu(props: { changeLanguage: (e: string) => void }) {
    const {i18n, t} = useTranslation();
    const user: UserDetails | undefined = useOptionalUser();
    const navigationRef = createRef<MainNavigation>();
    const { mutate} = useInvalidateUser();

    const changeLanguage = (lang: string) => {
        props.changeLanguage(lang);
        handleCloseMenu();
    };

    function handleCloseMenu(): void {
        if (!!navigationRef?.current) {
            navigationRef.current.setState({
                openMobile: false,
                activeMenuItem: undefined,
            });
        }
    }

    function doLogout() {
        mutate(user, {
            onSuccess: () => {
                clearToken();
                window.location.href = '/oidc/total_logout?next=/oidc/logout_done/&fail=/';
            }
        });
        return;
    }

    return (
        <MainNavigation className='text-center' ref={navigationRef}
                        logo={<NavLink onClick={handleCloseMenu} to="/home">{`SMeTS ${t('beta')}`}</NavLink>}>
            {!!user && user.role === Role.WFPManager && (
                <MainNavigationItem className="hidden md:block"
                                    subNavigation={
                                        <SubNavigation>
                                            <SubNavigationContent children={
                                                !!user && RouteGuardConfig.filter(item => !!item.label).filter(
                                                    (item) => ((item.roles.includes('*') && !!user?.role) || item.roles.includes(user?.role || '')) && !item.blocked?.includes(user?.role || '')
                                                ).map((item, idx) => (
                                                    <SubNavigationItem key={idx} className='text-center'>
                                                        <NavLink to={item.routing}
                                                                 onClick={handleCloseMenu}>{t(item.label!)}</NavLink>
                                                    </SubNavigationItem>
                                                ))
                                            }/>
                                        </SubNavigation>
                                    }>
                    <Link>{t('menu')}</Link>
                </MainNavigationItem>
            )}
            {!!user && user?.role !== Role.WFPManager && user?.role !== Role.SchoolRepresentative && user?.role !== Role.SupplierDriver && (
                RouteGuardConfig.filter(item => !!item.label).filter(
                    (item) => ((item.roles.includes('*') && !!user?.role) || item.roles.includes(user?.role || '')) && !item.blocked?.includes(user?.role || '')
                ).map((item, idx) => (
                    <MainNavigationItem key={idx} className='text-center hidden md:block'>
                        <NavLink to={item.routing}>{t(item.label!)}</NavLink>
                    </MainNavigationItem>
                ))
            )}
            {!!user && user?.role === Role.WFPManager && (
                RouteGuardConfig.filter(item => !!item.label).filter(
                    (item) => ((item.roles.includes('*') && !!user?.role) || item.roles.includes(user?.role || '')) && !item.blocked?.includes(user?.role || '')
                ).map((item, idx) => (
                    <div key={idx} className='visible md:hidden'>
                    <SecondaryNavigation className='p-3 text-center'>
                        <SubNavigationLink>
                            <NavLink onClick={handleCloseMenu} to={item.routing}>{t(item.label!)}</NavLink>
                        </SubNavigationLink>
                    </SecondaryNavigation>
                    </div>
                ))
            )}
            {!!user && (
                <MainNavigationItem className="hidden md:block text-center wfp--main-navigation__user" subNavigation={
                    <SubNavigation>
                        <SubNavigationHeader className='!text-center'>
                            <SubNavigationTitle>{`${t('welcome')} ${user?.username}`}</SubNavigationTitle>
                        </SubNavigationHeader>
                        <SubNavigationContent>
                            <SubNavigationLink className=' mb-3'>
                                <NavLink to='/user' onClick={handleCloseMenu}>{t('profile')}</NavLink>
                            </SubNavigationLink>
                            <SubNavigationLink>
                                <Button kind="secondary" small onClick={doLogout}>{t('logout')}</Button>
                            </SubNavigationLink>
                        </SubNavigationContent>
                    </SubNavigation>
                }>
                    <User className='!text-center' ellipsis name={user?.username || t('user') as string}
                          missingImage="letter"/>
                </MainNavigationItem>
            )}
            {!!user && (
                <MainNavigationItem className="visible md:hidden text-center wfp--main-navigation__user">
                    <SubNavigation>
                        <SubNavigationHeader className='text-center'>
                            <SubNavigationLink>{`${t('welcome')} ${user?.username}`}</SubNavigationLink>
                        </SubNavigationHeader>
                        <SubNavigationContent className='!pb-0'>
                            <SubNavigationLink className='mb-3'>
                                <NavLink to='/user' onClick={handleCloseMenu}>{t('profile')}</NavLink>
                            </SubNavigationLink>
                            <SubNavigationLink>
                                <Button kind="primary" small onClick={doLogout}>{t('logout')}</Button>
                            </SubNavigationLink>
                        </SubNavigationContent>
                    </SubNavigation>
                </MainNavigationItem>
            )}
            <MainNavigationItem className="wfp--main-navigation__user"
                                subNavigation={<LanguageMenu changeLanguage={changeLanguage}/>}>
                <span>{i18n.language}</span>
            </MainNavigationItem>
        </MainNavigation>
    )
};
