import {buildQueryString, restapi} from "./restapi";
import {ListPage, Snack, KVPair} from "../models/types";



export function disableSnack(id: number): Promise<Snack> {
    return restapi.post<Snack>(`/api/v1/smt_fab/snack/${id}/disable/`).then(res => res.data);
}

export function saveSnack(data: Snack): Promise<Snack> {
    if (!data.id) {
        return restapi.post<Snack>('/api/v1/smt_fab/snack/', data).then((res) => res.data);
    } else {
        return restapi.patch<Snack>(`/api/v1/smt_fab/snack/${data.id}/`, data).then((res) => res.data);
    }
}

export function getSnack(pk: number): Promise<Snack> {
    return restapi.get<Snack>(`/api/v1/smt_fab/snack/${pk}/`).then((res) => res.data);
}

export function getSnacks(page= 1, page_size?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Snack>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(page_size || '')}];
     if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Snack>>(`/api/v1/smt_fab/snack/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrieveSnacks(): Promise<Snack[]> {
    return restapi.get<Snack[]>("/api/v1/smt_fab/snack/?__pagination__=0").then(res => res.data);
}
