import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Text } from "@wfp/ui";
import { CrateReclaim, ListPage, ServerError } from "../models/types";
import { LoadingCenter } from "../components/commons/loading-center";
import { useRetrieveReclaimCrates } from "../hooks/crates";
import { CrateReclaimList } from "../components/crate-reclaim/list";

export default function Reclaims() {
    const { i18n, t } = useTranslation();
    const [page, setPage] = useState<ListPage<CrateReclaim>>();
    const [error, setError] = useState<ServerError>();

    const data = useRetrieveReclaimCrates({ onError: (e) => setError(e) });

    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter />
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <div className="m-5 flex">
                            <div className="ml-0 text-center">
                                <Text kind='h1'>{t("reclaims_overview")}</Text>
                            </div>
                        </div>
                        <CrateReclaimList page={page} mobile={true} />
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb className="mb-5">
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome /></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('reclaims')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="flex mb-5">
                                <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                                    <Text kind='h1'>{t("reclaims_overview")}</Text>
                                </div>
                            </div>
                            <CrateReclaimList page={page} mobile={false} />
                        </div>
                    </div>
                </>
            )}
        </>
    );
}


