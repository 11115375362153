import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {Button, Icon} from "@wfp/ui";
import {iconCheckmark, iconRestart} from "@wfp/icons";
import {Role, SchoolMealItem} from "../../models/types";
import {dateParse} from "../../hooks/formats";
import {useLoggedUser} from "../../hooks/user";

interface Props {
    item: SchoolMealItem;
    readonly?: boolean;    // disable actions
    hideState?: boolean;    // hide state
    acceptReturn?: () => void;  // by the driver
    rejectReturn?: () => void;  // by the driver
    pickup?: () => void;  // by the driver
    handover?: () => void;  // by the driver
    undoPickup?: () => void;  // by the driver
    accept?: () => void;  // by the school representative
    loss?: () => void;  // by the school representative
    reject?: () => void;  // by the school representative
    modify?: () => void;  // by the school representative
    onClick?: () => void; // selection
}

export const SchoolMealItemCard = (props: Props) => {
    const user = useLoggedUser({});
    const {t} = useTranslation();

    return (
        <div className="flex items-start flex-col w-full" onClick={props.onClick}>
            <div className="flex flex-col space-y-4 w-full">
                <p className='text-end text-color-blue'>{t('cold_meal')}</p>
                <div className="">
                    {t("code")}: {props.item.code}
                </div>
                <h4 className="text-gray-800xs font-semibold my-2">
                    {props.item.schoolName}
                </h4>
                <div className="mr-auto text-blue-600 font-semibold">
                    {props.item.quantity} {props.item.mealName}
                </div>
                <div className="mr-auto">
                    {t("from")}: <strong>{props.item.centralSchoolName}</strong>
                </div>
                <div className="mr-auto">
                    {t("quantity_AM")}: <strong>{props.item.quantityAm}</strong>
                </div>
                <div className="mr-auto">
                    {t("quantity_PM")}: <strong>{props.item.quantityPm}</strong>
                </div>
                <div className="mr-auto">
                    {t("delivery_date")}: <strong>{dateParse(props.item.estimateDeliveryDate)}</strong>
                </div>
                {!!props.readonly && !props.hideState && (
                    <div className="flex items-center">
                        <div>{t("state")}</div>
                        <div className={`p-2 bg-order-item-state-${props.item.state} rounded-3xl ml-5 mr-auto p-2`}>
                            {t(props.item.state)}
                        </div>
                    </div>
                )}
            </div>
            {!props.readonly && (
                <div className="w-full">
                    {(user?.role === Role.SupplierDriver || Role.SchoolDriver) && props.item.state === "CONFIRMED" && (
                        <Button large onClick={props.pickup} className="mt-2" disabled={!props.pickup}>
                            <div className="flex">
                                <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                      icon={iconCheckmark}/>
                                <div className="px-1">{t("pickup")}</div>
                            </div>
                        </Button>
                    )}
                    {(user?.role === Role.SupplierDriver || Role.SchoolDriver) && props.item.state === "LOADED" && (
                        <div className="flex flex-row mt-2">
                            <Button onClick={props.undoPickup} kind="secondary">
                                <FontAwesomeIcon icon={faCartShopping}/> {t("undo_pickup")}
                            </Button>
                            <Button onClick={props.handover} kind="primary">
                                <div className="flex">
                                    <Icon fill="white" className="mr-0" width="22" height="22" description={''}
                                          icon={iconRestart}/>
                                    <div className="px-1">{t("handover")}</div>
                                </div>
                            </Button>
                        </div>
                    )}
                    {((user?.role === Role.SupplierDriver || user?.role === Role.SchoolDriver) && props.item.state === "DELIVERED") && (
                        <div className="w-full flex flex-col mt-2">
                            <div>{/*<div className="flex justify-between mb-2">
                            <Button disabled onClick={props.rejectReturn} kind="danger--primary" large>
                                <div className="flex">
                                    <Icon fill="white" className="mr-2" width="22" height="22" description={''} icon={iconErrorOutline}/>
                                    <div className="px-2">{t("reject")}</div>
                                </div>
                            </Button>*/}
                                <Button onClick={props.acceptReturn} kind="primary" large>
                                    <div className="flex">
                                        <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                              icon={iconCheckmark}/>
                                        <div className="px-1">{t("pickup")}</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                    {user?.role === Role.SchoolRepresentative && props.item.state === "DELIVERED" && (
                        <div className="w-full flex flex-col mt-2">
                            <div className="flex justify-between mb-2">
                                <Button onClick={props.accept} kind="primary" large>
                                    <div className="flex">
                                        <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                              icon={iconCheckmark}/>
                                        <div className="px-1">{t("pickup")}</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
