import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Form, InputGroup} from "@wfp/ui";
import {SMETSTextInput} from "../commons/text-input";
import {Location, School} from "../../models/types";
import {SchoolError} from "../../models/validate-school";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";
import {getLocations} from "../../restapi/geo";

interface Props {
    school: School;
    error?: SchoolError;
    onChange: (u: School) => void
}

export const SchoolForm = (props: Props) => {
    const {t} = useTranslation();
    const [locationSelected, setLocationSelected] = useState<Location>({name: props.school.locationName, id: props.school.location} as Location);

    return (
        <Form>
            {!!(props.error?.detail) && (
                <Blockquote kind="error" withIcon>
                    {t(props?.error?.detail?.toString() || '')}
                </Blockquote>
            )}
            <InputGroup>
                <div className='flex flex-col w-full items-start'>
                    <div className={`w-3/4 ${!!props.error?.cerd ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            value={props.school.cerd}
                            invalid={!!props.error?.cerd}
                            invalidText={t(props.error?.cerd?.toString() || '')}
                            name='cerd'
                            onChange={(e: any) => props.onChange({...props.school, cerd: e.target.value})}
                            labelText={t("cerd?")!}
                        />
                    </div>
                    <div className={`w-3/4 ${!!props.error?.cerd ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTypeahead<Location>
                            noOptionsMessage={t('no_option_value') as string}
                            value={locationSelected}
                            invalid={!!props.error?.location}
                            invalidText={t(props.error?.location?.toString() || '')!}
                            onClick={() => props.onChange({
                                ...props.school,
                                location: locationSelected.id,
                                locationName: locationSelected.name
                            })}
                            onSelectOption={(o) => {
                                setLocationSelected({
                                    ...locationSelected,
                                    id: Number(o.id),
                                    name: o.name
                                } as Location);
                                props.onChange({
                                    ...props.school,
                                    location: Number(o.id),
                                    locationName: o.name
                                } as School)
                            }}
                            label={t('location')}
                            search={(input) => getLocations(1, 5, [{
                                key: "name_ic",
                                value: input
                            }]).then(res => res.results)}/>
                    </div>
                    <div className={`w-3/4 ${!!props.error?.nameAr ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            value={props.school.nameAr}
                            invalid={!!props.error?.nameAr}
                            invalidText={t(props.error?.nameAr?.toString() || 'mb-3')}
                            name='nameAr'
                            onChange={(e: any) => props.onChange({...props.school, nameAr: e.target.value})}
                            labelText={t("nameAr?")!}
                        />
                    </div>
                    <div className={`w-3/4 ${!!props.error?.nameEn ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            value={props.school.nameEn}
                            invalid={!!props.error?.nameEn}
                            invalidText={t(props.error?.nameEn?.toString() || 'mb-3')}
                            name='nameEn'
                            onChange={(e: any) => props.onChange({...props.school, nameEn: e.target.value})}
                            labelText={t("nameEn?")!}
                        />
                    </div>
                    <div className={`w-3/4 ${!!props.error?.numberOfStudentsAm ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required={false}
                            type='numeric'
                            min="0"
                            value={props.school.numberOfStudentsAm}
                            invalid={!!props.error?.numberOfStudentsAm}
                            invalidText={t(props.error?.numberOfStudentsAm?.toString() || '')}
                            name='phone'
                            onChange={(e: any) => props.onChange({...props.school, numberOfStudentsAm: e.target.value})}
                            labelText={t("numberOfStudentsAm?")!}
                        />
                    </div>
                    <div className={`w-3/4 ${!!props.error?.numberOfStudentsPm ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required={false}
                            type='numeric'
                            min="0"
                            value={props.school.numberOfStudentsPm}
                            invalid={!!props.error?.numberOfStudentsPm}
                            invalidText={t(props.error?.numberOfStudentsPm?.toString() || '')}
                            name='phone'
                            onChange={(e: any) => props.onChange({...props.school, numberOfStudentsPm: e.target.value})}
                            labelText={t("numberOfStudentsPm?")!}
                        />
                    </div>
                </div>
            </InputGroup>
        </Form>
    )
}
