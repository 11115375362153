import {buildQueryString, restapi} from "./restapi";
import {KVPair, ListPage, Supplier} from "../models/types";

export function getSupplier(id: number): Promise<Supplier> {
    return restapi.get<Supplier>(`/api/v1/smt_core/supplier/${id}/`).then(res => res.data);
}

export function deleteSupplier(id: number): Promise<Supplier> {
    return restapi.post<Supplier>(`/api/v1/smt_core/supplier/${id}/disable/`).then(res => res.data);
}

export function getSuppliers(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Supplier>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Supplier>>(`/api/v1/smt_core/supplier/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrieveSuppliers(): Promise<Supplier[]> {
    return restapi.get<Supplier[]>('/api/v1/smt_core/supplier/?__pagination__=0').then(res => res.data);
}

export function saveSupplier(data: Supplier): Promise<Supplier> {
    if (!data.id) {
        return restapi.post<Supplier>('/api/v1/smt_core/supplier/', data).then(res => res.data);
    } else {
        return restapi.patch<Supplier>(`/api/v1/smt_core/supplier/${data.id}/`, data).then(res => res.data);
    }
}


