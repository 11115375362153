import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Blockquote, Select, SelectItem, } from "@wfp/ui";
import { School, } from "../../../models/types";
import { WizardError } from "./wizard";
import { useMediaQuery } from "../../../hooks/commons";
import { useRetrieveSchools } from "../../../hooks/school";
import { LoadingCenter } from "../../commons/loading-center";
import "./survey.css";

interface IProps {
    selectedSchool: number
    onChange: (s: School) => void
    error?: WizardError
}

export function PageTransferSchools(props: IProps) {
    const { t } = useTranslation();
    const [selectedSchool, setSelectedSchool] = useState<number>(props.selectedSchool);
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const schools = useRetrieveSchools({});


    function handleSelect(e: any) {
        const school = schools?.find(s => s.id === Number(e.target.value)) || {} as School;
        setSelectedSchool(school.id);
        props.onChange(school);
    }

    return (
        <div className="mt-10">
            {!isSmallScreen && (
                <Blockquote className="blockquote-form">
                    <h4 className='mb-3'>{t("select_school_transfer_label")}</h4>
                </Blockquote>
            )}
            {!!schools ?
                <Select
                    labelText={t('to_school')}
                    value={selectedSchool}
                    onChange={handleSelect}>
                    {[{
                        id: 0,
                        name: t("choose_one")
                    }, ...(schools || [])].map((school, idx) => (
                        <SelectItem key={idx} id={school.id.toString()} value={school.id} text={school.name} />
                    ))}
                </Select> : <LoadingCenter />
            }
        </div>
    )
}
