import {useQuery} from "react-query";
import {KVPair, ListPage, ServerError, Supplier} from "../models/types";
import {getSupplier, getSuppliers, retrieveSuppliers} from "../restapi/supplier";


export function useGetSuppliers({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<Supplier> | undefined {
    const supplierQuery = useQuery(['supplier', 'page'], () => getSuppliers(page, pageSize, filters, sorting), {onError: onError});
    return supplierQuery.data;
}


export function useRetrieveSupplier({id, onError}: {id: number, onError?: (e: ServerError) => void}): Supplier | undefined {
    const supplierQuery = useQuery(['supplier'], () => getSupplier(id), {
        onError: onError,
        retry: false
    });
    return supplierQuery.data;
}

export function useRetrieveSuppliers({onError}: {onError?: (e: ServerError) => void}): Supplier[] | undefined {
    const supplierQuery = useQuery(['supplier', 'list'], () => retrieveSuppliers(), {onError: onError});
    return supplierQuery.data;
}
