import { useTranslation } from 'react-i18next';
import { OrderItemChange, OrderItem } from '../../models/types';
import { OrderError } from '../../models/validate-order';
import { NumberInputValue } from '../commons/number-input';
import { useState } from 'react';


interface Props {
  orderItem: OrderItem;
  error?: OrderError;
  onChange: (o: OrderItemChange) => void;
}


export function OrderItemChangeForm(props: Props) {
  const { t } = useTranslation();
  const [crateObject, setCrateObject] = useState<OrderItemChange>({id: props.orderItem.id} as OrderItemChange);

  function onchangeValue(objId: number, crateType: number, value: number, ids: number): void {
    if (ids === 0) {
      if (crateType === 0) {
        setCrateObject({ ...crateObject, objectId1: objId, quantityAm1: value });
        props.onChange({ ...crateObject, objectId1: objId, quantityAm1: value });

      } else {
        setCrateObject({ ...crateObject, objectId1: objId, quantityPm1: value });
        props.onChange({ ...crateObject, objectId1: objId, quantityPm1: value });

      }
    } else {
      if (crateType === 0) {
        setCrateObject({ ...crateObject, objectId2: objId, quantityAm2: value });
        props.onChange({ ...crateObject, objectId2: objId, quantityAm2: value });

      } else {
        setCrateObject({ ...crateObject, objectId2: objId, quantityPm2: value });
        props.onChange({ ...crateObject, objectId2: objId, quantityPm2: value });

      }
    } 
  }

  return (
    <>
      {props.orderItem.contentNames?.map((snack, ids) => (
        <div key={ids} className="flex align-middle mt-5 mb-5">
          <div className="wfp--form-item">
            <div className='wfp--form-item justify-center'>{snack}</div>
          </div>
          {props.orderItem.crates?.slice(ids * 2, (ids + 1) * 2)?.map((crate, idx) => (
            <div key={crate.id} className="wfp--form-item">
              <NumberInputValue
                key={crate.id}
                labelText={crate.crateType === 0 ? t("quantityAm") || '' : 'quantityPm'}
                min={0}
                onChangeValue={(value) => onchangeValue(crate.objectId, crate.crateType, value, ids)}
                value={crate.acceptedQuantity}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  )
}
