import { useTranslation } from "react-i18next";
import { Role, TransferOrder } from "../../models/types";
import { Button, Icon } from "@wfp/ui";
import { iconCheckmark, iconRestart } from "@wfp/icons";
import { useLoggedUser } from "../../hooks/user";


interface Props {
    item: TransferOrder;
    readonly?: boolean;    // disable actions
    hideState?: boolean;    // hide state
    acceptReturn?: () => void;  // by the driver
    rejectReturn?: () => void;  // by the driver
    pickup?: () => void;  // by the driver
    handover?: () => void;  // by the driver
    undoPickup?: () => void;  // by the driver
    accept?: () => void;  // by the school representative
    loss?: () => void;  // by the school representative
    reject?: () => void;  // by the school representative
    modify?: () => void;  // by the school representative
    onClick?: () => void; // selection
}

export const TransferOrderCard = (props: Props) => {
    const { t } = useTranslation();
    const user = useLoggedUser({});

    return (
        <div className="flex items-start flex-col w-full" onClick={props.onClick}>
            <div className="flex flex-col space-y-4 w-full">
                <p className='text-end text-color-blue'>{t('transfer')}</p>
                <div className="">
                    {t('code')}: {props.item.code}
                </div>
                <div >
                    {t('author')} {props.item.authorName}
                </div>
                {props.item.items.map((item, idx) => (
                    <div key={idx} className="mr-auto text-blue-600 font-semibold">
                        {item.snackName} {item.quantity}
                    </div>
                ))}
                <div className="mr-auto">
                    {t('delivery_date')}: <strong>{props.item.deliveryDate}</strong>
                </div>
                <div className="mr-auto">
                    {t('from')}: <strong>{props.item.fromSchoolName}</strong>
                </div>
                <div className="mr-auto">
                    {t('to')}: <strong>{props.item.toSchoolName}</strong>
                </div>
                {!!props.readonly && !props.hideState && (
                    <div className="flex items-center">
                        <div>{t("state")}</div>
                        <div className={`p-2 bg-order-item-state-${props.item.state} rounded-3xl ml-5 mr-auto p-2`}>
                            {t(props.item.state)}
                        </div>
                    </div>
                )}
            </div>
            {!props.readonly && (
                <>
                {user?.role === Role.PartnerMonitor && props.item.state === "CONFIRMED" && (
                    <Button large onClick={props.pickup} className="mt-2" disabled={!props.pickup}>
                        <div className="flex">
                            <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                icon={iconCheckmark} />
                            <div className="px-1">{t("pickup")}</div>
                        </div>
                    </Button>
                )}
                {user?.role === Role.PartnerMonitor && props.item.state === "LOADED" && (
                    <div className="flex flex-row mt-2">
                        <Button onClick={props.handover} kind="primary" disabled={!props.handover}>
                            <div className="flex">
                                <Icon fill="white" className="mr-0" width="22" height="22" description={''}
                                    icon={iconRestart} />
                                <div className="px-1">{t("handover")}</div>
                            </div>
                        </Button>
                    </div>
                )}
                {user?.role === Role.SchoolRepresentative && props.item.state === "DELIVERED" && (
                    <div className="flex flex-row mt-2">
                        <Button onClick={props.accept} kind="primary" large disabled={!props.accept}>
                            <div className="flex">
                                <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                    icon={iconCheckmark} />
                                <div className="px-1">{t("pickup")}</div>
                            </div>
                        </Button>
                    </div>
                )}
                </>
            )}
        </div>
    );
};
