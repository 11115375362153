import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { CrateReclaim } from "../../../models/types"
import { useRetrieveSchools } from "../../../hooks/school"
import { Button, Form, Select, SelectItem, Icon, Blockquote } from "@wfp/ui"
import { useTranslation } from "react-i18next"
import { LoadingCenter } from "../../../components/commons/loading-center"
import { useRetrieveSuppliers } from "../../../hooks/supplier"
import { NumberInputValue } from "../../../components/commons/number-input"
import { iconAddGlyph } from "@wfp/icons";
import { reclaimCrates } from "../../../restapi/crate"
import { useLoggedUser } from "../../../hooks/user"

interface CrateError {
    detail: string | undefined;
    school: string | undefined;
    supplier: string | undefined;
    quantity: string | undefined;
}

export default function CreateReclaim() {
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const user = useLoggedUser({});


    const [crateReclaim, setCrateReclaim] = useState<CrateReclaim>({} as CrateReclaim)
    const [crateError, setCrateError] = useState<CrateError>({} as CrateError)
    const [successMessage, setSuccessMessage] = useState<string>()
    const schools = useRetrieveSchools({});
    const suppliers = useRetrieveSuppliers({});



    useEffect((() => {
        if (!!location.state && !!location.state.order) {
            console.log(location.state)
            const crate = {
                driver: location.state.order.driver,
                school: location.state.order.school,
                schoolName: location.state.order.schoolName,
                supplier: user?.entity,
                supplierName: user?.entityName,
                quantity: 0
            } as CrateReclaim
            setCrateReclaim(crate)
        }
    }), [location.state])

    function validateReclaim(crateReclaim: CrateReclaim): CrateError {
        const error = {} as CrateError
        if (!crateReclaim.school) {
            error.school = t("this_field_is_required")!
        }
        if (!crateReclaim.supplier) {
            error.supplier = t("this_field_is_required")!
        }
        if (!crateReclaim.quantity) {
            error.quantity = t("must_be_positive")!
        }
        return error
    }

    function handleReclaim(e: any): void {
        const err = validateReclaim(crateReclaim)
        if (!err.school && !err.supplier && !err.quantity) {
            reclaimCrates(crateReclaim).then(() => {
                setSuccessMessage(t("crate_reclaimed")!)
            }).catch((e) => {
                setCrateError(e)
            })
        } else {
            setCrateError(err)
        }
    }

    return (
        <div className="m-10">
            <div >
                <h1>{t("reclaim_crate")}</h1>
                <p>{t("if_you_should_reclaim_crate_complete_the_form_otherwise_back_to_home")}</p>
            </div>
            <div className="flex items-start space-y-5 flex-col w-full mt-10" >
                {!!successMessage && <Blockquote kind="success" withIcon><p>{successMessage}</p></Blockquote>}
                {!!crateError.detail && <Blockquote kind="error" withIcon>{crateError.detail}</Blockquote>}
                <Form>
                    {!!crateReclaim.school && !!crateReclaim.supplier ?
                        <div className="flex flex-col space-y-3">
                            <div>
                                <strong>{t("school")}: </strong>{crateReclaim.schoolName}
                            </div>
                            <div>
                                <strong>{t("supplier")}: </strong>{crateReclaim.supplierName}
                            </div>
                        </div>
                    : (
                    <div>
                        {!!schools ?
                            <div className={`${crateError.school ? `mt-3 mb-10` : 'mt-3'}`} >
                                <Select
                                    labelText={t('from_school')}
                                    value={crateReclaim.school}
                                    onChange={(e: any) => {
                                        setCrateReclaim({ ...crateReclaim, school: Number(e.target.value) })
                                        setCrateError({ ...crateError, school: undefined })
                                    }}
                                    invalid={!!crateError.school}
                                    invalidText={crateError.school}
                                >

                                    {[{
                                        id: 0,
                                        name: t("choose_one")
                                    }, ...(schools || [])].map((school, idx) => (
                                        <SelectItem key={idx} id={school.id.toString()} value={school.id} text={school.name} />
                                    ))}
                                </Select>
                            </div>
                            : <LoadingCenter />
                        }
                            <div className={`${crateError.supplier ? `mt-3 mb-10` : 'mt-3'}`} >
                                {!!suppliers ?
                                    <Select
                                        labelText={t('supplier')}
                                        value={crateReclaim.supplier}
                                        onChange={(e: any) => {
                                            setCrateReclaim({ ...crateReclaim, supplier: Number(e.target.value) })
                                            setCrateError({ ...crateError, supplier: undefined })
                                        }}
                                        invalid={!!crateError.supplier}
                                        invalidText={crateError.supplier}
                                    >
                                        {[{
                                            id: 0,
                                            name: t("choose_one")
                                        }, ...(suppliers || [])].map((s, idx) => (
                                            <SelectItem key={idx} id={s.id.toString()} value={s.id} text={s.name} />
                                        ))}
                                    </Select>
                                    : <LoadingCenter />
                                }
                                </div>
                        </div>
                        )}

                        <div className={`${crateError.quantity ? `mt-3 mb-10` : 'mt-3'}`} >
                            <NumberInputValue className="required"
                                labelText={t("quantity")!}
                                min={0}
                                onChangeValue={(value) => {
                                    setCrateReclaim({ ...crateReclaim, quantity: value })
                                    setCrateError({ ...crateError, quantity: undefined })
                                }}
                                value={crateReclaim.quantity || 0}
                                invalid={!!crateError.quantity}
                                invalidText={crateError.quantity}
                            />
                        </div>
                        <div className="flex flex-row mt-10">
                            <>
                                <Button onClick={() => navigate('/mobile')} kind="secondary">
                                    {successMessage ? t("done") : t("back_to_home")}
                                </Button>
                                {!successMessage &&
                                    <Button onClick={handleReclaim} kind="primary">
                                        <div className="flex">
                                            <Icon fill="white" className="mr-0" width="22" height="22" description={''}
                                                icon={iconAddGlyph}
                                            />
                                            <div className="px-1">{t("reclaim")}</div>
                                        </div>
                                    </Button>
                                }
                            </>
                        </div>
                </Form>
            </div>
        </div>
    )
}
