import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote} from "@wfp/ui";
import {KVPair, ListPage, School, ServerError, Sortable} from "../../models/types";
import {updateSorting} from "../../restapi/restapi";
import {getSchools} from "../../restapi/school";
import {useInViewport} from "../../hooks/useInViewport";
import {SchoolCard} from "./card";
import {SchoolTable} from "./table";
import {SchoolFilters} from "./filters";
import {ServerErrorMessage} from "../commons/server-error-message";

const SortInfo: Sortable[] = [
    {code: 'cerd', field: 'cerd', enabled: true, mode: 0},
    {code: 'name', field: 'name', enabled: true, mode: 0},
    {code: 'district', field: 'district', enabled: true, mode: 0},
    {code: 'number_of_students_am', field: 'number_of_students_am', enabled: true, mode: 0},
    {code: 'number_of_students_pm', field: 'number_of_students_pm', enabled: true, mode: 0},
];

interface Props {
    page?: ListPage<School>;
    mobile?: boolean;
    sorting?: string[];
    onClick?: (id: number) => void;
    onSelect?: (id: number) => void;
    selectCheck?: number;
    loadPage?: (pageNumber: number, pageSize?: number, options?: KVPair[], sorting?: string[]) => Promise<ListPage<School>>;
}

export const SchoolList = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState<ListPage<School>>()
    const [error, setError] = useState<ServerError>();
    const {isInViewport, ref} = useInViewport();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        }
    }, [props.page]);

    function handleFilters(filters?: KVPair[]): void {
        doLoadPage(page?.pageNumber, page?.pageSize, filters, page?.sorting);
    }

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        doLoadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function doLoadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        const promise = !!props.loadPage ? props.loadPage(pageNumber, pageSize, options, sorting || props.sorting) :
            getSchools(pageNumber, pageSize, options, sorting || props.sorting);
        promise.then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setError(undefined);
                setPage(res);
                return Promise.resolve(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleClickSchool(id: number): void {
        if (!!props.onClick) {
            props.onClick(id);
        } else {
            navigate(`/schools/${id}`);
        }
    }

    function handleLoadMore(): boolean {
        const result = !loading && !!page?.next;
        if (result) {
            doLoadPage(page?.pageNumber + 1, page?.pageSize);
        }
        return result;
    }

    return (
        <div>
            <ServerErrorMessage error={error}/>
            {!!page && (
                <div>
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>
                            {t("empty_schools_list")}
                        </Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((school: School, idx) => (
                        <div key={idx} className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                            <SchoolCard item={school}/>
                        </div>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                    )}
                    {!props.mobile && page.count > 0 && (
                        <>
                            <SchoolFilters onSubmit={handleFilters}/>
                            <SchoolTable
                                page={page}
                                loadPage={doLoadPage}
                                hidePagination={page.count <= page.results.length}
                                onClick={handleClickSchool}
                                onSelect={props.onSelect}
                                sort={handleSort}
                                sortInfo={SortInfo}
                                selectCheck={props.selectCheck}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
