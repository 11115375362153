import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton, Table } from "@wfp/ui";
import { KVPair, ListPage, Sortable, TransferOrder } from "../../models/types";
import { dateFormat } from "../../hooks/formats";
import { useLoggedUser } from "../../hooks/user";
import { TablePagination } from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<TransferOrder>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onSelectOrder?: (id: number) => void;
    onClick: (id: number) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}


export function TransferOrderTable(props: Props) {
    const { t } = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>(props.sortInfo || []);
    const headers = ["code","author_name", "delivery_date", "from", "to", "state"];
    const user = useLoggedUser({});

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <div>
            <Table>
                <thead>
                    <tr className="item-row-head">
                        {!!props.onSelectOrder && (
                            <th></th>
                        )}
                        {headers.map((header, idx) => (
                            <th key={idx} onClick={() => handleSorting(idx)} className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                                {t(header)}
                                {!!sortables?.length && (
                                    <span className="ml-0.5">
                                        {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                                    </span>
                                )}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {props.page.results.map((item: TransferOrder) => (
                        <tr className="item-row cursor-pointer" key={item.id} >
                            {!!props.onSelectOrder && (
                                <td>
                                    <RadioButton labelText={''} name='row-select' value={item.id}
                                        onClick={() => !!props.onSelectOrder ? props.onSelectOrder(item.id) : {}}></RadioButton>
                                </td>
                            )}
                            <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                                {item.code}
                            </td>
                            <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                                {item.authorName}
                            </td>
                            <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                                {dateFormat(item.deliveryDate)}
                            </td>
                            <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                                {item.fromSchoolName}
                            </td>
                            <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                                {item.toSchoolName}
                            </td>
                            <td className="item-col text-start p-0.5" onClick={() => props.onClick(item.id)}>
                                <div className={`bg-order-state-${item.state} rounded-3xl`}>
                                    <p className={`ml-2 text-order-state-${item.state}`}>{item.state}</p>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage} />
            )}
        </div>
    )
}
