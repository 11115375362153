import {buildQueryString, restapi} from "./restapi";
import {ListPage, Order, OrderHistoryData, KVPair} from "../models/types";


export function downloadOrder(id: number, ids?: number[]): Promise<boolean> {
    return restapi.get(`/api/v1/smt_core/order/${id}/print?ids=${(ids || []).toString()}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                //'Accept': 'application/pdf'
            }
        })
        .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `waybills-order-${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            return true;
        });

}

export function getHistory(id: number): Promise<OrderHistoryData> {
    return restapi.get<OrderHistoryData>(`/api/v1/smt_core/order/${id}/history/`).then((res) => res.data);
}

export function getOrder(id: number): Promise<Order> {
    return restapi.get<Order>(`/api/v1/smt_core/order/${id}/`).then((res) => res.data);
}

export function getOrders(page=1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Order>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Order>>(`/api/v1/smt_core/order/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function saveOrder(data: Order): Promise<Order> {
    if (!data.id) {
        return restapi.post<Order>('/api/v1/smt_core/order/', data).then((res) => res.data);
    } else {
        return restapi.patch<Order>(`/api/v1/smt_core/order/${data.id}/`, data).then((res) => res.data);
    }
}

export function confirmOrder(pk: number): Promise<Order> {
    return restapi.post<Order>(`/api/v1/smt_core/order/${pk}/confirm/`).then((res) => res.data);
}

export function discardOrder(pk: number): Promise<Order> {
    return restapi.post<Order>(`/api/v1/smt_core/order/${pk}/discard/`).then((res) => res.data);
}
