import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import UserMenu from "./user-menu";

export const getLanguage = () => {
    return localStorage.getItem('lang');
}
const Header = () => {
    const {i18n} = useTranslation();
    const navigate = useNavigate();

    const selectLanguage = (lang: string) => {
        i18n.changeLanguage(lang).then(() => {
            localStorage.setItem('lang', lang);
            if (lang === 'ar') {
                i18n.dir("ltr");
            }
            navigate(0);
        });
    };

    return (
        <UserMenu changeLanguage={selectLanguage}/>
    )
};

export default Header;
