import {useLoggedUser} from "../hooks/user";
import {useTranslation} from "react-i18next";
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem} from "@wfp/ui";
import {NavLink} from "react-router-dom";


export default function UserProfile() {
    const user = useLoggedUser({});
    const {t} = useTranslation();

    return (
        <div className="m-10">
            <Breadcrumb className="mb-5">
                <BreadcrumbItem>
                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <NavLink to={'#'}>
                        {t('user_profile')} {user?.firstName} {user?.lastName}
                    </NavLink>
                </BreadcrumbItem>
            </Breadcrumb>
        </div>
    );
}


