import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Module, ModuleBody} from "@wfp/ui";
import {District, KVPair} from "../../models/types";
import {getDistricts} from "../../restapi/geo";
import {SMETSModuleHeader} from "../commons/module-header";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";

export function SchoolFilters(props: { onSubmit: (filters: KVPair[]) => void }) {
    const [visible, setVisible] = useState(false);
    const [districtSelected, setDistrictSelected] = useState<District>();
    const {t} = useTranslation();

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setDistrictSelected({} as District);
    }

    function handleApply() {
        const filters: KVPair[] = [];
        if (!!districtSelected?.id) {
            filters.push({key: "district", value: districtSelected.id.toString()});
        }
        props.onSubmit(filters);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-3 space-x-5 items-baseline text-start">
                            <SMETSTypeahead<District>
                                noOptionsMessage={t('no_option_value') as string}
                                value={districtSelected}
                                onClick={handleApply}
                                onSelectOption={(o) => setDistrictSelected({
                                    ...districtSelected,
                                    id: Number(o.id),
                                    name: o.name
                                } as District)}
                                label={t('district')}
                                search={(input) => getDistricts(1, 5, [{
                                    key: "name_ic",
                                    value: input
                                }]).then(res => res.results)}/>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                    onClick={handleApply}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
