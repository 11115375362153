import {useTranslation} from "react-i18next";
import {Blockquote, Table} from "@wfp/ui";
import { ContentType, Order, School, Snack } from '../../../models/types';
import {OrderSummary} from "../../order/summary";
import "./wizard.css";
import { WizardError } from "./wizard";

interface IProps {
    schools: School[],
    snacks: Snack[],
    order: Order,
    error?: WizardError
}

const Headers = ["school_ref", "school_name", "district", "item1", "item2", "quantityAM", "quantityPM"];

export function PageSummary(props: IProps) {

    const {t} = useTranslation();

    return (
        <div className="mt-10">
            {!!props.error && (
                <Blockquote kind="error" withIcon>
                    <ul>
                        {Object.entries(props.error).map(([key, values]) => 
                            values.map((value: string, index: string[]) => 
                                <li key={key}>{key}: {t(value)}</li>
                            )
                        )}
                    </ul>
                </Blockquote>
            )}
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("confirm_order_title")}</h2>
                <div>
                    <p>{t("confirm_order_subtitle")}</p>
                </div>
            </Blockquote>
            <div className="mt-2 mb-4">
                <OrderSummary order={props.order} contentType={ContentType.SNACKS}/>
            </div>
            <Table className='text-start'>
                <thead>
                <tr className="item-row-head">
                    {Headers.map((title, index) => (
                        <th key={index}>{t(title)}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.schools.map((s, idx) => (
                    <tr className="item-row" key={idx}>
                        <td className="item-col">
                            {s.cerd}
                        </td>
                        <td className="item-col">
                            {s.name}
                        </td>
                         <td className="item-col">
                            {s.districtName}
                        </td>
                        <td className="item-col">
                            {(props.snacks || []).filter(s => !!s.id)?.at(0)?.name || ''}
                        </td>
                        <td className="item-col">
                            {(props.snacks || []).filter(s => !!s.id)?.at(1)?.name || ''}
                        </td>
                        <td className="item-col">
                            {s.numberOfStudentsAm}
                        </td>
                        <td className="item-col">
                            {s.numberOfStudentsPm}
                        </td>
                    </tr>))}
                </tbody>
            </Table>
        </div>
    )
}

