import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Blockquote} from "@wfp/ui";
import {ContentType, ServerError} from "../../models/types";
import {verifyOrderItemQRCode} from "../../restapi/order-item";
import {verifySchoolMealItemQRCode} from "../../restapi/school-meal-item";
import {LoadingCenter} from "../../components/commons/loading-center";
import { verifyTransferOrderQRCode } from "../../restapi/transfer";

export const Code = (props: {contentType: ContentType}) => {
    const {code} = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState<ServerError>();

    useEffect(() => {
        if (!!code) {
            if (props?.contentType === ContentType.MEALS) {
                verifySchoolMealItemQRCode(code)
                    .then(res => navigate(`/school-items/${res}`))
                    .catch(error => setError(error || {errors: ["invalid code"]}));
            } if (props?.contentType === ContentType.TRANSFER) {
                verifyTransferOrderQRCode(code)
                .then(res => navigate(`/transfers/${res}`))
                .catch(error => setError(error || {errors: ["invalid code"]}));
            } 
            else {
                verifyOrderItemQRCode(code)
                    .then(res => navigate(`/items/${res}`))
                    .catch(error => setError(error || {errors: ["invalid code"]}));
            }
        }
    }, [code]);

    return (
        <>
            {!!error?.errors && (
                <Blockquote kind="error" withIcon>
                    {error?.errors?.toString()}
                </Blockquote>
            )}
            {!error && (
                <LoadingCenter/>
            )}
        </>
    );
};
