import {KVPair, ListPage, PartnerWeek, WeekSupplierSchoolPlan, SchoolSupplierDay, SnackSchoolDay, SupplierDay} from "../models/types";
import {buildQueryString, restapi} from "./restapi";

export function wizardSupplierDay(id: number): Promise<number> {
    return restapi.post<{id: number}>(`/api/v1/smt_plan/supplier_day/${id}/wizard/`).then(res => res.data['id']);
}

export function getPartnerWeek(id: number, filters?: KVPair[]): Promise<PartnerWeek> {
    const qs = buildQueryString(filters);
    return restapi.get<PartnerWeek>(`/api/v1/smt_plan/partner_week/${id}/?${qs}`).then(res => res.data);
}

export function getPartnerWeekByDate(date: string, filters?: KVPair[]): Promise<PartnerWeek> {
    const dateFilter: KVPair[] = [{key: 'date', value: date}];
    const qs = buildQueryString(!!filters ? filters.concat(dateFilter) : dateFilter);
    return restapi.get<PartnerWeek>(`/api/v1/smt_plan/partner_week/date/?${qs}`).then(res => res.data);
}

export function getWeekSupplierDays(week: number, supplier: number): Promise<SupplierDay[]> {
    const params: KVPair[] = [
        {key: 'partner_week', value: week.toString()},
        {key: 'supplier', value: supplier.toString()},
        {key: 'page', value: '1'},
        {key: 'page_size', value: '7'}
    ];
    const qs = buildQueryString(params);
    return restapi.get<ListPage<SupplierDay>>(`/api/v1/smt_plan/supplier_day/?${qs}`).then(res => res.data.results);
}

export function getWeekSupplierSchoolPlans(week: number, supplier: number, page = 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<WeekSupplierSchoolPlan>> {
    const params: KVPair[] = [{key: 'partner_week', value: week.toString()}, {key: 'supplier', value: supplier.toString()}, {key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        params.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(params) : params);
    return restapi.get<ListPage<WeekSupplierSchoolPlan>>(`/api/v1/smt_plan/week_supplier_school_plan/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function planPartnerWeek(date: string): Promise<PartnerWeek> {
    return restapi.post<PartnerWeek>('/api/v1/smt_plan/partner_week/plan/', {date: date}).then(res => res.data);
}

export function saveSchoolSupplierDay(data: SchoolSupplierDay): Promise<SchoolSupplierDay> {
    if (!data.id) {
        return restapi.post<SchoolSupplierDay>('/api/v1/smt_plan/school_supplier_day/', data).then((res) => res.data);
    } else {
        return restapi.patch<SchoolSupplierDay>(`/api/v1/smt_plan/school_supplier_day/${data.id}/`, data).then((res) => res.data);
    }
}

export function saveSnackSchoolDay(data: SnackSchoolDay): Promise<SnackSchoolDay> {
    if (!data.id) {
        return restapi.post<SnackSchoolDay>('/api/v1/smt_plan/snack_school_day/', data).then((res) => res.data);
    } else {
        return restapi.patch<SnackSchoolDay>(`/api/v1/smt_plan/snack_school_day/${data.id}/`, data).then((res) => res.data);
    }
}
