import {restapi} from "./restapi";
import {Wizard} from "../models/types";

export function completeWizard(id: number): Promise<Wizard> {
    return restapi.post<Wizard>(`/api/v1/smt_wizard/form_wizard/${id}/complete/`).then(res => res.data);
}

export function currentOrderWizard(): Promise<Wizard> {
    return restapi.get<Wizard>('/api/v1/smt_wizard/form_wizard/current/?title=order').then(res => res.data);
}

export function currentCommoditiesWizard(): Promise<Wizard> {
    return restapi.get<Wizard>('/api/v1/smt_wizard/form_wizard/current/?title=commodities').then(res => res.data);
}

export function currentMealsWizard(): Promise<Wizard> {
    return restapi.get<Wizard>('/api/v1/smt_wizard/form_wizard/current/?title=meals').then(res => res.data);
}

export function currentSurveyWizard(): Promise<Wizard> {
    return restapi.get<Wizard>('/api/v1/smt_wizard/form_wizard/current/?title=survey').then(res => res.data);
}

export function getWizard(id: number): Promise<Wizard> {
    return restapi.get<Wizard>(`/api/v1/smt_wizard/form_wizard/${id}/`).then(res => res.data);
}

export function deleteWizard(id: number): Promise<void> {
    return restapi.delete<void>(`/api/v1/smt_wizard/form_wizard/${id}/`).then(res => res.data);
}

export function saveWizard(title: string, data: Wizard): Promise<Wizard> {
    data.title = title;
    if (!data.id) {
        return restapi.post<Wizard>('/api/v1/smt_wizard/form_wizard/', data).then(res => res.data);
    } else {
        return restapi.patch<Wizard>(`/api/v1/smt_wizard/form_wizard/${data.id}/`, data).then(res => res.data);
    }
}

export function saveOrderWizard(data: Wizard): Promise<Wizard> {
    return saveWizard('order', data);
}

export function saveCommoditiesWizard(data: Wizard): Promise<Wizard> {
    return saveWizard('commodities', data);
}

export function saveMealsWizard(data: Wizard): Promise<Wizard> {
    return saveWizard('meals', data);
}
