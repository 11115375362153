import {ServerError, Snack} from "./types";


export interface SnackError extends ServerError {
    detail: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    category: string[] | undefined;
}

class SnackErrorInfo implements SnackError {
    detail: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    category: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}


export function validateSnack(snack: Snack): Promise<Snack> {
    const error = new SnackErrorInfo();
    if (!snack.category) {
        error.add('category', 'this_field_is_required');
    }
    if (!snack.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!snack.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }

    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(snack) : Promise.reject(error);
}
