import {Crate, CrateReclaim, KVPair, ListPage} from "../models/types";
import {buildQueryString, restapi} from "./restapi";

export function saveCrate(data: Crate): Promise<Crate> {
    if (!data.id) {
        return restapi.post<Crate>('/api/v1/smt_core/crate/', data).then((res) => res.data);
    } else {
        return restapi.patch<Crate>(`/api/v1/smt_core/crate/${data.id}/`, data).then((res) => res.data);
    }
}

export function reclaimCrates(data: CrateReclaim): Promise<CrateReclaim> {
    return restapi.post<CrateReclaim>('/api/v1/smt_crate/crate_reclaim/', data).then((res) => res.data);
}

export function getReclaimCrates(page=1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<CrateReclaim>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<CrateReclaim>>(`/api/v1/smt_crate/crate_reclaim/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}
