import {CommoditySchoolItem, ServerError} from "./types";

export interface CommoditySchoolItemError extends ServerError {
    detail: string[] | undefined;
    commodity: string[] | undefined;
    unitOfMeasure: string[] | undefined;
    quantity: string[] | undefined;
}

class CommoditySchoolItemErrorInfo implements CommoditySchoolItemError {
    detail: string[] | undefined;
    commodity: string[] | undefined;
    unitOfMeasure: string[] | undefined;
    quantity: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateCommoditySchoolItem(item: CommoditySchoolItem): Promise<CommoditySchoolItem> {
    const error = validateCommoditySchoolItemSynch(item);
    return !error ? Promise.resolve(item) : Promise.reject(error);
}

export function validateCommoditySchoolItemSynch(commodity: CommoditySchoolItem): CommoditySchoolItemError | null {
    const error = new CommoditySchoolItemErrorInfo();
    if (!commodity.commodity) {
        error.add('commodity', 'this_field_is_required');
    }
    if (commodity.commodity < 1) {
        error.add('commodity', 'this_field_is_required');
    }
    if (!commodity.quantity) {
        error.add('quantity', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? null : error;
}
