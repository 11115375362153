import {useEffect, useState} from "react";
import {Blockquote, Table} from "@wfp/ui";
import {useTranslation} from "react-i18next";
import {SnackBySchool, KVPair, ListPage, Sortable} from "../../../models/types";
import {formatFieldName, formatTableColumnCode, formatTableColumnField} from "../../../hooks/formats";
import {useRetrieveSnacks} from "../../../hooks/snack";
import {useRetrieveSnackCategories} from "../../../hooks/snack-category";
import {TablePagination} from "../../commons/table-pagination/table-pagination";
import "./table.css";

interface Props {
    hidePagination?: boolean;
    page?: ListPage<SnackBySchool>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}

const headers = ["school", "snacks_am", "snacks_pm", "snacks"];

const sort: Sortable[] = [
    {code: 'school', field: 'school', enabled: true, mode: 0},
    {code: 'snacks_am', field: 'snacks_am', enabled: true, mode: 0},
    {code: 'snacks_pm', field: 'snacks_pm', enabled: true, mode: 0},
    {code: 'snacks', field: 'snacks', enabled: true, mode: 0},
];

export function SnackBySchoolTable(props: Props) {
    const {t} = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>(sort);

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort && !!props.page?.count && props.page?.count > 0) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    function itemFieldValue(item: any, field: string): string {
        const fieldName = formatFieldName(field);
        return item[fieldName];
    }

    return (
        <>
            {!!props.page && (
                <>
                    <Table>
                        <thead>
                        <tr className="item-row-head">
                            {headers.map((header, idx) => (
                                <th key={idx} onClick={() => handleSorting(idx)}
                                    className={props.sort && sortables?.at(idx)?.enabled && !!props.page?.count && props.page?.count > 0 ? "!text-center cursor-pointer" : "!text-center"}>
                                    {t(header)}
                                    {!!sortables?.length && !!props.page?.count && props.page?.count > 0 && (
                                        <span className="ml-0.5">
                                            {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                                        </span>
                                    )}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        {!!props.page && (
                            <tbody>
                            {props.page.results.map((item: SnackBySchool, idx) => (
                                <tr className={`${idx ? "item-row" : "item-row-totals"}`} key={idx}>
                                    <td className={`item-col ${idx ? "text-start" : "text-center"}`}>
                                        {item.school}
                                    </td>
                                    <td className="item-col text-end">
                                        {item.snacksAm}
                                    </td>
                                    <td className="item-col text-end">
                                        {item.snacksPm}
                                    </td>
                                    <td className="item-col text-end">
                                        {item.snacks}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        )}
                    </Table>
                    {!props.hidePagination && (
                        <TablePagination page={props.page} loadPage={props.loadPage}/>
                    )}
                </>
            )}
            {!props.page && (
                <Blockquote>
                    <p>{t('select_date_and_click_apply')}</p>
                </Blockquote>
            )}
        </>
    )
}
