import { useTranslation } from "react-i18next";
import { Blockquote, Modal } from "@wfp/ui";
import { ServerError, TransferOrder } from "../../models/types";
import { TransferOrderCard } from "./card";
import { LoadingCenter } from "../commons/loading-center";

export interface Props {
    showLoader?: boolean;
    error?: ServerError;
    transferOrder: TransferOrder;
    onOk?: () => void;
    onCancel: () => void;
}

export const HandoverConfirmation = (props: Props) => {

    const { t } = useTranslation();

    return (
        <div>
            <Modal
                open={true}
                primaryButtonText={t('handover') as string}
                onRequestSubmit={props.onOk}
                secondaryButtonText={t('cancel') as string}
                onSecondarySubmit={props.onCancel}
                onRequestClose={props.onCancel}
            >
                {props.showLoader ? <LoadingCenter /> : (
                    <>
                        {props.error && <Blockquote kind="error" withIcon>{props.error?.errors?.toString()}</Blockquote>}
                        <TransferOrderCard readonly={true} hideState={true} item={props.transferOrder} />
                    </>
                )}
            </Modal>
        </div>
    );
};
