import {useState} from "react";
import {useTranslation} from "react-i18next";
import {QrReader} from "react-qr-reader";
import {useNavigate, useParams} from "react-router-dom";
import {Blockquote, Button, TextInput} from "@wfp/ui";
import {ContentType} from "../../models/types";

export const QRScan = () => {
    const {id} = useParams();
    const {t} = useTranslation();
    const [code, setCode] = useState<string>('');
    const [contentType, setContentType] = useState<ContentType>();
    const [error, setError] = useState<string>();
    const navigate = useNavigate();

    function isExpectedCode(code: string) {
        const pk = Number(id);
        const idx = contentType === ContentType.MEALS || ContentType.TRANSFER ? 1 : 0;
        return isNaN(pk) ? true : pk === Number(code.split('-')[idx]);
    }

    const handleOk = () => {
        if (!!code) {
            evaluateCode(code);
        }
    };

    function evaluateCode(codeValue: string) {
        if(isExpectedCode(codeValue)) {
            if (contentType === ContentType.MEALS) {
                navigate(`/school-code/${codeValue}`);
            }
            if (contentType === ContentType.TRANSFER) {
                navigate(`/transfer-code/${codeValue}`);
            } 
            else {
                navigate(`/code/${codeValue}`);
            }
        } else {
            setError('wrong code');
        }
    }

    function handleInputKeyPress(e: any) {
        if (e.key === "Enter") {
            handleOk();
        }
    }

    function checkCodeFormat(code: string): boolean {
        if (!code) {
            return false;
        }
        const idx = !!contentType && [ContentType.MEALS, ContentType.TRANSFER].includes(contentType) ? [3,1,2] : [2,0,1];
        const parts = code.split('-');
        return parts.length === idx[0]
            && !isNaN(Number(parts[idx[1]]))
            && !isNaN(Number(parts[idx[2]]));
    }

    const handleInputCode = (e: any) => {
        if (!!e.target?.value) {
            handleSetContentType(e.target.value);
            if(checkCodeFormat(code)) {
                setError(undefined);
            }
        }
    }

    function handleSetContentType(codeValue: any) {
       if (codeValue.startsWith('CM-')) {
            setContentType(ContentType.MEALS);
            setCode(codeValue);
        }
        if (codeValue.startsWith('T-')) {
            setContentType(ContentType.TRANSFER);
            setCode(codeValue);
        }
        else {
            setContentType(ContentType.SNACKS);
            setCode(codeValue);
       }
    }

    const handleReaderResult = (result: any, _: any) => {
        if (!!result) { //the qrCode text is a URL expected to end with '/code/<code> or /school-code/CM-<code>'
            const qrCodeURL = result.getText();
            const codeValue = qrCodeURL ? qrCodeURL.split('code/')[1] : undefined;        
            const isValid = checkCodeFormat(codeValue);
            if (isValid) {
                handleSetContentType(codeValue);
                evaluateCode(codeValue);
            } else {
                setError('Invalid qrcode');
            }
        }
    }

    return (
        <div className="flex flex-col items-center">
            <QrReader
                onResult={handleReaderResult}
                containerStyle={{width: '95%'}}
                className=" w-1/5 h-2/3"
                constraints={{facingMode: 'environment'}}
            />
            {!!error && (
                <Blockquote
                    kind="error"
                    withIcon>
                    {error}
                </Blockquote>
            )}
            <div className="w-3/4 my-10 flex flex-row align-middle">
                <TextInput
                    placeholder={t('enter_number_manually') as string}
                    name="txtItemId"
                    type="text"
                    className="mx-2"
                    onChange={handleInputCode}
                    onKeyUp={handleInputKeyPress}
                />
                <Button onClick={handleOk} disabled={!code}>
                    {t('ok') as string}
                </Button>
            </div>
        </div>
    );
};
