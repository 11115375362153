import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Blockquote} from "@wfp/ui";
import {KVPair, ListPage, ServerError, Sortable, UserDetails} from "../../models/types";
import {updateSorting} from "../../restapi/restapi";
import {getUsers} from "../../restapi/user";
import {useInViewport} from "../../hooks/useInViewport";
import {UserCard} from "./card";
import {UserFilters} from "./filters";
import {UserTable} from "./table";

const SortInfo: Sortable[] = [
    {code: 'email', field: 'email', enabled: true, mode: 0},
    {code: 'entity_name', field: 'entityName', enabled: true, mode: 0},
    {code: 'name', field: 'first_name', enabled: true, mode: 0},
    {code: 'first_name', field: 'first_name', enabled: true, mode: 0},
    {code: 'last_name', field: 'last_name', enabled: true, mode: 0},
    {code: 'entity', field: 'entity', enabled: true, mode: 0},
    {code: 'role', field: 'role', enabled: true, mode: 0},
    {code: 'username', field: 'username', enabled: true, mode: 0}
];

interface Props {
    page?: ListPage<UserDetails>,
    mobile?: boolean,
    filters?: KVPair[],
    sorting?: string[],
    disableFilter?: boolean,
    onClick?: (id: number) => void
    onSelectUser?: (id: number) => void
}

export const UserList = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<ServerError>();
    const [page, setPage] = useState<ListPage<UserDetails>>()
    const {isInViewport, ref} = useInViewport();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        } else {
            loadPage(1, undefined, props.filters, props.sorting);
        }
    }, [props.page]);

    function handleFiltering(filters: KVPair[]): void {
        const changed = filters.length !== page?.filtering?.length || filters.filter(kvp => !page?.filtering?.find(f => f.key === kvp.key && f.value === kvp.value)).length > 0
        if (changed) {
            loadPage(page?.pageNumber || 1, page?.pageSize, filters, page?.sorting);
        }
    }

    function handleSorting(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleClickUser(id: number): void {
        if (!!props.onClick) {
            props.onClick(id);
        } else {
            navigate(`/users/${id}`);
        }
    }

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getUsers(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page?.next;
            if (result) {
                loadPage(page.pageNumber + 1, page.pageSize, page?.filtering);
            }
            return result;
        }
        return false;
    }

    return (
        <div>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error.detail.toString()}
                </Blockquote>
            )}
            {!!page && (
                <div className="">
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>
                            {t("empty_users_list")}
                        </Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((user: UserDetails) => (
                        <UserCard key={user.pk} item={user} onClick={() => handleClickUser(user.pk)}/>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                    )}
                    {!props.mobile && page && (
                        <>
                            {!props.disableFilter && (
                                <UserFilters onSubmit={handleFiltering}/>
                            )}
                            <UserTable
                                page={page}
                                loadPage={loadPage}
                                onSelectUser={props.onSelectUser}
                                onClick={handleClickUser}
                                hidePagination={page.count <= page.results.length}
                                sortInfo={SortInfo}
                                sort={handleSorting}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}
