import {useEffect, useState} from "react";
import {RadioButton, Table} from "@wfp/ui";
import {useTranslation} from "react-i18next";
import {KVPair, ListPage, Snack, Sortable} from '../../models/types';
import {TablePagination} from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<Snack>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onClick: (id: number) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
    selectId?: number;
    onSelect?: (id: number) => void;
}

const headerSnack = ["id", "name", "category"];

export function SnackTable(props: Props) {
    const {t} = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>();
    const [radioChecked, setRadioChecked] = useState<boolean[]>();

    useEffect(() => {
        if (!!props.page) {
            const checked = props.page.results?.map((s) => !!props.selectId ? s.id === props.selectId : false);
            setRadioChecked(checked);
        }

    }, [props.page, props.selectId]);

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headerSnack.map((headerSnack) => {
                const sort = props.sortInfo?.find((sort) => sort.code === headerSnack);
                return !!sort ? sort : {code: headerSnack, field: headerSnack, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);


    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <div className='min-h-800 relative'>
            <Table>
                <thead>
                <tr className="item-row-head">
                    {!!props.onSelect && (
                        <th></th>
                    )}
                    {headerSnack.map((header, idx) => (
                        <th key={idx} onClick={() => handleSorting(idx)}
                            className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                            {t(header)}
                            {!!sortables?.length && (
                                <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.page.results.map((item: Snack,idx) => (
                    <tr className="item-row cursor-pointer" key={item.id}>
                        {!!props.onSelect && (
                            <td className="item-col text-start">
                                <RadioButton labelText={''} name='row-select' value={item.id} checked={radioChecked?.at(idx) || false}
                                             onClick={() => !!props.onSelect ? props.onSelect(item.id) : radioChecked![idx] = true}></RadioButton>
                            </td>
                        )}
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {item.id}
                        </td>
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {item.name}
                        </td>
                        <td className="item-col text-start" onClick={() => props.onClick(item.id)}>
                            {item.categoryName}
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage}/>
            )}
        </div>
    )
}
