import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Blockquote } from "@wfp/ui";
import { KVPair, ListPage, Role, ServerError, Sortable, TransferOrder } from "../../models/types";
import { updateSorting } from "../../restapi/restapi";
import { useInViewport } from "../../hooks/useInViewport";
import { getTransferOrders, handoverTransferOrder } from "../../restapi/transfer";
import { TransferOrderCard } from "./card";
import { TransferOrderTable } from "./table";
import { useLoggedUser } from "../../hooks/user";
import { getCurrentPosition } from "../../hooks/position";
import { HandoverConfirmation } from "./handover-confirmation";
import { sendTransferPosition } from "../../restapi/position";


const SortInfo: Sortable[] = [
    { code: 'code', field: 'code', enabled: true, mode: 0 },
    { code: 'author', field: 'author', enabled: true, mode: 0 },
    { code: 'delivery_date', field: 'delivery_date', enabled: true, mode: 0 },
    { code: 'from', field: 'from_school', enabled: true, mode: 0 },
    { code: 'to', field: 'to_school', enabled: true, mode: 0 },
    { code: 'state', field: 'state', enabled: true, mode: 0 },
];

interface Props {
    page?: ListPage<TransferOrder>,
    mobile?: boolean,
    filters?: KVPair[],
    disableFilter?: boolean,
    sorting?: string[];
    onClick?: (id: number) => void
    onSelectOrder?: (id: number) => void
}

export const TransferOrderList = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<ListPage<TransferOrder>>();
    const [transferOrderHandover, setTransferOrderHandover] = useState<TransferOrder>();
    const [error, setError] = useState<ServerError>();
    const { isInViewport, ref } = useInViewport();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const user = useLoggedUser({});
    const [showLoader, setShowLoader] = useState<boolean>(false);

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        } else {
            loadPage(1, undefined, props.filters, props.sorting);
        }
    }, [props.page]);

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getTransferOrders(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function sendPosition(transferOrder: TransferOrder,  navigate: () => void) {
        setShowLoader(true);
        getCurrentPosition()
            .then((pos) => {
                sendTransferPosition({
                    latitude: pos.coords.latitude.toFixed(6),
                    longitude: pos.coords.longitude.toFixed(6),
                    altitude: pos.coords.altitude?.toFixed(6) || undefined,
                    accuracy: pos.coords.accuracy?.toFixed(12),
                    altitudeAccuracy: pos.coords.altitudeAccuracy?.toFixed(12) || undefined,
                    state: transferOrder.state,
                    transfer: Number(transferOrder.id),
                }).catch(error => setError(error));
            })
            .catch(error => console.log(error))
            .finally(() => {
                setShowLoader(false);
                navigate();
            });
    }
    function handleSorting(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleRowClick(id: number): void {
        navigate(`/transfers/${id}`);
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page.next;
            if (result) {
                loadPage(page.pageNumber + 1, page.pageSize, page?.filtering, page?.sorting);
            }
            return result;
        }
        return false;
    }

    function handleHandover(order: TransferOrder) {
        setTransferOrderHandover(order);
    }

    function handleHandoverConfirm() {
        if (!!transferOrderHandover) {
            handoverTransferOrder(transferOrderHandover.id)
                .then((data) => sendPosition(data, () => navigate('/home')))
                .catch((error) => setError(error));
        }
    }

    return (
        <div>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error.detail.toString()}
                </Blockquote>
            )}
            {!!transferOrderHandover && (
                <HandoverConfirmation
                    showLoader={showLoader}
                    transferOrder={transferOrderHandover}
                    onCancel={() => setTransferOrderHandover(undefined)}
                    onOk={handleHandoverConfirm}
                />
            )}
            {!!page && (
                <div className="mx-0">
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>
                            {t("empty_orders_list")}
                        </Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((order: TransferOrder) => (
                        <div key={order.id}
                            className="flex items-start max-w-md py-4 px-4 bg-white shadow-md rounded-lg my-4 mx-2">
                            <TransferOrderCard
                                item={order}
                                handover={order?.state === "LOADED" && user?.role === Role.PartnerMonitor ? () => handleHandover(order) : undefined}
                                readonly={order?.state === "DELIVERED" && user?.role === Role.SchoolRepresentative}
                                onClick={order?.state === "DELIVERED" && user?.role === Role.SchoolRepresentative ? () => navigate(`/transfers/${order.id}/qrscan`) : undefined}
                            />
                        </div>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                    )}
                    {!props.mobile && !!page && (
                        <TransferOrderTable page={page} loadPage={loadPage}
                            onSelectOrder={props.onSelectOrder}
                            hidePagination={page.count <= page.results.length}
                            sort={handleSorting}
                            sortInfo={SortInfo}
                            onClick={handleRowClick}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
