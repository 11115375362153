import {useEffect, useState} from "react";
import {SingleDatePicker} from "react-dates";
import {useTranslation} from "react-i18next";
import moment, {Moment} from "moment";
import {Blockquote, Select, SelectItem} from "@wfp/ui";
import {Order, Supplier} from "../../../models/types";
import {useRetrieveSuppliers} from "../../../hooks/supplier";
import {dateFormat} from "../../../hooks/formats";
import {LoadingCenter} from "../../commons/loading-center";
import {WizardError} from "./wizard";
import "./wizard.css";

interface IProps {
    order: Order,
    error?: WizardError,
    onChange: (order: Order) => void
}

export function PageDelivery(props: IProps) {
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const suppliers = useRetrieveSuppliers({});
    const [supplierList, setSupplierList] = useState<Supplier[]>();
    const [selected, setSelected] = useState<number>(0);
    const [deliveryDate, setDeliveryDate] = useState<Moment>(moment().add(1, 'days'));
    const [distributionDate, setDistributionDate] = useState<Moment>(moment().add(1, 'days'));
    const [focusDelivery, setFocusDelivery] = useState(false);
    const [focusDistribution, setFocusDistribution] = useState(false);

    useEffect(() => {
        if (!!props.order?.supplier && !selected) {
            setSelected(props.order.supplier);
        }
        if (!!props.order?.deliveryDate && !moment(props.order.deliveryDate).isSame(deliveryDate, 'days')) {
            setDeliveryDate(moment(props.order.deliveryDate));
        }
        if (!!props.order?.distributionDate && !moment(props.order.deliveryDate).isSame(distributionDate, 'days')) {
            setDistributionDate(moment(props.order.distributionDate));
        }
    }, [props.order])


    useEffect(() => {
        if (!!suppliers) {
            setSupplierList([{name: "choose_one"} as Supplier, ...suppliers]);
        }
    }, [suppliers])

    function handleSelect(event: any) {
        if (event.target?.value !== undefined) {
            const selected = suppliers?.find((s) => s.id === parseInt(event.target.value));
            props.onChange({
                ...props.order,
                supplier: selected?.id || 0,
                supplierName: selected?.name || '',
                deliveryDate: dateFormat(deliveryDate) || '',
                distributionDate: dateFormat(distributionDate) || ''
            });
            setSelected(selected?.id || 0);
        }
    }

    function handleDeliveryDate(value: Moment | null) {
        const dateValue = dateFormat(value) || '';
        props.onChange({
            ...props.order,
            supplier: selected,
            deliveryDate: dateValue,
            distributionDate: dateFormat(distributionDate) || ''
        });
        setDeliveryDate(value!);
    }

    function handleDistributionDate(value: Moment | null) {
        const dateValue = dateFormat(value) || '';
        props.onChange({
            ...props.order,
            supplier: selected,
            deliveryDate: dateFormat(deliveryDate) || '',
            distributionDate: dateValue
        });
        setDistributionDate(value!);
    }

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("select_snack_date_title")}</h2>
                <p>{t("select_snack_date_title_subtitle")}</p>
            </Blockquote>
            {!supplierList && (
                <LoadingCenter/>
            )}
            {!!supplierList && (
                <>
                    <div className="wfp--form-item mt-10 mb-10">
                        <label htmlFor="supplier" className="wfp--label">
                            {t("select_supplier_title")}*
                        </label>

                        <Select
                            helperText={t('helper_supplier_label')}
                            id="supplier"
                            onChange={handleSelect}
                            value={selected}
                            invalid={!!props.error?.supplier ? {message: t(props.error.supplier.toString())} : undefined}
                        >
                            {supplierList?.map((supplier, idx) => (
                                <SelectItem key={idx} id={`{supplier.id}`} value={supplier.id} text={t(supplier.name)}/>
                            ))}
                        </Select>
                    </div>
                    <div className="wfp--form-item mt-10 mb-10">
                        <label htmlFor="deliveryDate" className="wfp--label">
                            {t("select_delivery_date_title")}*
                        </label>
                        <SingleDatePicker
                            isRTL={i18n.dir() === 'rtl'}
                            renderMonthText={month => {
                                month.locale(i18n.language)
                                return (
                                    moment(month).format('MMMM YYYY')
                                )
                            }}
                            renderDayContents={day => {
                                day.locale('en')
                                return (
                                    moment(day).format('D')
                                )
                            }}
                            date={deliveryDate!}
                            onDateChange={handleDeliveryDate}
                            focused={focusDelivery}
                            onFocusChange={(f) => {
                                setFocusDelivery(f.focused)
                            }}
                            numberOfMonths={1}
                            displayFormat="DD/MM/YYYY"
                            showClearDate={true}
                            id="deliveryDate"/>
                        {!!props.error?.deliveryDate && (
                            <Blockquote
                                kind="error"
                                withIcon>
                                {t(props.error.deliveryDate)}
                            </Blockquote>
                        )}
                    </div>
                    <div className="wfp--form-item mt-10 mb-10">
                        <label htmlFor="distributionDate" className="wfp--label">
                            {t("select_distribution_date_title")}*</label>
                        <SingleDatePicker
                            isRTL={i18n.dir() === 'rtl'}
                            renderMonthText={month => {
                                month.locale(i18n.language)
                                return (
                                    moment(month).format('MMMM YYYY')
                                )
                            }}
                            renderDayContents={day => {
                                day.locale('en')
                                return (
                                    moment(day).format('D')
                                )
                            }}
                            date={distributionDate!}
                            onDateChange={handleDistributionDate}
                            focused={focusDistribution}
                            onFocusChange={(f) => setFocusDistribution(f.focused)}
                            numberOfMonths={1}
                            displayFormat="DD/MM/YYYY"
                            showClearDate={true}
                            id="distributionDate"/>
                        {!!props.error?.distributionDate && (
                            <Blockquote
                                kind="error"
                                withIcon>
                                {t(props.error.distributionDate)}
                            </Blockquote>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}
