import * as React from 'react';
import {StepNavigationItem} from "@wfp/ui";

/**
 * Required extension to manage inconsistencies between TS definitions and JS implementation via @ts-ignore. */
interface SMETSStepNavigationItemProps extends React.HTMLProps<StepNavigationItem> {
    page?: number
    label?: string
    role?: string
    selectedPage: number
    onChangePage: (value: number) => void
}

export class SMETSStepNavigationItem extends React.Component<SMETSStepNavigationItemProps, {}> {
    render() {
        const {onChangePage, ...stepNavigationItemProps} = this.props;
        (stepNavigationItemProps as any).handleTabClick = (index:any, _:any, __:any) => onChangePage(index);
        return <>{/*
                    // @ts-ignore */}
            <StepNavigationItem page={this.props.page} {...stepNavigationItemProps}></StepNavigationItem>
            </>
    }
}
