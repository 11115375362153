import {useTranslation} from "react-i18next";
import {Blockquote, Form, Select, SelectItem, Text} from "@wfp/ui";
import {SchoolSupplierDay, SnackSchoolDay} from "../../models/types";
import {useRetrieveSnacks} from "../../hooks/snack";
import {NumberInputValue} from "../commons/number-input";
import {useEffect, useState} from "react";
import {SnackSchoolDayError} from "../../models/validate-snack-school-day";

interface Props {
    schoolSupplierDay: SchoolSupplierDay;
    onChange: (ssd: SnackSchoolDay[]) => void;
    error?: SnackSchoolDayError;
}

export const SnacksForm = (props: Props) => {
    const {t} = useTranslation();
    const snacks = useRetrieveSnacks({});
    const [snacksArray, setSnacksArray] = useState<SnackSchoolDay[]>();

    useEffect(() => {
        if (!!props.schoolSupplierDay) {
            const value = !!props.schoolSupplierDay.snacks?.length ? props.schoolSupplierDay.snacks : [{
                schoolSupplierDay: props.schoolSupplierDay.id,
                quantityAm: 0,
                quantityPm: 0
            }, {schoolSupplierDay: props.schoolSupplierDay.id, quantityAm: 0, quantityPm: 0}] as SnackSchoolDay[]
            setSnacksArray(value);
        }
    }, [props.schoolSupplierDay]);

    function handleSelectSnack(e: any, idx: number) {
        if (!!e.target?.value && !!snacksArray?.at(idx)) {
            const values = [...snacksArray];
            values.at(idx)!.snack = Number(e.target.value);
            setSnacksArray(values);
            props.onChange(values);
        }
    }

    function onChangeQuantity(value: number, idx: number, time: string) {
        if (!!snacksArray) {
            if (time === 'am') {
                snacksArray[idx].quantityAm = value;
            }
            if (time === 'pm') {
                snacksArray[idx].quantityPm = value;
            }
            setSnacksArray(snacksArray);
            props.onChange(snacksArray);
        }
    }


    return (
        <Form className='my-5'>
            {!!props.error?.detail && (
                <Blockquote
                    kind="error"
                    withIcon>
                    {props.error?.detail}
                </Blockquote>
            )}
            <div className={` ${!!props.error?.snackName1 ? 'mb-10' : 'mb-5'}`}>
                <div className='mb-3'><Text kind='subtitle'>{t('first_snack')}</Text></div>
                <Select
                    onChange={(e) => handleSelectSnack(e, 0)}
                    labelText={t("please_select_one_snack")}
                    invalid={!!props.error?.snackName1}
                    value={snacksArray?.at(0)?.snack}
                    invalidText={t(props.error?.snackName1?.toString() || '')!}
                >
                    {[{id: 0, name: "choose_one"}, ...(snacks || [])]
                        .map((s, snackIdx) => (
                            <SelectItem key={snackIdx} id={`${s.id}`} value={s.id} text={t(s.name)}/>
                        ))}
                </Select>
            </div>
            <div className='flex mb-10'>
                <div className={`w-2/4 ${!!props.error?.quantityAm1 ? 'mb-10' : 'mb-5'}`}>
                    <NumberInputValue className="required"
                                      labelText={t("quantityAM")!}
                                      min={0}
                                      onChangeValue={(value) => onChangeQuantity(value, 0, 'am')}
                                      value={snacksArray?.at(0)?.quantityAm || 0}
                                      invalid={!!props.error?.quantityAm1}
                                      invalidText={t(props.error?.quantityAm1?.toString() || '')!}

                    />
                </div>
                <div className={`w-2/4 ${!!props.error?.quantityPm1 ? 'mb-10' : 'mb-5'}`}>
                    <NumberInputValue
                        className='justify-end'
                        labelText={t("quantityPM")!}
                        min={0}
                        onChangeValue={(value) => onChangeQuantity(value, 0, 'pm')}
                        value={snacksArray?.at(0)?.quantityPm || 0}
                        invalid={!!props.error?.quantityPm1}
                        invalidText={t(props.error?.quantityPm1?.toString() || '')!}
                    />
                </div>
            </div>
            <div className={`${!!props.error?.snackName2 ? 'mb-10' : 'mb-5'}`}>
                <div className='mb-3'><Text kind='subtitle'>{t('second_snack')}</Text></div>
                <Select
                    onChange={(e) => handleSelectSnack(e, 1)}
                    labelText={t("please_select_one_snack")}
                    invalid={!!props.error?.snackName2}
                    value={snacksArray?.at(1)?.snack}
                    invalidText={t(props.error?.snackName2?.toString() || '')!}
                >
                    {[{id: 0, name: "choose_one"}, ...(snacks || [])]
                        .map((s, snackIdx) => (
                            <SelectItem key={snackIdx} id={`${s.id}`} value={s.id} text={t(s.name)}/>
                        ))}
                </Select>
            </div>
            <div className='flex mb-10'>
                <div className={`w-2/4 ${!!props.error?.quantityAm2 ? 'mb-10' : 'mb-5'}`}>
                    <NumberInputValue className="required"
                                      labelText={t("quantityAM")!}
                                      min={0}
                                      onChangeValue={(value) => onChangeQuantity(value, 1, 'am')}
                                      value={snacksArray?.at(1)?.quantityAm || 0}
                                      invalid={!!props.error?.quantityAm2}
                                      invalidText={t(props.error?.quantityAm2?.toString() || '')!}

                    />
                </div>
                <div className={`w-2/4 ${!!props.error?.quantityPm2 ? 'mb-10' : 'mb-5'}`}>
                    <NumberInputValue
                        labelText={t("quantityPM")!}
                        min={0}
                        onChangeValue={(value) => onChangeQuantity(value, 1, 'pm')}
                        value={snacksArray?.at(1)?.quantityPm || 0}
                        invalid={!!props.error?.quantityPm2}
                        invalidText={t(props.error?.quantityPm2?.toString() || '')!}
                    />
                </div>
            </div>
        </Form>
    )
}
