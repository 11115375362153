import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from "@wfp/ui";
import {iconDeleteGlyph, iconEditGlyph} from "@wfp/icons";
import {UserDetails} from "../../models/types";
import {validateUser, UserError} from "../../models/validate-user";
import {useRetrieveUser} from "../../hooks/user";
import {disableUser, getUser, saveUser} from "../../restapi/user";
import {LoadingCenter} from "../commons/loading-center";
import {UserCard} from "./card";
import {UserForm} from "./form";

export const UserDetail = () => {
    const {i18n, t} = useTranslation();
    const navigate = useNavigate();
    const {id} = useParams();
    const [error, setError] = useState<UserError>();
    const [userDetails, setUserDetails] = useState<UserDetails>();
    const [userDetailsEdit, setUserDetailsEdit] = useState<UserDetails>();
    const [showDisableUserConfirm, setShowDisableUserConfirm] = useState<boolean>(false);

    const data = useRetrieveUser({id: Number(id), onError: (e) => setError(e as UserError)});

    useEffect(() => {
        if (data) {
            setUserDetails(data);
        }
    }, [data]);

    function doReloadUserDetails() {
        if(!!userDetails) {
            getUser(userDetails.pk)
                .then(res => setUserDetails(res))
                .catch(e => setError(e))
        }
    }

    function handleDisableUser() {
        setError(undefined);
        setShowDisableUserConfirm(true);
    }

    function handleDisableUserConfirm() {
        if(!!userDetails) {
            disableUser(userDetails.pk).then(() => navigate(-1)).catch(e => setError(e as UserError));
        }
    }

    function handleEditSubmit() {
        if (!!userDetailsEdit) {
            setError(undefined);
            validateUser(userDetailsEdit)
                .then((data) => saveUser(data)
                    .then((res) => {
                        setUserDetails(res);
                        setError(undefined);
                        setUserDetailsEdit(undefined);
                        doReloadUserDetails();
                    })
                    .catch(error => setError(error)))
                .catch(error => setError(error));
        }
    }

    function handleEditClose() {
        setUserDetailsEdit(undefined);
        setError(undefined);
    }

    return (
        <>
        {!!(error?.errors || error?.detail) && (
            <Blockquote kind="error" withIcon>
                {error.errors?.toString()} {error?.detail?.toString()}
            </Blockquote>
        )}
        {!!userDetails && (
            <div className="m-10">
                <Breadcrumb className='mb-5'>
                    <BreadcrumbItem>
                        <NavLink to="/"><BreadcrumbHome/></NavLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem>
                        <NavLink to="/users">{t('user')}</NavLink>
                    </BreadcrumbItem>
                    <BreadcrumbItem disableLink>
                        {t('view_user')}
                    </BreadcrumbItem>
                </Breadcrumb>
                <div className="flex">
                    <div>
                        <Text kind='h1'>{t("user_details")}</Text>
                    </div>
                    <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                        <div className="grid-flow-row flex auto-rows-max mb-10 text-end">
                            <div className="mx-3">
                                <Button onClickCapture={() => handleDisableUser()} className='!inline-flex' icon={iconDeleteGlyph} iconReverse>
                                    {t('delete')}
                                </Button>
                            </div>
                            <div className="mx-3">
                                <Button onClickCapture={() => setUserDetailsEdit(userDetails)} className='!inline-flex' icon={iconEditGlyph} iconReverse>
                                    {t('edit_user')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                    <UserCard item={userDetails} />
                </div>
            </div>
        )}
        {!!userDetailsEdit && (
            <Modal
                open={true}
                modalHeading={t('edit_user') || ''}
                modalLabel={t('edit_user') || ''}
                primaryButtonText={t('save') || ''}
                onRequestSubmit={handleEditSubmit}
                onRequestClose={handleEditClose}
                secondaryButtonText={t('cancel') || ''}
                onSecondarySubmit={handleEditClose}
            >
                <UserForm user={userDetailsEdit} error={error} onChange={setUserDetailsEdit}/>
            </Modal>
        )}
        {!(error || userDetails) && (
            <LoadingCenter/>
        )}
        <Modal
                onRequestSubmit={handleDisableUserConfirm}
                open={showDisableUserConfirm}
                primaryButtonText={t('ok')!}
                modalLabel={t('delete_user_confirm')!}
                secondaryButtonText={t("cancel") as string}
                onSecondarySubmit={() => setShowDisableUserConfirm(false)}
                onRequestClose={() => setShowDisableUserConfirm(false)}
            >
            {t('confirm')}
        </Modal>
        </>
    );
};
