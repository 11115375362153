import {useQuery} from "react-query";
import {KVPair, ListPage, SchoolMeal, ServerError} from "../models/types";
import {getSchoolMeal, getSchoolMeals} from "../restapi/school-meal";



export function useRetrieveSchoolMeals({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<SchoolMeal> | undefined {
    const itemsQuery = useQuery(['SchoolMeal', 'page'], () => getSchoolMeals(page, pageSize, filters, sorting), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}


export function useRetrieveSchoolMeal({id, onError}: {id: number, onError?: (e: ServerError) => void}): SchoolMeal | undefined {
    const SchoolMealQuery = useQuery(['SchoolMeal'], () => getSchoolMeal(id), {
        onError: onError,
        retry: false
    });
    return SchoolMealQuery.data;
}
