import {Loading} from "@wfp/ui";


export function LoadingCenter() {
    return (
        <div
            className="w-full h-full flex flex-col space-y-14 my-1 items-center content-center justify-items-center">
            <Loading withOverlay={false}/>
        </div>
    )
}
