import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Modal, Select, SelectItem} from "@wfp/ui";
import {
    Crate,
    Loss,
    LossCategory,
    OrderItem,
    ServerError,
} from "../../models/types";
import {useRetrieveLossCategories} from "../../hooks/loss-category";
import {NumberInputValue} from "../commons/number-input";
import {OrderItemCard} from "./card";

export interface LossError extends ServerError {
    crate?: string[];
    orderItem?: string[];
    quantity?: string[];
    category?: string[];
}

interface Props {
    orderItem: OrderItem;
    errors?: LossError[];
    onOk: (losses: Loss[]) => void;
    onCancel: () => void;
}

export const LossConfirmation = (props: Props) => {
    const {t} = useTranslation();
    const lossCategories = useRetrieveLossCategories({});
    const [lossCategoryList, setLossCategoryList] = useState<LossCategory[]>();
    const [losses, setLosses] = useState<Loss[]>();
    const [category, setCategory] = useState<number>();
    const [quantities, setQuantities] = useState<number[]>();
    const [invalid, setInvalid] = useState<boolean>(true);

    useEffect(() => {
        if (!!lossCategories) {
            setLossCategoryList([{id: 0, name: "choose_one"} as LossCategory, ...lossCategories]);
        }
    }, [lossCategories]);

    useEffect(() => {
        if (!!props.orderItem) {
            const orderItem = props.orderItem;
            const crates = orderItem.crates || [];
            const losses = crates.map((c) => doFormatLossItem(c));
            if (!!orderItem.losses?.length) {
                orderItem.losses.forEach(loss => {
                    const found = losses.findIndex(l => l.crate === loss.crate);
                    if (found !== -1) {
                        losses[found] = loss;
                    }
                });
                setCategory(orderItem.losses?.at(0)?.category);
            }
            setLosses(losses);
            const quantities = losses.map(l => l.quantity);
            setQuantities(quantities);
        }
    }, [props.orderItem]);

    function doFormatLossItem(crate: Crate): Loss {
        return {
            crate: crate.id,
            contentName: crate.contentName,
            quantity: 0,
        } as Loss;
    }

    const handleChangeQuantity = (idx: number, value: number) => {
        if (!!quantities) {
            const data = [...quantities];
            data[idx] = value;
            setQuantities(data);
            checkValid(data, category);
        }
    };

    const handleSelectChange = (event: any) => {
        if (!!event.target?.value) {
            setCategory(event.target.value);
            checkValid(quantities, event.target.value);
        }
    };

    function handleConfirmation() {
        if (!!losses) {
            const value = losses.map((loss, idx) => ({
                ...loss,
                category: category,
                quantity: quantities?.at(idx)
            } as Loss));
            setLosses(value);
            props.onOk(value.filter(l => !!l.quantity   ));
        }
    }

    function checkValid(quantities: number[] | undefined, category: number | undefined): void {
        const modified = (quantities || []).filter((quantity) => quantity > 0);
        const invalid = modified.length === 0 || category === undefined;
        setInvalid(invalid);
    }

    return (
        <div>
            <Modal
                open={true}
                primaryButtonText={t('confirm') as string}
                primaryButtonDisabled={invalid}
                onRequestSubmit={handleConfirmation}
                secondaryButtonText={t('cancel') as string}
                onSecondarySubmit={props.onCancel}
                onRequestClose={props.onCancel}
            >
                <OrderItemCard readonly={true} hideState={true} item={props.orderItem}/>
                {!!losses && losses.map((loss, idx) => (
                    <div key={idx}>
                        {!!(props.errors || [])[idx]?.errors && (
                            <Blockquote kind="error" withIcon>{props.errors![idx].errors?.toString()}</Blockquote>
                        )}
                        {idx === 0 && !!(props.errors || [])[idx]?.orderItem && (
                            <Blockquote kind="error" withIcon>{props.errors![idx].orderItem?.toString()}</Blockquote>
                        )}
                        <div className={`mt-4 ${!!(props.errors || [])[idx]?.quantity ? 'mb-10' : ''}`}>
                            <NumberInputValue
                                placeholder="Loss quantity"
                                labelText={`${t('enter_quantity_of')} ${loss.contentName} (${props.orderItem.crates?.at(idx)?.crateType === 0 ? t('am') : t('pm')}) lost*`}
                                name={`quantity-${idx}`}
                                value={quantities?.at(idx) || 0}
                                min={0}
                                max={props.orderItem.crates?.at(idx)?.acceptedQuantity}
                                invalid={!!(props.errors || [])[idx]?.quantity}
                                invalidText={(props.errors || [])[idx]?.quantity?.toString()}
                                onChangeValue={(value) => handleChangeQuantity(idx, value)}
                            />
                        </div>
                    </div>
                ))}
                <div className={`mt-4`}>
                    <Select
                        labelText={t('reason_of_the_loss?')}
                        name="declare_loss_reason"
                        className="mt-2"
                        value={category || 0}
                        onChange={handleSelectChange}
                        invalid={!!props.errors}
                    >
                        {lossCategoryList?.map((rr, idx) => (
                            <SelectItem key={idx} value={rr.id} text={t(rr.name)}/>
                        ))}
                    </Select>
                </div>
            </Modal>
        </div>
    );
};
