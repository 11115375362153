import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Modal, Select, SelectItem} from "@wfp/ui";
import {OrderItem, ReturnItem, ReturnReason, ServerError} from "../../models/types";
import {useRetrieveReturnReasons} from "../../hooks/return-reason";
import {NumberInputValue} from "../commons/number-input";
import {OrderItemCard} from "./card";

export interface ReturnItemError extends ServerError {
    orderItem?: string[]
    quantity?: string[]
    reason?: string[]
}

interface Props {
    orderItem: OrderItem;
    errors?: ReturnItemError[];
    onOk: (returnItems: ReturnItem[]) => void;
    onCancel: () => void;
}

export const ModifyConfirmation = (props: Props) => {
    const {t} = useTranslation();
    const [quantities, setQuantities] = useState<number[]>();
    const returnReasons = useRetrieveReturnReasons({});
    const [returnReasonList, setReturnReasonList] = useState<ReturnReason[]>();
    const [returnItems, setReturnItems] = useState<ReturnItem[]>();
    const [invalid, setInvalid] = useState<boolean>(true);

    useEffect(() => {
        if (!!returnReasons) {
            setReturnReasonList([{id: 0, name: "choose_one"} as ReturnReason, ...returnReasons]);
        }
    }, [returnReasons])

    useEffect(() => {
        if (!!props.orderItem) {
            const returnItems = (props.orderItem.crates || []).map(crate => crate.returnItem || {
                contentName: crate.contentName,
                quantity: 0,
                crate: crate.id,
                originalQuantity: crate.quantity,
            } as ReturnItem);
            setReturnItems(returnItems);
            setQuantities(returnItems.map(ri => ri.originalQuantity - ri.quantity));
        }
    }, [props.orderItem]);


    function checkValid(): void {
        const modified = (returnItems || []).filter(ri => ri.quantity > 0);
        const invalid = modified.length === 0 || !!modified.find((ri) => !ri.reason );
        setInvalid(invalid);
    }

    const handleChangeQuantity = (index: number, value: number) => {
        if (!!returnItems && !!quantities) {
            quantities[index] = value;
            const data = [...returnItems];
            data[index].quantity = data[index].originalQuantity - value;
            setReturnItems(data);
            checkValid();
        }
    };

    const handleSelectReturnReason = (index: number, e: any) => {
        if (!!e.target?.value && !!returnItems) {
            const data = [...returnItems];
            data[index].reason = Number(e.target.value);
            setReturnItems(data);
            checkValid();
        }
    };

    function handleConfirmation() {
        if (!!returnItems) {
            props.onOk(returnItems);
        }
    }

    return (
        <div >
            <Modal className="p-3"
                open={true}
                primaryButtonText={t('modify') as string}
                primaryButtonDisabled={invalid}
                onRequestSubmit={handleConfirmation}
                secondaryButtonText={t('cancel') as string}
                onSecondarySubmit={props.onCancel}
                onRequestClose={props.onCancel}
            >
                <OrderItemCard readonly={true} hideState={true} item={props.orderItem}/>
                {!!returnItems && returnItems.map((ri, idx) => {
                        return (
                            <div key={idx} className="py-4">
                                {!!(props.errors || [])[idx]?.errors && (
                                    <Blockquote kind="error" withIcon>{props.errors![idx].errors?.toString()}</Blockquote>
                                )}
                                {idx === 0 && !!(props.errors || [])[idx]?.orderItem && (
                                    <Blockquote kind="error" withIcon>{props.errors![idx].orderItem?.toString()}</Blockquote>
                                )}
                                <div className={`mt-2 ${!!(props.errors || [])[idx]?.quantity ? 'mb-10' : ''}`}>
                                    <NumberInputValue
                                        placeholder={t('return_quantity')!}
                                        labelText={`${t('enter_quantity_of')} ${ri.contentName}* (${(props.orderItem.crates || [])[idx].crateType === 0 ? t('am') : t('pm')})`}
                                        name={`${t('quantity')}-${idx}`}
                                        value={quantities ? quantities[idx] : 0}
                                        min={0}
                                        max={isNaN(ri.originalQuantity) ? undefined : Math.min(ri.originalQuantity, 1000)}
                                        invalid={!!(props.errors || [])[idx]?.quantity}
                                        invalidText={(props.errors || [])[idx]?.quantity?.toString()}
                                        onChangeValue={(value) => handleChangeQuantity(idx, value)}
                                    />
                                </div>
                                <div className={`mt-2 ${!!(props.errors || [])[idx]?.reason ? 'mb-10' : ''}`}>
                                    <Select
                                        value={ri.reason}
                                        labelText={t('reason_modification?')}
                                        name={`reason-${idx}`}
                                        onChange={(value) => handleSelectReturnReason(idx, value)}
                                        invalid={!!(props.errors || [])[idx]?.reason}
                                        invalidText={(props.errors || [])[idx]?.reason?.toString()}
                                    >
                                        {returnReasonList?.map((rr, idx) => (
                                            <SelectItem key={idx} value={rr.id} text={t(rr.name)}/>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        );
                    })
                }
            </Modal>
        </div>
    );
};
