import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Blockquote, Button, Loading } from '@wfp/ui';
import { CrateReclaimSupplierReport, KVPair, ListPage, ServerError } from "../../../models/types";
import { downloadCrateReclaim, getCrateReclaim } from '../../../restapi/report';
import { updateSorting } from "../../../restapi/restapi";
import { ServerErrorMessage } from "../../commons/server-error-message";
import { ReportFilters } from "../filters";
import { CrateReclaimTable } from "./table";
import i18n from '../../../i18n';

export const CrateReclaimList = () => {
    const { t } = useTranslation();
    const [error, setError] = useState<ServerError>();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<ListPage<CrateReclaimSupplierReport>>();

    function handleFilter(filters: KVPair[]) {
        loadPage(page?.pageNumber, page?.pageSize, filters, page?.sorting);
    }

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getCrateReclaim(pageNumber, pageSize, options, sorting)
            .then(res => setPage(res))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleDownload() {
        if (!!page) {
            setLoading(true);
            downloadCrateReclaim(page.filtering)
                .catch(e => setError(e))
                .finally(() => setLoading(false));
        }
    }

    return (
        <div>
            <ServerErrorMessage error={error} />
            {page?.count === 0 && (
                <Blockquote kind="warning" withIcon>{t("empty_list")}</Blockquote>
            )}
            <div>
                <div className="flex">
                    <div className={`mb-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                        {loading ? <Loading/> :
                            <Button disabled={!page?.count} className='!inline-flex' onClick={handleDownload}>
                                {t('download_csv')}
                            </Button>
                        }
                    </div>
                </div>
                <ReportFilters onSubmit={handleFilter}/>
                <CrateReclaimTable sort={handleSort} loadPage={loadPage} page={page}/>
            </div>
        </div>

    );
}
