import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import {
    Blockquote,
    Breadcrumb,
    BreadcrumbHome,
    BreadcrumbItem,
    Button,
    Modal,
    Text,
} from "@wfp/ui";
import { iconAddGlyph } from "@wfp/icons";
import { ListPage, UserDetails } from "../models/types";
import { validateUser, UserError } from "../models/validate-user";
import { saveUser, resetUser, getUsers } from "../restapi/user";
import { useGetUsers } from "../hooks/user";
import { UserForm } from "../components/user/form";
import { UserList } from "../components/user/list";
import { LoadingCenter } from "../components/commons/loading-center";
import { SMETSTypeahead } from "../components/commons/typeahead/typeahead";

export default function Users() {
    const { t, i18n } = useTranslation();
    const [user, setUser] = useState<UserDetails>({} as UserDetails);
    const [page, setPage] = useState<ListPage<UserDetails>>();
    const [error, setError] = useState<UserError>();
    const [openCreate, setOpenCreate] = useState(false);
    const navigate = useNavigate();
    const data = useGetUsers({ onError: (e) => setError(e as UserError) });

    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    function doReloadPage() {
        getUsers(page?.pageNumber, page?.pageSize)
            .then(res => setPage(res))
            .catch(e => setError(e));
    }

    function handleCreateOpen() {
        setUser({} as UserDetails);
        setOpenCreate(true);
    }

    function handleCreateClose() {
        setOpenCreate(false);
        setUser({} as UserDetails);
        setError(undefined);
    }

    function handleCreateSubmit() {
        const data = { ...user, username: !!user.username ? user.username : user.email };
        validateUser(data)
            .then(value => saveUser(value)
                .then(() => {
                    setError(undefined);
                    setUser({} as UserDetails);
                    setOpenCreate(false);
                    doReloadPage();
                })
                .catch(e => setError(e)))
            .catch(e => setError(e));
    }

    function handleUserFormChange(e: UserDetails): void {
        setUser(e);
    }

    function handleClickUser(id: string | number): void {
        navigate(`/users/${id}`);
    }


    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter />
            )}
            {!!page && (
                <div className="m-10">
                    <Breadcrumb className='mb-5'>
                        <BreadcrumbItem>
                            <NavLink to="/"><BreadcrumbHome /></NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem disableLink>
                            {t('user')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div>
                        <div className="flex mb-5">
                            <Text kind='h1'>{t("user_overview")}</Text>
                        </div>
                        <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                            <div className="flex">
                                <div className='ml-0 w-1/3 text-left'>
                                    <SMETSTypeahead<UserDetails>
                                        placeholder={t('search_name_email') as string}
                                        noOptionsMessage={t('no_option_value') as string}
                                        getOptionLabel={(e) => e?.username}
                                        getOptionValue={(e) => e?.pk?.toString()}
                                        onSelectOption={(e) => handleClickUser(e.id)}
                                        search={(e) => getUsers(1, 5, [{ key: "username_name_ic", value: e }]).then(res => res.results)}
                                    >
                                    </SMETSTypeahead>
                                </div>
                                <div className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                    <div className='mx-3'>
                                        <Button onClickCapture={handleCreateOpen} iconDescription="create" className='!inline-flex' icon={iconAddGlyph} iconReverse>
                                            {t('create_user')}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <UserList page={page} mobile={false} onClick={handleClickUser} />
                </div>
            )}
            {openCreate && (
                <Modal
                    open={openCreate}
                    modalHeading={t('add_new_user_to_your_list')!}
                    modalLabel={t('create_user')!}
                    primaryButtonText={t('create')!}
                    onRequestSubmit={handleCreateSubmit}
                    onRequestClose={handleCreateClose}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleCreateClose}
                >
                    <UserForm user={user} error={error} onChange={handleUserFormChange} />
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                    )}
                </Modal>
            )}
        </>
    );
}


