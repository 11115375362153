import * as React from 'react';
import {ModuleHeader} from "@wfp/ui";

/**
 * Required extension to manage inconsistencies between TS definitions and JS implementation. */
export type SMETSModuleHeaderProps = ModuleHeader.ModuleHeaderProps & {
    className?: string
    filter?: React.ReactNode
};

export class SMETSModuleHeader extends React.Component<SMETSModuleHeaderProps, {}> {
    render() {
        return <ModuleHeader {...this.props}></ModuleHeader>
    }
}
