import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Form, InputGroup, Select, SelectItem, Text} from "@wfp/ui";
import {Role, School, UserDetails} from "../../models/types";
import {SMETSTextInput} from "../commons/text-input";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";
import {UserError} from "../../models/validate-user";
import {useRetrieveSuppliers} from "../../hooks/supplier";
import {useRetrievePartners} from "../../hooks/partner";
import {getSchools} from '../../restapi/school';

interface Props {
    user: UserDetails;
    error?: UserError;
    onChange: (u: UserDetails) => void
}

export const UserForm = (props: Props) => {
    const {t} = useTranslation();
    const roleList = ["choose_one", ...Object.values(Role)];
    const [showPartners, setShowPartners] = useState(false);
    const [showSchools, setShowSchools] = useState(false);
    const [showSuppliers, setShowSuppliers] = useState(false);
    const partners = useRetrievePartners({});
    const suppliers = useRetrieveSuppliers({});
    const [schoolSelected, setSchoolSelected] = useState<School>();

    function handleChangeRole(e: any): void {
        if (!!e.target?.value) {
            if (!!props.user.pk) {
                props.onChange({...props.user, role: e.target.value})
            } else {
                props.onChange({...props.user, role: e.target.value, entity: undefined, entityName: undefined})
                setShowSchools(e.target.value === Role.SchoolRepresentative);
                setShowSuppliers([Role.SupplierDriver, Role.SupplierManager].includes(e.target.value));
                setShowPartners([Role.PartnerManager, Role.PartnerStaff].includes(e.target.value));
            }
        }
    }

    function handleSelectEntity(e: any): void {
        props.onChange({...props.user, entity: e.target?.value})
    }

    return (
        <Form>
            {!!(props.error?.detail || props.error?.username) && (
                <Blockquote kind="error" withIcon>
                    {t(props?.error?.detail?.toString() || '')} {`${!!props?.error?.username ? t('username') : ''}`}: {t(props?.error?.username?.toString() || '' )}
                </Blockquote>
            )}
            <InputGroup>
                <div className='grid grid-rows'>
                    {!!props.user.pk && (
                        <div className="max-w-xs">
                            <Text kind='p'>{props.user.entityName} ({t(props.user.role)})</Text>
                            {!!props.error?.entity && !(showPartners || showSchools || showSchools) && (
                                <Blockquote kind="error" withIcon>
                                    {t(props.error.entity)}
                                </Blockquote>
                            )}
                        </div>
                    )}
                    <div className={`mt-0 ${!!props.error?.firstName ? 'mb-10' : 'mb-5'}`}>
                        <SMETSTextInput
                            required
                        type='text'
                        value={props.user.firstName}
                        invalid={!!props.error?.firstName}
                            invalidText={t(props.error?.firstName?.toString() || '')}
                            name='firstName'
                            onChange={(e: any) => props.onChange({...props.user, firstName: e.target.value})}
                            labelText={t("first_name?")!}
                        />
                    </div>
                    <div className={`mt-0 ${!!props.error?.lastName ? 'mb-10' : 'mb-5'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            name='lastName'
                            value={props.user.lastName}
                            invalid={!!props.error?.lastName}
                            invalidText={t(props.error?.lastName?.toString() || '')}
                            onChange={(e: any) => props.onChange({...props.user, lastName: e.target.value})}
                            labelText={t("last_name?")!}
                        />
                    </div>
                    <div className={`mt-0 ${!!props.error?.email ? 'mb-10' : 'mb-5'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            name='email'
                            value={props.user.email}
                            invalid={!!props.error?.email}
                            invalidText={t(props.error?.email?.toString() || '')}
                            onChange={(e: any) => props.onChange({...props.user, email: e.target.value})}
                            labelText={t("user_email?") as string}
                        />
                    </div>
                    {!props.user.pk && (
                    <div className={`mt-0 ${!!props.error?.role ? 'mb-10' : 'mb-5'}`}>
                        <Select
                            required
                            className='max-w mb-4'
                            labelText={t('user_role?')}
                            name='role'
                            value={props.user.role}
                            invalid={!!props.error?.role}
                            invalidText={t(props.error?.role?.toString() || '') as string}
                            onChange={handleChangeRole}>
                            {roleList.map((role, idx) => (
                                <SelectItem key={idx} id={role} value={role} text={t(role)}/>
                            ))}
                        </Select>
                    </div>
                    )}
                    {!showPartners && !showSchools && !showSuppliers && !props.user.pk &&(
                    <div className='max-w mt-0 mb-0'>{t('entity')}: {t(props.user.role)}</div>
                    )}
                    {showPartners && !!partners && (
                    <div className='max-w mt-0 mb-0'>
                        <Select
                            required
                            labelText={t('partner')}
                            name='entity'
                            value={props.user.entity}
                            invalid={!!props.error?.entity}
                            invalidText={props.error?.entity?.toString()}
                            onChange={handleSelectEntity}>
                            {[{id: 0, name: t("choose_one")}, ...partners].map((item, idx) => (
                                <SelectItem key={idx} value={item.id} text={item.name}/>
                            ))}
                        </Select>
                    </div>
                    )}
                    {showSchools && (
                    <div className='max-w mt-0 mb-0'>
                        <SMETSTypeahead<School>
                            invalid={!!props.error?.entity}
                            invalidText={props.error?.entity?.toString()}
                            label={t('schools')}
                            noOptionsMessage={t('no_option_value') as string}
                            onChange={handleSelectEntity}
                            onSelectOption={(o) => setSchoolSelected({...schoolSelected, id: Number(o.id), name: o.name} as School)}
                            value={schoolSelected || {} as School}
                            search={(input) => getSchools(1, 5, [{
                                key: 'name__ic',
                                value: input
                            }]).then(res => res.results)}
                        />
                    </div>
                    )}
                    {showSuppliers && !!suppliers && (
                    <div className='max-w mt-0 mb-0'>
                        <Select
                            required
                            labelText={t('supplier')}
                            name='entity'
                            value={props.user.entity}
                            invalid={!!props.error?.entity}
                            invalidText={props.error?.entity?.toString()}
                            onChange={handleSelectEntity}>
                            {[{id: 0, name: t("choose_one")}, ...suppliers].map((item, idx) => (
                                <SelectItem key={idx} value={item.id} text={item.name}/>
                            ))}
                        </Select>
                    </div>
                    )}
                </div>
            </InputGroup>
            {!!props.error?.errors && (
            <Blockquote
                kind="error"
                withIcon>
                {props.error.errors}
            </Blockquote>
            )}
        </Form>
    );
}
