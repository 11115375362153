import {useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from "@wfp/ui";
import {iconAddGlyph, iconDeleteGlyph} from '@wfp/icons';
import {ListPage, Role, SchoolMeal, ServerError} from "../models/types";
import {discardSchoolMeal, getSchoolMeals} from "../restapi/school-meal";
import {useLoggedUser} from "../hooks/user";
import {useRetrieveSchoolMeals} from "../hooks/school-meal";
import {LoadingCenter} from "../components/commons/loading-center";
import {SchoolMealList} from "../components/school-meal/list";

export default function SchoolMeals() {
    const user = useLoggedUser({});
    const navigate = useNavigate();
    const {i18n, t} = useTranslation();
    const [selected, setSelected] = useState<SchoolMeal>();
    const [openDiscard, setOpenDiscard] = useState(false);
    const [page, setPage] = useState<ListPage<SchoolMeal>>();
    const [error, setError] = useState<ServerError>();

    const data = useRetrieveSchoolMeals({onError: (e) => setError(e)});

    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    function doReloadPage() {
        getSchoolMeals(page?.pageNumber, page?.pageSize)
            .then(res => setPage(res))
            .catch(e => setError(e));
    }

    function handleDiscardClose() {
        setOpenDiscard(false);
        setError(undefined);
    }

    function handleDiscardConfirm() {
        if (!!selected) {
            discardSchoolMeal(selected.id)
                .then(() => {
                    setError(undefined);
                    setOpenDiscard(false);
                    doReloadPage();
                })
                .catch(error => setError(error));
        }
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(meal => meal.id === id);
        setSelected(selected);
    }

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter/>
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <div className="m-5 flex">
                            <div className="ml-0 text-center">
                                <Text kind='h1'>{t("school_meal_overview")}</Text>
                            </div>
                             <div className={`${i18n.dir() === 'ltr' ? 'mr-0' : 'mr-3'}`}>
                                    <Button className="!inline-flex" icon={iconAddGlyph} iconReverse
                                            iconDescription="create cold meals order"
                                            disabled={user?.role === Role.WFPManager}
                                            onClick={() => navigate('/school-meals/wizard-meals')}>{t('create_school_meal_order')}</Button>
                                </div>
                        </div>
                        <SchoolMealList page={page} mobile={true}/>
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb className="mb-5">
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('school_meals')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="flex mb-5">
                                <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                                    <Text kind='h1'>{t("school_meal_overview")}</Text>
                                </div>
                                <div className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                    <Button className='!inline-flex' disabled={!(selected?.state === 'REQUESTED')}
                                            onClick={() => setOpenDiscard(true)} icon={iconDeleteGlyph}
                                            iconReverse>
                                        {t('discard')}
                                    </Button>
                                </div>
                                 <div className={`${i18n.dir() === 'ltr' ? 'mr-0' : 'mr-3'}`}>
                                    <Button className="!inline-flex" icon={iconAddGlyph} iconReverse
                                            iconDescription="create cols meal order"
                                            disabled={user?.role === Role.WFPManager}
                                            onClick={() => navigate('/school-meals/wizard-meals')}>{t('create_school_meal_order')}</Button>
                                </div>
                            </div>
                            <SchoolMealList page={page} mobile={false} onSelectedSchoolMeal={handleSelected}/>
                        </div>
                    </div>
                </>
            )}
            {openDiscard && !!selected && (
                <Modal
                    open={openDiscard}
                    modalHeading={t('discard_school_meal')!}
                    modalLabel={t('discard_school_meal')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleDiscardConfirm}
                    onRequestClose={handleDiscardClose}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleDiscardClose}
                >
                    <p>{`${t('discard_school_meal_confirm')} ${selected.deliveryDate}`}</p>
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                    )}
                </Modal>
            )}
        </>
    );
}


