import moment, {Moment} from "moment";

/**
 * Parse a date value received from the BE into a user-friendly format
 * @param value: the date in format 'YYYY-MM-DD'
 * @param sep: the formatting separator, default is '.'
 */
export function dateParse(value: string, sep = '.'): string {
    return moment(value).format("DD.MM.YYYY");
}

/**
 * Parse a datetime value received from the BE into a user-friendly format
 * @param value: the date in format "YYYY-MM-DDThh:mm:ss+03:00"
 * @param sep: the formatting separator, default is '.'
 */
export function datetimeParse(value: string, sep = '.') {
    return moment(value).format("DD.MM.YYYY hh:mm");
}

/**
 * Format a Moment value as string to send to the BE in format 'YYYY-MM-DD'
 * @param value: the Moment (Date)
 */
export function dateFormat(value: String | Moment | null): string | null {
    if (!value) {
        return null;
    }
    const momentValue = typeof value === "string" ? moment(value) : value as Moment;
    return momentValue?.format('YYYY-MM-DD') || ""
}

export function datetimeFormat(value: String | Moment | null): string | null {
    if (!value) {
        return null;
    }
    const momentValue = typeof value === "string" ? moment(value) : value as Moment;
    return momentValue?.format('YYYY-MM-DD HH:mm:ss') || ""
}

export function formatTableColumnCode({value, prefix = "Total "}: { value: string, prefix?: string }): string {
    return prefix + value;
}

export function formatTableColumnField({value, prefix = "total_"}: { value: string, prefix?: string }): string {
    return prefix + value.replaceAll(' ', '_').toLocaleLowerCase();
}


/**
 * total_banana => totalBanana
 * @param value
 */
export function formatFieldName(value: string): string {
    const parts = value.split('_').map((part, idx) => {
        return idx === 0 ? part : part[0].toUpperCase() + part.slice(1).toLowerCase();
    });
    return parts.join('');
}

export function parseTime(value: string): Moment {
    return moment(value, 'HH:mm:ss');
}

export function formatTime(value: Moment) {
    return value.format('HH:mm:ss');
}
