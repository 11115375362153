import {ServerError} from "../../models/types";
import {Blockquote} from "@wfp/ui";
export function ServerErrorMessage(props: { error?: ServerError }) {
    return (
        <>
            {(!!props.error?.errors || !!props.error?.detail) && (
                <Blockquote kind="error" withIcon>{props.error?.errors?.toString()} {props.error?.detail?.toString()}</Blockquote>
            )}
        </>
    )
}
