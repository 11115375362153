import {useQuery} from "react-query";
import {CrateReclaim, KVPair, ListPage, ServerError} from "../models/types";
import {getReclaimCrates} from "../restapi/crate";

export function useRetrieveReclaimCrates({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<CrateReclaim> | undefined {
    const itemsQuery = useQuery(['reclaim-crates', 'page'], () => getReclaimCrates(page, pageSize, filters, sorting), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}
