import {useTranslation} from "react-i18next";
import {School} from "../../models/types";

export const SchoolCard = (props: { item: School }) => {
    const {t} = useTranslation();

    return (
        <div className="flex flex-col space-y-3 w-full items-start">
            <div>
                {t("cerd")}: {props.item.cerd}
            </div>
            <h2 className="text-gray-800 text-3xl font-semibold">
                {t('name')} {props.item.name}
            </h2>
            <div>
                {t('location')}: {props.item.locationName} ({props.item.districtName})
            </div>
            <div>
                {t('numberOfStudentsAm')}: {props.item.numberOfStudentsAm}
            </div>
            <div>
                {t('numberOfStudentsPm')}: {props.item.numberOfStudentsPm}
            </div>
        </div>
    );
};
