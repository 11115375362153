import React, { useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Tab, Tabs, Text, } from "@wfp/ui";
import { Role, ServerError, TransferOrder } from "../../models/types";
import { LoadingCenter } from "../commons/loading-center";
import i18n from "../../i18n";
import { useRetrieveTransferOrder } from "../../hooks/transfer";
import { TransferOrderCard } from "./card";
import { useLoggedUser } from "../../hooks/user";
import { AcceptConfirmation } from "./accept-confirmation";
import { HandoverConfirmation } from "./handover-confirmation";
import { acceptTransferOrder, handoverTransferOrder, pickupTransferOrder } from "../../restapi/transfer";
import { getCurrentPosition, } from "../../hooks/position";
import { useMediaQuery } from "../../hooks/commons";
import TransferHistory from "./transfer-history";
import { sendTransferPosition } from "../../restapi/position";

export default function TransferOrderDetail() {
    const { t } = useTranslation();
    const { id } = useParams();
    const user = useLoggedUser({});
    const navigate = useNavigate();
    const [serverError, setServerError] = useState<ServerError>();
    const [transferOrderHandover, setTransferOrderHandover] = useState<TransferOrder>();
    const [transferOrderAccept, setTransferOrderAccept] = useState<TransferOrder>();
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const transferOrder = useRetrieveTransferOrder({ id: Number(id), onError: (e) => setServerError(e) });
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('TransferDetailTabIdx') || '0'));

    function sendPosition(transferOrder: TransferOrder, navigate: () => void) {
        setShowLoader(true);
        getCurrentPosition()
            .then((pos) => {
                sendTransferPosition({
                    latitude: pos.coords.latitude.toFixed(6),
                    longitude: pos.coords.longitude.toFixed(6),
                    altitude: pos.coords.altitude?.toFixed(6) || undefined,
                    accuracy: pos.coords.accuracy?.toFixed(12),
                    altitudeAccuracy: pos.coords.altitudeAccuracy?.toFixed(12) || undefined,
                    state: transferOrder.state,
                    transfer: Number(transferOrder.id),
                }).catch(error => setServerError(error))
                .finally(() => {
                    setShowLoader(false);
                    navigate();
                });;
            })
            .catch(error => console.log(error))
            
    }
 
    function handleHandoverConfirm() {
        if (!!transferOrderHandover) {
            handoverTransferOrder(transferOrderHandover.id)
                .then((data) => sendPosition(data, () => navigate('/home')))
                .catch((error) => setServerError(error));
        }
    }

    function handleAcceptConfirm() {
        if (!!transferOrder) {
            acceptTransferOrder(transferOrder.id)
                .then((data) => sendPosition(data, () => navigate('/home')))
                .catch((error) => setServerError(error));
        }
    }
    function handlePickup() {
        if (!!transferOrder) {
            pickupTransferOrder(transferOrder.id)
                .then((data) => sendPosition(data, () => navigate('/home')))
                .catch((error) => setServerError(error));
        }
    }
    function handleTabChange(idx: number): void {
        localStorage.setItem("TransferDetailTabIdx", idx.toString());
        setTabIndex(idx);
    }

    return (
        <div>
            {!!transferOrderHandover && (
                <HandoverConfirmation
                    error={serverError}
                    showLoader={showLoader}
                    transferOrder={transferOrderHandover}
                    onCancel={() => setTransferOrderHandover(undefined)}
                    onOk={handleHandoverConfirm}
                />
            )}
            {!!transferOrderAccept && (
                <AcceptConfirmation
                    error={serverError}
                    showLoader={showLoader}
                    transferOrder={transferOrderAccept}
                    onCancel={() => setTransferOrderAccept(undefined)}
                    onOk={handleAcceptConfirm}
                />
            )}
            {!!serverError?.errors && (
                <Blockquote kind="error" withIcon>{serverError.errors}</Blockquote>
            )}
            {!!serverError?.detail && (
                <Blockquote kind="error" withIcon>{serverError?.detail?.toString()}</Blockquote>
            )}
            {!transferOrder || showLoader && (
                <LoadingCenter />
            )}
            {!!transferOrder && (
                <div className="m-5">
                    {!isSmallScreen && (
                        <Breadcrumb className="mb-5">
                            <BreadcrumbItem>
                                <NavLink to="/"><BreadcrumbHome /></NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <NavLink to="/transfers">{t('transfers')}</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem disableLink>
                                {t('view_transfer')}
                            </BreadcrumbItem>
                        </Breadcrumb>
                    )}
                    <div className="flex justify-between mb-5">
                        <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                            <Text kind='h1'>{t("transfer_details")}</Text>
                        </div>
                    </div>
                    <Tabs className='mt-2' selected={tabIndex}>
                        <Tab onClick={() => handleTabChange(0)} label={t('info')} href='' role={'info'} selected tabIndex={0}>
                            {!!transferOrder && (
                                <div className="flex items-start max-w-md py-4 px-4 bg-white shadow-md rounded-lg my-4 ">
                                    <TransferOrderCard item={transferOrder}
                                        pickup={
                                            transferOrder?.state === 'CONFIRMED' && user?.role === Role.PartnerMonitor ? handlePickup : undefined
                                        }
                                        handover={
                                            transferOrder?.state === 'LOADED' && user?.role === Role.PartnerMonitor ? () => setTransferOrderHandover(transferOrder) : undefined
                                        }
                                        accept={
                                            transferOrder?.state === 'DELIVERED' && user?.role === Role.SchoolRepresentative ? () => setTransferOrderAccept(transferOrder) : undefined
                                        }
                                    />
                                </div>
                            )}
                        </Tab>
                        {!isSmallScreen && (
                            <Tab onClick={() => handleTabChange(1)} label={t('history')} href='' role={'history'} selected tabIndex={1}>
                                {!!id && (
                                    <TransferHistory id={Number(id)} />
                                )}
                            </Tab>
                        )}
                    </Tabs>
                </div>
            )}

        </div>

    );
};
