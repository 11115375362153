import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCartShopping} from "@fortawesome/free-solid-svg-icons";
import {Button, Icon} from "@wfp/ui";
import {
    iconCheckmark,
    iconCloseOutline,
    iconDelete,
    iconEdit,
    iconErrorOutline,
    iconInfoOutline,
    iconRestart
} from "@wfp/icons";
import {OrderItem, Role} from "../../models/types";
import {orderItemStateParse} from "../../models/validate-order-item";
import {dateParse} from "../../hooks/formats";
import {useLoggedUser} from "../../hooks/user";

interface Props {
    item: OrderItem;
    readonly?: boolean;    // disable actions
    hideState?: boolean;    // hide state
    acceptReturn?: () => void;  // by the driver
    rejectReturn?: () => void;  // by the driver
    pickup?: () => void;  // by the driver
    handover?: () => void;  // by the driver
    undoPickup?: () => void;  // by the driver
    accept?: () => void;  // by the school representative
    loss?: () => void;  // by the school representative
    reject?: () => void;  // by the school representative
    modify?: () => void;  // by the school representative
    onClick?: () => void; // selection
}

export const OrderItemCard = (props: Props) => {
    const user = useLoggedUser({});
    const {i18n, t} = useTranslation();

    return (
        <div className="flex items-start flex-col w-full" onClick={props.onClick}>
            <div className="flex flex-col space-y-4 w-full">
                <p className='text-end text-color-blue'>{t('snacks_&_commodities')}</p>
                <div className="">
                    {t("code")}: {props.item.code}
                </div>
                <h4 className="text-gray-800 font-semibold my-2">
                    {props.item.schoolName}
                </h4>
                {(props.item.crates || []).map((c, idx) => (
                    <div key={c.id} className="flex flex-row justify-start">
                        {user?.role !== Role.SupplierDriver && (
                            <div className="flex flex-row w-4/5 py-1 items-center">
                                <div className="mr-2 text-blue-600 font-semibold">
                                    <div className="flex">
                                        <Icon fill="blue" className="mr-1" description={''} icon={iconInfoOutline}/>
                                        {!!props.item.commoditiesInfo && true ? (
                                            <div>{c.contentName} {c.acceptedQuantity} {props.item?.commoditiesInfo.at(idx)?.symbol}</div>
                                        ) : (
                                            <div>{c.contentName} {c.acceptedQuantity} ({t(c.crateType === 0 ? 'am' : 'pm')})</div>
                                        )}
                                    </div>
                                </div>
                                {props.item.losses?.filter((loss) => c.losses?.includes(loss.id))?.map((loss, idx) => (
                                    <div key={idx} className="flex flex-row w-1/5 order-item-card-loss p-1">
                                        <div className="text-center text-red-500 font-semibold w-full">
                                            <div className="flex">
                                                <Icon fill="red" className="mr-1" description={''}
                                                      icon={iconCloseOutline}/>
                                                <div
                                                    className={i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}>{loss.quantity}</div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {user?.role === Role.SupplierDriver && (
                            <div className="w-full">
                                <div className="flex flex-row p-1 items-center">
                                    <div className="mr-2 w-3/5 text-blue-600 font-semibold">
                                        <div className="flex">
                                            <Icon fill="blue" className="mr-1" description={''}
                                                  icon={iconInfoOutline}/>
                                            {!!props.item.commoditiesInfo && true ? (
                                                <div>{c.contentName} {c.acceptedQuantity} {props.item?.commoditiesInfo.at(idx)?.symbol}</div>
                                            ) : (
                                                <div>{c.contentName} {c.acceptedQuantity} ({t(c.crateType === 0 ? 'am' : 'pm')})</div>
                                            )}
                                        </div>
                                    </div>
                                    {!!c.returnItem && (
                                        <div className="w-2/5 order-item-card-return-item p-1">
                                            <div className="font-semibold w-full">
                                                <div className="flex ">
                                                    <Icon fill="black" className="mr-1" description={''}
                                                          icon={iconCloseOutline}/>
                                                    <div
                                                        className={i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}>{c.returnItem.quantity} {c.contentName}</div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                {!!c.returnItem && (
                                    <div
                                        className="text-right ml-5 text-yellow-800 p-1">{c.returnItem.reasonName}</div>
                                )}
                            </div>
                        )}
                    </div>
                ))}
                {/* alternative grid layout
               <div className="flex flex-col space-y-2 w-full">
                    <div>
                            {(props.orderItem.contentNames)?.map((n,idx) => (
                                <div className='grid grid-flow-col space-x-3 mb-2' key={idx}>
                                     <div className='text-start'>{n}</div>
                                     <div className='text-center'>{(props.orderItem.crates?.filter((s) => s.crateType === 0 && s.contentName === n)[0].quantity)} ({t('am')})</div>
                                     <div className='text-center'>{(props.orderItem.crates?.filter((s) => s.crateType === 1 && s.contentName === n)[0].quantity)} ({t('pm')})</div>
                                </div>
                                )
                            )}
                    </div>
                </div>
             */}
                <div className="mr-auto">
                    {t("from")}: <strong>{props.item.supplierName}</strong>
                </div>
                <div className="mr-auto">
                    {t("delivery_date")}: <strong>{dateParse(props.item.estimateDeliveryDate)}</strong>
                </div>
                {!!props.readonly && !props.hideState && (
                    <div className="flex items-center">
                        <div>{t("state")}</div>
                        <div className={`p-2 bg-order-item-state-${props.item.state} rounded-3xl ml-5 mr-auto p-2`}>
                            {t(orderItemStateParse(props.item))}
                        </div>
                    </div>
                )}
            </div>
            {!props.readonly && (
                <div className="w-full">
                    {user?.role === Role.SupplierDriver && props.item.state === "CONFIRMED" && (
                        <Button large onClick={props.pickup} className="mt-2" disabled={!props.pickup}>
                            <div className="flex">
                                <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                      icon={iconCheckmark}/>
                                <div className="px-1">{t("pickup")}</div>
                            </div>
                        </Button>
                    )}
                    {user?.role === Role.SupplierDriver && props.item.state === "LOADED" && (
                        <div className="flex flex-row mt-2">
                            <Button onClick={props.undoPickup} kind="secondary">
                                <FontAwesomeIcon icon={faCartShopping}/> {t("undo_pickup")}
                            </Button>
                            <Button onClick={props.handover} kind="primary">
                                <div className="flex">
                                    <Icon fill="white" className="mr-0" width="22" height="22" description={''}
                                          icon={iconRestart}/>
                                    <div className="px-1">{t("handover")}</div>
                                </div>
                            </Button>
                        </div>                   
                    )}
                    {user?.role === Role.SupplierDriver && props.item.state === "DELIVERED" && (
                        <div className="w-full flex flex-col mt-2">
                            <div>{/*<div className="flex justify-between mb-2">
                            <Button disabled onClick={props.rejectReturn} kind="danger--primary" large>
                                <div className="flex">
                                    <Icon fill="white" className="mr-2" width="22" height="22" description={''} icon={iconErrorOutline}/>
                                    <div className="px-2">{t("reject")}</div>
                                </div>
                            </Button>*/}
                                <Button onClick={props.acceptReturn} kind="primary" large>
                                    <div className="flex">
                                        <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                              icon={iconCheckmark}/>
                                        <div className="px-1">{t("pickup")}</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                    {user?.role === Role.SchoolRepresentative && props.item.state === "DELIVERED" && (
                        <div className="w-full flex flex-col mt-2">
                            <div className="flex justify-between mb-2">
                                <Button onClick={props.reject} kind="danger--primary" large>
                                    <div className="flex">
                                        <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                              icon={iconErrorOutline}/>
                                        <div className="px-2">{t("reject")}</div>
                                    </div>
                                </Button>
                                <Button onClick={props.accept} kind="primary" large>
                                    <div className="flex">
                                        <Icon fill="white" className="mr-2" width="22" height="22" description={''}
                                              icon={iconCheckmark}/>
                                        <div className="px-1">{t("pickup")}</div>
                                    </div>
                                </Button>
                            </div>
                            <div className="flex">
                                <Button className="ml-0" onClick={props.modify} kind="accent" large>
                                    <div className="flex">
                                        <Icon className="mr-2" width="22" height="22" description={''}
                                              icon={iconEdit}/>
                                        <div className="px-1">{t("modify")}</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                    {user?.role === Role.SchoolRepresentative && props.item.state === "DONE" && (
                        <div className="w-full flex mt-2">
                            <Button className="ml-0" disabled={!props.loss} onClick={props.loss}
                                    kind={!!props.item?.losses?.length ? 'accent' : 'danger--primary'} large>
                                <div className="flex">
                                    <Icon className="mr-2" fill={!!props.item?.losses?.length ? '' : 'white'}
                                          width="22" height="22" description={''}
                                          icon={!!props.item?.losses?.length ? iconEdit : iconDelete}/>
                                    <div>{!!props.item?.losses?.length ? t("modify_loss") : t("declare_loss")}</div>
                                </div>
                            </Button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};
