import {useMutation, useQuery, useQueryClient} from "react-query";
import {ListPage, KVPair, ServerError, OrderItem, OrderItemChange, OrderItemChangeHistory, OrderHistoryData} from "../models/types";
import {changeConfirmed, getOrderItemChangeHistory, getOrderItem, getOrderItems} from "../restapi/order-item";
import { AxiosError } from "axios";
import { getHistory } from "../restapi/order";

export function useRetrieveOrderItem({id, onError}: {id: number, onError?: (e: ServerError) => void}): OrderItem | undefined {
    const itemQuery = useQuery<OrderItem, ServerError>(['orderItem', id], () => getOrderItem(id), {
        onError: onError,
        retry: false
    });
    return itemQuery.data;
}

export function useRetrieveOrderItems({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<OrderItem> | undefined {
    const itemsQuery = useQuery(['orderItems', 'page'], () => getOrderItems(page, pageSize, filters, sorting), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}

export function useChangeOrderItem() {
    const queryClient = useQueryClient();
    
    return useMutation((data: OrderItemChange) => {
        return changeConfirmed(data);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['Order'] });
            queryClient.invalidateQueries({ queryKey: ['orderItems', 'page'] });
            queryClient.invalidateQueries({ queryKey: ['orderItemChange'] });
        },
        onError: (error: AxiosError) => {}
    });
}

export function useGetOrderItemChangeHistory({code, onError}: {code: string, onError?: (e: ServerError) => void}): OrderItemChangeHistory[] | undefined{
    const itemsQuery = useQuery(['orderItemChange'], () => getOrderItemChangeHistory(code), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}

export function useGetHistory(id: number, onError?: (e: ServerError) => void): OrderHistoryData | undefined {
    const itemsQuery = useQuery(['orderItemshisrtory'], () => getHistory(id), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}
