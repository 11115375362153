import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Select, SelectItem} from "@wfp/ui";
import {Snack, SnackCategory} from "../../../models/types";
import {useRetrieveSnacks} from "../../../hooks/snack";
import {LoadingCenter} from "../../commons/loading-center";
import {WizardError} from "./wizard";
import "./wizard.css";

interface IProps {
    error?: WizardError
    snackCategories: SnackCategory[]
    selectedSnacks: Snack[]
    onChange: (e: Snack[]) => void
}

export function PageSnacks(props: IProps) {
    const {t} = useTranslation();
    const snacks = useRetrieveSnacks({});
    const [selectedSnacks, setSelectedSnacks] = useState<Snack[]>();

    useEffect(() => {
        if(!!props.selectedSnacks && !selectedSnacks) {
            setSelectedSnacks([...props.selectedSnacks]);
        }
    }, [props.selectedSnacks, selectedSnacks]);

    /**
     * #203240 ability to select multiple snacks of the same category
     * @param e select event
     * @param idx index on selects
     */
    function handleSelectSnack(e: any, idx: number): void {
        if(!isNaN(Number(e.target?.value)) && !!snacks) {
            /* const category = props.snackCategories[idx]?.id; #203240 */
            const snack = snacks
                /* .filter((s) => s.category === category) #203240 */
                .find((s) => s.id === parseInt(e.target?.value)
            ) || {id: 0, category: 0, name: ''} as Snack;
            const selected = [...(selectedSnacks || props.selectedSnacks || [])];
            selected[idx] = snack;
            setSelectedSnacks(selected);
            props.onChange([...selected]);
        }
    }

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("select_snack_title")}</h2>
                <div>
                    <p>{t("select_snack_subtitle")}</p>
                </div>
            </Blockquote>
        {!props.snackCategories && (
            <LoadingCenter />
        )}
        {props.snackCategories?.slice(0, 2).map((_, idx) => (
            <div key={idx} className="mt-10">
                <h3 className="text-left mt-2">Snack Item {idx+1}*</h3>
                <div className={!!(props.error?.snacks || [])[idx] ? `mt-3 mb-5` : 'mt-3'}>
                <Select
                    onChange={(e) => handleSelectSnack(e, idx)}
                    value={(selectedSnacks || [])[idx]?.id || 0}
                    labelText={t("please_select_one_snack")} /* {`Please select one ${sc.name}`} #203240 */
                    invalid={!!(props.error?.snacks || [])[idx]}
                    invalidText={t((props.error?.snacks || [])[idx]?.toString())!}
                >
                {[{id: 0, name: "choose_one"}, ...(snacks || [])]
                    /*.filter((s) => s.category === sc.id) #203240 */
                    .map((s, snackIdx) => (
                    <SelectItem key={snackIdx} id={`${s.id}`} value={s.id} text={t(s.name)}/>
                ))}
                </Select>
                </div>
            </div>
        ))}
        </div>
    )
}
