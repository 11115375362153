import {useTranslation} from "react-i18next";
import {Icon, Text} from '@wfp/ui';
import {iconWfpHumEmergencyResponsePos} from '@wfp/icons';

export function Logo(props: { text_key?: string }) {

    const {t} = useTranslation();
    return (
        <div>
            <div className="flex items-center justify-center h-screen home">
                <div className="block">
                    <Icon className='icon-home' description='SMeTS' icon={iconWfpHumEmergencyResponsePos} color={'blue'}
                          width={'300'} height={'300'}/>
                    <Text className='!ml-5 md:!ml-0' kind="title">{t(!!props.text_key ? props.text_key! : 'welcome_to_smets')}</Text>
                    <Text className='!ml-5 md:!ml-0' kind='p'>{t('please_contact')} <a
                        href='mailto:SMeTS@wfp.org'>SMeTS@wfp.org</a> {t('questions_or_comments')}.</Text>
                    <Text className='!ml-5 md:!ml-0' kind='p'>SMeTS Team</Text>
                </div>
            </div>
            <div className="footer text-color-blue hidden md:flex">
                <div className="ml-10 copyrights">
                    <h5>2023 © World Food Programme</h5>
                </div>
                <div className="ml-5 version">
                    <h5>SMeTS v1.0-beta</h5>
                </div>
            </div>
        </div>
    )
}
