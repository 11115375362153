import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text } from "@wfp/ui";
import { ListPage, ServerError, TransferOrder } from "../models/types";
import { LoadingCenter } from "../components/commons/loading-center";
import { TransferOrderList } from "../components/transfer/list";
import { useRetrieveTransferOrders } from "../hooks/transfer";
import { confirmTransferOrder, getTransferOrders } from "../restapi/transfer";


export default function TransferOrders() {
    const { i18n, t } = useTranslation();
    const [selected, setSelected] = useState<TransferOrder>();
    const [page, setPage] = useState<ListPage<TransferOrder>>();
    const [error, setError] = useState<ServerError>();
    const [openConfirm, setOpenConfirm] = useState(false);
    const [showLoader, setShowLoader] = useState<boolean>(false);

    const data = useRetrieveTransferOrders({ onError: (e) => setError(e) });

    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    function doReloadPage() {
        getTransferOrders(page?.pageNumber, page?.pageSize)
            .then(res => setPage(res))
            .catch(e => setError(e));
    }

    function handleSelectedClose() {
        setOpenConfirm(false);
        setError(undefined);
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(user => user.id === id);
        setSelected(selected);
    }

    function handleSelectedConfirm() {
        if (!!selected) {
            confirmTransferOrder(selected.id)
                .then(() => {
                    setError(undefined);
                    setOpenConfirm(false);
                    doReloadPage();
                })
                .catch(error => setError(error));
        }
    }

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter />
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <div className="m-5 flex">
                            <div className="ml-0 text-center">
                                <Text kind='h1'>{t("transfers_overview")}</Text>
                            </div>
                        </div>
                        <TransferOrderList page={page} mobile={true} />
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb className="mb-5">
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome /></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('transfers')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="flex mb-5">
                                <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'} align-baseline`}>
                                    <Text kind='h1'>{t("transfers_overview")}</Text>
                                </div>
                                <div className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                    <Button className="!inline-flex"
                                        disabled={!(selected?.state === 'REQUESTED')}
                                        iconDescription="Accept"
                                        onClick={() => setOpenConfirm(true)}>
                                        {t('confirm')}
                                    </Button>
                                </div>
                            </div>
                            <TransferOrderList page={page} mobile={false} onSelectOrder={handleSelected} />
                        </div>
                    </div>
                </>
            )}
            {openConfirm && !!selected && (
                <Modal
                    open={openConfirm}
                    modalHeading={t('confirm_transfer')!}
                    modalLabel={t('confirm_transfer')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleSelectedConfirm}
                    onRequestClose={handleSelectedClose}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleSelectedClose}
                    primaryButtonDisabled={showLoader}
                    secondaryButtonDisabled={showLoader}
                >
                    {showLoader ? <LoadingCenter /> : (<>
                        <div className="my-1 py-1 ">
                            {t('code')}: <a className="font-semibold text-black" >{selected.code}</a>
                        </div>
                        <div className="my-1 py-1">
                            {t('author')}: {selected.authorName}
                        </div>
                        <div className="my-1 py-1">
                            {t('delivery_date')}: {selected.deliveryDate}
                        </div>
                        <div className="my-1 py-1">
                            {t('from')}: {selected.fromSchoolName}
                        </div>
                        <div className="my-1 py-1">
                            {t('to')}: {selected.toSchoolName}
                        </div>
                        {!!error?.detail && (
                            <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                        )}
                    </>
                    )}
                </Modal>
            )}
        </>
    );
}


