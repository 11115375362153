import { useTranslation } from "react-i18next";
import { Blockquote, } from "@wfp/ui";
import { useMediaQuery } from "../../../hooks/commons";
import { Item } from "./page-snacks";
import { useRetrieveSchools } from "../../../hooks/school";
import { LoadingCenter } from "../../commons/loading-center";
import { WizardError } from "./wizard";
import "./survey.css";

interface IProps {
    snacks: Item[],
    surveySchool: number,
    transferSchool: number,
    error?: WizardError
}



export function PageSummary(props: IProps) {

    const { t } = useTranslation();
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const schools = useRetrieveSchools({});

    return (
        <div className="mt-10">
            {!isSmallScreen && (
                <Blockquote className="blockquote-form">
                    <h2 className='mb-3'>{t("confirm_order_title")}</h2>
                </Blockquote>
            )}
            {!!props.error?.snacks && (
                <div className="mt-3">
                    <Blockquote kind="error"  className="blockquote-form">
                        <h4 className='mb-3'>{t("snacks")}</h4>
                        <p>{props.error.snacks[0].toString()}</p>
                    </Blockquote>
                </div>
            )}
            {!!props.error?.surveySchool && (
                <div className="mt-3">
                    <Blockquote kind="error"  className="blockquote-form">
                        <h4 className='mb-3'>{t("from_school")}</h4>
                        <p>{props.error.surveySchool[0].toString()}</p>
                    </Blockquote>
                </div>
            )}
            {!!props.error?.transferSchool && (
                <div className="mt-3">
                    <Blockquote kind="error"  className="blockquote-form">
                        <h4 className='mb-3'>{t("to_school")}</h4>
                        <p>{props.error.transferSchool[0].toString()}</p>
                    </Blockquote>
                </div>
            )}
            <div className={`${isSmallScreen ? '' : 'mt-10'}`}>
                <div className="mt-3">
                    <h3>{t("snacks")}</h3>
                        {props.snacks.map((s, i) => (
                            <p key={i}>{s.name}  {s.quantity}</p>
                        ))}
                </div>
                {!!schools ? (
                    <>
                        <div className="mt-3">
                            <h3>{t("from_school")}</h3>
                            <p>{schools?.filter((s) => s.id === props.surveySchool)[0].name}</p>
                        </div>
                        <div className="mt-3">
                            <h3>{t("to_school")}</h3>
                            <p>{schools?.filter((s) => s.id === props.transferSchool)[0].name}</p>
                        </div>
                    </>
                ) : (<LoadingCenter />)}
            </div>
        </div>
    )
}

