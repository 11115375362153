import {useTranslation} from "react-i18next";
import {Blockquote, Table} from "@wfp/ui";
import {CommoditySchoolItem, ContentType, Order, School} from "../../../models/types";
import {OrderSummary} from "../../order/summary";
import "./wizard.css";

interface IProps {
    commoditySchoolItems: CommoditySchoolItem[],
    school: School;
    order: Order,
}

const Headers = ["commodity", "quantity", "measure",];

export function PageSummary(props: IProps) {

    const {t} = useTranslation();

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("confirm_order_title")}</h2>
                <div>
                    <p>{t("confirm_order_subtitle")}</p>
                </div>
            </Blockquote>
            <div className='mb-3'>
                <p>{t('school')}:
                    <strong> {props.school.name}</strong>
                </p>
            </div>
            <div className="mt-2 mb-4">
                <OrderSummary order={props.order} contentType={ContentType.COMMODITIES}/>
            </div>
            <Table className='text-start'>
                <thead>
                <tr className="item-row-head">
                    {Headers.map((title, index) => (
                        <th key={index}>{t(title)}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.commoditySchoolItems.map((s, idx) => (
                    <tr className="item-row" key={idx}>
                        <td className="item-col">
                            {s.commodityName}
                        </td>
                        <td className="item-col">
                            {s.quantity}
                        </td>
                        <td className="item-col">
                            {s.unitOfMeasureName}
                        </td>
                    </tr>))}
                </tbody>
            </Table>
        </div>
    )
}

