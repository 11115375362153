import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {isInclusivelyBeforeDay, SingleDatePicker} from "react-dates";
import moment, {Moment} from "moment"
import "moment/locale/ar";
import "moment/locale/en-gb";
import {Blockquote, Button, Form, Module, ModuleBody, Select, SelectItem} from "@wfp/ui";
import {KVPair} from "../../models/types";
import {dateFormat} from "../../hooks/formats";
import {useRetrieveDistricts} from '../../hooks/geo';
import {useRetrievePartners} from "../../hooks/partner";
import {ReportError, validateReport} from "../../models/validate-report";

interface Props {
    onSubmit: (filters: KVPair[]) => void,
    showDistricts?: boolean
    showPartners?: boolean
}

export function ReportFilters(props: Props) {
    const {t, i18n} = useTranslation();
    const [district, setDistrict] = useState<string>();
    const [partner, setPartner] = useState<string>();
    const [fromDateFilter, setFromDateFilter] = useState<Moment>();
    const [untilDateFilter, setUntilDateFilter] = useState<Moment>();
    const [focusFrom, setFocusFrom] = useState(false);
    const [focusUntil, setFocusUntil] = useState(false);
    const [error, setError] = useState<ReportError>();
    const [disableApply, setDisableApply] = useState<boolean>(true);

    const districtList = useRetrieveDistricts({onError: (e) => setError(e as ReportError)});
    const partnerList = useRetrievePartners({onError: (e) => setError(e as ReportError)});

    function handleClearFilters() {
        setDistrict(undefined);
        setFromDateFilter(undefined);
        setPartner(undefined);
        setUntilDateFilter(undefined);
        setError(undefined);
    }

    useEffect(() =>{
        setDisableApply(!fromDateFilter || !untilDateFilter);
    }, [fromDateFilter, untilDateFilter])

    function handleApplyFilters() {
        setError(undefined);
        const fromDate = !!fromDateFilter ? dateFormat(fromDateFilter) : null;
        const untilDate = !!untilDateFilter ? dateFormat(untilDateFilter) : null;
        validateReport({district, fromDate, partner, untilDate}).then((res) => {
            const filters: KVPair[] = [];
            if (!!res.district) {
                if (res.district === '0') {
                    res.district = '';
                }
                filters.push({key: "district", value: res.district});
            }
            if (!!res.fromDate) {
                filters.push({key: "from_date", value: res.fromDate});
            }
            if (!!res.partner) {
                if (res.partner === '0') {
                    res.partner = '';
                }
                filters.push({key: "partner", value: res.partner});
            }
            if (!!res.untilDate) {
                filters.push({key: "until_date", value: res.untilDate});
            }
            props.onSubmit(filters);
            setError(undefined);
        }).catch(e => setError(e));
    }

    function handleDistrictChange(e: any): void {
        if (!!e.target?.value) {
            e.target.value === 0 ? setDistrict(undefined) : setDistrict(e.target.value)
        }
    }

    function handleFromDate(value: Moment | null) {
        setError({...error, fromDate: undefined} as ReportError);
        setFromDateFilter(!! value ? value : undefined);
    }

    function handlePartnerChange(e: any): void {
        if (!!e.target?.value) {
            e.target.value === 0 ? setPartner(undefined) : setPartner(e.target.value)
        }
    }

    function handleUntilDate(value: Moment | null) {
        setError({...error, untilDate: undefined} as ReportError);
        setUntilDateFilter(!! value ? value : undefined);
    }

    return (
        <Module noMargin>
            <ModuleBody>
                <Form>
                    <div className="grid grid-cols-4">
                        <div>
                            <label htmlFor="fromDateFilter" className="wfp--label">
                                {t("from_date")}*
                            </label>
                            <SingleDatePicker
                                isRTL={i18n.dir() === 'rtl'}
                                renderMonthText={month => {
                                    month.locale(i18n.language) // TODO: check this!!
                                    return (moment(month).format('MMMM YYYY'))
                                }}
                                renderDayContents={day => {
                                    day.locale('en') // TODO: check this!!
                                    return (moment(day).format('D'))
                                }}
                                date={fromDateFilter!}
                                isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                                onDateChange={handleFromDate}
                                focused={focusFrom}
                                onFocusChange={(f) => setFocusFrom(f.focused)}
                                numberOfMonths={1}
                                showClearDate={true}
                                displayFormat="DD/MM/YYYY"
                                id="fromDateFilter"
                            />
                            {!!error?.fromDate && (
                                <Blockquote kind="error" withIcon>{t(error.fromDate)}</Blockquote>
                            )}
                        </div>
                        <div>
                            <label htmlFor="untilDateFilter" className="wfp--label">
                                {t("until_date")}*
                            </label>
                            <SingleDatePicker
                                isRTL={i18n.dir() === 'rtl'}
                                renderMonthText={month => {
                                    month.locale(i18n.language) // TODO: check this!!
                                    return (moment(month).format('MMMM YYYY'))
                                }}
                                renderDayContents={day => {
                                    day.locale('en') // TODO: check this!!
                                    return (moment(day).format('D'))
                                }}
                                date={untilDateFilter!}
                                isOutsideRange={() => false}
                                onDateChange={handleUntilDate}
                                focused={focusUntil}
                                onFocusChange={(f) => setFocusUntil(f.focused)}
                                numberOfMonths={1}
                                showClearDate={true}
                                displayFormat="DD/MM/YYYY"
                                id="untilDateFilter"
                            />
                            {!!error?.untilDate && (
                                <Blockquote kind="error" withIcon>{t(error.untilDate)}</Blockquote>
                            )}
                        </div>
                        {props.showDistricts && (
                            <Select
                                labelText={t('district')}
                                name="district"
                                className="m-2"
                                value={district || 0}
                                onChange={handleDistrictChange}
                                invalid={!!error?.district}
                            >
                                <SelectItem key={0} value={0} text={t('choose_one')}/>
                                {districtList?.map((rr, idx) => (
                                    <SelectItem key={idx} value={rr.id} text={t(rr.name)}/>
                                ))}
                            </Select>
                        )}
                        {props.showPartners && (
                            <Select
                                labelText={t('partner')}
                                name="partner"
                                className="m-2"
                                value={partner || 0}
                                onChange={handlePartnerChange}
                                invalid={!!error?.partner}
                            >
                                <SelectItem key={0} value={0} text={t('choose_one')}/>
                                {partnerList?.map((rr, idx) => (
                                    <SelectItem key={idx} value={rr.id} text={t(rr.name)}/>
                                ))}
                            </Select>
                        )}
                    </div>
                    <div className="flex justify-end mb-4">
                        <Button
                            type="button"
                            kind="ghost"
                            onClick={handleClearFilters}
                        >{t('clear_filters')}</Button>
                        <Button className={"ml-5"} type="button" disabled={disableApply}
                                onClick={handleApplyFilters}>{t('apply')}</Button>
                    </div>
                </Form>
            </ModuleBody>
        </Module>
    )
}
