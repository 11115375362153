import axios, {AxiosResponse} from "axios";
import {clearToken, getToken} from "./auth";
import {KVPair, ServerError} from "../models/types";
import {getLanguage} from "../pages/layout/header";

export function updateSorting(sort: string, sorting: string[]): string[] {
    const field = sort.startsWith('+') || sort.startsWith('-') ? sort.slice(1) : sort;
    const options = [field, `+${field}`, `-${field}`];
    const idx = sorting.findIndex(s => options.includes(s));
    if (idx !== -1 ) {
        sorting.splice(idx, 1);
    }
    return sort.startsWith('+') ? [field, ...sorting] : sort.startsWith('-') ? [sort, ...sorting] : sorting;
}

export function buildQueryString(values?: KVPair[]): string {
    let qs: string = '';
    (values || []).forEach((pair) => {
        const c = `${pair.key}=${(pair.value || '').toString()}`;
        qs = !!qs ? `${qs}&${c}` : c;
    });
    return qs;
}

export const restapi = axios.create({
    baseURL: process.env.REACT_APP_REST_API_BASE_URL,
});

restapi.defaults.xsrfCookieName = 'csrftoken';
restapi.defaults.xsrfHeaderName = 'X-CSRFToken';


restapi.interceptors.request.use(async config => {
    const c = {...config, headers: config.headers || {}, };
    const token = getToken();
    if (token) {
        c.headers['Authorization'] = `Token ${token}`;
    }
    const lang = getLanguage();
    if (lang) {
        c.headers['X-USER-LANGUAGE'] = lang;
    }
    return c;
});


restapi.interceptors.response.use((response: AxiosResponse) => response, (error) => {
    if (!!error.response && error.response.status  === 401) {
        clearToken();
        window.location.href = '/oidc/authenticate?next=/home&fail=/';
        return;
    }
    return Promise.reject(!!error.response ? new ServerErrorResponseInfo(error.response) : error);
});

class ServerErrorResponseInfo implements ServerError {

    detail: [string] | undefined;
    errors: [string] | undefined;
    status?: number;

    constructor(response: any) {
        if (response.data) {
            Object.assign(this, response.data);
        }
        this.status = response.status;
    }
}




