import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Select, SelectItem} from "@wfp/ui";
import {Meal, SchoolMeal} from "../../../models/types";
import {WizardMealError} from "./wizard";
import "./wizard.css";
import moment, {Moment} from "moment/moment";
import {dateFormat} from "../../../hooks/formats";
import {SingleDatePicker} from "react-dates";
import i18n from "../../../i18n";

interface IProps {
    error?: WizardMealError
    onChange: (schoolMeal: SchoolMeal) => void,
    schoolMeal: SchoolMeal,
    meals: Meal[],
}

export function PageMeals(props: IProps) {
    const {t} = useTranslation();
    const [selectedMeal, setSelectedMeal] = useState<number>(0);
    const [deliveryDate, setDeliveryDate] = useState<Moment>(moment().add(1, 'days'));
    const [focusDelivery, setFocusDelivery] = useState(false);

    useEffect(() => {
        if (!!props.schoolMeal.meal && props.schoolMeal.meal > 0) {
            setSelectedMeal(props.schoolMeal.meal);
        }
    }, [props.schoolMeal, selectedMeal]);

    useEffect(() => {
        if (!!props.schoolMeal?.deliveryDate && !moment(props.schoolMeal.deliveryDate).isSame(deliveryDate, 'days')) {
            setDeliveryDate(moment(props.schoolMeal.deliveryDate));
        }
        if (!props.schoolMeal.deliveryDate) {
            props.onChange({
                ...props.schoolMeal,
                deliveryDate: dateFormat(deliveryDate) || '',
            })
        }
    }, [props.schoolMeal, deliveryDate])


    function handleSelectMeal(e: any): void {
        if (!isNaN(Number(e.target?.value)) && !!props.meals) {
            const meal = props.meals
                .find((s) => s.id === parseInt(e.target?.value)
                ) || {id: 0, category: 0, name: ''} as Meal;
            props.onChange({
                ...props.schoolMeal,
                meal: meal.id,
                mealName: meal.name,
            } as SchoolMeal);
            setSelectedMeal(meal.id);
        }
    }

    function handleDeliveryDate(value: Moment | null) {
        const dateValue = dateFormat(value) || '';
        props.onChange({
            ...props.schoolMeal,
            deliveryDate: dateValue,
        } as SchoolMeal);
        setDeliveryDate(value!);
    }


    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("select_meal_title")}</h2>
                <div>
                    <p>{t("select_meal_subtitle")}</p>
                </div>
            </Blockquote>
            <div className="mt-10 flex">
                <div className={`w-64 ${!!(props.error?.meal) ? `mt-3 mb-5` : 'mt-3'}`}>
                    <Select
                        onChange={(e) => handleSelectMeal(e)}
                        value={selectedMeal || 0}
                        labelText={t("please_select_meal")} /* {`Please select one ${sc.name}`} #203240 */
                        invalid={!!(props.error?.meal)}
                        invalidText={t((props.error?.meal)?.toString() || '')!}
                    >
                        {[{id: 0, name: "choose_one"} as Meal, ...(props.meals || [])]?.map((s, idx) => (
                            <SelectItem key={idx} id={`${s.id}`} value={s.id} text={t(s.name)}/>
                        ))}
                    </Select>
                </div>
            </div>
            <div className="wfp--form-item mt-10 mb-10">
                <label htmlFor="deliveryDate" className="wfp--label">
                    {t("select_delivery_date_title")}*
                </label>
                <SingleDatePicker
                    isRTL={i18n.dir() === 'rtl'}
                    renderMonthText={month => {
                        month.locale(i18n.language)
                        return (
                            moment(month).format('MMMM YYYY')
                        )
                    }}
                    renderDayContents={day => {
                        day.locale('en')
                        return (
                            moment(day).format('D')
                        )
                    }}
                    date={deliveryDate!}
                    onDateChange={handleDeliveryDate}
                    focused={focusDelivery}
                    onFocusChange={(f) => {
                        setFocusDelivery(f.focused)
                    }}
                    numberOfMonths={1}
                    displayFormat="DD/MM/YYYY"
                    showClearDate={true}
                    id="deliveryDate"/>
                {!!props.error?.deliveryDate && (
                    <Blockquote
                        kind="error"
                        withIcon>
                        {t(props.error.deliveryDate)}
                    </Blockquote>
                )}
            </div>
        </div>
    )
}
