import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem, Text} from '@wfp/ui';
import {PlanningList} from "../components/planning/week";

export default function Planning() {
    const {t} = useTranslation();
    return (
        <>
            <div className="visible md:hidden">
                <div className="m-5 flex">
                    <div className="ml-0 text-center">
                        <Text kind='h1'>{t("planning_week")}</Text>
                    </div>
                </div>
            </div>
            <div className="hidden md:block">
                <div className="m-10">
                    <Breadcrumb className="mb-10">
                        <BreadcrumbItem>
                            <NavLink to="/"><BreadcrumbHome/></NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem disableLink>
                            {t('planning')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <div className="text-left mb-5">
                        <PlanningList/>
                    </div>
                </div>
            </div>
        </>
    );
}


