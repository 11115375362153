import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";
import {Form} from "@wfp/ui";
import {Snack, SnackCategory} from "../../models/types";
import {SnackError} from "../../models/validate-snack";
import {getSnackCategories} from "../../restapi/snack-category";
import {SMETSTextInput} from "../commons/text-input";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";


interface Props {
    snack: Snack;
    error?: SnackError;
    onChange: (s: Snack) => void;
}

export const SnackForm = (props: Props) => {
    const {t} = useTranslation();
    const [categorySelected, setCategorySelected] = useState<SnackCategory>();

    useEffect(() => {
        if (!!props.snack && !!props.snack.category) {
            setCategorySelected({id: props.snack.category, name: props.snack.categoryName} as SnackCategory);
        }
    }, [props.snack]);

    function handleChangeCategory(): void {
        setCategorySelected(undefined);
        props.onChange({...props.snack, category: 0, categoryName: ''} as Snack);
    }

    function handleSelectCategory(o: { id: string, name: string }): void {
        const value = {
            ...categorySelected,
            id: Number(o.id),
            name: o.name,
        } as SnackCategory;
        setCategorySelected(value);
        props.onChange({...props.snack, category: value.id, categoryName: value.name} as Snack);
    }

    return (
        <Form>
            <div className={`mt-0 ${!!props.error?.nameAr ? 'mb-10' : 'mb-5'}`}>
                <SMETSTextInput
                    type='text'
                    required
                    id="text"
                    invalid={!!props.error?.nameAr}
                    invalidText={t(props.error?.nameAr?.toString() || '')}
                    value={props.snack.nameAr || ''}
                    labelText={t('nameAr')!}
                    onChange={(e: any) => props.onChange({
                        ...props.snack,
                        nameAr: e.target.value,
                    } as Snack)}
                />
            </div>
            <div className={`mt-0 ${!!props.error?.nameEn ? 'mb-10' : 'mb-5'}`}>
                <SMETSTextInput
                    type='text'
                    required
                    id="text"
                    invalid={!!props.error?.nameEn}
                    invalidText={t(props.error?.nameEn?.toString() || '')}
                    value={props.snack.nameEn || ''}
                    labelText={t('nameEn')!}
                    onChange={(e: any) => props.onChange({
                        ...props.snack,
                        nameEn: e.target.value,
                    } as Snack)}
                />
            </div>
            <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'}`}>
                <SMETSTypeahead<SnackCategory>
                    noOptionsMessage={t('no_option_value') as string}
                    value={categorySelected}
                    invalid={!!props.error?.category}
                    invalidText={t(props.error?.category?.toString() || '')!}
                    onChange={handleChangeCategory}
                    onSelectOption={handleSelectCategory}
                    label={t('category')}
                    search={(input) => getSnackCategories(1, 5, [{
                        key: "name_ic",
                        value: input
                    }]).then(res => res.results)}
                />
            </div>
        </Form>
    )
}
