import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Button, Modal} from "@wfp/ui";
import {iconAddGlyph, iconDeleteGlyph, iconEditGlyph} from "@wfp/icons";
import i18n from "../../i18n";
import {KVPair, ListPage, ServerError, SnackCategory, Sortable} from "../../models/types";
import {SnackCategoryError, validateSnackCategory} from "../../models/validate-snack-category";
import {updateSorting} from "../../restapi/restapi";
import {disableSnackCategory, getSnackCategories, saveSnackCategory} from "../../restapi/snack-category";
import {useInViewport} from "../../hooks/useInViewport";
import {SnackCategoryCard} from "./card";
import {SnackCategoryFilters} from "./filters";
import {SnackCategoryForm} from "./form";
import {SnackCategoryTable} from "./table";

const SortInfo: Sortable[] = [
    {code: 'id', field: 'id', enabled: true, mode: 0},
    {code: 'name', field: 'name', enabled: true, mode: 0},
];

interface Props {
    page?: ListPage<SnackCategory>,
    mobile?: boolean,
    onClick?: (id: number) => void,
    onSelect?: (id: number) => void
}

export const SnackCategoryList = (props: Props) => {
    const {t} = useTranslation();
    const [error, setError] = useState<ServerError>();
    const [loading, setLoading] = useState(false);
    const {isInViewport, ref} = useInViewport();
    const [openDisable, setOpenDisable] = useState(false);
    const [openSelected, setOpenSelected] = useState(false);
    const [page, setPage] = useState<ListPage<SnackCategory>>();
    const [selected, setSelected] = useState<SnackCategory>();
    const [snackCategoryError, setSnackCategoryError] = useState<SnackCategoryError>();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        } else {
            doLoadPage();
        }
    }, [props.page]);

    function doLoadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]) {
        setLoading(true);
        getSnackCategories(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
                return Promise.resolve(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleFilters(filters: KVPair[]): void {
        doLoadPage(page?.pageNumber, page?.pageSize, filters, page?.sorting);
    }

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        doLoadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page?.next;
            if (result) {
                doLoadPage(page.pageNumber + 1, page.pageSize, page?.filtering, page?.sorting);
            }
            return result;
        }
        return false;
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(item => item.id === id);
        setSelected(selected);
    }

    function handleDisableClose() {
        setOpenDisable(false);
        setError(undefined);
    }

    function handleDisableSubmit() {
        if (!!selected) {
            disableSnackCategory(selected.id)
                .then(() => {
                    setError(undefined);
                    setOpenDisable(false);
                    setSelected(undefined);
                    doLoadPage();
                })
                .catch(error => setError(error));
        }
    }

    function handleSelectedClose() {
        setOpenSelected(false);
        setSnackCategoryError(undefined);
        setSelected(undefined);
    }

    function handleSelectedSubmit() {
        if (!!selected) {
            validateSnackCategory(selected)
                .then((data) =>
                    saveSnackCategory(data)
                        .then(res => {
                            setOpenSelected(false);
                            setSnackCategoryError(undefined);
                            setSelected(res);
                            doLoadPage();
                        })
                        .catch(e => setSnackCategoryError(e as SnackCategoryError)))
                .catch(e => setSnackCategoryError(e));
        }
    }

    function handleAdd() {
        setSelected(undefined);
        setOpenSelected(true);
        setSnackCategoryError(undefined);
    }

    function handleEdit() {
        if (!!selected?.id) {
            setOpenSelected(true);
            setSnackCategoryError(undefined);
        }
    }

    return (
        <div>
        {!!error?.errors && (
            <Blockquote kind="error" withIcon>{error.errors.toString()}</Blockquote>
        )}
        {page?.count === 0 && (
            <Blockquote kind="warning" withIcon>{t("empty_snack_list")}</Blockquote>
        )}
            <div>
                <div className="flex">
                    <div className={`mx-3 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                        <Button className='!inline-flex' disabled={!selected} onClick={() => setOpenDisable(true)}
                                icon={iconDeleteGlyph}
                                iconReverse>
                            {t('delete')}
                        </Button>
                    </div>
                    <div className='mx-0'>
                        <Button disabled={!selected} onClick={handleEdit} className='!inline-flex'
                                icon={iconEditGlyph} iconReverse>
                            {t('edit_category')}
                        </Button>
                    </div>
                    <div className={`${i18n.dir() === 'ltr' ? 'ml-3' : 'mr-3'}`}>
                        <Button onClickCapture={handleAdd} iconDescription="create"
                                className='!inline-flex' icon={iconAddGlyph} iconReverse>
                            {t('add_category')}
                        </Button>
                    </div>
                </div>
                {!!props.mobile && (page?.results.map((item: SnackCategory) => (
                <div key={item.id} className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                    <SnackCategoryCard item={item} onClick={() => handleSelected(item.id)}/>
                </div>
                )))}
                {!!props.mobile && (
                <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                )}
                {!props.mobile && page && (
                <>
                    <SnackCategoryFilters onSubmit={handleFilters}/>
                    <SnackCategoryTable loadPage={doLoadPage} page={page}
                                        hidePagination={page.count <= page.results.length}
                                        sort={handleSort}
                                        onSelect={handleSelected}
                                        sortInfo={SortInfo}
                                        selectId={selected?.id}
                    />
                </>
                )}
            </div>
        {openDisable && !!selected && (
            <Modal
                open={openDisable}
                modalHeading={t('delete_snack_category')!}
                modalLabel={t('delete_snack_category')!}
                primaryButtonText={t('confirm')!}
                onRequestSubmit={handleDisableSubmit}
                onRequestClose={handleDisableClose}
                secondaryButtonText={t('cancel')!}
                onSecondarySubmit={handleDisableClose}
            >
                <p>{`${t('delete_snack_category_confirm')} ${selected.name}`}</p>
                {!!error?.errors && (
                    <Blockquote kind="error" withIcon>{error.errors}</Blockquote>
                )}
            </Modal>
        )}
        {openSelected && (
            <Modal
                open={true}
                modalHeading={(!!selected?.id ? t('edit_category') : t('add_new_category')) as string}
                modalLabel={(!!selected?.id ? t('edit_category') : t('add_new_category')) as string}
                primaryButtonText={t('confirm')!}
                onRequestSubmit={handleSelectedSubmit}
                onRequestClose={handleSelectedClose}
                secondaryButtonText={t('cancel')!}
            >
                <SnackCategoryForm
                    snackCategory={selected || {} as SnackCategory}
                           error={snackCategoryError}
                           onChange={setSelected}
                />
            </Modal>
        )}
        </div>
    );
}
