import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Role} from "../models/types";
import {LoadingCenter} from "../components/commons/loading-center";
import {Logo} from "../components/commons/logo";
import {useLoggedUser} from "../hooks/user";

export default function Home() {
    const navigate = useNavigate();
    const user = useLoggedUser({});

     useEffect(() => {
         if (!!user && !!navigate){
             if ([Role.SupplierDriver, Role.SchoolDriver, Role.SchoolRepresentative, Role.PartnerMonitor].includes(user.role)) {
                 navigate(`/mobile`);
             }
         }
     }, [user, navigate]);

    if (!user) {
        return (
            <LoadingCenter/>
        )
    }
    return (
        <Logo/>
    )
}

