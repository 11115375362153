import {ServerError, SnackSchoolDay} from "./types";


export interface SnackSchoolDayError extends ServerError {
    detail: string[] | undefined;
    snackName1: string[] | undefined;
    snackName2: string[] | undefined;
    quantityAm1: string[] | undefined;
    quantityAm2: string[] | undefined;
    quantityPm1: string[] | undefined;
    quantityPm2: string[] | undefined;


}

class SnackSchoolDayErrorInfo implements SnackSchoolDayError {
    detail: string[] | undefined;
    snackName1: string[] | undefined;
    snackName2: string[] | undefined;
    quantityAm1: string[] | undefined;
    quantityAm2: string[] | undefined;
    quantityPm1: string[] | undefined;
    quantityPm2: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}


export function validateSnackSchoolDay(snack: SnackSchoolDay[]): Promise<SnackSchoolDay[]> {
    const error = new SnackSchoolDayErrorInfo();
    if (snack.length !== 2) {
        error.add('detail', 'select 2 snacks');
    } else {
        if (!snack[0].snack) {
            error.add('snackName1', 'this_field_is_required');
        }
        if (!snack[1].snack) {
            error.add('snackName2', 'this_field_is_required');
        }
        if (snack[0].snack === snack[1].snack) {
            error.add('snackName1', 'must_be_different_snacks');
            error.add('snackName2', 'must_be_different_snacks');
        }
        if (snack[0].quantityAm + snack[0].quantityPm === 0) {
            error.add('quantityPm1', 'invalid_quantity');
        }
        if (snack[1].quantityAm + snack[1].quantityPm === 0) {
            error.add('quantityPm2', 'invalid_quantity');
        }
    }

    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(snack) : Promise.reject(error);
}
