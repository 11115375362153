import {buildQueryString, restapi} from "./restapi";
import {KVPair, ListPage, SchoolMealItem} from "../models/types";

export function getSchoolMealItem(pk: number): Promise<SchoolMealItem> {
    return restapi.get<SchoolMealItem>(`/api/v1/smt_meal/school_meal_item/${pk}/`).then(res => res.data);
}

export function getSchoolMealItems(page = 1, page_size?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<SchoolMealItem>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(page_size || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<SchoolMealItem>>(`/api/v1/smt_meal/school_meal_item/?${qs}`).then(res => ({
        ...res.data,
        filtering: filters,
        sorting: sorting
    }));
}


export function acceptSchoolMealItem(pk: number): Promise<SchoolMealItem> {
    return restapi.post<SchoolMealItem>(`/api/v1/smt_meal/school_meal_item/${pk}/accept/`).then(res => res.data);
}

export function pickupSchoolMealItem(pk: number): Promise<SchoolMealItem> {
    return restapi.post<SchoolMealItem>(`/api/v1/smt_meal/school_meal_item/${pk}/pickup/`).then(res => res.data);
}

export function handoverSchoolMealItem(pk: number): Promise<SchoolMealItem> {
    return restapi.post<SchoolMealItem>(`/api/v1/smt_meal/school_meal_item/${pk}/handover/`).then(res => res.data);
}

export function undoPickupSchoolMealItem(pk: number): Promise<SchoolMealItem> {
    return restapi.post<SchoolMealItem>(`/api/v1/smt_meal/school_meal_item/${pk}/undo_pickup/`).then(res => res.data);
}

export function undoHandoverSchoolMealItem(pk: number): Promise<SchoolMealItem> {
    return restapi.post<SchoolMealItem>(`/api/v1/smt_meal/school_meal_item/${pk}/undo_handover/`).then(res => res.data);
}

export function verifySchoolMealItemQRCode(code: string): Promise<number> {
    return restapi.post<{ pk: number }>(`/api/v1/smt_meal/school_meal_item/verify_code/`, {code}).then(res => Promise.resolve(res.data?.pk));
}