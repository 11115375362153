import {ServerError, Supplier} from "./types";


export interface SupplierError extends ServerError {
    detail: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    phone: string[] | undefined;
}

class SupplierErrorInfo implements SupplierError {
    detail: string[] | undefined;
    errors: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    phone: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateSupplier(supplier: Supplier): Promise<Supplier> {
    const error = new SupplierErrorInfo();
    if (!supplier.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!supplier.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(supplier) : Promise.reject(error);
}
