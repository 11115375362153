import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Blockquote} from "@wfp/ui";
import {KVPair, ListPage, SchoolMeal, ServerError, Sortable} from "../../models/types";
import {updateSorting} from "../../restapi/restapi";
import {getSchoolMeals} from "../../restapi/school-meal";
import {useInViewport} from "../../hooks/useInViewport";
import {SchoolMealCard} from "./card";
import {SchoolMealFilters} from "./filters";
import {SchoolMealTable} from "./table";

const SortInfo: Sortable[] = [
    {code: 'id', field: 'id', enabled: true, mode: 0},
    {code: 'state', field: 'state', enabled: true, mode: 0},
    {code: 'delivery_date', field: 'delivery_date', enabled: true, mode: 0},
    {code: 'last_modified', field: 'last_modified', enabled: true, mode: 0},
];

interface Props {
    page?: ListPage<SchoolMeal>,
    mobile?: boolean,
    filters?: KVPair[],
    disableFilter?: boolean,
    sorting?: string[];
    onClick?: (id: number) => void
    onSelectedSchoolMeal?: (id: number) => void
}

export const SchoolMealList = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<ListPage<SchoolMeal>>();
    const [error, setError] = useState<ServerError>();
    const {isInViewport, ref} = useInViewport();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        } else {
            loadPage(1, undefined, props.filters, props.sorting);
        }
    }, [props.page]);

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getSchoolMeals(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleFiltering(filters: KVPair[]): void {
        const changed = filters.length !== page?.filtering?.length || filters.filter(kvp => !page?.filtering?.find(f => f.key === kvp.key && f.value === kvp.value)).length > 0
        if (changed) {
            loadPage(page?.pageNumber || 1, page?.pageSize, filters, page?.sorting);
        }
    }

    function handleSorting(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleClickSchoolMeal(id: number): void {
        if (!!props.onClick) {
            props.onClick(id);
        } else {
            navigate(`/school-meals/${id}`);
        }
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page.next;
            if (result) {
                loadPage(page.pageNumber + 1, page.pageSize, page?.filtering, page?.sorting);
            }
            return result;
        }
        return false;
    }

    return (
        <div>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error.detail.toString()}
                </Blockquote>
            )}
            {!!page && (
                <div className="mx-0">
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>
                            {t("empty_orders_list")}
                        </Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((schoolMeal: SchoolMeal) => (
                        <SchoolMealCard key={schoolMeal.id} item={schoolMeal}
                                        onClick={() => handleClickSchoolMeal(schoolMeal.id)}/>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                    )}
                    {!props.mobile && page && (
                        <>
                        {!props.disableFilter && (
                            <SchoolMealFilters onSubmit={handleFiltering}/>
                        )}
                            <SchoolMealTable page={page} loadPage={loadPage}
                                             onSelectedSchoolMeal={props.onSelectedSchoolMeal}
                                             onClick={handleClickSchoolMeal}
                                             hidePagination={page.count <= page.results.length}
                                             sort={handleSorting}
                                             sortInfo={SortInfo}
                            />
                        </>

                    )}
                </div>
            )}
        </div>
    );
}
