import {useTranslation} from "react-i18next";
import {Blockquote, Modal} from "@wfp/ui";
import {OrderItem} from "../../models/types";
import {OrderItemCard} from "./card";

export interface Props {
    error?: any;
    orderItem: OrderItem;
    onOk?: () => void;
    onCancel: () => void;
}

export const ReturnConfirmation = (props: Props) => {

    const {t} = useTranslation();

    return (
        <div>
            <Modal
                open={true}
                primaryButtonText={t('pickup') as string}
                onRequestSubmit={props.onOk}
                secondaryButtonText={t('cancel') as string}
                onSecondarySubmit={props.onCancel}
                onRequestClose={props.onCancel}
            >
                {!!props.error?.errors && (
                    <Blockquote kind="error" withIcon>{props.error?.errors?.toString()}</Blockquote>
                )}
                {!!props.error?.detail && (
                    <Blockquote kind="error" withIcon>{props.error?.detail?.toString()}</Blockquote>
                )}
                <OrderItemCard readonly={true} hideState={true} item={props.orderItem}/>
            </Modal>
        </div>
    );
};
