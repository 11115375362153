import {buildQueryString, restapi} from "./restapi";
import {KVPair, ListPage, Meal} from "../models/types";

export function getMeal(id: number): Promise<Meal> {
    return restapi.get<Meal>(`/api/v1/smt_fab/meal/${id}/`).then(res => res.data);
}

export function getMeals(page = 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Meal>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Meal>>(`/api/v1/smt_fab/meal/?${qs}`).then(res => ({
        ...res.data,
        filtering: filters,
        sorting: sorting
    }));
}

export function retrieveMeals(filters?: KVPair[], sorting?: string[]): Promise<Meal[]> {
    const pageOptions = [{key: '__pagination__', value: '0'}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<Meal[]>(`/api/v1/smt_fab/meal/?${qs}`).then(res => res.data);
}
