import {useTranslation} from "react-i18next";
import {Blockquote, Form, InputGroup} from "@wfp/ui";
import {SMETSTextInput} from "../commons/text-input";
import {Partner} from "../../models/types";
import {PartnerError} from "../../models/validate-partner";

interface Props {
    partner: Partner;
    error?: PartnerError;
    onChange: (u: Partner) => void
}

export const PartnerForm = (props: Props) => {
    const {t} = useTranslation();
    return (
        <Form>
            {!!(props.error?.detail) && (
                <Blockquote kind="error" withIcon>
                    {t(props?.error?.detail?.toString() || '')}
                </Blockquote>
            )}
            <InputGroup>
                <div className='flex flex-col  w-full items-start'>
                    <div className={`w-3/4 ${!!props.error?.nameAr ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            value={props.partner.nameAr}
                            invalid={!!props.error?.nameAr}
                            invalidText={t(props.error?.nameAr?.toString() || '')}
                            name='nameAr'
                            onChange={(e: any) => props.onChange({...props.partner, nameAr: e.target.value})}
                            labelText={t("nameAr?")!}
                        />
                    </div>
                    <div className={`w-3/4 ${!!props.error?.nameEn ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required
                            type='text'
                            value={props.partner.nameEn}
                            invalid={!!props.error?.nameEn}
                            invalidText={t(props.error?.nameEn?.toString() || '')}
                            name='nameEn'
                            onChange={(e: any) => props.onChange({...props.partner, nameEn: e.target.value})}
                            labelText={t("nameEn?")!}
                        />
                    </div>
                    <div className={`w-3/4 ${!!props.error?.phone ? 'mb-10' : 'mb-3'}`}>
                        <SMETSTextInput
                            required={false}
                            type='text'
                            value={props.partner.phone}
                            invalid={!!props.error?.phone}
                            invalidText={t(props.error?.phone?.toString() || '')}
                            name='phone'
                            onChange={(e: any) => props.onChange({...props.partner, phone: e.target.value})}
                            labelText={t("phone?")!}
                        />
                    </div>
                </div>
            </InputGroup>
        </Form>
    )
}
