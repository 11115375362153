import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Blockquote} from "@wfp/ui";
import {Role, SchoolMealItem, ServerError} from "../../models/types";
import {
    acceptSchoolMealItem,
    handoverSchoolMealItem,
    pickupSchoolMealItem,
    undoPickupSchoolMealItem,
} from "../../restapi/school-meal-item";
import {useRetrieveSchoolMealItem} from "../../hooks/school-meal-item";
import {useLoggedUser} from "../../hooks/user";
import {LoadingCenter} from "../commons/loading-center";
import {SchoolMealItemCard} from "./card";
import {AcceptConfirmation} from "./accept-confirmation";
import {HandoverConfirmation} from "./handover-confirmation";

export const SchoolMealItemDetail = () => {
    const {id} = useParams();
    const user = useLoggedUser({});
    const navigate = useNavigate();
    const [schoolMealItem, setSchoolMealItem] = useState<SchoolMealItem>();
    const [error, setError] = useState<ServerError>();
    const [schoolMealItemHandover, setSchoolMealItemHandover] = useState<SchoolMealItem>();
    const [schoolMealItemAccept, setSchoolMealItemAccept] = useState<SchoolMealItem>();

    const item = useRetrieveSchoolMealItem({id: Number(id), onError: (e) => setError(e)});

    useEffect(() => {
        if (!!item) {
            setSchoolMealItem(item);
        }
    }, [item]);

    function handlePickup() {
        if (!!schoolMealItem) {
            pickupSchoolMealItem(schoolMealItem.id)
                .then(() => navigate('/home'))
                .catch((error) => setError(error));
        }
    }

    function handleUndoPickup() {
        if (!!schoolMealItem) {
            undoPickupSchoolMealItem(schoolMealItem.id)
                .then(() => navigate('/home'))
                .catch((error) => setError(error));
        }
    }

    function handleHandover() {
        if (!!schoolMealItem) {
            setSchoolMealItemHandover(schoolMealItem);
        }
    }

    function handleHandoverConfirm() {
        if (!!schoolMealItemHandover) {
            handoverSchoolMealItem(schoolMealItemHandover.id)
                .then(() => navigate("/home"))
                .catch((error) => setError(error));
        }
    }

    function handleAccept() {
        setSchoolMealItemAccept(schoolMealItem);
    }

    function handleAcceptConfirm() {
        if (!!schoolMealItemAccept) {
            acceptSchoolMealItem(schoolMealItemAccept.id)
                .then(() => navigate('/home'))
                .catch((error) => setError(error));
        }
    }

    return (
        <>
            {!!schoolMealItemHandover && (
                <HandoverConfirmation
                    schoolMealItem={schoolMealItemHandover}
                    onCancel={() => setSchoolMealItemHandover(undefined)}
                    onOk={handleHandoverConfirm}
                />
            )}
            {!!schoolMealItemAccept && (
                <AcceptConfirmation
                    schoolMealItem={schoolMealItemAccept}
                    onCancel={() => setSchoolMealItemAccept(undefined)}
                    onOk={handleAcceptConfirm}
                />
            )}
            {!!error?.errors && (
                <Blockquote kind="error" withIcon>{error?.errors?.toString()}</Blockquote>
            )}
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error?.detail?.toString()}</Blockquote>
            )}
            {!!schoolMealItem && (
                <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-3">
                    <SchoolMealItemCard item={schoolMealItem}
                                        pickup={
                                            schoolMealItem?.state === 'CONFIRMED' && user?.role === Role.SchoolDriver ? handlePickup : undefined
                                        }
                                        undoPickup={
                                            schoolMealItem?.state === 'LOADED' && user?.role === Role.SchoolDriver ? handleUndoPickup : undefined
                                        }
                                        handover={
                                            schoolMealItem?.state === 'LOADED' && user?.role === Role.SchoolDriver ? handleHandover : undefined
                                        }
                                        accept={
                                            schoolMealItem?.state === 'DELIVERED' && user?.role === Role.SchoolRepresentative ? handleAccept : undefined
                                        }
                    />
                </div>
            )}
            {!(error || schoolMealItem) && <LoadingCenter/>}
        </>
    );
};
