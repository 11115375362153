import moment from "moment";
import {Report, ServerError} from "./types";

export interface ReportError extends ServerError {
    district: string[] | undefined;
    fromDate: string[] | undefined;
    partner: string[] | undefined;
    untilDate: string[] | undefined;
}

class ReportErrorInfo implements ReportError {
    district: string[] | undefined;
    fromDate: string[] | undefined;
    partner: string[] | undefined;
    untilDate: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateReport(report: Report): Promise<Report> {
    const error = new ReportErrorInfo();
    if (!report.fromDate) {
        error.add('fromDate', 'this_field_is_required');
    }
    if (!report.untilDate) {
        error.add('untilDate', 'this_field_is_required');
    } else if (moment(report.untilDate).isBefore(report.fromDate)) {
        error.add('untilDate', 'invalid_value');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(report) : Promise.reject(error);
}
