import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SingleDatePicker} from "react-dates";
import {Moment} from "moment";
import moment from "moment/moment";
import {Button, Form, Module, ModuleBody, Select, SelectItem} from "@wfp/ui";
import {KVPair, LogType, UserDetails} from "../../models/types";
import {getUsers} from "../../restapi/user";
import {dateFormat} from "../../hooks/formats";
import {SMETSModuleHeader} from "../commons/module-header";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";

interface Props {
    onSubmit: (filters: KVPair[]) => void,
    logType: LogType,
}

const optionsCRUD = [{id: 0, value: 'choose_one'}, {id: 1, value: 'Create'}, {id: 2, value: 'Update'}, {id: 3, value: 'Delete'}];
const optionsLogin = [{id: 0, value: 'choose_one'}, {id: 1, value: 'Login'}, {id: 2, value: 'Logout'}, {id: 3, value: 'Failed login'}];
const optionsRequest = [{id: 0, value: 'choose_one'}, {id: 1, value: 'GET'}, {id: 2, value: 'PATCH'}, {id: 3, value: 'POST'}, {id: 3, value: 'PUT'}];

export function LogsFilters(props: Props) {
    const [visible, setVisible] = useState(false);
    const [methodSelected, setMethodSelected] = useState<string>();
    const [userSelected, setUserSelected] = useState<UserDetails>();
    const [dateSelected, setDateSelected] = useState<Moment>();
    const [focusDate, setFocusDate] = useState(false);
    const [options, setOptions] = useState<{id: number, value: string}[]>();
    const {t, i18n} = useTranslation();

    useEffect(() => {
        if(!!props.logType) {
            switch(props.logType) {
                case LogType.CRUD:
                    setOptions(optionsCRUD);
                    break;
                case LogType.LOGIN:
                    setOptions(optionsLogin);
                    break;
                case LogType.REQUEST:
                    setOptions(optionsRequest);
                    break;
            }
        }
    }, [props.logType])

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setMethodSelected(undefined);
        setUserSelected(undefined);
        setDateSelected(undefined);
    }

    function handleApply() {
        const filters: KVPair[] = [];
        if (!!userSelected?.username) {
            filters.push({key: "username", value: userSelected.username});
        }
        if (!!dateSelected) {
            filters.push({key: "date", value: dateFormat(dateSelected) || ''});
        }
        if (!!methodSelected) {
            filters.push({key: "method", value: methodSelected || ''});
        }
        props.onSubmit(filters);
    }

    function handleDateChange(value: Moment | null) {
        setDateSelected(value || undefined);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-5 space-x-5 items-baseline text-start">
                            <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'}`}>
                                <SMETSTypeahead<UserDetails>
                                    getOptionLabel={(u) => u?.username}
                                    getOptionValue={(u) => u?.pk?.toString()}
                                    noOptionsMessage={t('no_option_value') as string}
                                    value={userSelected}
                                    onClick={handleApply}
                                    onSelectOption={(o) => setUserSelected({
                                        ...userSelected,
                                        pk: Number(o.id),
                                        username: o.name
                                    } as UserDetails)}
                                    label={t('username')}
                                    search={(input) => getUsers(1, 5, [{
                                        key: "username_ic",
                                        value: input
                                    }]).then(res => res.results)}/>
                            </div>
                            <div>
                                <label htmlFor="dateFilter" className="wfp--label">
                                    {t("select_date")}
                                </label>
                                <SingleDatePicker
                                    isRTL={i18n.dir() === 'rtl'}
                                    renderMonthText={month => {
                                        month.locale(i18n.language) // TODO: check this!!
                                        return (moment(month).format('MMMM YYYY'))
                                    }}
                                    renderDayContents={day => {
                                        day.locale('en') // TODO: check this!!
                                        return (moment(day).format('D'))
                                    }}
                                    date={dateSelected!}
                                    isOutsideRange={() => false}
                                    onDateChange={handleDateChange}
                                    focused={focusDate}
                                    onFocusChange={(f) => setFocusDate(f.focused)}
                                    numberOfMonths={1}
                                    showClearDate={true}
                                    displayFormat="DD/MM/YYYY"
                                    id="dateFilter"
                                />
                            </div>
                            <div className="">
                                {!!options &&(
                                <Select
                                    labelText={t('method')}
                                    value={methodSelected || ''}
                                    onChange={(e: any) => setMethodSelected(e.target?.value)}>
                                    {options.map((option, idx) => (
                                        <SelectItem key={idx} id={option.id.toString()} value={option.value} text={t(option.value)}/>
                                    ))}
                                </Select>
                                )}
                            </div>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button" onClick={handleApply}>
                                {t('apply')}
                            </Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
