import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, Module, ModuleBody, Select, SelectItem} from "@wfp/ui";
import {KVPair, Role, School, Supplier} from "../../models/types";
import {useRetrievePartners} from "../../hooks/partner";
import {getSchools} from "../../restapi/school";
import {getSuppliers} from "../../restapi/supplier";
import {SMETSModuleHeader} from "../commons/module-header";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";

export function UserFilters(props: { onSubmit: (filters: KVPair[]) => void }) {
    const [visible, setVisible] = useState(false);
    const [supplierSelected, setSupplierSelected] = useState<Supplier>();
    const [schoolSelected, setSchoolSelected] = useState<School>();
    const [selectedRoleIdx, setSelectedRoleIdx] = useState<number>();
    const [selectedPartner, setSelectedPartner] = useState<number>();
    const {t, i18n} = useTranslation();
    const roleList = [t("choose_one"), ...Object.values(Role)];
    const partnerList = useRetrievePartners({});

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setSupplierSelected({} as Supplier);
        setSchoolSelected({} as School);
        setSelectedRoleIdx(undefined);
        setSelectedPartner(undefined);
    }

    function handleApply() {
        const filters: KVPair[] = [];
        if (!!supplierSelected?.id) {
            filters.push({key: "supplier", value: supplierSelected.id.toString()});
        }
        if (!!selectedRoleIdx && selectedRoleIdx > 0) {
            filters.push({key: "role", value: roleList[selectedRoleIdx]});
        }
        if (!!selectedPartner && selectedPartner > 0) {
            filters.push({key: "partner", value: selectedPartner.toString()});
        }
        if (!!schoolSelected?.id) {
            filters.push({key: "school", value: schoolSelected.id.toString()});
        }
        props.onSubmit(filters);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-5 space-x-5 items-baseline text-start">
                            <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'}`}>
                                <SMETSTypeahead<Supplier>
                                    noOptionsMessage={t('no_option_value') as string}
                                    value={supplierSelected}
                                    onClick={handleApply}
                                    onSelectOption={(o) => setSupplierSelected({
                                        ...supplierSelected,
                                        id: Number(o.id),
                                        name: o.name
                                    } as Supplier)}
                                    label={t('suppliers')}
                                    search={(input) => getSuppliers(1, 5, [{
                                        key: "name_ic",
                                        value: input
                                    }]).then(res => res.results)}/>
                            </div>
                            <div className="">
                                <SMETSTypeahead<School>
                                    noOptionsMessage={t('no_option_value') as string}
                                    value={schoolSelected}
                                    onClick={handleApply}
                                    onSelectOption={(o) => setSchoolSelected({
                                        ...schoolSelected,
                                        id: Number(o.id),
                                        name: o.name
                                    } as School)}
                                    label={t('schools')}
                                    search={(input) => getSchools(1, 5, [{
                                        key: "name_ic",
                                        value: input
                                    }]).then(res => res.results)}/>
                            </div>
                            <div className="">
                                <Select
                                    labelText={t('role')}
                                    value={selectedRoleIdx}
                                    onChange={(e: any) => setSelectedRoleIdx(e.target?.value)}>
                                    {roleList.map((role, idx) => (
                                        <SelectItem key={idx} id={role} value={idx} text={t(role)}/>
                                    ))}
                                </Select>
                            </div>
                            <div className="">
                                <Select
                                    labelText={t('partner')}
                                    value={selectedPartner}
                                    onChange={(e: any) => setSelectedPartner(e.target?.value)}>
                                    {[{id: 0, name: t("choose_one")}, ...(partnerList || [])].map((partner, idx) => (
                                        <SelectItem key={idx} id={partner.id.toString()} value={partner.id}
                                                    text={partner.name}/>
                                    ))}
                                </Select>
                            </div>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                    onClick={handleApply}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
