import {useQuery} from "react-query";
import {ListPage, KVPair, ServerError, SchoolMealItem} from "../models/types";
import {getSchoolMealItem, getSchoolMealItems} from "../restapi/school-meal-item";

export function useRetrieveSchoolMealItem({id, onError}: {id: number, onError?: (e: ServerError) => void}): SchoolMealItem | undefined {
    const itemQuery = useQuery<SchoolMealItem, ServerError>(['schoolMeal', id], () => getSchoolMealItem(id), {
        onError: onError,
        retry: false
    });
    return itemQuery.data;
}

export function useRetrieveSchoolMealItems({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<SchoolMealItem> | undefined {
    const itemsQuery = useQuery(['schoolMeals', 'page'], () => getSchoolMealItems(page, pageSize, filters, sorting), {
        onError: onError,
        retry: false
    });
    return itemsQuery.data;
}
