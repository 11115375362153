import {SnackCategory} from "../../models/types";
import {useTranslation} from "react-i18next";

interface Props {
    item: SnackCategory;
    onClick?: () => void
}

export const SnackCategoryCard = (props: Props) => {
    const {t} = useTranslation();

    function handleClick(): void {
        if (!!props.onClick ) {
            props.onClick();
        }
    }

    return (
        <div className="flex flex-col items-start" onClick={handleClick}>
            <div>
                {t('id')}: {props.item.id}
            </div>
            <h2 className="text-gray-800 text-3xl font-semibold my-3">
                {t('name')} {props.item.name}
            </h2>
        </div>
    );
};
