import {useTranslation} from "react-i18next";
import {
    Link,
    SubNavigation,
    SubNavigationContent, SubNavigationGroup,
    SubNavigationHeader, SubNavigationItem, SubNavigationList,
    SubNavigationTitle
} from '@wfp/ui';

export default function LanguageMenu(props: {changeLanguage: (e: string) => void}) {
    const {i18n, t} = useTranslation();
    return (
        <SubNavigation className='text-center'>
            <SubNavigationHeader>
                <SubNavigationTitle>{t('choose_language')}</SubNavigationTitle>
            </SubNavigationHeader>
            <SubNavigationContent>
                <SubNavigationList>
                    <SubNavigationGroup>
                        <SubNavigationItem>
                            <Link className={i18n.language === 'en' ? 'active' : ''}
                                  onClick={() => props.changeLanguage("en")}> English <i>(إنجليزي)</i></Link>
                        </SubNavigationItem>
                        <SubNavigationItem>
                            <Link className={i18n.language === 'ar' ? 'active' : ''}
                                  onClick={() => props.changeLanguage("ar")}> Arabic <i>(عربي)</i></Link>
                        </SubNavigationItem>
                    </SubNavigationGroup>
                </SubNavigationList>
            </SubNavigationContent>
        </SubNavigation>
    )
}
