import {useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Blockquote, Button, Modal, Text} from "@wfp/ui";
import {iconAddGlyph} from "@wfp/icons";
import {LoadingCenter} from "../commons/loading-center";
import {PartnerWeek, Role, SchoolSupplierDay, ServerError, SnackSchoolDay} from "../../models/types";
import {SnackSchoolDayError, validateSnackSchoolDay} from "../../models/validate-snack-school-day";
import {getPartnerWeek, getPartnerWeekByDate, planPartnerWeek, saveSnackSchoolDay} from "../../restapi/planning";
import {useGetPartnerWeekByDate} from "../../hooks/partner-week";
import {useLoggedUser} from "../../hooks/user";
import {dateFormat, dateParse} from "../../hooks/formats";
import {NavigationWeek} from "./navigation";
import {SupplierWeekTable} from "./supplier-week-table";
import {SnacksForm} from "./snacks-form";

export const PlanningList = () => {
    const {t} = useTranslation();
    const [error, setError] = useState<ServerError>();
    const [partnerWeek, setPartnerWeek] = useState<PartnerWeek>();
    const [planDate, setPlanDate] = useState<string>();
    const [openSnacks, setOpenSnacks] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();
    const [dayDate, setDayDate] = useState<string>();
    const [disableEditSnacksButton, setDisableEditSnacksButton] = useState<boolean>(false);
    const [disableNavigation, setDisableNavigation] = useState<boolean>(false);
    const [snackError, setSnackError] = useState<SnackSchoolDayError>();
    const [schoolSupplierDaySelected, setSchoolSupplierDaySelected] = useState<SchoolSupplierDay>();

    const user = useLoggedUser({});
    const weekDate = dateFormat(moment().add(7, "days")) || "";
    useGetPartnerWeekByDate({
        date: weekDate,
        onSuccess: (res) => {
            setPlanDate(res.date || weekDate);
            setPartnerWeek(res);
            setLoading(false);
        },
        onError: (e) => setError(e)
    });

    function doReloadWeek(): void {
        if (!!partnerWeek) {
            setLoading(true);
            getPartnerWeek(partnerWeek.id)
                .then(res => {
                    setPartnerWeek(res);
                    setError(undefined);
                })
                .catch(e => setError(e))
                .finally(() => setLoading(false));
        }
    }

    function dayDateOfWeek(idx: number) {
        if (!!partnerWeek) {
            return moment(partnerWeek.date)?.add(idx, "days")?.format("dddd DD") || "";
        }
    }

    function handleNext() {
        setLoading(true);
        const nextDate = dateFormat(moment(planDate, "YYYY-MM-DD").add(1, "week")) || "";
        getPartnerWeekByDate(nextDate)
            .then((res) => {
                setPartnerWeek(res);
                setPlanDate(nextDate);
                setError(undefined);
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }

    function handlePrev() {
        setLoading(true);
        const prevDate = dateFormat(moment(planDate, "YYYY-MM-DD").subtract(1, "week")) || "";
        getPartnerWeekByDate(prevDate)
            .then((res) => {
                setPartnerWeek(res);
                setPlanDate(prevDate);
                setError(undefined);
            })
            .catch(e => setError(e))
            .finally(() => setLoading(false));
    }

    function handlePlanWeek() {
        if (!!planDate) {
            setLoading(true);
            setDisableNavigation(true)
            planPartnerWeek(planDate)
                .then((res) => {
                    setPartnerWeek(res);
                    setError(undefined);
                })
                .catch(e => setError(e))
                .finally(() => {
                    setDisableNavigation(false);
                    setLoading(false);
                });
        }
    }

    function handleClickSsd(ssd: SchoolSupplierDay, dayOfWeek: number) {
        const dayDate = dayDateOfWeek(dayOfWeek);
        setSchoolSupplierDaySelected(ssd);
        setDayDate(dayDate);
        setOpenSnacks(true);
    }

    function handleEditSnacksSubmit() {
        if (!!schoolSupplierDaySelected?.snacks?.length) {
            setDisableEditSnacksButton(true);
            validateSnackSchoolDay(schoolSupplierDaySelected.snacks)
                .then((res) => {
                    const promises = res.map((ssd) => saveSnackSchoolDay(ssd).catch(e => setError(e)));
                    Promise.all(promises).then(() => {
                        setSchoolSupplierDaySelected(undefined);
                        setOpenSnacks(false);
                        setSnackError(undefined);
                        doReloadWeek();
                    })
                })
                .catch(e => setSnackError(e))
                .finally(() => setDisableEditSnacksButton(false));
        }
    }

    function handleEditSnacksClose() {
        setSchoolSupplierDaySelected(undefined);
        setOpenSnacks(false);
        setSnackError(undefined);
    }

    function handleChange(ssd: SnackSchoolDay[]) {
        setSchoolSupplierDaySelected({...schoolSupplierDaySelected, snacks: ssd} as SchoolSupplierDay);
    }

    return (
        <div>
            {!!error && (
                <Blockquote kind="error" withIcon>{error.detail?.toString()} {error.errors?.toString()}</Blockquote>
            )}
            {loading && (
                <LoadingCenter/>
            )}
            {!!partnerWeek && (
                <>
                    <div className="flex justify-center">
                        <Text kind="h1">{t("planning_week")}</Text>
                        <NavigationWeek disabled={disableNavigation} onClickNext={handleNext} onClickPrev={handlePrev}
                                        week={planDate}/>
                    </div>
                    {!partnerWeek?.suppliers?.length && (
                        <>
                            <Blockquote kind="warning" withIcon>{t("empty_week")}</Blockquote>
                            {moment(planDate).isValid() ?
                                <div className="align-middle text-center">
                                    <Text className="my-5 text-2xl"
                                          kind="p">{t("do_you_want_to_plan_the_week_starting_from")} {dateParse(planDate || "")} ?</Text>
                                    <Button disabled={loading || user?.role !== Role.PartnerManager}
                                            className="!inline-flex"
                                            icon={iconAddGlyph} iconReverse large onClick={handlePlanWeek}>
                                        {t("plan")}
                                    </Button>
                                </div>
                                :
                                <Blockquote kind="error" withIcon>{t("invalid_date")}</Blockquote>
                            }
                        </>
                    )}
                    {(partnerWeek.suppliers || []).map((supplier) => (
                        <SupplierWeekTable key={supplier.id} week={partnerWeek} supplier={supplier} onClick={handleClickSsd}/>
                    ))}
                </>
            )}
            {!!openSnacks && !!schoolSupplierDaySelected && (
                <Modal className="p-3"
                       open={true}
                       modalHeading={schoolSupplierDaySelected.schoolName}
                       modalLabel={dayDate}
                       primaryButtonText={t("save") as string}
                       onRequestSubmit={handleEditSnacksSubmit}
                       secondaryButtonText={t("cancel") as string}
                       onSecondarySubmit={handleEditSnacksClose}
                       primaryButtonDisabled={disableEditSnacksButton}
                       onRequestClose={handleEditSnacksClose}
                >
                    <Text className="!mt-0 mb-10" kind="h3">{t("select_snacks_and_quantity")}</Text>
                    <SnacksForm schoolSupplierDay={schoolSupplierDaySelected} onChange={handleChange}
                                error={snackError}/>
                </Modal>
            )}
        </div>
    );
}
