import {restapi} from "./restapi";
import {UserDetails} from "../models/types";


export function getToken() {
    return localStorage.getItem('Token');
}


export function clearToken() {
    localStorage.setItem('Token', '');
}


export function getUserDetails(): Promise<UserDetails> {
    return restapi.get<UserDetails>('/api-auth/current/').then(r => r.data);
}
