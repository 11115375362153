import { useEffect, useState } from "react";
import { Blockquote, Table } from "@wfp/ui";
import { useTranslation } from "react-i18next";
import { CrateReclaimSupplierReport, KVPair, ListPage, Role, Sortable } from "../../../models/types";
import { TablePagination } from "../../commons/table-pagination/table-pagination";
import { useLoggedUser } from "../../../hooks/user";

interface Props {
    hidePagination?: boolean;
    page?: ListPage<CrateReclaimSupplierReport>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    sort?: (field: string) => void;
    sortInfo?: Sortable[];
}

const headersDefault = ["school", "supplier", "claims", "quantities"];

const sort: Sortable[] = [
    { code: 'school', field: 'school', enabled: true, mode: 0 },
    { code: 'supplier', field: 'supplier', enabled: true, mode: 0 },
    { code: 'claims', field: 'claims', enabled: true, mode: 0 },
    { code: 'quantities', field: 'quantities', enabled: true, mode: 0 },
];

export function CrateReclaimTable(props: Props) {
    const { t } = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>(sort);
    const user = useLoggedUser({});
    const headers = !!user && user.role === Role.SupplierManager ?
    headersDefault.filter((header) => header !== "supplier") : headersDefault

    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : { code: header, field: header, mode: 0, enabled: false }
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);


    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort && !!props.page?.count && props.page?.count > 0) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <>
            {!!props.page && (
                <>
                    <Table>
                        <thead>
                            <tr className="item-row-head">
                                {headers.map((header, idx) => (
                                    <th key={idx} onClick={() => handleSorting(idx)}
                                        className={props.sort && sortables?.at(idx)?.enabled && !!props.page?.count && props.page?.count > 0 ? "!text-center cursor-pointer" : "!text-center"}>
                                        {t(header)}
                                        {!!sortables?.length && !!props.page?.count && props.page?.count > 0 && (
                                            <span className="ml-0.5">
                                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                                            </span>
                                        )}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        {!!props.page && (
                            <tbody>
                                {props.page.results?.map((item: CrateReclaimSupplierReport, idx) => (
                                    <tr className="item-row" key={idx}>
                                        <td className="item-col text-start">
                                            {item.school}
                                        </td>
                                        {!!user && user.role !== Role.SupplierManager && (
                                        <td className="item-col text-end">
                                            {item.supplier}
                                        </td>
                                        )}
                                        <td className="item-col text-end">
                                            {item.claims}
                                        </td>
                                        <td className="item-col text-end">
                                            {item.quantities}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </Table>
                    {!props.hidePagination && (
                        <TablePagination page={props.page} loadPage={props.loadPage} />
                    )}
                </>
            )}
            {!props.page && (
                <Blockquote>
                    <p>{t('select_date_and_click_apply')}</p>
                </Blockquote>
            )}
        </>
    )
}
