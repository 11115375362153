import {useTranslation} from "react-i18next";
import {Blockquote, Form} from "@wfp/ui";
import {SnackCategory} from "../../models/types";
import {SnackCategoryError} from "../../models/validate-snack-category";
import {SMETSTextInput} from "../commons/text-input";


interface Props {
    snackCategory: SnackCategory;
    error?: SnackCategoryError;
    onChange: (s: SnackCategory) => void;
}

export const SnackCategoryForm = (props: Props) => {
    const {t} = useTranslation();

    return (
        <Form>
            <div className={`w-3/4 ${!!props.error?.nameAr ? 'mb-10' : 'mb-3'}`}>
                <SMETSTextInput
                    type='text'
                    required
                    id="text"
                    invalid={!!props.error?.nameAr}
                    invalidText={t(props.error?.nameAr?.toString() || '')}
                    value={props.snackCategory.nameAr || ''}
                    labelText={t('nameAr')!}
                    onChange={(e: any) => props.onChange({
                        ...props.snackCategory,
                        nameAr: e.target.value,
                    } as SnackCategory)}
                />
            </div>
            <div className={`w-3/4 ${!!props.error?.nameEn ? 'mb-10' : 'mb-3'}`}>
                <SMETSTextInput
                    type='text'
                    required
                    id="text"
                    invalid={!!props.error?.nameEn}
                    invalidText={t(props.error?.nameEn?.toString() || '')}
                    value={props.snackCategory.nameEn || ''}
                    labelText={t('nameEn')!}
                    onChange={(e: any) => props.onChange({
                        ...props.snackCategory,
                        nameEn: e.target.value,
                    } as SnackCategory)}
                />
            </div>
            {!!props.error?.detail && (
                <Blockquote kind="error" withIcon>{props.error.detail}</Blockquote>
            )}
        </Form>
    )
}
