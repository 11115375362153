import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SchoolMeal} from "../../models/types";
import {dateParse} from "../../hooks/formats";

interface Props {
    item: SchoolMeal;
    onClick?: () => void
}

export const SchoolMealCard = (props: Props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    function handleClick(): void {
        if (!!props.onClick) {
            props.onClick();
        } else {
            navigate(`/school-meals/${props.item.id}`);
        }
    }

    return (
        <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
            <div className="flex items-start flex-col" onClick={handleClick}>
                <div>
                    {t('id')}: {props.item.id}
                </div>
                <h2 className="text-gray-800 text-3xl font-semibold my-3">
                    {t('school')} {props.item.schoolName}
                </h2>
                <div>
                    {t('meal')} {props.item.mealName}
                </div>
                <div className="my-1">
                    {t('delivery_date')} {dateParse(props.item.deliveryDate)}
                </div>
                <div className="flex p-2 items-center">
                    <div>{t("state")}</div>
                    <div
                        className={`bg-order-state-${props.item.state} rounded-3xl ml-5 mr-auto p-2`}>
                        {props.item.state}
                    </div>
                </div>
            </div>
        </div>
    );
};
