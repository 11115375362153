import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Table, Text} from "@wfp/ui";
import {KVPair, ListPage, OrderItem, Sortable} from "../../models/types";
import {TablePagination} from "../commons/table-pagination/table-pagination";

interface Props {
    hidePagination?: boolean;
    page: ListPage<OrderItem>;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    onSelectOrderItems?: (orderItemsId: number[]) => void;
    sortInfo?: Sortable[];
    sort?: (field: string) => void;
}

const headers = ["commodities", "quantity", "state"];

const sort: Sortable[] = [
    {field: 'commodities', code: 'commodities', enabled: true, mode: 0},
    {field: 'quantity', code: 'quantity', enabled: true, mode: 0},
    {field: 'state', code: 'state', enabled: true, mode: 0},
];

export function OrderItemCommoditiesTable(props: Props) {
    const {t} = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>(sort);

    useEffect(() => {
        if (!!props.page.results && !!props.onSelectOrderItems) {
            const id = props.page.results.map((order) => order && order.id);
            props.onSelectOrderItems(id);
        }
    }, [props.page]);


    useEffect(() => {
        if (!!props.sortInfo) {
            const sorts = headers.map((header) => {
                const sort = props.sortInfo?.find((sort) => sort.code === header);
                return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
            });
            setSortables(sorts);
        }
    }, [props.sortInfo]);

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <>
            <div className='my-3'>
                <Text className='!mt-0' kind='p'>{t('school')}: <strong>{props.page.results.at(0)?.schoolName}</strong></Text>
                <Text className='!mt-0'
                      kind='p'>{t('school_ref')}: <strong>{props.page.results.at(0)?.schoolRef}</strong></Text>
            </div>
            <Table>
                <thead>
                <tr className="item-row-head">
                    {headers.map((header, idx) => (
                        <th key={idx} onClick={() => handleSorting(idx)}
                            className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                            {t(header)}
                            {!!sortables?.at(idx) && (
                                <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {!!props.page && (props.page.results.at(0)?.crates || []).map((item, idx) => (
                    <tr className="item-row" key={item.id}>
                        <td className="item-col text-start !align-middle">
                            {item.contentName}
                        </td>
                        <td className="item-col text-start !align-middle">
                            {item.quantity}
                        </td>
                        <td className="item-col text-start !align-middle">
                            <div className={`bg-order-state-${props.page.results.at(0)?.state} rounded-3xl`}>
                                <p className={`ml-2 text-order-state-${props.page.results.at(0)?.state}`}>{props.page.results.at(0)?.state}</p>
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage}/>
            )}
        </>
    )
}
