import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { NavLink, useParams } from 'react-router-dom';
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from "@wfp/ui";
import {iconEditGlyph} from "@wfp/icons";
import {SupplierError, validateSupplier} from "../../models/validate-supplier";
import {Supplier} from "../../models/types";
import {saveSupplier} from "../../restapi/supplier";
import {useRetrieveSupplier} from "../../hooks/supplier";
import {LoadingCenter} from "../commons/loading-center";
import {SupplierCard} from "./card";
import {SupplierForm} from "./form";

export const SupplierDetail = () => {
    const {i18n, t} = useTranslation();
    const {id} = useParams();
    const [error, setError] = useState<SupplierError>();
    const [supplier, setSupplier] = useState<Supplier>();
    const [supplierEdit, setSupplierEdit] = useState<Supplier>();

    const data = useRetrieveSupplier({id: Number(id), onError: (e) => setError(e as SupplierError)});

    useEffect(() => {
        if(data) {
            setSupplier(data);
        }
    }, [data]);

    function handleEditSubmit() {
        if (!!supplierEdit) {
            setError(undefined);
            validateSupplier(supplierEdit)
                .then((data) => saveSupplier(data)
                    .then((res) => {
                        setSupplier(res);
                        setError(undefined);
                        setSupplierEdit(undefined);
                    })
                    .catch(error => setError(error)))
                .catch(error => setError(error));
        }
    }

    function handleEditClose() {
        setSupplierEdit(undefined);
        setError(undefined);
    }

    return (
        <div className="m-10">
            <Breadcrumb className='mb-5'>
                <BreadcrumbItem>
                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <NavLink to="/suppliers">{t('supplier')}</NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem disableLink>
                    {t('view_supplier')}
                </BreadcrumbItem>
            </Breadcrumb>
            <div className="flex mt-10 mb-5 mx-0">
                <div>
                    <Text kind='h1'>{t("supplier_details")}</Text>
                </div>
                <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                    <div className="grid-flow-row flex auto-rows-max mb-10 text-end">
                        <div className="mx-3">
                            <Button onClickCapture={() => setSupplierEdit(supplier)} className='!inline-flex' icon={iconEditGlyph} iconReverse>
                                {t('edit_supplier')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        {!!supplier && (
            <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                <SupplierCard item={supplier} />
            </div>
        )}
        {!!supplierEdit && (
            <Modal
                open={true}
                modalHeading={t('edit_supplier') || ''}
                modalLabel={t('edit_supplier') || ''}
                primaryButtonText={t('save') || ''}
                onRequestSubmit={handleEditSubmit}
                onRequestClose={handleEditClose}
                secondaryButtonText={t('cancel') || ''}
                onSecondarySubmit={handleEditClose}
            >
                <SupplierForm supplier={supplierEdit} error={error} onChange={setSupplierEdit}/>
            </Modal>
        )}
        {!(error || supplier) && (
            <LoadingCenter/>
        )}
        </div>
    )
}
