import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Blockquote } from "@wfp/ui";
import { CrateReclaim, KVPair, ListPage, ServerError, Sortable } from "../../models/types";
import { updateSorting } from "../../restapi/restapi";
import { useInViewport } from "../../hooks/useInViewport";
import { CrateReclaimFilter } from "./filters";
import { CrateReclaimTable } from "./table";
import { getReclaimCrates } from "../../restapi/crate";
import { CrateReclaimCard } from "./card";

const SortInfo: Sortable[] = [
    { code: 'school', field: 'school', enabled: true, mode: 0 },
    { code: 'supplier', field: 'supplier', enabled: true, mode: 0 },
    { code: 'date', field: 'date', enabled: true, mode: 0 },
    { code: 'quantities', field: 'quantities', enabled: true, mode: 0 },
];

interface Props {
    page?: ListPage<CrateReclaim>,
    mobile?: boolean,
    filters?: KVPair[],
    disableFilter?: boolean,
    sorting?: string[];
    onClick?: (id: number) => void
    onSelectOrder?: (id: number) => void
}

export const CrateReclaimList = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<ListPage<CrateReclaim>>();
    const [error, setError] = useState<ServerError>();
    const { isInViewport, ref } = useInViewport();
    const { t } = useTranslation();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        } else {
            loadPage(1, undefined, props.filters, props.sorting);
        }
    }, [props.page]);

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getReclaimCrates(pageNumber, pageSize, options, sorting)
            .then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleSorting(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleFiltering(filters: KVPair[]): void {
        const changed = filters.length !== page?.filtering?.length || filters.filter(kvp => !page?.filtering?.find(f => f.key === kvp.key && f.value === kvp.value)).length > 0
        if (changed) {
            loadPage(page?.pageNumber || 1, page?.pageSize, filters, page?.sorting);
        }
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page.next;
            if (result) {
                loadPage(page.pageNumber + 1, page.pageSize, page?.filtering, page?.sorting);
            }
            return result;
        }
        return false;
    }

    return (
        <div>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error.detail.toString()}
                </Blockquote>
            )}
            {!!page && (
                <div className="mx-0">
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>
                            {t("empty_reclaim_list")}
                        </Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((crate: CrateReclaim) => (
                        <CrateReclaimCard key={crate.id} item={crate}/>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                    )}
                    {!props.mobile && !!page && (
                        <>
                            {!props.disableFilter && (
                                <CrateReclaimFilter onSubmit={handleFiltering} />
                            )}
                            <CrateReclaimTable page={page} loadPage={loadPage}
                                onSelectOrder={props.onSelectOrder}
                                hidePagination={page.count <= page.results.length}
                                sort={handleSorting}
                                sortInfo={SortInfo}
                            />
                        </>

                    )}
                </div>
            )}
        </div>
    );
}
