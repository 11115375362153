import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote} from "@wfp/ui";
import {KVPair, ListPage, ServerError, Sortable, Supplier} from "../../models/types";
import {getSuppliers} from "../../restapi/supplier";
import {updateSorting} from "../../restapi/restapi";
import {useInViewport} from "../../hooks/useInViewport";
import {SupplierCard} from "./card";
import {SupplierTable} from "./table";

const SortInfo: Sortable[] = [
    {code: 'name', field: 'name', enabled: true, mode: 0},
];

interface Props {
    page?: ListPage<Supplier>;
    mobile?: boolean;
    onClick?: (id: number) => void;
    onSelect?: (id: number) => void;
    selectCheck?: number;
    loadPage?: (pageNumber: number, pageSize?: number, options?: KVPair[], sorting?: string[]) => Promise<ListPage<Supplier>>;
}

export const SupplierList = (props: Props) => {
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState<ListPage<Supplier>>()
    const [error, setError] = useState<ServerError>();
    const {isInViewport, ref} = useInViewport();
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        }
    }, [props.page]);

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        doLoadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function handleClickSupplier(id: number): void {
        if (!!props.onClick) {
            props.onClick(id);
        } else {
            navigate(`/suppliers/${id}`);
        }
    }

    function doLoadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        const promise = !!props.loadPage ?
            props.loadPage(pageNumber, pageSize, options, sorting) :
            getSuppliers(pageNumber, pageSize, options, sorting);
        promise.then((res) => {
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
                return Promise.resolve(res);
            })
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function handleLoadMore(): boolean {
        const result = !loading && !!page?.next;
        if (result) {
            doLoadPage(page?.pageNumber + 1, page?.pageSize);
        }
        return result;
    }

    return (
        <div>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error.detail.toString()}
                </Blockquote>
            )}
            {!!page && (
                <div>
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>
                            {t("empty_suppliers_list")}
                        </Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((supplier: Supplier) => (
                        <SupplierCard key={supplier.id} item={supplier}/>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>{isInViewport && handleLoadMore() && (<div>Loading.....</div>)}</div>
                    )}
                    {!props.mobile && page.count > 0 && (
                        <SupplierTable
                            page={page}
                            loadPage={doLoadPage}
                            hidePagination={page.count <= page.results.length}
                            onSelect={props.onSelect}
                            onClick={handleClickSupplier}
                            sort={handleSort}
                            sortInfo={SortInfo}
                            selectCheck={props?.selectCheck}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
