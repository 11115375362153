import {KVPair, ListPage, LogEntry, LogType} from "../models/types";
import {buildQueryString, restapi} from "./restapi";

export function getLogEntries(logType: LogType, page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<LogEntry>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<LogEntry>>(`/api/v1/smt_audit/${logType}/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}
