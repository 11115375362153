import {ReturnItem} from "../models/types";
import {restapi} from "./restapi";

export function getReturnItem(id: number): Promise<ReturnItem> {
    return restapi.get<ReturnItem>(`/api/v1/smt_core/return_item/${id}/`).then(res => res.data);
}

export function saveReturnItem(data: ReturnItem): Promise<ReturnItem> {
    if (!data.id) {
        return restapi.post<ReturnItem>('/api/v1/smt_core/return_item/', data).then((res) => res.data);
    } else {
        return restapi.patch<ReturnItem>(`/api/v1/smt_core/return_item/${data.id}/`, data).then((res) => res.data);
    }
}
