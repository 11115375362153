import {buildQueryString, restapi} from "./restapi";
import {ListPage, KVPair, School} from "../models/types";

export function deleteSchool(id: number): Promise<School> {
    return restapi.delete<School>(`/api/v1/smt_core/school/${id}/`).then(res => res.data);
}

export function getSchool(id: number): Promise<School> {
    return restapi.get<School>(`/api/v1/smt_core/school/${id}/`).then(res => res.data);
}

export function getSchools(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<School>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<School>>(`/api/v1/smt_core/school/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrieveCentralSchools(filters?: KVPair[], sorting?: string[]): Promise<School[]> {
    const options =  [{key: 'central', value: 'true'}];
    return retrieveSchools(!!filters ? filters.concat(options) : options, sorting);
}

export function retrieveSchools(filters?: KVPair[], sorting?: string[]): Promise<School[]> {
    const pageOptions = [{key: '__pagination__', value: '0'}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<School[]>(`/api/v1/smt_core/school/?${qs}`).then(res => res.data);
}

export function saveSchool(data: School): Promise<School> {
    if (!data.id) {
        return restapi.post<School>('/api/v1/smt_core/school/', data).then(res => res.data);
    } else {
        return restapi.patch<School>(`/api/v1/smt_core/school/${data.id}/`, data).then(res => res.data);
    }
}
