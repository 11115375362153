import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import moment from "moment";
import {Blockquote, Icon, Modal, Table, Text} from "@wfp/ui";
import {iconAddGlyph} from "@wfp/icons";
import {
    KVPair,
    ListPage,
    PartnerWeek,
    Role,
    SchoolSupplierDay,
    ServerError,
    Sortable,
    Supplier,
    SupplierDay,
    WeekSupplierSchoolPlan
} from '../../models/types';
import {updateSorting} from "../../restapi/restapi";
import {getWeekSupplierDays, getWeekSupplierSchoolPlans, wizardSupplierDay} from "../../restapi/planning";
import {useLoggedUser} from "../../hooks/user";
import {LoadingCenter} from "../commons/loading-center";
import {TablePagination} from "../commons/table-pagination/table-pagination";
import {PlanningFilters} from "./filters";
import "./planning.css";

interface SupplierDayWizardError extends ServerError {
    snacks?: string[];
    schools?: string[];
    order?: string[];
}

interface Props {
    week: PartnerWeek;
    supplier: Supplier;
    onClick: (ssd: SchoolSupplierDay, dayOfWeek: number) => void;
    sort?: (field: string) => void;
}

const SortInfo: Sortable[] = [
    {code: 'school', field: 'school', enabled: true, mode: 0},
    {code: 'district', field: 'district', enabled: true, mode: 0},
];

const Headers = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export function SupplierWeekTable(props: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState<ListPage<WeekSupplierSchoolPlan>>();
    const [supplierDays, setSupplierDays] = useState<SupplierDay[]>();
    const [error, setError] = useState<SupplierDayWizardError>();
    const [loading, setLoading] = useState<boolean>();
    const [sortables, setSortables] = useState<Sortable[]>(SortInfo);
    const user = useLoggedUser({});

    useEffect(() => {
        if (!!props.week && !!props.supplier) {
            doLoadPage(page?.pageNumber || 1, page?.pageSize, page?.filtering, page?.sorting);
            getWeekSupplierDays(props.week.id, props.supplier.id)
                .then(res => setSupplierDays(res))
                .catch(e => setError(e));
        }
    }, [props.week, props.supplier])

    function handleFiltering(filters: KVPair[]): void {
        const changed = filters.length !== page?.filtering?.length || filters.filter(kvp => !page?.filtering?.find(f => f.key === kvp.key && f.value === kvp.value)).length > 0
        if (changed) {
            doLoadPage(page?.pageNumber || 1, page?.pageSize, filters, page?.sorting);
        }
    }

    function handleSorting(idx: number) {
        if (!!sortables) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const sort = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            const value = updateSorting(sort, page?.sorting || []);
            doLoadPage(page?.pageNumber || 1, page?.pageSize, page?.filtering, value);
        }
    }

    function doLoadPage(pageNumber: number, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setError(undefined);
        setLoading(true);
        getWeekSupplierSchoolPlans(props.week.id, props.supplier.id, pageNumber, pageSize, options, sorting)
            .then(res => setPage(res))
            .catch(error => setError(error))
            .finally(() => setLoading(false));
    }

    function formatDayOfWeek(idx: number) {
        return moment(props.week.date)?.add(idx, "days")?.format("DD") || "";
    }

    function handleCreateOrderWizard(id: number | undefined) {
        if (!!id) {
            setLoading(true);
            wizardSupplierDay(id).then((wizard) => {
                if (wizard) {
                    navigate(`/orders/wizard/${wizard}`);
                }
            }).catch(e => {
                console.log('wizardSupplierDay', e);
                setError(e)
            })
            .finally(() => setLoading(false));
        }
    }

    return (
        <div className="border-1px-solid mt-5 ">
            <PlanningFilters onSubmit={handleFiltering} title={<Text className="!mb-5 text-center wfp--type-beta  wfp--text__h2 wfp--text h2" kind="p">{props.supplier.name}</Text>}/>
            <Table className="table-fixed">
                <thead>
                <tr className="item-row-head">
                    <th onClick={() => handleSorting(0)}
                        className={sortables?.at(0)?.enabled ? "cursor-pointer align-middle" : "align-middle"}>
                        <div className="text-base text-center font-semibold align-middle">
                            {t("school")}
                            {!!sortables?.length && (
                                <span className="ml-0.5">
                                {sortables[0].mode === 2 ? '\u25bc' : sortables[0].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </div>
                    </th>
                    <th onClick={() => handleSorting(1)}
                        className={sortables?.at(1)?.enabled ? "cursor-pointer align-middle" : "align-middle"}>
                        <div className="text-base text-center font-semibold align-middle">
                            {t("district")}
                            {!!sortables?.length && (
                                <span className="ml-0.5">
                                {sortables[1].mode === 2 ? '\u25bc' : sortables[1].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </div>
                    </th>
                    {Headers.slice(0, 6).map((header, idx) => (
                        <th className="align-middle justify-center" key={idx}>
                            <div className="grid grid-rows-2 items-center gap-2">
                                <div
                                    className="!inline-flex justify-center !text-center !text-base">{t(header)} {formatDayOfWeek(idx)}
                                    <span onClick={() => handleCreateOrderWizard(supplierDays?.at(idx)?.id)}
                                          title={t('create_order')!}
                                          className='align-middle m-1 cursor-pointer tooltip transition ease-in-out hover:-translate-y-1 duration-300'>
                                <Icon description={'create'} fill={'#0b77c2'} icon={iconAddGlyph}/>
                                <span className='tooltiptext bg-sky-100 text-color-blue'>{t('create_order')!}</span>
                            </span>
                                </div>
                                <div className="inline-grid grid-cols-4">
                                    <div className="text-center">{t("am")}</div>
                                    <div className="text-center">{t("pm")}</div>
                                    <div className="col-span-2 text-center">{t("details")}</div>
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {loading ?
                    <tr className='text-center'>
                        <td colSpan={7}>
                            <LoadingCenter/>
                        </td>
                    </tr>
                    : <>
                        {page?.count === 0 && (
                            <tr>
                                <td colSpan={7}>
                                    <Blockquote kind='warning'>{t('empty_week')}</Blockquote>
                                </td>
                            </tr>
                        )}
                        {page?.results.map((schoolPlan) => (
                            <tr className={user?.role !== Role.SupplierManager ? "cursor-pointer" : ""} key={schoolPlan.id}>
                                <td  className="!text-start !align-middle ">{schoolPlan.name}</td>
                                <td className="!text-start !align-middle">{schoolPlan.districtName}</td>
                                {schoolPlan.days.slice(0, 6).map((ssd: SchoolSupplierDay, idx) => (
                                    <td key={ssd.id} className="align-middle justify-center ">
                                        <div onClick={() => {user?.role !== Role.SupplierManager && props.onClick(ssd, idx);}}>
                                            {!ssd.snacks?.length && (
                                                <div className="grid m-5">
                                                    <div className={user?.role !== Role.SupplierManager ? "transition ease-in-out hover:-translate-y-1 duration-300 text-center add-snack" : "hidden"}>
                                                        {t("add_snack")}
                                                    </div>
                                                </div>
                                            )}
                                            {ssd.snacks?.map((snackSchoolDay) => (
                                                <div className={"grid grid-cols-4 gap-1 my-3 mx-0 text-xs"} key={snackSchoolDay.id}>
                                                    <div className="text-right-1">{snackSchoolDay.quantityAm}</div>
                                                    <div className="text-right-1">{snackSchoolDay.quantityPm}</div>
                                                    <div className="col-span-2 text-center">{snackSchoolDay.snackName}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                        <tr className='row-totals'>
                            <td colSpan={2} className="!text-center !align-middle">{t("total_of_the_day")}</td>
                            {(supplierDays || []).slice(0, 6).map((day) => (
                                <td key={day.id}>
                                    {!!day.totals.length && (<div className="grid grid-cols-5">
                                        <div className="text-center">{t('am')}</div>
                                        <div className="text-center">{t('pm')}</div>
                                        <div className="text-center">{t('day')}</div>
                                        <div className="col-span-2 text-center">{t('details')}</div>
                                    </div>)}
                                    {day.totals.map((total, idx) => (
                                        <div className="grid grid-cols-5 gap-2 my-2 text-xs" key={idx}>
                                                <div className="text-right">{total.totalAm}</div>
                                                <div className="text-right">{total.totalPm}</div>
                                                <div
                                                    className="text-right">{!!total.snackName ? total.totalAm + total.totalPm : ''}</div>
                                                <div className="col-span-2 text-center">{total.snackName}</div>
                                            </div>
                                    ))}
                                </td>
                            ))}
                        </tr>
                    </>
                }
                </tbody>
            </Table>
            <TablePagination page={page} loadPage={doLoadPage}/>
            {!!error && (
                <Modal className="p-3" passiveModal open={true} onRequestClose={() => setError(undefined)}>
                    <Blockquote kind="error" withIcon>
                        <div>{error?.errors}</div>
                        <div>{error?.detail}</div>
                        <div>{!!error?.schools ? `${t('school')}: ${error.schools}` : ''}</div>
                        <div>{!!error?.snacks ? `${t('snacks')}: ${error.snacks}` : ''}</div>
                        <div>{!!error?.order ? `${t('order')}: ${error.order}` : ''}</div>
                    </Blockquote>
                </Modal>
            )}
        </div>
    )
}
