import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem, FormWizard, Modal, Select, SelectItem} from "@wfp/ui";
import i18n from "../../../i18n";
import {Role, School, SchoolMeal, ServerError, Wizard} from "../../../models/types";
import {getSchool, retrieveSchools} from "../../../restapi/school";
import {completeWizard, deleteWizard, saveMealsWizard} from "../../../restapi/wizard";
import {useRetrieveMeals} from "../../../hooks/meal";
import {useRetrieveCentralSchools} from "../../../hooks/school";
import {useRetrieveMealsWizard} from "../../../hooks/wizard";
import {useLoggedUser} from "../../../hooks/user";
import {LoadingCenter} from "../../commons/loading-center";
import {Headerbar} from "./headerbar";
import {Sidebar} from "./sidebar";
import {Navigationbar} from "./navigationbar"
import {PageSchools} from "./page-schools";
import {PageMeals} from "./page-meals";
import {PageSummary} from "./page-summary";

interface WizardMealState {
    disabledSchoolIds: number[],
    error: string | null,
    schoolMeal: SchoolMeal,
    page: number,
    schools: School[],
}

export interface WizardMealError extends ServerError {
    supplier: string[] | undefined,
    deliveryDate: string[] | undefined,
    meal: string[] | undefined,
}

interface WizardPage {
    header: string,
    label: string,
    title: string,
    nextLabel?: string,
    previousLabel?: string,
    component?: (p: any) => React.ReactNode
}

export const mealsWizardPages: WizardPage[] = [
    {
        header: 'select_schools_header_page',
        title: 'select_schools_students_title_page',
        label: 'select_schools_label_page',
    },
    {
        header: 'select_meal_header_page',
        title: 'select_meal_title_page',
        label: 'select_meal_label_page'
    },
    {
        header: 'summary',
        title: 'confirm_order_page_title',
        label: 'summary',
        nextLabel: 'confirm',
    }
];

export function MealsWizard() {
    const [message, setMessage] = useState<string>();
    const [page, setPage] = useState<number>();
    const [error, setError] = useState<WizardMealError>();
    const [loading, setLoading] = useState<boolean>(false);
    const [stateModified, setStateModified] = useState<boolean>(false);
    const [isOpenPartnerModal, setIsOpenPartnerModal] = useState<boolean>(false);
    const [centralSchool, setCentralSchool] = useState<School>();
    const [schoolList, setSchoolList] = useState<School[]>();
    const [wizard, setWizard] = useState<Wizard>();

    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const initialWizard = useRetrieveMealsWizard({id: Number(id), onError: (e) => setError(e as WizardMealError)});
    const user = useLoggedUser({});
    const mealsList = useRetrieveMeals({onError: (e) => setError(e as WizardMealError)});
    const centralSchoolList = useRetrieveCentralSchools({onError: e => setError(e as WizardMealError)});

    useEffect(() => {
        if (!!initialWizard) {
            if (!initialWizard.id) {
                const value = {data: {page: 0}, title: 'meals'} as Wizard;
                storeWizard(value).then((res) => doSetPage(res.data.page));
            } else {
                setWizard(initialWizard);
                doSetPage(initialWizard.data?.page || 0);
            }
        }
    }, [initialWizard]);

    useEffect(() => {
        if (!!wizard && !!user) {
            if (!!centralSchool) {
                if (centralSchool.id !== wizard?.data?.schoolMeal?.school) {
                    const schoolMeal = {
                        ...wizard?.data?.schoolMeal,
                        school: centralSchool.id,
                        schoolName: centralSchool.name
                    };
                    const data = {...wizard.data, schoolMeal: schoolMeal};
                    setWizard({...wizard, data: data});
                    setStateModified(true);
                }
            } else {
                if (user.role === Role.PartnerManager) {
                    setIsOpenPartnerModal(true);
                } else if (user.role === Role.SchoolManager) {
                    getSchool(user.entity as number)
                        .then((s) => {
                            setCentralSchool(s);
                            doRetrieveSubsidiarySchools(s).then(() => {});
                        })
                        .catch(e => setError(e));
                } else {
                    setError({detail: ['invalid user']} as WizardMealError);
                }
            }
        }
    }, [centralSchool, user, wizard]);

    useEffect(() => {
        if (!!schoolList && !!wizard && !wizard.data?.schools) {
            const data = {...wizard.data, schools: schoolList};
            setWizard({...wizard, data: data});
            setStateModified(true);
        }
    }, [schoolList, wizard]);

    useEffect(() => {
        if (centralSchoolList?.length === 1) {
            setCentralSchool(centralSchoolList[0]);
            doRetrieveSubsidiarySchools(centralSchoolList[0]).then(() => {});
        }
    }, [centralSchoolList]);

    const handleSelectCentralSchool = (e: any) => setCentralSchool(centralSchoolList?.find(s => s.id.toString() === e.target?.value))

    function handleRetrieveSubsidiarySchools() {
        if (!!centralSchool) {
            doRetrieveSubsidiarySchools(centralSchool)
                .then(() => setIsOpenPartnerModal(false))
                .catch(e => setError(e));
        }
    }

    function doRetrieveSubsidiarySchools(central: School): Promise<void> {
        return retrieveSchools([{key: 'parent', value: central.id.toString()}]).then(res => setSchoolList([central].concat(res)));
    }

    function storeWizardState(data: WizardMealState): Promise<WizardMealState> {
        const w = {...wizard, data: data} as Wizard;
        return storeWizard(w).then((res) => {
            setStateModified(false);
            return Promise.resolve(res.data as WizardMealState);
        });
    }

    function storeWizard(w: Wizard): Promise<Wizard> {
        if (!w) {
            return Promise.reject({errors: ["wizard is null"]} as ServerError);
        }
        return saveMealsWizard(w).then((res) => {
            setWizard(res);
            return Promise.resolve(res);
        });
    }

    function handleChangeSchools(schools: School[]): void {
        if (!!wizard) {
            wizard.data.schools = schools;
            if (!doCheckStateError({...wizard.data, page: 0})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeDisabledSchoolIds(disabledSchoolIds: number[]): void {
        if (!!wizard) {
            wizard.data.disabledSchoolIds = disabledSchoolIds;
            if (!doCheckStateError({...wizard.data, page: 0})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeSchoolMeal(schoolMeal: SchoolMeal): void {
        if (!!wizard && !!user && !!centralSchool) {
            wizard.data.schoolMeal = {...schoolMeal, school: centralSchool.id, schoolName: centralSchool.name} as SchoolMeal;
            if (!doCheckStateError({...wizard.data, page: 1})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleExitWizard() {
        setMessage('confirm_exit?');
    }

    function handlePreviousPage() {
        if (page !== undefined) {
            handleNavigateToPage(page - 1)
        }
    }

    function handleNextPage() {
        if (page !== undefined) {
            handleNavigateToPage(page + 1)
        }
    }

    function handleNavigateToPage(to: number): void {
        if (to === -1) {
            return handleExitWizard();
        }
        if (to < (page || 0)) {
            return doChangePage(to);
        }
        setLoading(true);
        doValidatePage(page !== undefined ? page : wizard?.data?.page || 0).then((allow) => {
            setLoading(false);
            if (allow) {
                if (to === mealsWizardPages.length) {
                    return handleConfirmWizard();
                } else {
                    doChangePage(to);
                }
            }
        }).catch(error => {
            setError(error);
            setLoading(false);
        });
    }

    function handleModalConfirmation(flag: boolean) {
        if (!flag) {
            return setMessage(undefined);
        }
        if (!!wizard) {
            reset();
            if (!!wizard.id) {
                return deleteWizard(wizard.id).then(() => navigate('/school-meals'));
            }
            return navigate('/school-meals');
        }
    }

    function reset(): void {
        setMessage(undefined);
        setPage(undefined);
        setError(undefined);
        setLoading(false);
        setStateModified(false);
        setIsOpenPartnerModal(false);
        setCentralSchool(undefined);
        setSchoolList(undefined);
        setWizard(undefined);
    }

    function handleConfirmWizard() {
        if (!!wizard) {
            completeWizard(wizard.id).then(
                (w) => navigate(`/school-meals/${w.data.schoolMeal.id}`)
            ).catch(
                (error) => setError(error)
            );
        }
    }

    function doChangePage(p: number) {
        setLoading(true);
        if (stateModified) {
            storeWizardState({
                ...wizard?.data,
                page: p
            }).then((w) => doSetPage(w.page)).finally(() => setLoading(false));
        } else {
            setLoading(false);
            doSetPage(p);
        }
    }

    function doSetPage(p: number) {
        setError(undefined);
        setPage(p);
    }

    function doCheckStateError(data: WizardMealState): WizardMealError | undefined {
        let isValid: boolean;
        const page = data.page;
        let error: WizardMealError;
        switch (page) {
            case 0:
                isValid = (data.schools || []).filter((s: School) => !(data.disabledSchoolIds || []).includes(s.id)).length !== 0;
                error = {
                    errors: isValid ? undefined : ["select_at_least_one_school"]
                } as WizardMealError;
                break
            case 1:
                error = {
                    meal: !data.schoolMeal || !data.schoolMeal.meal ? ["this_field_is_required"] : undefined,
                    deliveryDate: !data.schoolMeal || !data.schoolMeal.deliveryDate ? ["this_field_is_required"] : undefined,

                } as WizardMealError;
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break
            case 2:
                error = {
                    ...doCheckStateError({...data, page: 1}),
                    ...doCheckStateError({...data, page: 0})
                } as WizardMealError;
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break;
            default:
                isValid = false;
                error = {errors: ["invalid_page"]} as WizardMealError;
                break;
        }
        return isValid ? undefined : error;
    }

    function doValidatePage(page: number): Promise<boolean> {
        if (!!wizard) {
            const error = doCheckStateError({...wizard.data, page: page});
            setError(error);
            return Promise.resolve(!error);
        }
        return Promise.resolve(false);
    }

    return (
        <div className='m-10'>
            {isOpenPartnerModal && (
                <div>
                    <Modal
                        hideClose={true}
                        onRequestSubmit={() => handleRetrieveSubsidiarySchools()}
                        onRequestClose={() => handleModalConfirmation(true)}
                        open={true}
                        primaryButtonText={t("confirm") as string}
                        secondaryButtonText={t("cancel") as string}
                        type='info'
                    >
                        {!!centralSchoolList ?
                            <Select
                                labelText={t('central_schools')}
                                value={centralSchool?.id}
                                onChange={handleSelectCentralSchool}>
                                {[{
                                    id: 0,
                                    name: t("choose_one")
                                }, ...(centralSchoolList || [])].map((school, idx) => (
                                    <SelectItem key={idx} id={school.id.toString()} value={school.id} text={school.name}/>
                                ))}
                            </Select> : <LoadingCenter/>
                        }
                    </Modal>
                </div>
            )}
            {!wizard && (
                <LoadingCenter/>
            )}
            {!!wizard && (
                <>
                    <div className="hidden md:block mt-10 mb-10">
                        <Breadcrumb className="mb-5">
                            <BreadcrumbItem className={`${i18n.dir() === 'ltr' ? 'mr-0' : '!mr-10'}`}>
                                <NavLink to="/"><BreadcrumbHome/></NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <NavLink to="/school-meals">{t('school_meals')}</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem disableLink>{t('create_school_meal')}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <h1 className={`text-start mt-10 mb-10 ${i18n.dir() === 'ltr' ? 'mr-0' : 'mr-10'}`}>
                        {t(mealsWizardPages[page || 0].title)}
                    </h1>
                    {loading ? <LoadingCenter/> :
                        <FormWizard className="mr-3"
                                    stickySidebar={false}
                                    sidebar={page !== undefined ?
                                        <Sidebar onChange={handleNavigateToPage} selectedPage={page!}/> : null}
                                    formHeader={page !== undefined ?
                                        <Headerbar page={page!} message={!!message ? t(message)! : undefined}
                                                   errors={!!error?.errors ? [t(error?.errors.toString())!] : !!error?.detail ? [t(error?.detail.toString())!] : undefined}/> : null}//TODO fix i18n
                                    formControls={page !== undefined ? <Navigationbar
                                        previous={handlePreviousPage}
                                        previousDisabled={false}
                                        previousLabel={page === 0 ? t("exit")! : mealsWizardPages[page!].previousLabel}
                                        next={handleNextPage}
                                        nextDisabled={!!error}
                                        nextLabel={mealsWizardPages[page!].nextLabel}
                                    /> : null}
                        >
                            {page === 0 && !wizard.data.schools && (
                                <LoadingCenter/>
                            )}
                            {page === 0 && !!wizard.data.schools && (
                                <PageSchools
                                    schools={wizard.data.schools}
                                    disabledSchoolIds={wizard?.data.disabledSchoolIds || []}
                                    onChange={handleChangeSchools}
                                    onChangeDisabled={handleChangeDisabledSchoolIds}
                                />
                            )}
                            {page === 1 && !mealsList && (
                                <LoadingCenter/>
                            )}
                            {page === 1 && !!mealsList && (
                                <PageMeals
                                    error={error}
                                    onChange={handleChangeSchoolMeal}
                                    schoolMeal={wizard.data.schoolMeal || {}}
                                    meals={mealsList}
                                />
                            )}
                            {page === 2 && (
                                <PageSummary
                                    schools={(wizard.data?.schools || []).filter((s: School) => !(wizard.data?.disabledSchoolIds || []).includes(s.id))}
                                    schoolMeal={wizard.data?.schoolMeal}
                                />
                            )}
                        </FormWizard>
                    }
                    {!!message && (
                        <div className="z-10">
                            <Modal
                                hideClose={false}
                                onRequestSubmit={() => handleModalConfirmation(true)}
                                onRequestClose={() => handleModalConfirmation(false)}
                                onSecondarySubmit={() => handleModalConfirmation(false)}
                                open={!!message}
                                primaryButtonText={t("confirm") as string}
                                secondaryButtonText={t("cancel") as string}
                                type='info'
                            >{t(message)}</Modal>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
