import {Loss} from "../models/types";
import {restapi} from "./restapi";

export function deleteLoss(data: Loss): Promise<boolean> {
    return restapi.delete<void>(`/api/v1/smt_core/loss/${data.id}/`).then(res => res.status === 204);
}

export function saveLoss(data: Loss): Promise<Loss> {
    if (!data.id) {
        return restapi.post<Loss>('/api/v1/smt_core/loss/', data).then((res) => res.data);
    } else {
        return restapi.patch<Loss>(`/api/v1/smt_core/loss/${data.id}/`, data).then((res) => res.data);
    }
}
