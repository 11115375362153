import {ServerError, SnackCategory} from "./types";


export interface SnackCategoryError extends ServerError {
    detail: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
}

class SnackCategoryErrorInfo implements SnackCategoryError {
    detail: string[] | undefined;
    nameAr: string[] | undefined;
    nameEn: string[] | undefined;
    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}


export function validateSnackCategory(snackCategory: SnackCategory): Promise<SnackCategory> {
    const error = new SnackCategoryErrorInfo();
    if (!snackCategory.nameAr) {
        error.add('nameAr', 'this_field_is_required');
    }
    if (!snackCategory.nameEn) {
        error.add('nameEn', 'this_field_is_required');
    }

    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(snackCategory) : Promise.reject(error);
}
