import {ServerError, Wizard} from "../models/types";
import {useQuery} from "react-query";
import {currentCommoditiesWizard, currentMealsWizard, currentOrderWizard, currentSurveyWizard, getWizard} from "../restapi/wizard";


export function useRetrieveOrderWizard({id, onError}: {id?: number, onError?: (e: ServerError) => void}): Wizard | undefined {
    const wizardQuery = useQuery(['current-wizard'],
        () => {
            const promise = !!id ? getWizard(id) : currentOrderWizard();
            return promise.catch((error) => {
                if (error.status === 404) {
                    return Promise.resolve({data: {}} as Wizard).then();
                }
                return Promise.reject(error);
            })
        }, {onError: onError}
    );
    return wizardQuery.data;
}

export function useRetrieveCommoditiesWizard({id, onError}: {id?: number, onError?: (e: ServerError) => void}): Wizard | undefined {
    const wizardQuery = useQuery(['current-wizard'],
        () => {
            const promise = !!id ? getWizard(id) : currentCommoditiesWizard();
            return promise.catch((error) => {
                if (error.status === 404) {
                    return Promise.resolve({data: {}} as Wizard).then();
                }
                return Promise.reject(error);
            })
        }, {onError: onError}
    );
    return wizardQuery.data;
}

export function useRetrieveMealsWizard({id, onError}: {id?: number, onError?: (e: ServerError) => void}): Wizard | undefined {
    const wizardQuery = useQuery(['current-wizard'],
        () => {
            const promise = !!id ? getWizard(id) : currentMealsWizard();
            return promise.catch((error) => {
                if (error.status === 404) {
                    return Promise.resolve({data: {}} as Wizard);
                }
                return Promise.reject(error);
            })
        }, {onError: onError}
    );
    return wizardQuery.data;
}

export function useRetrieveSurveyWizard({id, onError}: {id?: number, onError?: (e: ServerError) => void}): Wizard | undefined {
    const wizardQuery = useQuery(['current-wizard'],
        () => {
            const promise = !!id ? getWizard(id) : currentSurveyWizard();
            return promise.catch((error) => {
                if (error.status === 404) {
                    return Promise.resolve({data: {}} as Wizard);
                }
                return Promise.reject(error);
            })
        }, {onError: onError}
    );
    return wizardQuery.data;
}
