import {KVPair, ListPage, PartnerSupplier} from "../models/types";
import {buildQueryString, restapi} from "./restapi";

export function addPartnerSupplier(data: PartnerSupplier): Promise<PartnerSupplier> {
    return restapi.post<PartnerSupplier>('/api/v1/smt_core/partner_supplier/', data).then(res => res.data);
}
export function deletePartnerSupplier(id: number): Promise<boolean> {
    return restapi.delete<void>(`/api/v1/smt_core/partner_supplier/${id}/`).then(res => res.status === 204);
}
export function getPartnerSupplier(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<PartnerSupplier>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<PartnerSupplier>>(`/api/v1/smt_core/partner_supplier/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}
