import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {Blockquote} from "@wfp/ui";
import {KVPair, ListPage, Role, SchoolMealItem, ServerError} from "../../models/types";
import {updateSorting} from "../../restapi/restapi";
import {useInViewport} from "../../hooks/useInViewport";
import {useLoggedUser} from "../../hooks/user";
import {HandoverConfirmation} from "./handover-confirmation";
import {SchoolMealItemCard} from "./card";
import {
    getSchoolMealItems,
    handoverSchoolMealItem,
    pickupSchoolMealItem,
    undoPickupSchoolMealItem
} from "../../restapi/school-meal-item";
import {SchoolMealItemTable} from "./school-meal-item-table";

interface Props {
    page?: ListPage<SchoolMealItem>,
    mobile?: boolean,
    disableFilter?: boolean,
    onSelectSchoolMeal?: (id: number) => void,
    onSelectSchoolMealItems?: (schoolMealId: number[]) => void
}

export const SchoolMealItemList = (props: Props) => {
    const user = useLoggedUser({});
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<ListPage<SchoolMealItem>>();
    const [error, setError] = useState<ServerError>();
    const {isInViewport, ref} = useInViewport();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [schoolMealHandover, setSchoolMealHandover] = useState<SchoolMealItem>();

    useEffect(() => {
        if (!!props.page) {
            setPage(props.page);
        }
    }, [props.page]);

    function handleSort(field: string): void {
        const value = updateSorting(field, page?.sorting || []);
        loadPage(page?.pageNumber, page?.pageSize, page?.filtering, value);
    }

    function loadPage(pageNumber = 1, pageSize?: number, options?: KVPair[], sorting?: string[]): void {
        setLoading(true);
        setError(undefined);
        getSchoolMealItems(pageNumber, pageSize, options, sorting)
            .then((res) => {
                setLoading(false);
                if (props.mobile && !!page) {
                    res.results = (page.results || []).concat(res.results);
                }
                setPage(res);
            })
            .catch(error => setError(error));
    }

    function handleLoadMore(): boolean {
        if (!!page) {
            const result = !loading && !!page.next;
            if (result) {
                loadPage(page.pageNumber + 1, page.pageSize, page.filtering, page.sorting);
            }
            return result;
        }
        return false;
    }

    function handleHandover(schoolMealItem: SchoolMealItem) {
        setSchoolMealHandover(schoolMealItem);
    }

    function handleHandoverConfirm() {
        if (!!schoolMealHandover) {
            handoverSchoolMealItem(schoolMealHandover.id)
                .then(() => navigate("/mobile"))
                .catch((error) => setError(error));
        }
    }

    function handleUndoPickup(schoolMealItem: SchoolMealItem) {
        undoPickupSchoolMealItem(schoolMealItem.id).then(
            () => navigate('/mobile')
        ).catch(
            (error) => setError(error)
        );
    }

    function handlePickup(schoolMealItem: SchoolMealItem) {
        pickupSchoolMealItem(schoolMealItem.id).then(
            () => navigate('/mobile')
        ).catch(
            (error) => setError(error)
        );
    }

    function scan(schoolMealItem: SchoolMealItem) {
        navigate(`/school-items/${schoolMealItem.id}/qrscan`)
    }


    return (
        <div>
            {!!schoolMealHandover && (
                <HandoverConfirmation
                    schoolMealItem={schoolMealHandover}
                    onCancel={() => setSchoolMealHandover(undefined)}
                    onOk={() => handleHandoverConfirm()}
                />
            )}
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error.detail.toString()}</Blockquote>
            )}
            {!!page && (
                <div>
                    {page?.count === 0 && (
                        <Blockquote kind="warning" withIcon>{t("empty_list")}</Blockquote>
                    )}
                    {!!props.mobile && page?.results.map((schoolMealItem) => (
                        <div key={schoolMealItem.id}
                             className="flex items-start max-w-md py-4 px-4 bg-white shadow-md rounded-lg my-4 mx-2">
                            <SchoolMealItemCard
                                item={schoolMealItem}
                                readonly={schoolMealItem?.state === "DELIVERED" && (user?.role === Role.SchoolRepresentative || user?.role === Role.SchoolManager)}
                                handover={schoolMealItem?.state === "LOADED" && user?.role === Role.SchoolDriver ? () => handleHandover(schoolMealItem) : undefined}
                                undoPickup={schoolMealItem?.state === "LOADED" && user?.role === Role.SchoolDriver ? () => handleUndoPickup(schoolMealItem) : undefined}
                                pickup={schoolMealItem?.state === "CONFIRMED" && user?.role === Role.SchoolDriver ? () => handlePickup(schoolMealItem) : undefined}
                                onClick={schoolMealItem?.state === "DELIVERED" && user?.role === Role.SchoolRepresentative ? () => scan(schoolMealItem) : undefined}
                            />
                        </div>
                    ))}
                    {!!props.mobile && (
                        <div ref={ref}>
                            {isInViewport && handleLoadMore() && <div>Loading.....</div>}
                        </div>
                    )}
                    {!props.mobile && page.count > 0 && (
                        <SchoolMealItemTable
                            onSelectSchoolMealItems={props?.onSelectSchoolMealItems}
                            hidePagination={page.count <= page.results.length}
                            loadPage={loadPage}
                            page={page}
                            sort={handleSort}
                        />
                    )}
                </div>
            )}
        </div>
    );
}
