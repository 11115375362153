import {District, KVPair, ListPage, Location
} from "../models/types";
import {buildQueryString, restapi} from "./restapi";

export function getDistricts(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<District>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<District>>(`/api/v1/smt_geo/district/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}
export function getLocations(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Location>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Location>>(`/api/v1/smt_geo/location/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrieveDistricts(): Promise<District[]> {
    return restapi.get<District[]>("/api/v1/smt_geo/district/?__pagination__=0").then(res => res.data);
}