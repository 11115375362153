import {UserDetails} from "../../models/types";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

interface Props {
    item: UserDetails;
    onClick?: () => void
    readonly?: boolean;
}

export const UserCard = (props: Props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    function handleClick(): void {
        if (!!props.readonly) {
            return;
        }
        if (!!props.onClick) {
            props.onClick();
        } else {
            navigate(`/users/${props.item.pk}`);
        }
    }

    return (
        <div className="flex items-start flex-col" onClick={handleClick}>
            <div>
                {t('id')}: {props.item.pk}
            </div>
            <h2 className="text-gray-800 text-3xl font-semibold my-3">
                {t('name')} {props.item.firstName} {props.item.lastName}
            </h2>
            <div className="my-1">
                {t('email')} {props.item.email}
            </div>
            <div className="my-1">
                {t('role')} {t(props.item.role)} {props.item.entityName || ""}
            </div>
        </div>
    );
};
