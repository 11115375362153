import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Blockquote, Select, SelectItem } from "@wfp/ui";
import { Snack } from "../../../models/types";
import { useRetrieveSnacks } from "../../../hooks/snack";
import { LoadingCenter } from "../../commons/loading-center";
import { WizardError } from "./wizard";
import "./survey.css";
import { useMediaQuery } from "../../../hooks/commons";
import { NumberInputValue } from "../../commons/number-input";

import { useRetrieveSnackCategories } from "../../../hooks/snack-category";
export interface Item {
    id: number,
    quantity: number,
    name: string,
}
interface IProps {
    error?: WizardError
    selectedSnacks: Item[]
    onChange: (e: Item[]) => void
}

export function PageSnacks(props: IProps) {
    const { t } = useTranslation();
    const snacks = useRetrieveSnacks({});
    const [selectedSnacks, setSelectedSnacks] = useState<Item[]>(props.selectedSnacks || []);
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const snackCategories = useRetrieveSnackCategories({});


    /**
     * #203240 ability to select multiple snacks of the same category
     * @param e select event
     * @param idx index on selects
     */
    function handleSelectSnack(e: any, idx: number): void {
        if (!isNaN(Number(e.target?.value)) && !!snacks) {
            /* const category = props.snackCategories[idx]?.id; #203240 */
            const snack = snacks
                /* .filter((s) => s.category === category) #203240 */
                .find((s) => s.id === parseInt(e.target?.value)
                ) || { id: 0, category: 0, name: '' } as Snack;
            const selected = [...(selectedSnacks || props.selectedSnacks || [])];
            selected[idx] = { ...selected[idx], id: snack.id, name: snack.name, quantity: selected[idx]?.quantity || 0 };
            setSelectedSnacks(selected);
            props.onChange([...selected]);
        }
    }

    function handleChangedQuantity(value: number, idx: number): void {
        const selected = [...(selectedSnacks || props.selectedSnacks || [])];
        if (!selected[idx]) {
            selected[idx] = { id: 0, quantity: value, name: '' };
            setSelectedSnacks(selected);
            props.onChange([...selected]);
        }
        else {
            selected[idx] = { ...selected[idx], quantity: value };
            setSelectedSnacks(selected);
            props.onChange([...selected]);
        }

    }


    return (
        <div className="mt-10">
            {!isSmallScreen && (
                <Blockquote className="blockquote-form">
                    <h2 className='mb-3'>{t("select_snack_survey_label")}</h2>
                </Blockquote>
            )}
            {!snackCategories && (
                <LoadingCenter />
            )}
            {snackCategories?.map((s, idx) => (
                <div key={idx} className={`${isSmallScreen ? 'mt-5' : 'mt-10'}`}>
                    <h3 className="text-left mt-2">Snack Item {idx + 1}</h3>
                    <div className={!!(props.error?.snacks || [])[idx] ? `mt-3 mb-10` : 'mt-3'}>
                        <Select
                            onChange={(e) => handleSelectSnack(e, idx)}
                            value={(selectedSnacks || [])[idx]?.id || 0}
                            labelText={t("please_select_one_snack")} /* {`Please select one ${sc.name}`} #203240 */
                            invalid={!!(props.error?.snacks || [])[idx]}
                            invalidText={t((props.error?.snacks || [])[idx]?.toString())!}
                        >
                            {[{ id: 0, name: t("choose_one") }, ...(snacks || [])]
                                /*.filter((s) => s.category === sc.id) #203240 */
                                .map((s, snackIdx) => (
                                    <SelectItem key={snackIdx} id={`${s.id}`} value={s.id} text={t(s.name)} />
                                ))}
                        </Select>
                    </div>
                    <div className="wfp--form-item mt-5">
                        <div className={!!(props.error?.quantity || [])[idx] ? `mt-3 mb-10` : 'mt-3'}>
                            <NumberInputValue
                                key={idx}
                                className="survey-input"
                                formItemClassName='opacity-100'
                                labelText={t("quantity")!}
                                min={0}
                                onChangeValue={(value) => handleChangedQuantity(value, idx)}
                                value={selectedSnacks[idx]?.quantity || 0}
                                invalid={!!(props.error?.quantity || [])[idx]}
                                invalidText={t((props.error?.quantity || [])[idx]?.toString())!}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
