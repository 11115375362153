import {I18nextProvider, useTranslation} from "react-i18next";
import {QueryClient, QueryClientProvider} from "react-query";
import "react-dates/initialize";
import "./input.css"
import "@wfp/ui/src/globals/scss/styles.scss";
import "./App.css";
import i18next from "./i18n";
import {AppRouting} from "./AppRouting";


function App() {
    const {i18n} = useTranslation();
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <I18nextProvider i18n={i18next}>
                <div className="App" dir={i18n.dir()}>
                    <AppRouting />
                </div>
            </I18nextProvider>
        </QueryClientProvider>
    );
}

export default App;
