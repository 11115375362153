import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Blockquote, Checkbox} from "@wfp/ui";
import {School} from "../../../models/types";
import {NumberInputValue} from "../../commons/number-input";
import {LoadingCenter} from "../../commons/loading-center";
import "./wizard.css";

interface IProps {
    schools: School[],
    disabledSchoolIds: number[],
    onChange: (e: School[]) => void
    onChangeDisabled: (e: number[]) => void
}

export function PageSchools(props: IProps) {
    const {t} = useTranslation();
    const [disabledSchools, setDisabledSchools] = useState<boolean[]>([]);
    const [isCheckAll, setIsCheckAll] = useState(false);

    useEffect(() => {
        if(!!props.schools && !!props.disabledSchoolIds && !disabledSchools?.length) {
            const disabled = (props.schools || []).map((s) => props.disabledSchoolIds.includes(s.id));
            setDisabledSchools(disabled);
        }
    }, [props.schools, props.disabledSchoolIds, disabledSchools])

    function onChangeSchool(value: number, idx: number, field: string): void {
        if (!!props.schools) {
            const selectedSchools = [...props.schools];
            (selectedSchools[idx] as any)[field] = value;
            props.onChange(selectedSchools);
        }
    }

    function toggleEnabledSchool(idx: number): void {
        disabledSchools[idx] = !disabledSchools[idx];
        setDisabledSchools([...disabledSchools]);
        const disabledSchoolIds = (props.schools || []).filter((s, idx) => disabledSchools[idx]).map(s => s.id);
        props.onChangeDisabled(disabledSchoolIds);
        
    }
    function handleSelectAll(): void{
       
        const disabledSchoolIds: number[] = props.schools.map(s => s.id);
        const disableAll = Array(props.schools.length).fill(isCheckAll);

        setIsCheckAll(!isCheckAll);
        setDisabledSchools(disableAll);
        props.onChangeDisabled(isCheckAll ? disabledSchoolIds : []);
    }

    return (
        <div className="mt-10">
            <Blockquote className="blockquote-form">
                <h2 className='mb-3'>{t("select_school_title")}</h2>
                <p>{t("select_school_subtitle")}</p>
            </Blockquote>
            {!props.schools && (
                <LoadingCenter />
            )}
               <Checkbox
                        id={`check-all`}
                        labelText={`${isCheckAll ? t("deselect_all") : t("select_all")}`}
                        onChange={handleSelectAll}
                        checked={isCheckAll}
                        wrapperClassName="justify-content-center"
                        />
            {props.schools?.map((school, idx) => (
                <div key={idx} className="flex align-middle mt-5 mb-5">
                    <div className="wfp--form-item">
                        <Checkbox
                            id={`check-${idx}`}
                            labelText={`${school.name} (${school.districtName})`}
                            onChange={() => toggleEnabledSchool(idx)}
                            checked={!disabledSchools[idx]}
                            wrapperClassName="justify-content-center"
                        />
                    </div>
                    <div className="wfp--form-item">
                        <NumberInputValue className="required"
                            key={idx}
                            labelText={idx === 0 ? t("quantityAM") || '' : undefined}
                            min={0}
                            onChangeValue={(value) => onChangeSchool(value, idx, 'numberOfStudentsAm')}
                            value={school.numberOfStudentsAm}
                            disabled={disabledSchools[idx]}
                        />
                    </div>
                    <div className="wfp--form-item">
                        <NumberInputValue
                            key={idx}
                            labelText={idx === 0 ? t("quantityPM") || '' : undefined}
                            min={0}
                            onChangeValue={(value) => onChangeSchool(value, idx, 'numberOfStudentsPm')}
                            value={school.numberOfStudentsPm}
                            disabled={disabledSchools[idx]}
                        />
                    </div>
                </div>
            ))}
        </div>
    )
}
