import { KVPair, ListPage, ServerError, Snack } from '../models/types';
import {useQuery} from "react-query";
import { getSnack, getSnacks, retrieveSnacks } from '../restapi/snack';

export function useGetSnacks({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<Snack> | undefined {
    const snacksQuery = useQuery(['schools', 'page'], () => getSnacks(page, pageSize, filters, sorting), {onError: onError});
    return snacksQuery.data;
}

export function useRetrieveSnacks({onError}: {onError?: (e: ServerError) => void}): Snack[] | undefined {
    const snacksQuery = useQuery(['snacks'], () => retrieveSnacks(), {onError: onError});
    return snacksQuery.data;
}

export function useRetrieveSnack({id, onError}: {id: number, onError?: (e: ServerError) => void}): Snack | undefined {
    const snackQuery = useQuery(['snack'], () => getSnack(id), {
        onError: onError,
        retry: false
    });
    return snackQuery.data;
}
