import {DeliveryState, OrderItem, OrderItemState} from "./types";
/**
 * PLANNED -> REQUESTED
 * CONFIRMED -> SUPPLIER ACCEPTED ORDER
 * LOADED -> PICKED UP BY DRIVER
 * DELIVERED -> DRIVER HAND OVER
 * DISCARDED -> ORDER CANCELED
 * DONE ->
 *   DONE -> RECEIVED BY SCHOOL
 *   DONE_REJECTED -> REJECTED BY SCHOOL
 *   DONE_MODIFICATION -> RECEIVED WITH MODIFICATION
 *   DONE_RETURNED -> RETURNED BY DRIVER
 */
export function orderItemStateParse(item: OrderItem): string {
    switch(item.state) {
        case OrderItemState.DONE:
            switch (item.deliveryState) {
                case DeliveryState.ISSUED:
                    return "item_DONE";
                case DeliveryState.REJECTED_BY_SCHOOL:
                    return "item_DONE_REJECTED";
                case DeliveryState.ISSUED_WITH_NOTES:
                    return "item_DONE_MODIFICATION";
                case DeliveryState.RETURNED_BY_DRIVER:
                    return "item_DONE_RETURNED";
                default:
                    return '';
                }
        default:
            return `item_${item.state}`;
    }
}
