import {
    OrderHistoryData,
    OrderItemHistoryData,
    OrderItemTransition
} from "../../models/types";
import {datetimeParse} from "../../hooks/formats";
import "./delivery.css"

export function OrderDelivery(props: {data: OrderHistoryData}) {
    return (
        <div className="grid space-y-2">
            {props.data.items.map((item: OrderItemHistoryData) => (
                <div  key={item.codeValue} className="grid space-y-2 auto-cols-max space-x-2">
                    <div className="p-2">{item.schoolName}</div>
                    {item.transitions.filter(oit => oit.toState !== "CONFIRMED").map((oit: OrderItemTransition) => (
                    <div key={oit.id} className={`bg-order-item-state-${oit.toState} p-2 rounded-3xl tooltip-delivery`}>
                        <div className="ml-2">
                            {datetimeParse(oit.timestamp)} - <span className={`text-order-item-state-${oit.toState}`}>{oit.toState}</span>
                        </div>
                        <span className='tooltiptext-delivery'>{oit.username}</span>
                    </div>
                    ))}
                </div>
            ))}
        </div>
    );
}
