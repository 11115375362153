import React, {useEffect, useState} from "react";
import {NavLink, useParams} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import { Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Tab, Tabs } from '@wfp/ui';
import {ListPage, SchoolMealHistoryData, SchoolMealItem, ServerError} from "../../models/types";
import {downloadReceipt, getSchoolMealHistory} from "../../restapi/school-meal";
import {getSchoolMealItems} from "../../restapi/school-meal-item";
import {LoadingCenter} from "../commons/loading-center";
import {SchoolMealItemList} from "../school-meal-item/list";
import {useRetrieveSchoolMeal} from "../../hooks/school-meal";
import {SchoolMealHistory} from "./history";
import { iconDocument } from '@wfp/icons';

export const SchoolMealDetail = () => {
    const {t, i18n} = useTranslation();
    const {id} = useParams();
    const [history, setHistory] = useState<SchoolMealHistoryData>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<ServerError>();
    const [schoolMealItemPage, setSchoolMealItemPage] = useState<ListPage<SchoolMealItem>>();
    const [tabIndex, setTabIndex] = useState(parseInt(localStorage.getItem('SchoolMealDetailTabIndex') || '0'));

    const schoolMeal = useRetrieveSchoolMeal({id: Number(id), onError: (e) => setError(e)});

    useEffect(() => {
        if (!!id && !!Number(id)) {
            const filters = [{key: 'school_meal', value: id}];
            getSchoolMealItems(1, undefined, filters, undefined)
                .then(res => setSchoolMealItemPage(res))
                .catch(e => setError(e));
            getSchoolMealHistory(Number(id))
                .then(res => setHistory(res))
                .catch(e => setError(e));
        }
    }, [id]);

    function handlePrint() {
        if (!!schoolMeal) {
            setLoading(true);
            downloadReceipt(schoolMeal.id).catch((e) => setError(e)).finally(() => setLoading(false));
        }
    }

    function handleSelectSchoolMealItem() {
        // console.log(id)  TODO: define behavior on selection
    }

    function handleTabChange(idx: number): void {
        localStorage.setItem("SchoolMealDetailTabIndex", idx.toString());
        setTabIndex(idx);
    }

    return (
        <div>
            {!!error?.errors && (
                <Blockquote kind="error" withIcon>{error.errors}</Blockquote>
            )}
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>{error?.detail?.toString()}</Blockquote>
            )}
            {!!schoolMeal && (
                <div className="m-10">
                    <Breadcrumb className="mb-5">
                        <BreadcrumbItem>
                            <NavLink to="/"><BreadcrumbHome/></NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem>
                            <NavLink to="/school-meals">{t('school_meals')}</NavLink>
                        </BreadcrumbItem>
                        <BreadcrumbItem disableLink>
                            {t('view_school_meal')}
                        </BreadcrumbItem>
                    </Breadcrumb>
                    <h1 className="text-start mt-10 mb-10">
                        <p>{t('school_meal_details')}</p>
                        {loading &&(
                            <div><LoadingCenter/></div>
                        )}
                    </h1>
                    <Tabs className='mt-2' selected={tabIndex}>
                        <Tab onClick={() => handleTabChange(0)} label={t('info')} href='' role={'info'} selected
                             tabIndex={0}>
                            {!!schoolMealItemPage && (
                                <>
                                    <div className="flex items-center mb-3">
                                        <div>{t("state")}</div>
                                        <div className={(i18n.dir() === 'ltr' ? 'mr-auto ml-5 ' : 'ml-auto mr-5 ') + `bg-order-item-state-${schoolMeal.state} rounded-3xl p-2`}>{schoolMeal.state}</div>
                                        <div className={i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}>
                                            <Button className="!inline-flex" icon={iconDocument} iconReverse onClick={handlePrint}
                                                disabled={schoolMeal.state === 'DRAFT' || loading}>
                                                {t('print_receipt')}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="visible md:hidden">
                                        <SchoolMealItemList page={schoolMealItemPage} mobile={true}/>
                                    </div>
                                    <div className="hidden md:block">
                                        <SchoolMealItemList onSelectSchoolMealItems={handleSelectSchoolMealItem}
                                                            page={schoolMealItemPage} mobile={false}/>
                                    </div>
                                </>
                            )}
                        </Tab>
                        <Tab onClick={() => handleTabChange(1)} label={t('history')} href='' role={'history'} selected
                             tabIndex={1}>
                            {!!history && (
                                <SchoolMealHistory data={history}/>
                            )}
                        </Tab>
                    </Tabs>
                </div>
            )}
            {!(error || schoolMeal) && (
                <LoadingCenter/>
            )}
        </div>
    );
};
