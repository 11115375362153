import {KVPair, ListPage, PartnerSchool} from "../models/types";
import {buildQueryString, restapi} from "./restapi";

export function addPartnerSchool(data: PartnerSchool): Promise<PartnerSchool> {
        return restapi.post<PartnerSchool>('/api/v1/smt_core/partner_school/', data).then(res => res.data);
}
export function deletePartnerSchool(id: number): Promise<boolean> {
    return restapi.delete<void>(`/api/v1/smt_core/partner_school/${id}/`).then(res => res.status === 204);
}
export function getPartnerSchools(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<PartnerSchool>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<PartnerSchool>>(`/api/v1/smt_core/partner_school/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}
