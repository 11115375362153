import {useMutation, useQuery, useQueryClient} from 'react-query';
import {KVPair, ListPage, ServerError, UserDetails} from '../models/types';
import {getUserDetails} from "../restapi/auth";
import {getUser, getUsers} from "../restapi/user";

export function useOptionalUser(): UserDetails | undefined {
    const queryClient = useQueryClient();
    const queryResult = useQuery(['user', 'optional'], {
       queryFn: () => queryClient.getQueryData(['user', 'current']) as UserDetails | undefined
    });
    return queryResult.data;
}

// read current user
export function useLoggedUser({onError}: { onError?: (e: ServerError) => void }) {
    const queryClient = useQueryClient();
    const userQuery = useQuery(['user', 'current'], () => getUserDetails(), {
        staleTime: Infinity,  // never reload
        cacheTime: Infinity,  // never reload
        retry: false,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'optional'] }),
        onError: onError
    });
    return userQuery.data;
}

export function useInvalidateUser() {
    const queryClient = useQueryClient();
    return useMutation((user: UserDetails|undefined) => Promise.resolve(), {
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user', 'current'] })
    });
}

export function useGetUsers({page = 1, pageSize, filters, sorting, onError}: { page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void }): ListPage<UserDetails> | undefined {
    const usersQuery = useQuery(['user', 'page'], () => getUsers(page, pageSize, filters, sorting), {
        retry: false,
        onError: onError
    });
    return usersQuery.data;
}

export function useRetrieveUser({id, onError}: {id: number, onError?: (e: ServerError) => void}): UserDetails | undefined {
    const userQuery = useQuery(['user'], () => getUser(id), {
        onError: onError,
        retry: false
    });
    return userQuery.data;
}
