import {KVPair, ListPage, UserDetails} from "../models/types";
import {buildQueryString, restapi} from "./restapi";


export function getUser(pk: number): Promise<UserDetails> {
    return restapi.get<UserDetails>(`/api-auth/user/${pk}/`).then((res) => res.data);
}

export function getUsers(page = 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<UserDetails>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<UserDetails>>(`/api-auth/user/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function saveUser(data: UserDetails): Promise<UserDetails> {
    if (!data.pk) {
        return restapi.post<UserDetails>('/api-auth/user/', data).then(res => res.data);
    } else {
        return restapi.patch<UserDetails>(`/api-auth/user/${data.pk}/`, data).then(res => res.data);
    }
}

export function disableUser(id: number): Promise<boolean> {
    return restapi.post<UserDetails>(`/api-auth/user/${id}/disable/`).then(res => res.status === 200);
}

export function resetUser(id: number): Promise<UserDetails> {
    return restapi.post<UserDetails>(`/api-auth/user/${id}/reset/`).then(res => res.data);
}
