import { useTranslation } from "react-i18next";
import { OrderHistoryData, OrderItemHistoryData, OrderTransition, OrderItemTransition } from "../../models/types";
import { datetimeParse } from "../../hooks/formats";
import { AccordionItem } from "../commons/accordion/accordion";
import { OrderItemHistory } from "../order-item/history";

function TransitionRow(props: { transition: OrderTransition | OrderItemTransition }) {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row space-x-3 p-1">
            <div>{datetimeParse(props.transition.timestamp)}</div>
            <div>{props.transition.username}</div>
            <div>{props.transition.toState}</div>
            {!!props.transition.position && (
                <div>
                    <a target="_blank" href={`https://www.openstreetmap.org/?mlat=${props.transition.position!.latitude}&mlon=${props.transition.position!.longitude}#map=14/${parseFloat(props.transition.position!.latitude)}/${props.transition.position!.longitude}`}>{t('transition_position')}: {`${parseFloat(props.transition.position!.latitude)}, ${parseFloat(props.transition.position!.longitude)}`}</a>
                </div>
            )}
        </div>
    )
}

function OrderItemInfo(props: { item: OrderItemHistoryData, t?: any }) {
    return (
        <div className="flex flex-row space-x-3 p-2 items-center">
            <div>{props.item.codeValue}</div>
            <div>{props.item.schoolName}</div>
            <div className={`bg-order-state-${props.item.state} rounded-3xl p-2`}>
                <p className={`text-order-state`}>{props.item.state}</p>
            </div>
        </div>
    )
}


export function OrderHistory(props: { data: OrderHistoryData }) {
    const { t } = useTranslation();

    return (
        <div className="grid grid-flow-row space-y-3 text-left">
            {props.data.transitions.map((ot, idx) => (
                <TransitionRow key={idx} transition={ot} />
            ))}
            {!!props.data.items?.length && (
                <div className="font-semibold p-1">{t('items')}</div>
            )}
            <div className="mx-10">
                {props.data.items.map((item, idx) => (
                    <AccordionItem key={idx} title={<OrderItemInfo item={item} />} open={false}>
                        <div className="grid grid-flow-row auto-rows-max space-y-3">
                            {item.transitions.map((oit, idx) => (
                                <TransitionRow key={idx} transition={oit} />
                            ))}
                            <OrderItemHistory open={false} data={item} />
                        </div>
                    </AccordionItem>
                ))}

            </div>
        </div>
    );
}
