import { NavLink, useParams } from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from '@wfp/ui';
import {iconEditGlyph} from "@wfp/icons";
import i18n from "../../i18n";
import {ServerError, Snack} from '../../models/types';
import {SnackError, validateSnack} from "../../models/validate-snack";
import {saveSnack} from "../../restapi/snack";
import {useRetrieveSnack} from "../../hooks/snack";
import {LoadingCenter} from '../commons/loading-center';
import {SnackForm} from "./form";
import {SnackCard} from "./card";

export const SnackDetail = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const [error, setError] = useState<ServerError>();
    const [snack, setSnack] = useState<Snack>();
    const [snackEdit, setSnackEdit] = useState<Snack>();
    const [snackError, setSnackError] = useState<SnackError>();

    const data = useRetrieveSnack({id: Number(id), onError: (e) => setError(e)});

    useEffect(() => {
        if (data) {
            setSnack(data);
        }
    }, [data]);

    function handleEditClose() {
        setSnackEdit(undefined);
        setSnackError(undefined);
    }

    function handleEditSubmit() {
        if (!!snackEdit) {
            validateSnack(snackEdit)
                .then((data) =>
                    saveSnack(data)
                        .then((res) => {
                            setSnack(res);
                            setSnackEdit(undefined);
                            setSnackError(undefined);
                        }).catch(e => setError(e)))
                .catch(e => setSnackError(e));
        }
    }

    return (
        <div className="m-10">
            <Breadcrumb className="mb-5">
                <BreadcrumbItem>
                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem>
                    <NavLink to="/snacks">{t('snack')}</NavLink>
                </BreadcrumbItem>
                <BreadcrumbItem disableLink>
                    {t('view_snack')}
                </BreadcrumbItem>
            </Breadcrumb>
            <div className="flex mt-10 mb-5 mx-0">
                <Text kind='h1'>{t("snack_details")}</Text>
                <div className={`${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                    <div className="grid-flow-row flex auto-rows-max mb-10 text-end">
                        <div className="mx-3">
                            <Button onClickCapture={() => setSnackEdit(snack)} className='!inline-flex'
                                    icon={iconEditGlyph} iconReverse>
                                {t('edit_snack')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            {!!error?.errors && (
            <Blockquote kind="error" withIcon>{error.errors.toString()}</Blockquote>
            )}
            {!!error?.detail && (
            <Blockquote kind="error" withIcon>{error?.detail.toString()}</Blockquote>
            )}
            {!!snack && (
            <div className="flex items-start max-w-md py-4 px-8 bg-white shadow-md rounded-lg my-10 mx-5">
                <SnackCard item={snack} />
            </div>
            )}
            {!(error || snack) && (
            <LoadingCenter/>
            )}
            {!!snackEdit && (
            <Modal
                open={true}
                modalHeading={t('edit_snack')!}
                modalLabel={t('edit_snack')}
                primaryButtonText={t('save')!}
                onRequestSubmit={handleEditSubmit}
                onRequestClose={handleEditClose}
                secondaryButtonText={t('cancel')!}
            >
                <SnackForm snack={snackEdit}
                           error={snackError}
                           onChange={setSnackEdit}
                />
            </Modal>
            )}
        </div>
    );
};
