import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Form, Module, ModuleBody} from "@wfp/ui";
import {KVPair, SnackCategory} from "../../models/types";
import {SMETSModuleHeader} from "../commons/module-header";
import {SMETSTypeahead} from "../commons/typeahead/typeahead";
import {getSnackCategories} from "../../restapi/snack-category";

interface Props {
    onSubmit: (filters: KVPair[]) => void,
}


export function SnackCategoryFilters(props: Props) {
    const [visible, setVisible] = useState(false);
    const [snackCategorySelected, setSnackCategorySelected] = useState<SnackCategory>();
    const {t, i18n} = useTranslation();

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setSnackCategorySelected({} as SnackCategory);
    }

    function handleApply() {
        const filters: KVPair[] = [];
        if (!!snackCategorySelected?.name) {
            filters.push({key: "id", value: snackCategorySelected.id.toString()});
        }
        props.onSubmit(filters);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-3 space-x-5 items-baseline text-start">
                            <div className={`${i18n.dir() === 'ltr' ? 'ml-0' : 'ml-3'}`}>
                                <SMETSTypeahead<SnackCategory>
                                    noOptionsMessage={t('no_option_value') as string}
                                    value={snackCategorySelected}
                                    onClick={handleApply}
                                    onSelectOption={(o) => setSnackCategorySelected({
                                        ...snackCategorySelected,
                                        id: Number(o.id),
                                        name: o.name
                                    } as SnackCategory)}
                                    label={t('category')}
                                    search={(input) => getSnackCategories(1, 5, [{
                                        key: "name_ic",
                                        value: input
                                    }]).then(res => res.results)}/>
                            </div>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button" onClick={handleApply}>
                                {t('apply')}
                            </Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
