import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Table} from "@wfp/ui";
import {KVPair, ListPage, LogEntry, LogType, Sortable} from "../../models/types";
import {datetimeParse} from "../../hooks/formats";
import {TablePagination} from "../commons/table-pagination/table-pagination";


interface Props {
    hidePagination?: boolean;
    page: ListPage<LogEntry>;
    logType: LogType;
    loadPage: (page: number, pageSize?: number, filtering?: KVPair[], sorting?: string[]) => void;
    sort?: (field: string) => void;
}

const SortInfo: Sortable[] = [
    {code: 'date', field: 'date', enabled: true, mode: 0},
    {code: 'username', field: 'username', enabled: true, mode: 0},
    {code: 'method', field: 'method', enabled: true, mode: 0},
    {code: 'url', field: 'url', enabled: true, mode: 0},
    {code: 'content_type', field: 'content_type', enabled: true, mode: 0},
];

const headersCRUD = ['date', 'username', 'method', 'content_type', 'content_id'];
const headersLogin = ['date', 'username', 'method'];
const headersRequest = ['date', 'username', 'method', 'url'];

export function LogsTable(props: Props) {
    const {t} = useTranslation();
    const [sortables, setSortables] = useState<Sortable[]>();
    const [headers, setHeaders] = useState<string[]>();

    useEffect(() => {
        if(!!props.logType) {
            switch(props.logType) {
                case LogType.CRUD:
                    setHeaders(headersCRUD);
                    setSortables(formatSortable(headersCRUD));
                    break;
                case LogType.LOGIN:
                    setHeaders(headersLogin);
                    setSortables(formatSortable(headersLogin));
                    break;
                case LogType.REQUEST:
                    setHeaders(headersRequest);
                    setSortables(formatSortable(headersRequest));
                    break;
            }
        }
    }, [props.logType])

    function formatSortable(headers: string[]) {
        return headers.map((header) => {
            const sort = SortInfo.find((sort) => sort.code === header);
            return !!sort ? sort : {code: header, field: header, mode: 0, enabled: false}
        });
    }

    function handleSorting(idx: number) {
        if (!!sortables && !!props.sort) {
            const sortable = sortables[idx];
            if (!sortable.enabled) {
                return;
            }
            sortable.mode = (sortable.mode + 1) % 3;
            setSortables([...sortables]);
            const value = sortable.mode === 1 ? `+${sortable.field}` : sortable.mode === 2 ? `-${sortable.field}` : sortable.field;
            props.sort(value);
        }
    }

    return (
        <>
            {!!headers && !!props.page &&(
            <Table>
                <thead>
                <tr className="item-row-head">
                    {headers.map((header, idx) => (
                        <th key={idx} onClick={() => handleSorting(idx)}
                            className={props.sort && sortables?.at(idx)?.enabled ? "cursor-pointer" : ""}>
                            {t(header)}
                            {!!sortables?.length && (
                                <span className="ml-0.5">
                                {sortables[idx].mode === 2 ? '\u25bc' : sortables[idx].mode === 0 ? '\u2000' : '\u25b2'}
                            </span>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {props.page.results.map((item: LogEntry, idx) => (
                    <tr className="item-row cursor-pointer" key={idx}>
                        <td className="item-col text-start">
                            {datetimeParse(item.datetime)}
                        </td>
                        <td className="item-col text-start">
                            {item.username}
                        </td>
                        <td className="item-col text-start">
                            {item.method}
                        </td>
                        {props.logType === LogType.CRUD && (
                        <td className="item-col text-start">
                            {item.contentType}
                        </td>
                        )}
                        {props.logType === LogType.CRUD && (
                        <td className="item-col text-start">
                            {item.contentId}
                        </td>
                        )}
                        {props.logType === LogType.REQUEST && (
                        <td className="item-col text-start">
                            {item.url}
                        </td>
                        )}
                    </tr>
                ))}
                </tbody>
            </Table>
            )}
            {!props.hidePagination && (
                <TablePagination page={props.page} loadPage={props.loadPage}/>
            )}
        </>
    )
}
