import {SchoolMealItem} from "../../models/types";
import {useTranslation} from "react-i18next";
import {Modal} from "@wfp/ui";
import {SchoolMealItemCard} from "./card";

interface Props {
    schoolMealItem: SchoolMealItem;
    onOk?: () => void;
    onCancel: () => void;
}

export const HandoverConfirmation = (props: Props) => {

    const {t} = useTranslation();

    return (
        <div>
            <Modal
                open={true}
                primaryButtonText={t("handover") as string}
                onRequestSubmit={props.onOk}
                secondaryButtonText={t("cancel") as string}
                onSecondarySubmit={props.onCancel}
                onRequestClose={props.onCancel}
            >
                <div className="mx-10 p-0">
                    <SchoolMealItemCard readonly={true} hideState={true} item={props.schoolMealItem}/>
                </div>
            </Modal>
        </div>
    );
};
