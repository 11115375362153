import {Snack} from "../../models/types";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

interface Props {
    item: Snack;
    onClick?: () => void
}

export const SnackCard = (props: Props) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    function handleClick(): void {
        if (!!props.onClick ) {
            props.onClick();
        } else if(!!props.item) {
            navigate(`/snacks/${props.item.id}`);
        }
    }

    return (
        <div className="flex items-start flex-col" onClick={handleClick}>
            <div>
                {t('id')}: {props.item.id}
            </div>
            <h2 className="text-gray-800 text-3xl font-semibold my-3">
                {props.item.name}
            </h2>
            <div className="my-1">
                {t('category')}: {props.item.categoryName}
            </div>
        </div>
    );
};
