import {useState} from "react";
import {useTranslation} from "react-i18next";
import {SingleDatePicker} from "react-dates";
import moment from 'moment'
import {Moment} from "moment/moment";
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import {Button, Form, Module, ModuleBody, Select, SelectItem} from "@wfp/ui";
import {KVPair, OrderState, Role} from "../../models/types";
import {useLoggedUser} from "../../hooks/user";
import {dateFormat} from "../../hooks/formats";
import {SMETSModuleHeader} from "../commons/module-header";


export function SchoolMealFilters(props: { onSubmit: (filters: KVPair[]) => void }) {
    const [visible, setVisible] = useState(false);
    const [selectedStateId, setSelectedStateId] = useState<number>(0);
    const [deliveryDateFilter, setDeliveryDateFilter] = useState<Moment | null>();
    const [focusDelivery, setFocusDelivery] = useState(false);
    const {t} = useTranslation();
    const {i18n} = useTranslation();
    const user = useLoggedUser({});
    const stateList = ["choose_one", ...Object.values(OrderState)];
    const stateListSupplierManager = ["choose_one", OrderState.LOADED, OrderState.CONFIRMED, OrderState.DONE];

    function handleToggleFilters() {
        setVisible(!visible);
    }

    function handleClearFilters() {
        setDeliveryDateFilter(null);
        setSelectedStateId(0);
    }


    function handleApplyFilters() {
        const filters: KVPair[] = [];
        if (selectedStateId > 0 && user?.role !== Role.SupplierManager) {
            filters.push({key: "state", value: stateList[selectedStateId]});
        }
        if (selectedStateId > 0 && user?.role === Role.SupplierManager) {
            filters.push({key: "state", value: stateListSupplierManager[selectedStateId]});
        }
        if (!!deliveryDateFilter) {
            filters.push({key: "deliveryDate", value: dateFormat(deliveryDateFilter) || ''});
        }
        props.onSubmit(filters);
    }

    function handleDeliveryDate(value: Moment | null) {
        setDeliveryDateFilter(value!);
    }

    return (
        <Module noMargin>
            <SMETSModuleHeader filter={<Button
                onClick={handleToggleFilters}
                kind="secondary">
                {visible ? t('hide_filters') : t('filters')}</Button>}/>
            {visible && (
                <ModuleBody>
                    <Form>
                        <div className="grid grid-cols-5 space-x-5 items-baseline text-start">
                            <Select
                                labelText={t('state')}
                                value={selectedStateId}
                                onChange={(e: any) => setSelectedStateId(e.target?.value)}>
                                {(user?.role === Role.SupplierManager ? stateListSupplierManager : stateList).map((state, idx) => (
                                    <SelectItem key={idx} value={idx} text={t(state)}/>
                                ))}
                            </Select>
                            <div>
                                <label htmlFor="deliveryDateFilter" className="wfp--label">
                                    {t("select_delivery_date_title")}
                                </label>
                                <SingleDatePicker
                                    isRTL={i18n.dir() === 'rtl'}
                                    renderMonthText={month => {
                                        month.locale(i18n.language) // TODO: check this!!
                                        return (moment(month).format('MMMM YYYY'))
                                    }}
                                    renderDayContents={day => {
                                        day.locale('en') // TODO: check this!!
                                        return (moment(day).format('D'))
                                    }}
                                    date={deliveryDateFilter!}
                                    isOutsideRange={() => false}
                                    onDateChange={handleDeliveryDate}
                                    focused={focusDelivery}
                                    onFocusChange={(f) => setFocusDelivery(f.focused)}
                                    numberOfMonths={1}
                                    showClearDate={true}
                                    displayFormat="DD/MM/YYYY"
                                    id="deliveryDateFilter"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mb-4">
                            <Button
                                type="button"
                                kind="ghost"
                                onClick={handleClearFilters}
                            >{t('clear_filters')}</Button>
                            <Button className={"ml-5"} type="button"
                                    onClick={handleApplyFilters}>{t('apply')}</Button>
                        </div>
                    </Form>
                </ModuleBody>
            )}
        </Module>
    )
}
