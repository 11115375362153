import {buildQueryString, restapi} from "./restapi";
import {ListPage, SnackCategory, KVPair} from "../models/types";


export function disableSnackCategory(id: number): Promise<SnackCategory> {
    return restapi.post<SnackCategory>(`/api/v1/smt_fab/snack-category/${id}/disable/`).then(res => res.data);
}

export function saveSnackCategory(data: SnackCategory): Promise<SnackCategory> {
    if (!data.id) {
        return restapi.post<SnackCategory>('/api/v1/smt_fab/snack-category/', data).then((res) => res.data);
    } else {
        return restapi.patch<SnackCategory>(`/api/v1/smt_fab/snack-category/${data.id}/`, data).then((res) => res.data);
    }
}

export function getSnackCategories(page= 1, page_size?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<SnackCategory>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(page_size || '')}];
     if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<SnackCategory>>(`/api/v1/smt_fab/snack-category/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrieveCategories(): Promise<SnackCategory[]> {
    return restapi.get<SnackCategory[]>("/api/v1/smt_fab/snack-category/?__pagination__=0").then(res => res.data);
}
