import {useTranslation} from "react-i18next";
import {ContentType, Order, Role} from "../../models/types";
import {dateFormat} from "../../hooks/formats";
import {useLoggedUser} from "../../hooks/user";
import {useMediaQuery} from "../../hooks/commons";

export interface SummaryProps {
    order: Order
    contentType: string;
}

export function OrderSummary(props: SummaryProps) {
    const {t} = useTranslation();
    const user = useLoggedUser({});
    const isSmallScreen = useMediaQuery("(max-width: 768px)");

    return (
        <div className={isSmallScreen ? 'space-y-2' : 'flex space-y-0 space-x-1'}>
            <div
                className={props.order.deliveryDate ? `bg-sky-100 p-2 rounded-full text-color-blue` : 'bg-red-10 p-2 rounded-full text-red-700'}>
                {t("delivery_date")}: {dateFormat(props.order?.deliveryDate)}
            </div>
            <div
                className={props.order.distributionDate ? 'bg-sky-100 p-2 rounded-full text-color-blue' : 'bg-red-10 p-2 rounded-full text-red-700'}>
                {t("distribution_date")}: {props.order?.distributionDate}
            </div>
            {user?.role !== Role.SupplierManager ?
                <div
                    className={props.order.supplierName ? 'bg-sky-100 p-2 rounded-full text-color-blue' : 'bg-red-100 p-2 rounded-full text-red-700'}>
                    {t("supplier")}: {props.order?.supplierName}
                </div>
                : <div
                    className='bg-sky-100 p-2 rounded-full text-color-blue'>
                    {t("order_id")}: {props.order.id}
                </div>
            }
            {props?.contentType !== ContentType.COMMODITIES && (
                <>
                    <div
                        className={props.order.totalQuantityAm ? 'bg-sky-100 p-2 rounded-full text-color-blue' : 'bg-red-100 p-2 rounded-full text-red-700'}>
                        {t('quantity_AM')}: {props.order?.totalQuantityAm}
                    </div>
                    <div
                        className={props.order.totalQuantityPm ? 'bg-sky-100 p-2 rounded-full text-color-blue' : 'bg-red-100 p-2 rounded-full text-red-700'}>
                        {t('quantity_PM')}: {props.order?.totalQuantityPm}
                    </div>
                    <div
                        className={props.order.totalQuantityAm || props.order?.totalQuantityPm ? 'bg-sky-100 p-2 rounded-full text-color-blue' : 'bg-red-100 p-2 rounded-full text-red-700'}>
                        {t('quantity')}: {props.order?.totalQuantityAm + props.order?.totalQuantityPm | 0}
                    </div>
                </>
            )}
        </div>
    )
}
