import {useTranslation} from "react-i18next";
import {Button} from "@wfp/ui";

export interface NavigationProps {
    next?: () => void,
    nextLabel?: string,
    nextDisabled?: boolean,
    previous?: () => void,
    previousLabel?: string,
    previousDisabled?: boolean
}

export function Navigationbar(props: NavigationProps) {
    const {t} = useTranslation();
    return (
        <div className="flex w-full">
            {!!props.previous && (
            <Button className="ml-6"
                    tabIndex={1}
                disabled={props.previousDisabled}
                onClick={props.previous}>
                {t(props.previousLabel!) || t("previous")}
            </Button>
            )}
            {!!props.next && (
            <Button className="ml-auto mr-7"
                    tabIndex={2}
                disabled={props.nextDisabled}
                onClick={props.next}>
                {t(props.nextLabel!) || t("next")}
            </Button>
            )}
        </div>
    )
}
