import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Blockquote, Select, SelectItem, } from "@wfp/ui";
import { School } from "../../../models/types";
import { WizardError } from "./wizard";
import { useMediaQuery } from "../../../hooks/commons";
import { useRetrieveSchools } from "../../../hooks/school";
import { LoadingCenter } from "../../commons/loading-center";
import "./survey.css";


interface IProps {
    selectedSchool: number
    onChange: (e: School) => void
    error?: WizardError
}

export function PageSurveySchools(props: IProps) {
    const { t } = useTranslation();
    const [selectedSchool, setSelectedSchool] = useState<number>(props.selectedSchool);
    const isSmallScreen = useMediaQuery("(max-width: 768px)");
    const schools = useRetrieveSchools({});

    function handleChangeSchool(e: any) {
        const school = schools?.find(s => s.id === Number(e.target.value)) || {} as School;
        setSelectedSchool(school.id);
        props.onChange(school as School);
    }

    return (
        <div className="mt-10">
            {!isSmallScreen && (
                <Blockquote className="blockquote-form">
                    <h4 className='mb-3'>{t("select_school_survey_label")}</h4>
                </Blockquote>
            )}
            <div className={`${isSmallScreen ? '' : 'mt-10'}`}>
                <div className={!!props.error?.surveySchool ? `mt-3 mb-5` : 'mt-3'}>
                    {!!schools ?
                        <Select
                            labelText={t('from_school')}
                            value={selectedSchool}
                            onChange={handleChangeSchool}>
                            {[{
                                id: 0,
                                name: t("choose_one")
                            }, ...(schools || [])].map((school, idx) => (
                                <SelectItem key={idx} id={school.id.toString()} value={school.id} text={school.name} />
                            ))}
                        </Select> : <LoadingCenter />
                    }
                </div>
            </div>

        </div>
    )
}
