import {useQuery} from "react-query";
import {KVPair, ListPage, School, ServerError} from "../models/types";
import {getSchool, getSchools, retrieveSchools, retrieveCentralSchools} from "../restapi/school";


export function useGetSchools({page = 1, pageSize, filters, sorting, onError}: {page?: number, pageSize?: number, filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): ListPage<School> | undefined {
    const schoolsQuery = useQuery(['schools', 'page'], () => getSchools(page, pageSize, filters, sorting), {onError: onError});
    return schoolsQuery.data;
}

export function useRetrieveSchool({id, onError}: {id: number, onError?: (e: ServerError) => void}): School | undefined {
    const schoolQuery = useQuery(['school'], () => getSchool(id), {
        onError: onError,
        retry: false
    });
    return schoolQuery.data;
}

export function useRetrieveCentralSchools({filters, sorting, onError}: {filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): School[] | undefined {
    const schoolQuery = useQuery(['schools', 'central'], () => retrieveCentralSchools(filters, sorting), {onError: onError});
    return schoolQuery.data;
}

export function useRetrieveSchools({filters, sorting, onError}: {filters?: KVPair[], sorting?: string[], onError?: (e: ServerError) => void}): School[] | undefined {
    const schoolQuery = useQuery(['schools'], () => retrieveSchools(filters, sorting), {onError: onError});
    return schoolQuery.data;
}
