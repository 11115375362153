import React, {useEffect, useState} from "react";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {Breadcrumb, BreadcrumbHome, BreadcrumbItem, FormWizard, Modal} from "@wfp/ui";
import i18n from "../../../i18n";
import {Order, OrderItem, School, ServerError, Snack, SnackCategory, Wizard} from "../../../models/types";
import {completeWizard, deleteWizard, saveOrderWizard} from "../../../restapi/wizard";
import {useRetrieveSchools} from "../../../hooks/school";
import {useRetrieveOrderWizard} from "../../../hooks/wizard";
import {useRetrieveSnackCategories} from "../../../hooks/snack-category";
import {LoadingCenter} from "../../commons/loading-center";
import {Headerbar} from "./headerbar";
import {Sidebar} from "./sidebar";
import {Navigationbar} from "./navigationbar"
import {PageDelivery} from "./page-delivery"
import {PageSchools} from "./page-schools";
import {PageSnacks} from "./page-snacks";
import {PageSummary} from "./page-summary";

interface WizardState {
    disabledSchoolIds: number[],
    error: string | null,
    order: Order,
    orderItems: OrderItem[],
    page: number,
    schools: School[],
    snacks: Snack[],
}

export interface WizardError extends ServerError {
    detail: string[] | undefined,
    supplier: string[] | undefined,
    deliveryDate: string[] | undefined,
    distributionDate: string[] | undefined,
    snacks: string[][] | undefined,
}

interface WizardPage {
    header: string,
    label: string,
    title: string,
    nextLabel?: string,
    previousLabel?: string,
    component?: (p: any) => React.ReactNode
}

export const orderWizardPages: WizardPage[] = [
    {
        header: 'select_schools_header_page',
        title: 'select_schools_students_title_page',
        label: 'select_schools_label_page',
    },
    {
        header: 'select_snacks_header_page',
        title: 'select_snacks_title_page',
        label: 'select_snacks_label_page'
    },
    {
        header: 'select_supplier_delivery_date_header',
        title: 'select_deliveries_details_title',
        label: 'select_define_delivery_label',
    },
    {
        header: 'summary',
        title: 'confirm_order_page_title',
        label: 'summary',
        nextLabel: 'confirm',
    }
];

export function OrderWizard() {
    const {id} = useParams();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [confirming, setConfirming] = useState<boolean>();
    const [message, setMessage] = useState<string>();
    const [page, setPage] = useState<number>();
    const [error, setError] = useState<WizardError>();
    const [stateModified, setStateModified] = useState<boolean>(false);
    const [wizard, setWizard] = useState<Wizard>();
    const initialWizard = useRetrieveOrderWizard({id: Number(id), onError: (e) => setError(e as WizardError)});
    const schoolList = useRetrieveSchools({onError: (e) => setError(e as WizardError)});
    const snackCategoryList = useRetrieveSnackCategories({});

    useEffect(() => {
        if (!!initialWizard) {
            if (!initialWizard.id) {
                const value = {data: {page: 0}, title: 'order'} as Wizard;
                storeWizard(value).then((res) => doSetPage(res.data.page));
            } else {
                setWizard(initialWizard);
                doSetPage(initialWizard.data?.page || 0);
            }
        }
    }, [initialWizard]);

    useEffect(() => {
        if (!!snackCategoryList && !!wizard && !wizard.data?.snacks?.length) {
            const data = {
                ...wizard.data,
                snacks: snackCategoryList.map((sc: SnackCategory) => ({id: 0, category: sc.id}) as Snack)
            };
            setWizard({...wizard, data: data});
            setStateModified(true);
        }
    }, [snackCategoryList, wizard])

    useEffect(() => {
        if (!!schoolList && !!wizard && !wizard.data?.schools) {
            const order = wizard.data.order || {} as Order;
            order.totalQuantityAm = schoolList.map((s: School) => s.numberOfStudentsAm || 0).reduce((p: number, v: number) => p + v, 0);
            order.totalQuantityPm = schoolList.map((s: School) => s.numberOfStudentsPm || 0).reduce((p: number, v: number) => p + v, 0);
            const data = {...wizard.data, schools: schoolList, order: order};
            setWizard({...wizard, data: data});
            setStateModified(true);
        }
    }, [schoolList, wizard]);

    function storeWizardState(data: WizardState): Promise<WizardState> {
        const w = {...wizard, data: data} as Wizard;
        return storeWizard(w).then((res) => {
            setStateModified(false);
            return Promise.resolve(res.data as WizardState);
        });
    }

    function storeWizard(w: Wizard): Promise<Wizard> {
        if (!w) {
            return Promise.reject({errors: ["wizard is null"]} as ServerError);
        }
        return saveOrderWizard(w).then((res) => {
            setWizard(res);
            return Promise.resolve(res);
        });
    }

    function handleChangeSchools(schools: School[]): void {
        if (wizard) {
            wizard.data.schools = schools;
            if (!doCheckStateError({...wizard.data, page: 0})) {
                setError(undefined);
                if (!!wizard.data.schools) {
                    if (!wizard.data.order) {
                        wizard.data.order = {} as Order;
                    }
                    wizard.data.order.totalQuantityAm = wizard.data.schools.map((s: School) => s.numberOfStudentsAm || 0).reduce((p: number, v: number) => p + v, 0);
                    wizard.data.order.totalQuantityPm = wizard.data.schools.map((s: School) => s.numberOfStudentsPm || 0).reduce((p: number, v: number) => p + v, 0);
                }
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeDisabledSchoolIds(disabledSchoolIds: number[]): void {
        if (wizard) {
            wizard.data.disabledSchoolIds = disabledSchoolIds;
            if (!doCheckStateError({...wizard.data, page: 0})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeSnacks(snacks: Snack[]): void {
        if (wizard) {
            wizard.data.snacks = snacks;
            const hasPreviousError = !!error;
            const newError = doCheckStateError({...wizard.data, page: 1});
            if (hasPreviousError) {
                setError(newError); // update|remove the error displayed
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleChangeOrder(order: Order): void {
        if (wizard) {
            wizard.data.order = {...order} as Order;
            if (!doCheckStateError({...wizard.data, page: 2})) {
                setError(undefined);
            }
            setStateModified(true);
            setWizard(wizard);
        }
    }

    function handleExitWizard() {
        setMessage('confirm_exit?');
    }

    function handlePreviousPage() {
        if (page !== undefined) {
            handleNavigateToPage(page - 1)
        }
    }

    function handleNextPage() {
        if (page !== undefined) {
            handleNavigateToPage(page + 1)
        }
    }

    function handleNavigateToPage(to: number): void {
        if (to === -1) {
            return handleExitWizard();
        }
        if (to < (page || 0)) {
            return doChangePage(to);
        }
        doValidatePage(page !== undefined ? page : wizard?.data?.page || 0).then((allow) => {
            if (allow) {
                if (to === orderWizardPages.length) {
                    return handleConfirmWizard();
                } else {
                    doChangePage(to);
                }
            }
        }).catch(error => setError(error));
    }

    function handleModalConfirmation(flag: boolean) {
        if (!flag) {
            return setMessage(undefined);
        }
        if (!!wizard) {
            if (!!wizard.id) {
                return deleteWizard(wizard.id).then(() => navigate('/orders'));
            }
            return navigate('/orders');
        }
    }

    function handleConfirmWizard() {
        if(!!wizard) {
            setConfirming(true)
            completeWizard(wizard.id).then(
                (w) => navigate(`/orders/${w.data.order.id}`)
            ).catch(
                (error) => setError(error)
            ).finally(() => setConfirming(false));
        }
    }

    function doChangePage(p: number) {
        if (stateModified) {
            storeWizardState({...wizard?.data, page: p}).then((w) => doSetPage(w.page));
        } else {
            doSetPage(p);
        }
    }

    function doSetPage(p: number) {
        setError(undefined);
        setPage(p);
    }

    function doCheckStateError(data: WizardState): WizardError | undefined {
        let isValid: boolean;
        const page = data.page;
        let error: WizardError;
        switch (page) {
            case 0:
                isValid = (data.schools || []).filter((s: School) => !(data.disabledSchoolIds || []).includes(s.id)).length !== 0;
                error = {
                    errors: isValid ? undefined : ["select_at_least_one_school"]
                } as WizardError;
                break
            case 1:
                const snacks = (data.snacks || []).filter((s: Snack) => !!s.id);
                isValid = snacks.length === 2;
                error = {
                    errors: isValid ? undefined : [snacks.length > 2 ? "select_max_snack_items" : "select_all_the_snack_items"]
                } as WizardError;
                const idSet = new Set();
                snacks.forEach((s, idx) => {
                    if (idSet.has(s.id)) {
                        if (!error.snacks) {
                            error.snacks = new Array<string[]>(snacks.length);
                        }
                        error.snacks[idx] = ['snack_already_selected'];
                    }
                    idSet.add(s.id);
                });
                isValid &&= !!error ? Object.values(error).every(f => !f) : true;
                break
            case 2:
                error = {
                    supplier: !data.order?.supplier ? ["this_field_is_required"] : undefined,
                    deliveryDate: !data.order?.deliveryDate ? ["this_field_is_required"] : undefined,
                    distributionDate: !data.order?.distributionDate ? ["this_field_is_required"] : undefined,
                } as WizardError;
                if (moment(data.order?.distributionDate).isBefore(moment(data.order?.deliveryDate))) {
                    error.distributionDate = ["invalid_value"];
                }
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break;
            case 3:
                error = {
                    ...doCheckStateError({...data, page: 2}),
                    ...doCheckStateError({...data, page: 1}),
                    ...doCheckStateError({...data, page: 0})
                } as WizardError;
                isValid = !!error ? Object.values(error).every(f => !f) : true;
                break;
            default:
                isValid = false;
                error = {errors: ["invalid_page"]} as WizardError;
                break;
        }
        return isValid ? undefined : error;
    }

    function doValidatePage(page: number): Promise<boolean> {
        if (!!wizard) {
            const error = doCheckStateError({...wizard.data, page: page});
            setError(error);
            return Promise.resolve(!error);
        }
        return Promise.resolve(false);
    }

    return (
        <div className='m-10'>
            {!wizard && (
                <LoadingCenter/>
            )}
            {!!wizard && (
                <>
                    <div className="hidden md:block mt-10 mb-10">
                        <Breadcrumb className="mb-5">
                            <BreadcrumbItem className={`${i18n.dir() === 'ltr' ? 'mr-0' : '!mr-10'}`}>
                                <NavLink to="/"><BreadcrumbHome/></NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <NavLink to="/orders">{t('order')}</NavLink>
                            </BreadcrumbItem>
                            <BreadcrumbItem disableLink>{t('create_order')}</BreadcrumbItem>
                        </Breadcrumb>
                    </div>
                    <h1 className={`text-start mt-10 mb-10 ${i18n.dir() === 'ltr' ? 'mr-0' : 'mr-10'}`}>
                        {t(orderWizardPages[page || 0].title)}
                    </h1>
                    <FormWizard className="mr-3"
                                stickySidebar={false}
                                sidebar={page !== undefined ?
                                    <Sidebar onChange={handleNavigateToPage} selectedPage={page!}/> : null}
                                formHeader={page !== undefined ?
                                    <Headerbar page={page!} message={!!message ? t(message)! : undefined} errors={!!error?.errors ? [t(error?.errors.toString())!] : !!error?.detail ? [t(error?.detail.toString())!] : undefined}/> : null}//TODO fix i18n
                                formControls={page !== undefined ? <Navigationbar
                                    previous={handlePreviousPage}
                                    previousDisabled={confirming}
                                    previousLabel={page === 0 ? t("exit")! : orderWizardPages[page!].previousLabel}
                                    next={handleNextPage}
                                    nextDisabled={!!error || confirming}
                                    nextLabel={orderWizardPages[page!].nextLabel}
                                /> : null}
                    >
                        {page === 0 && !schoolList && (
                            <LoadingCenter />
                        )}
                        {page === 0 && !!schoolList && (
                            <PageSchools
                                schools={wizard?.data.schools || []}
                                disabledSchoolIds={wizard?.data.disabledSchoolIds || []}
                                onChange={handleChangeSchools}
                                onChangeDisabled={handleChangeDisabledSchoolIds}
                            />
                        )}
                        {page === 1 && !snackCategoryList && (
                            <LoadingCenter />
                        )}
                        {page === 1 && !!snackCategoryList && (
                            <PageSnacks
                                error={error}
                                snackCategories={snackCategoryList}
                                selectedSnacks={wizard?.data?.snacks}
                                onChange={handleChangeSnacks}
                            />
                        )}
                        {page === 2 && (
                            <PageDelivery
                                order={wizard?.data.order || {}}
                                error={error}
                                onChange={handleChangeOrder}
                            />
                        )}
                        {page === 3 && confirming && (
                            <LoadingCenter />
                        )}
                        {page === 3 && (
                            <PageSummary
                                error={error}
                                schools={(wizard.data?.schools || []).filter((s: School) => !(wizard.data?.disabledSchoolIds || []).includes(s.id))}
                                snacks={wizard.data?.snacks || []}
                                order={wizard.data?.order}
                            />
                        )}
                    </FormWizard>
                    {!!message && (
                        <div>
                            <Modal
                                hideClose={false}
                                onRequestSubmit={() => handleModalConfirmation(true)}
                                onRequestClose={() => handleModalConfirmation(false)}
                                onSecondarySubmit={() => handleModalConfirmation(false)}
                                open={!!message}
                                primaryButtonText={t("confirm") as string}
                                secondaryButtonText={t("cancel") as string}
                                type='info'
                            >{t(message)}</Modal>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}
