import {SupplierList} from "../components/supplier/list";
import {useEffect, useState} from "react";
import {useTranslation} from 'react-i18next';
import {NavLink} from "react-router-dom";
import {Blockquote, Breadcrumb, BreadcrumbHome, BreadcrumbItem, Button, Modal, Text} from "@wfp/ui";
import {iconAddGlyph, iconDeleteGlyph} from '@wfp/icons';
import {ListPage, Partner, Supplier} from "../models/types";
import {SupplierError, validateSupplier} from "../models/validate-supplier";
import {deleteSupplier, getSuppliers, saveSupplier} from "../restapi/supplier";
import {useGetSuppliers} from "../hooks/supplier";
import {LoadingCenter} from "../components/commons/loading-center";
import {SupplierForm} from '../components/supplier/form';
import i18n from "../i18n";


export default function Suppliers() {
    const {t} = useTranslation();
    const [error, setError] = useState<SupplierError>();
    const [supplier, setSupplier] = useState<Supplier>();
    const [page, setPage] = useState<ListPage<Partner>>();
    const [openCreate, setOpenCreate] = useState(false);
    const [openDisable, setOpenDisable] = useState(false);
    const [selected, setSelected] = useState<Supplier>();
    const data = useGetSuppliers({onError: (e) => setError(e as SupplierError)});

    useEffect(() => {
        if (!!data) {
            setPage(data);
        }
    }, [data]);

    function doReloadPage() {
        getSuppliers(page?.pageNumber, page?.pageSize)
            .then(res => setPage(res))
            .catch(e => setError(e));
    }

    function handleCreateOpen() {
        setOpenCreate(true);
        setSupplier(undefined);
    }

    function handleCreateCancel(): void {
        setOpenCreate(false);
        setSupplier(undefined);
        setError(undefined);
    }

    function handleCreateSubmit(): void {
        if (!!supplier) {
            validateSupplier(supplier)
                .then(value => saveSupplier(value)
                    .then(() => {
                        setError(undefined);
                        setSupplier(undefined);
                        setOpenCreate(false);
                        doReloadPage();
                    })
                    .catch(e => setError(e)))
                .catch(e => setError(e));
        }
    }

    function handleDeleteClose() {
        setOpenDisable(false);
        setError(undefined);
    }

    function handleDeleteSubmit() {
        if (!!selected) {
            deleteSupplier(selected.id)
                .then(() => {
                    setError(undefined);
                    setOpenDisable(false);
                    doReloadPage();
                })
                .catch(error => setError(error));
        }
    }

    function handleSelected(id: number): void {
        const selected = (page?.results || []).find(item => item.id === id);
        setSelected(selected);
    }

    return (
        <>
            {!!error?.detail && (
                <Blockquote kind="error" withIcon>
                    {error.detail.toString()}
                </Blockquote>
            )}
            {!error?.detail && !page && (
                <LoadingCenter/>
            )}
            {!!page && (
                <>
                    <div className="visible md:hidden">
                        <SupplierList page={page} mobile={true}/>
                    </div>
                    <div className="hidden md:block">
                        <div className="m-10">
                            <Breadcrumb>
                                <BreadcrumbItem>
                                    <NavLink to="/"><BreadcrumbHome/></NavLink>
                                </BreadcrumbItem>
                                <BreadcrumbItem disableLink>
                                    {t('supplier')}
                                </BreadcrumbItem>
                            </Breadcrumb>
                            <div className="flex mt-10 mb-5 mx-0">
                                <div className="ml-0">
                                    <Text kind='h1'>{t("supplier_overview")}</Text>
                                </div>
                                <div className={`mr-0 ${i18n.dir() === 'ltr' ? 'ml-auto' : 'mr-auto'}`}>
                                    <Button className='!inline-flex' disabled={!selected}
                                            onClick={() => setOpenDisable(true)}
                                            icon={iconDeleteGlyph}
                                            iconReverse>
                                        {t('delete')}
                                    </Button>
                                </div>
                                <div className={`${i18n.dir() === 'ltr' ? 'ml-3' : 'mr-3'}`}>
                                    <Button onClickCapture={handleCreateOpen} iconDescription="create"
                                            className='!inline-flex' icon={iconAddGlyph} iconReverse>
                                        {t('create_supplier')}
                                    </Button>
                                </div>
                            </div>
                            <SupplierList selectCheck={selected?.id} onSelect={handleSelected} page={page} mobile={false}/>
                        </div>
                    </div>
                </>
            )}
            {openCreate && (
                <Modal
                    open={openCreate}
                    modalHeading={t('add_supplier')!}
                    modalLabel={t('create_supplier')!}
                    primaryButtonText={t('create')!}
                    primaryButtonDisabled={!supplier}
                    onRequestSubmit={handleCreateSubmit}
                    onRequestClose={handleCreateCancel}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleCreateCancel}
                >
                    <SupplierForm supplier={supplier || {} as Supplier} error={error} onChange={setSupplier}/>
                    {!!error?.detail && (
                        <Blockquote
                            kind="error"
                            withIcon>
                            {error.detail}
                        </Blockquote>
                    )}
                </Modal>
            )}
            {openDisable && !!selected && (
                <Modal
                    open={openDisable}
                    modalHeading={t('delete_supplier')!}
                    modalLabel={t('delete_supplier')!}
                    primaryButtonText={t('confirm')!}
                    onRequestSubmit={handleDeleteSubmit}
                    onRequestClose={handleDeleteClose}
                    secondaryButtonText={t('cancel')!}
                    onSecondarySubmit={handleDeleteClose}
                >
                    <p>{`${t('delete_supplier_confirm')} ${selected.name}`}</p>
                    {!!error?.detail && (
                        <Blockquote kind="error" withIcon>{error.detail}</Blockquote>
                    )}
                </Modal>
            )}
        </>
    );
}


