import {KVPair, ListPage, Partner} from "../models/types";
import {buildQueryString, restapi} from "./restapi";


export function disablePartner(id: number): Promise<Partner> {
    return restapi.post<Partner>(`/api/v1/smt_core/partner/${id}/disable/`).then(res => res.data);
}

export function getPartner(id: number): Promise<Partner> {
    return restapi.get<Partner>(`/api/v1/smt_core/partner/${id}/`).then(res => res.data);
}

export function getPartners(page= 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<Partner>> {
    const pageOptions = [{key: 'page', value: String(page)}, {key: 'page_size', value: String(pageSize || '')}];
    if (!!sorting) {
        pageOptions.push({key: '__sort__', value: sorting.toString()});
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<Partner>>(`/api/v1/smt_core/partner/?${qs}`).then(res => ({...res.data, filtering: filters, sorting: sorting}));
}

export function retrievePartners(): Promise<Partner[]> {
    return restapi.get<Partner[]>('/api/v1/smt_core/partner/?__pagination__=0').then(res => res.data);
}

export function savePartner(data: Partner): Promise<Partner> {
    if (!data.id) {
        return restapi.post<Partner>('/api/v1/smt_core/partner/', data).then(res => res.data);
    } else {
        return restapi.patch<Partner>(`/api/v1/smt_core/partner/${data.id}/`, data).then(res => res.data);
    }
}
