import {Location, Navigate, Outlet, useLocation} from "react-router-dom";

export function locationRoleChecker(role: string | null, loc: Location, entries: RouteGuardEntry[]) {
    if (!!role) {
        const pathRoot = loc.pathname.split('/').slice(0, 2).join('/');
        const entry = entries.find(c => c.routing === pathRoot || new RegExp(`^${c.routing}.*`).test(pathRoot));
        if (!!entry) {
            return (entry.roles?.at(0) === '*' || entry.roles.includes(role)) && !entry.blocked?.includes(role);
        }
    }
    return false;
}

export interface RouteGuardEntry {
    label?: string;
    routing: string;
    roles: string[];
    blocked?: string[];
}

interface RouteGuardProps {
    authorize: (location: Location) => boolean;
    fallback?: string;
}
export const RouteGuard = (props: RouteGuardProps) => {
    const location = useLocation();
    const auth = props.authorize(location);
    return auth ? <Outlet /> : <Navigate to={props.fallback || "/"} />;
}
