import { KVPair, ListPage, TransferOrder, TransferOrderHistoryData } from "../models/types";
import { buildQueryString, restapi } from "./restapi";


export function getTransferOrder(id: number): Promise<TransferOrder> {
    return restapi.get<TransferOrder>(`/api/v1/smt_transfer/transfer/${id}/`).then((res) => res.data);
}

export function getTransferOrders(page = 1, pageSize?: number, filters?: KVPair[], sorting?: string[]): Promise<ListPage<TransferOrder>> {
    const pageOptions = [{ key: 'page', value: String(page) }, { key: 'page_size', value: String(pageSize || '') }];
    if (!!sorting) {
        pageOptions.push({ key: '__sort__', value: sorting.toString() });
    }
    const qs = buildQueryString(!!filters ? filters.concat(pageOptions) : pageOptions);
    return restapi.get<ListPage<TransferOrder>>(`/api/v1/smt_transfer/transfer/?${qs}`,).then(res => ({ ...res.data, filtering: filters, sorting: sorting }));
}

export function confirmTransferOrder(id: number): Promise<TransferOrder> {
    return restapi.post<TransferOrder>(`/api/v1/smt_transfer/transfer/${id}/confirm/`).then((res) => res.data);
}

export function verifyTransferOrderQRCode(code: string): Promise<number> {
    return restapi.post<{ pk: number }>(`/api/v1/smt_transfer/transfer/verify_code/`, { code }).then(res => Promise.resolve(res.data?.pk));
}

export function pickupTransferOrder(id: number): Promise<TransferOrder> {
    return restapi.post<TransferOrder>(`/api/v1/smt_transfer/transfer/${id}/pickup/`).then((res) => res.data);
}

export function handoverTransferOrder(id: number): Promise<TransferOrder> {
    return restapi.post<TransferOrder>(`/api/v1/smt_transfer/transfer/${id}/handover/`).then((res) => res.data);
}

export function acceptTransferOrder(id: number): Promise<TransferOrder> {
    return restapi.post<TransferOrder>(`/api/v1/smt_transfer/transfer/${id}/accept/`).then((res) => res.data);
}

export function getTransferOrderHistory(id: number): Promise<TransferOrderHistoryData> {
    return restapi.get<TransferOrderHistoryData>(`/api/v1/smt_transfer/transfer/${id}/history`).then((res) => res.data);
}



