import * as React from 'react';
import {NumberInput} from "@wfp/ui";

/**
 * Extension to manage inconsistencies in onChange utilisation in JS implementation */
export type NumberInputValueProps = NumberInput.NumberInputProps & {
    onChangeValue?: (value: number) => void;
};

export class NumberInputValue extends React.Component<NumberInputValueProps, {}> {
    render() {
        const {onChangeValue, ...numberInputProps} = this.props;
        if (!!onChangeValue) {
            numberInputProps.onChange = (e: any) => {
                const value = Number(e);
                if (isNaN(value)) {
                    if (e.target?.value) {
                        onChangeValue(parseInt(e.target.value));
                    }
                } else {
                    onChangeValue(value);
                }
            }
        }
        return <NumberInput {...numberInputProps}></NumberInput>
    }
}
