import {Role, RolesWithEntity, ServerError, UserDetails} from "./types";


export interface UserError extends ServerError {
    email: string[] | undefined;
    entity: string[] | undefined;
    firstName: string[] | undefined;
    lastName: string[] | undefined;
    role: string[] | undefined;
    username: string[] | undefined;
}

class UserErrorInfo implements UserError {
    detail: string[] | undefined;
    email: string[] | undefined;
    entity: string[] | undefined;
    firstName: string[] | undefined;
    lastName: string[] | undefined;
    errors: string[] | undefined;
    role: string[] | undefined;
    username: string[] | undefined;

    add(field: string, error: string): void {
        (this as any)[field] = [...((this as any)[field] || []), error];
    }
}

export function validateUser(user: UserDetails): Promise<UserDetails> {
    const error = new UserErrorInfo();
    if (!user.email) {
        error.add('email', 'this_field_is_required');
    }
    if (!user.firstName) {
         error.add('firstName', 'this_field_is_required');
    }
    if (!user.lastName) {
        error.add('lastName', 'this_field_is_required');
    }
    if (!Object.values(Role).includes(user.role)) {
        error.add('role', 'this_field_is_required');
    }
    if (!user.username) {
        error.add('username', 'this_field_is_required');
    }
    if (RolesWithEntity.includes(user.role) && !(user.entity && user.entity > 0)) {
        error.add('entity', 'this_field_is_required');
    }
    const isValid = Object.values(error).every(f => !f);
    return isValid ? Promise.resolve(user) : Promise.reject(error);
}
