import {Order, OrderState} from "./types";

export interface OrderError {
    errors: [string] | undefined,
    partner: [string] | undefined,
    supplier: [string] | undefined,
    deliveryDate: [string] | undefined,
    distributionDate: [string] | undefined
}

/**
 * REQUESTED -> REQUESTED
 * CONFIRMED -> SUPPLIER ACCEPTED ORDER
 * LOADED -> LOADED (if it's a possibility when not all order items are loaded to mark it as PARTIALLY LOADED)
 * DONE -> DONE
 * DISCARDED -> ORDER CANCELED
 * OVERDUE -> OVERDUE
 */
export function orderStateParse(order: Order): string {
    switch(order.state) {
        case OrderState.LOADED:
            return order.mixedState ? 'order_LOADED_PARTIALLY' : 'order_LOADED'
        default:
            return `order_${order.state}`;
    }
}
