import * as React from "react";
import {TextInput} from "@wfp/ui";
import {InputModes} from "@wfp/ui/src/types/utils";

/**
 * Defined extension to add 'password' to InputModes. */
// @ts-ignore
export interface SMETSTextInputProps extends TextInput.TextInputProps {
    type: 'password' | InputModes
}

export class SMETSTextInput extends React.Component<SMETSTextInputProps, {}> {
    render() {
        return <TextInput {...this.props as TextInput.TextInputProps}></TextInput>
    }
}
